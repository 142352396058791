import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const CheckoutSteps = ({ step1, step2, step3, step4, step5 }) => {
  return (
    <Nav className='justify-content-center mb4 steps-container'>
      <Nav.Item>
        {step1 ? (
          <LinkContainer to='/cart'>
            <Nav.Link className='step-enabled'>
              <b>Kosár</b>
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link className='step-disabled'>
            <b>Kosár</b>
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step2 ? (
          <LinkContainer to='/shipping'>
            <Nav.Link className='step-enabled'>
              <b>Szállítás</b>
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className='step-disabled'>
            Szállítás
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step3 ? (
          <LinkContainer to='/billing'>
            <Nav.Link className='step-enabled'>
              <b>Számlázás</b>
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className='step-disabled'>
            Számlázás
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step4 ? (
          <LinkContainer to='/payment'>
            <Nav.Link className='step-enabled'>
              <b>Fizetési mód</b>
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className='step-disabled'>
            Fizetési mód
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step5 ? (
          <LinkContainer to='/placeorder'>
            <Nav.Link className='step-enabled'>
              <b>Megrendelés</b>
            </Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className='step-disabled'>
            Megrendelés
          </Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  )
}

export default CheckoutSteps
