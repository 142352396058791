import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const Header = () => {
  const cart = useSelector((state) => state.cart)
  const [expanded, setExpanded] = useState(false)

  const handleLinkClick = () => {
    setExpanded(false)
  }

  return (
    <header>
      <Navbar
        bg='dark'
        variant='dark'
        expand='md'
        className='fixed-top navbar'
        expanded={expanded} n b
        onToggle={() => setExpanded(expanded ? false : true)}
      >
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img
                src='/images/green.webp'
                className='navbar-image d-inline-block align-center'
                alt='Logo'
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar' />
          <Navbar.Collapse id='basic-navbar' onSelect={handleLinkClick}>
            <Nav className='ml-auto align-items-center'>
              <NavLink
                className='nav-link nav-link-dreamcat'
                activeClassName='nav-link-active'
                to='/dreamcatwall'
                onClick={handleLinkClick}
              >
                <span>
                  <i className='fas fa-heart pr-1'></i>
                </span>
                Cicád álomfala
              </NavLink>
              <NavLink
                className='nav-link'
                activeClassName='nav-link-active'
                to='/webshop'
                onClick={handleLinkClick}
              >
                Webshop
              </NavLink>
              <NavLink
                className='nav-link cursor-pointer'
                activeClassName='nav-link-active'
                to='/offers'
                onClick={handleLinkClick}
              >
                Egyedi faltervezés
              </NavLink>
              <NavLink
                className='nav-link'
                activeClassName='nav-link-active'
                to='/gallery'
                onClick={handleLinkClick}
              >
                Képek
              </NavLink>
              <NavLink
                className='nav-link'
                activeClassName='nav-link-active'
                to='/contact'
                onClick={handleLinkClick}
              >
                Kapcsolat
              </NavLink>
              <NavLink
                className='nav-link'
                activeClassName='nav-link-active'
                to='/blog'
                onClick={handleLinkClick}
              >
                Blog
              </NavLink>
              <NavDropdown
                className=''
                title='Információk'
                id='nav-dropdown'
                onSelect={handleLinkClick}
              >
                <LinkContainer to='/partners'>
                  <NavDropdown.Item className='navbar-dropdown-link'>
                    Partnerek
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/install'>
                  <NavDropdown.Item className='navbar-dropdown-link'>
                    Szerelés
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/materials'>
                  <NavDropdown.Item className='navbar-dropdown-link'>
                    Anyagok
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              {/* <Nav.Item>
                <NavLink
                  className='nav-link'
                  activeClassName='nav-link-active'
                  to='/cart'
                  onClick={handleLinkClick}
                >
                  <span>
                    <i className='fas fa-shopping-cart pr-2'></i>
                  </span>
                  Kosár
                  {cart && cart.cartItems && cart.cartItems.length > 0 && (
                    <> ({cart.cartItems.length})</>
                  )}
                </NavLink>
              </Nav.Item> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header