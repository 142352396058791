import React from 'react'
import { Circle, Text } from 'react-konva'
import '../../../WallDesigner.css'
import { useSelector } from 'react-redux'

export default function Order(props) {
  const wall = useSelector((state) => state.wall)
  var data = wall.itemsData.filter(function (wallItem) {
    return wallItem.type === props.type
  })[0]
  let itemData = {
    ...props,
    x: props.pos.x,
    y: props.pos.y,
    gridStepX: wall.canvas.gridStep.x,
    gridStepY: wall.canvas.gridStep.y,
  }
  return (
    <>
      {wall.orderVisible && (props.type === '10K' || props.type === '10KL') && (
        <>
          <Circle
            radius={33}
            x={itemData.x - 50}
            y={itemData.y - 50}
            fill='#333'
          />
          <Circle
            radius={30}
            x={itemData.x - 50}
            y={itemData.y - 50}
            fill='#fdfcfc'
          />
          <Text
            x={itemData.x - 80}
            y={itemData.y - 80}
            fontSize={30}
            width={60}
            height={60}
            text={itemData.orderId}
            align='center'
            verticalAlign='middle'
            fontFamily='Calibri'
          />
        </>
      )}
    </>
  )
}
