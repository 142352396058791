import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import GoogleLogin from 'react-google-login'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  const responseGoogle = (response) => {}

  return (
    <div className='page-container'>
      <Container className='header-margin py-3 main'>
        <FormContainer className='py-3'>
          <h1>Bejelentkezés</h1>
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
          {/* <GoogleLogin
            clientId='799784249709-kvqqtc0e703tude53rjilsrg276h298a.apps.googleusercontent.com'
            render={(renderProps) => (
              <Button
                variant='outline-primary rounded'
                className='btn-block my-4'
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <i className='fab fa-google fa-2x pr-3'></i>
                <font>Google fiókkal</font>
              </Button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
          <p>
            <Button
              variant='outline-primary rounded'
              className='btn-block my-3'
            >
              <i className='fab fa-facebook fa-2x pr-3'></i>
              Facebook fiókkal
            </Button>
          </p> */}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='email'>
              <Form.Label>Email cím</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Label>Jelszó</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type='submit' variant='primary' className='rounded-pill'>
              Bejelentkezés
            </Button>
          </Form>
          <Row className='py-3'>
            <Col>
              Új vásárló vagy?{' '}
              <Link
                to={redirect ? `/register?redirect${redirect}` : '/register'}
              >
                Regisztráció
              </Link>
            </Col>
          </Row>
        </FormContainer>
      </Container>
    </div>
  )
}

export default LoginScreen
