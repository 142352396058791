import React, { useState, useEffect } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import Message from '../components/Message'
import Loader from '../components/Loader'
import CustomizeItem from '../components/CustomizeItem'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
  sendMailOrderDetails,
  addOrderToGoogleSheet,
} from '../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from '../constants/orderConstants'

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id

  const [sdkReady, setSdkReady] = useState(false)
  const [mailSent, setMailSent] = useState(false)

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  useEffect(() => {
    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get('/api/config/paypal')
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=HUF&locale=hu_HU`
      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (successPay || !order || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch(getOrderDetails(orderId))
    } else if (!order.isPaid) {
      addPayPalScript()
    } else {
      setSdkReady(true)
    }

    if (order !== undefined && order.paymentMethod === 'Utánvét' && !mailSent) {
      order._id = match.params.id
      setMailSent(true)
      dispatch(addOrderToGoogleSheet(order))
      dispatch(sendMailOrderDetails(order))
    }
    if (
      order !== undefined &&
      order.paymentMethod === 'PayPal' &&
      order.isPaid &&
      !mailSent
    ) {
      setMailSent(true)
      dispatch(addOrderToGoogleSheet(order))
      dispatch(sendMailOrderDetails(order))
    }
  }, [dispatch, order, orderId, successDeliver, successPay])

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult))
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }

  return (
    <>
      <div className='page-container'>
        <Container className='header-margin py-3'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <>
              <h1>Rendelés {order._id}</h1>
              {order.paymentMethod === 'Utánvét' ? (
                <>
                  <Row className='align-items-center'>
                    <Col md={12}>
                      <ListGroup variant='flush'>
                        <ListGroup.Item>
                          <p>Köszönjük a megrendelésed!</p>
                          <p>
                            Utánvéttel kérted a fizetést, úgyhogy egyéb teendőd
                            nincsen.
                          </p>
                          <p>
                            További részletekkel a megadott email címen fogunk
                            keresni.
                          </p>
                          <p>
                            Kiszállítási idő általában 4-8 hét, de ha korábban
                            megtörténne a feladás, akkor a megadott email címen
                            értesítünk.
                          </p>
                          <p>Megrendelésed részleteit alább láthatod:</p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <h2>Rendelési adatok</h2>
                          <p>
                            <strong>Név: </strong>
                            {order.shippingAddress.name}
                          </p>
                          <p>
                            <strong>Email: </strong>
                            {order.shippingAddress.email}
                          </p>
                          <p>
                            <strong>Telefonszám: </strong>
                            {order.shippingAddress.phone}
                          </p>
                          <p>
                            <strong>Cím: </strong>
                            {order.shippingAddress.address},
                            {order.shippingAddress.city},
                            {order.shippingAddress.postalCode},
                            {order.shippingAddress.country},
                          </p>
                          <p>
                            <strong>Fizetési mód: </strong>
                            {order.paymentMethod} (díja 1 490 Ft)
                          </p>
                          <p>
                            <strong>Teljes összeg: </strong>
                            {Intl.NumberFormat('hu-HU', {
                              style: 'currency',
                              currency: 'HUF',
                              maximumFractionDigits: 0,
                            }).format(order.totalPrice + 1490)}
                          </p>
                          <p>
                            <strong>Megrendelt termékek: </strong>
                          </p>
                          {order.orderItems.length === 0 ? (
                            <Message>Nincs megrendelt termék</Message>
                          ) : (
                            <ListGroup variant='flush'>
                              {order.orderItems.map((item, index) => (
                                < ListGroup.Item key={index} >
                                  <Row>
                                    <Col md={3}>
                                      <Image
                                        src={item.image}
                                        alt={item.name}
                                        fluid
                                        rounded
                                      />
                                    </Col>
                                    <Col md={9}>
                                      <Row>
                                        <Col md={6}>
                                          <Link to={`/product/${item.product}`}>
                                            {item.name}
                                          </Link>
                                        </Col>
                                        <Col md={6}>
                                          {item.qty} x
                                          {Intl.NumberFormat('hu-HU', {
                                            style: 'currency',
                                            currency: 'HUF',
                                            maximumFractionDigits: 0,
                                          }).format(item.price)}
                                          {'  '}=
                                          {Intl.NumberFormat('hu-HU', {
                                            style: 'currency',
                                            currency: 'HUF',
                                            maximumFractionDigits: 0,
                                          }).format(item.qty * item.price)}{' '}
                                        </Col>
                                      </Row>
                                      <Row className='my-2'>
                                        <Col md={6}>
                                          <CustomizeItem customize={item.customize} />
                                        </Col>
                                      </Row>
                                    </Col>

                                  </Row>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          )}
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>

                </>
              ) : order.isPaid ? (
                <Row className='align-items-center'>
                  <Col md={12}>
                    <ListGroup variant='flush'>
                      <ListGroup.Item>
                        <p>Köszönjük a megrendelésed!</p>
                        <p>
                          <b>Megrendelésed fizetve lett.</b>
                        </p>
                        <p>
                          További részletekkel a megadott email címen fogunk
                          keresni.
                        </p>
                        <p>Megrendelésed részleteit alább láthatod:</p>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <h2>Rendelési adatok</h2>
                        <p>
                          <strong>Név: </strong>
                          {order.shippingAddress.name}
                        </p>
                        <p>
                          <strong>Email: </strong>
                          {order.shippingAddress.email}
                        </p>
                        <p>
                          <strong>Telefonszám: </strong>
                          {order.shippingAddress.phone}
                        </p>
                        <p>
                          <strong>Cím: </strong>
                          {order.shippingAddress.address},
                          {order.shippingAddress.city},
                          {order.shippingAddress.postalCode},
                          {order.shippingAddress.country},
                        </p>
                        <p>
                          <strong>Fizetési mód: </strong>
                          {order.paymentMethod}
                        </p>
                        <p>
                          <strong>Teljes összeg: </strong>
                          {Intl.NumberFormat('hu-HU', {
                            style: 'currency',
                            currency: 'HUF',
                            maximumFractionDigits: 0,
                          }).format(order.totalPrice)}
                        </p>
                        <p>
                          <strong>Megrendelt termékek: </strong>
                        </p>
                        {order.orderItems.length === 0 ? (
                          <Message>Nincs megrendelt termék</Message>
                        ) : (
                          <ListGroup variant='flush'>
                            {order.orderItems.map((item, index) => (
                              < ListGroup.Item key={index} >
                                <Row>
                                  <Col md={3}>
                                    <Image
                                      src={item.image}
                                      alt={item.name}
                                      fluid
                                      rounded
                                    />
                                  </Col>
                                  <Col md={9}>
                                    <Row>
                                      <Col md={6}>
                                        <Link to={`/product/${item.product}`}>
                                          {item.name}
                                        </Link>
                                      </Col>
                                      <Col md={6}>
                                        {item.qty} x
                                        {Intl.NumberFormat('hu-HU', {
                                          style: 'currency',
                                          currency: 'HUF',
                                          maximumFractionDigits: 0,
                                        }).format(item.price)}
                                        {'  '}=
                                        {Intl.NumberFormat('hu-HU', {
                                          style: 'currency',
                                          currency: 'HUF',
                                          maximumFractionDigits: 0,
                                        }).format(item.qty * item.price)}{' '}
                                      </Col>
                                    </Row>
                                    <Row className='my-2'>
                                      <Col md={6}>
                                        <CustomizeItem customize={item.customize} />
                                      </Col>
                                    </Row>
                                  </Col>

                                </Row>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col md={8}>
                      <>
                        <ListGroup variant='flush'>
                          <ListGroup.Item>
                            <p>
                              Megrendelésed akkor válik teljessé, ha a PayPalos
                              vagy bankkártyás fizetés megtörténik.
                            </p>
                          </ListGroup.Item>
                        </ListGroup>
                        <Card>
                          <ListGroup variant='flush'>
                            <ListGroup.Item>
                              <h2>fizetés</h2>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <Row>
                                <Col>Fizetendő összeg:</Col>
                                <Col>
                                  {Intl.NumberFormat('hu-HU', {
                                    style: 'currency',
                                    currency: 'HUF',
                                    maximumFractionDigits: 0,
                                  }).format(order.totalPrice)}
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            {!order.isPaid && (
                              <ListGroup.Item>
                                {loadingPay && <Loader />}
                                {!sdkReady ? (
                                  <Loader />
                                ) : (
                                  <PayPalButton
                                    amount={order.totalPrice.toFixed(0)}
                                    onSuccess={successPaymentHandler}
                                    shippingPreference='NO_SHIPPING'
                                    currency='HUF'
                                  />
                                )}
                              </ListGroup.Item>
                            )}
                          </ListGroup>
                        </Card>
                      </>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container >
      </div >
    </>
  )
}

export default OrderScreen
