import React, { useState } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import styled from 'styled-components';

const FormWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  max-width: 100%;
  margin: 0 auto;
`;

const FormControl = styled(Form.Control)`
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  background-color: transparent;
  &:focus {
    box-shadow: none;
    border-bottom-color: #000;
  }
`;

const SubmitButton = styled(Button)`
  background-color: #000;
  border-color: #000;
  font-size: 1rem;
  padding: 0.8rem 2rem;
  &:hover {
    background-color: #333;
    border-color: #333;
  }
`;

const FormMessageControl = styled.textarea`
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  padding: 0.5rem 0.5rem;
  font-size: 0.8rem;
  background-color: transparent;
  width: 100%;
  resize: none;
  &:focus {
    box-shadow: none;
    border-bottom-color: #000;
  }
`;

const ContactForm = ({ submitButtonText }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      setError('Kérlek töltsd ki az összes mezőt.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    attachments.forEach((file) => {
      formData.append('attachments', file);
    });

    try {
      await axios.post('/api/send-email', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setSuccess(true);
      setError('');
      // Reset form fields
      setName('');
      setEmail('');
      setMessage('');
      setAttachments([]);
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Hiba történt az üzenet küldésekor, kérlek próbáld meg újra.');
      setSuccess(false);
    }
  };

  const handleAttachmentChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length <= 3) {
      setAttachments(files);
    } else {
      setError('Maximum 3 képet küldhetsz el.');
    }
  };
  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name">
          <Form.Label style={{ color: '#000' }}>Név</Form.Label>
          <FormControl
            type="text"
            placeholder="Add meg a neved"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label style={{ color: '#000' }}>Email</Form.Label>
          <FormControl
            type="email"
            placeholder="Add meg az email címed"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="message">
          <Form.Label style={{ color: '#000' }}>Üzenet</Form.Label>
          <FormMessageControl
            placeholder="Add meg a falad méreteit, hány cicád van, milyen elemek a kedvenceid. Bármit, ami segíthet nekünk a tervezésben."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            rows={4}
            minRows={4}
          />
        </Form.Group>
        <Form.Group controlId="attachments">
          <Form.Label style={{ color: '#000' }}>
            Képek a faladról (max 3 kép)
          </Form.Label>
          <FormControl
            type="file"
            accept="image/*"
            multiple
            onChange={handleAttachmentChange}
          />
          {error && <Alert variant="danger">{error}</Alert>}
        </Form.Group>

        <Button type="submit"
          className='green-button rounded-pill offers ' variant='success' size=''>
          {submitButtonText}

        </Button>
        {success && (
          <Alert variant="success" className="mt-3">
            Köszönjük az üzeneted, amint tudunk, jelentkezünk!
          </Alert>
        )}
      </Form>
    </FormWrapper>
  )
}
export default ContactForm