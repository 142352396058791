import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Product from './Product'
import ProductSolo from './ProductSolo'
import Message from './Message'
import Loader from './Loader'
import { Container, Card, Button, Row, Col, Image } from 'react-bootstrap'
import { listProducts } from '../actions/productActions'

const WebshopPart = ({ match }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const [complex, setComplex] = useState(true)

  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  useEffect(() => {
    dispatch(listProducts(keyword))
  }, [dispatch, keyword])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container className='text-center'>
          <Row>
            {products
              .filter((product) => product.category === 'part')
              .map((product) => (
                <Col key={product.id} xl={6}>
                  <ProductSolo product={product} />
                </Col>
              ))}
          </Row>
        </Container>
      )}
    </>
  )
}

export default WebshopPart
