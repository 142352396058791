import React from 'react'
import './ItemMenu.css'
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import plant from '../../../images/WD/plant.png'
import mount from '../../../images/WD/mount.png'
import feeder from '../../../images/WD/feeder.png'
import shelf from '../../../images/WD/shelf.png'
import fabric from '../../../images/WD/fabric.png'
import pole from '../../../images/WD/pole.png'
import bridge from '../../../images/WD/bridge.png'
import step from '../../../images/WD/step.png'
import shelf_step from '../../../images/WD/shelf_step.png'
import shelf_right from '../../../images/WD/shelf_right.png'
import shelf_left from '../../../images/WD/shelf_left.png'
import pillow from '../../../images/WD/pillow.png'
import cat from '../../../images/WD/cat.png'
import { faMousePointer } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { SELECT_WALLITEM_TOOL as ITEM_SELECTOR_CLICKED } from '../../../actions/WD/wallActions'

function ItemMenu() {
  const dispatch = useDispatch()
  const selectedItem = useSelector((state) => state.wall.selectedItem)

  const wallItemSelected = (itemName) => {
    dispatch(ITEM_SELECTOR_CLICKED(itemName))
    isButtonActive([])
  }

  const isButtonActive = (itemNames) => {
    let isAnyItemActive = false
    itemNames.forEach((itemName) => {
      if (selectedItem === itemName) {
        isAnyItemActive = true
      }
    })
    return isAnyItemActive ? 'button-active' : ''
  }

  return (
    <>
      <div className='canvasItemPicker'>
        {/* <Dropdown as={ButtonGroup} className='valign-top'>
          <button
            variant='secondary'
            id='pointer'
            className={
              isButtonActive(['pointer']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('pointer')}
          >
            <FontAwesomeIcon
              className='pointer-icon'
              icon={faMousePointer}
              size='lg'
            />
            <br />
            KIJELÖL <sub>0</sub>
          </button>
        </Dropdown> */}

        <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['10VW']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('10VW')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className={`fabric-button-img`}
                  src={fabric}
                  alt='wallitem'
                />
              </div>
            </div>
            VÁSZON <sub>1</sub>
          </button>
        </Dropdown>
        {/* <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            onClick={() => wallItemSelected('10VB')}
            className={isButtonActive(['10VB', '10VW']) + ' item-selector-btn'}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className='fabric-button-img'
                  src={fabric}
                  alt='wallitem'
                />
              </div>
            </div>
            VÁSZON <sub>1</sub>
          </button>
          <Dropdown.Toggle
            className='item-selector-split-btn'
            split
            variant='dark'
          />
          <Dropdown.Menu>
            <Dropdown.Item
              className='dropdown-item'
              eventKey='1'
              onClick={() => wallItemSelected('10VW')}
            >
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Világos
            </Dropdown.Item>
            <Dropdown.Item
              eventKey='2'
              onClick={() => wallItemSelected('10VB')}
            >
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Sötét
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            onClick={() => wallItemSelected('10P40')}
            className={
              isButtonActive([
                '10P40',
                '10P40OJ',
                '10P40OB',
                '10P80',
                '10P80OJ',
                '10P80OB',
                '10PJ',
                '10PB',
              ]) + ' item-selector-btn'
            }
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className={`shelf-button-img`}
                  src={shelf}
                  alt='wallitem'
                />
              </div>
            </div>
            <font>
              POLC <sub>2</sub>
            </font>
          </button>
          <Dropdown.Toggle
            className='item-selector-split-btn '
            split
            variant='dark'
          />
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => wallItemSelected('10PL')}>
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={shelf_step}
                alt='wallitem'
              />
              Lépés
            </Dropdown.Item>
            <DropdownButton title='Szimpla' variant='secondary'>
              <Dropdown.Item onClick={() => wallItemSelected('10P40')}>
                <img
                  className={`shelf-button item-selector-dropdown-img`}
                  src={shelf}
                  alt='wallitem'
                />
                Luk nélkül
              </Dropdown.Item>
              <Dropdown.Item onClick={() => wallItemSelected('10P40OB')}>
                <img
                  className={`shelf-button item-selector-dropdown-img`}
                  src={shelf_left}
                  alt='wallitem'
                />
                Bal lukas
              </Dropdown.Item>
              <Dropdown.Item onClick={() => wallItemSelected('10P40OJ')}>
                <img
                  className={`shelf-button item-selector-dropdown-img`}
                  src={shelf_right}
                  alt='wallitem'
                />
                Jobb lukas
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton title='Dupla' variant='secondary'>
              <Dropdown.Item onClick={() => wallItemSelected('10P80')}>
                <img
                  className={`shelf-button item-selector-dropdown-img`}
                  src={shelf}
                  alt='wallitem'
                />
                Luk nélkül
              </Dropdown.Item>
              <Dropdown.Item onClick={() => wallItemSelected('10P80OB')}>
                <img
                  className={`shelf-button item-selector-dropdown-img`}
                  src={shelf_left}
                  alt='wallitem'
                />
                Bal lukas
              </Dropdown.Item>
              <Dropdown.Item onClick={() => wallItemSelected('10P80OJ')}>
                <img
                  className={`shelf-button item-selector-dropdown-img`}
                  src={shelf_right}
                  alt='wallitem'
                />
                Jobb lukas
              </Dropdown.Item>
            </DropdownButton>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['10KO2']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('10KO2')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img className={`pole-button-img`} src={pole} alt='wallitem' />
              </div>
            </div>
            KAPARÓ <sub>3</sub>
          </button>
        </Dropdown>
        {/* <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            onClick={() => wallItemSelected('10KO2')}
            className={
              isButtonActive(['10KO2', '10KO3']) + ' item-selector-btn'
            }
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img className={`pole-button-img`} src={pole} alt='wallitem' />
              </div>
            </div>
            KAPARÓ <sub>3</sub>
          </button>
          <Dropdown.Toggle
            split
            className='item-selector-split-btn'
            variant='dark'
          />
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => wallItemSelected('10KO2')}>
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Dupla
            </Dropdown.Item>
            <Dropdown.Item onClick={() => wallItemSelected('10KO3')}>
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Tripla
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['10H1K']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('10H1K')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className={`bridge-button-img`}
                  src={bridge}
                  alt='wallitem'
                />
              </div>
            </div>
            HÍD <sub>4</sub>
          </button>
        </Dropdown>
        {/* <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            onClick={() => wallItemSelected('10H1K')}
            className={
              isButtonActive(['10H1K', '10H0K']) + ' item-selector-btn'
            }
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className={`bridge-button-img`}
                  src={bridge}
                  alt='wallitem'
                />
              </div>
            </div>
            HÍD <sub>4</sub>
          </button>
          <Dropdown.Toggle
            className='item-selector-split-btn'
            split
            variant='dark'
          />
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => wallItemSelected('10H1K')}>
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Korláttal
            </Dropdown.Item>
            <Dropdown.Item onClick={() => wallItemSelected('10H0K')}>
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Korlát nélkül
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['10PT']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('10PT')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className={`feeder-button-img`}
                  src={feeder}
                  alt='wallitem'
                />
              </div>
            </div>
            TÁL <sub>5</sub>
          </button>
        </Dropdown>
        <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['10PV']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('10PV')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className={`plant-button-img`}
                  src={plant}
                  alt='wallitem'
                />
              </div>
            </div>
            VIRÁG <sub>6</sub>
          </button>
        </Dropdown>

        <Dropdown as={ButtonGroup} className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['10KL']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('10KL')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img className={`step-button-img`} src={step} alt='wallitem' />
              </div>
            </div>
            LÉPÉS <sub>7</sub>
          </button>
        </Dropdown>
        {/* <Dropdown as={ButtonGroup} vertical className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['10PP40B', '10PP40V']) + ' item-selector-btn'
            }
            onClick={() => wallItemSelected('10PP40B')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img
                  className={`pillow-button-img`}
                  src={pillow}
                  alt='wallitem'
                />
              </div>
            </div>
            PÁRNA<sub>8</sub>
          </button>
          <Dropdown.Toggle
            className='item-selector-split-btn'
            split
            variant='dark'
          />
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => wallItemSelected('10PP40W')}>
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Világos
            </Dropdown.Item>
            <Dropdown.Item onClick={() => wallItemSelected('10PP40B')}>
              <img
                className={`mount-button item-selector-dropdown-img`}
                src={mount}
                alt='wallitem'
              />
              Sötét
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        {/* 
        <Dropdown as={ButtonGroup} className='valign-top'>
          <button
            variant='secondary'
            className={
              isButtonActive(['cat']) + ' item-selector-btn btn-rounded'
            }
            onClick={() => wallItemSelected('cat')}
          >
            <div className='item-button-div'>
              <div className='item-button-image-container'>
                <img className={`cat-button-img`} src={cat} alt='wallitem' />
              </div>
            </div>
            CICA <sub>9</sub>
          </button>
        </Dropdown> */}
      </div>
    </>
  )
}
export default ItemMenu
