import Top from './Top'
import Cart from './Cart'
import SizeInfo from './SizeInfo'
import ClearWall from './Controller/ClearWall'
import ViewSelector from './Controller/ViewSelector'
import ItemEditor from './Controller/ItemEditor'
import WallLoader from './Controller/WallLoader'
import WallSaver from './Controller/WallSaver'
import Resizer from './Resizer/Resizer'
import SaveToCart from './Controller/SaveToCart'

function Sidebar(props) {
  return (
    <>
      <div className='sidebar'>
        <Top />
        <div className='d-flex flex-row  '>
          <ClearWall />
          {/* <ViewSelector /> */}
          <WallLoader />
          {/* <WallSaver /> */}
        </div>
        <ItemEditor />
        <SizeInfo />
        <Cart />
        <div className='d-flex flex-row justify-content-between '>
          <SaveToCart />
        </div>
      </div>
      <Resizer />
    </>
  )
}

export default Sidebar
