import { WallManager } from './WallManager'
import { GridHelper } from './GridHelper'

export var ClusterHelper = {
  GetAllItemsInCluster(state, clusterId) {
    return state.items.filter((item) => item.clusterId === clusterId)
  },
  GetAllKonzolsInCluster(state, clusterId) {
    let allItemsInCluster = this.GetAllItemsInCluster(state, clusterId)

    return allItemsInCluster.filter((item) => item.type === '10K')
  },
  GetMaxClusterIdOnWall: function (state) {
    return state.items.reduce(
      (acc, item) => (acc = acc > item.clusterId ? acc : item.clusterId),
      0
    )
  },
  GetMaxOrderIdOnWall: function (state) {
    return state.items.reduce(
      (acc, item) => (acc = acc > item.orderId ? acc : item.orderId),
      0
    )
  },
  GetKonzolsToSplitToOtherCluster(state, deletedItem) {
    let clusterId = deletedItem.clusterId
    let firstKonzol = WallManager.GetKonzolInPos(state, deletedItem.pos)
    let secondKonzol = WallManager.GetKonzolInPos(
      state,
      WallManager.GetItemsSecondPos(state, deletedItem)
    )

    if (
      typeof firstKonzol === 'undefined' ||
      typeof secondKonzol === 'undefined'
    ) {
  
      return
    }
    let firstSplitKonzols = [firstKonzol]
    let secondSplitKonzols = [secondKonzol]

    let remainingClusterKonzols = this.GetAllKonzolsInCluster(
      state,
      clusterId
    ).filter(
      (konzol) => konzol.id !== firstKonzol.id && konzol.id !== secondKonzol.id
    )
    let allClusterKonzols = [...remainingClusterKonzols]

    while (remainingClusterKonzols.length > 0) {
      allClusterKonzols.forEach((konzol) => {
        let connectedKonzols = this.GetConnectedKonzolsToKonzol(state, konzol)
        if (
          this.HaveKonzolsAnyMatch(state, firstSplitKonzols, connectedKonzols)
        ) {
          firstSplitKonzols.push(konzol)
          remainingClusterKonzols = remainingClusterKonzols.filter(
            (remain) => remain.id !== konzol.id
          )
        }
        if (
          this.HaveKonzolsAnyMatch(state, secondSplitKonzols, connectedKonzols)
        ) {
          secondSplitKonzols.push(konzol)
          remainingClusterKonzols = remainingClusterKonzols.filter(
            (remain) => remain.id !== konzol.id
          )
        }
      })
    }

    return secondSplitKonzols
  },
  UpdateItemsClusterIdsConnectedToKonzols(state, konzols, clusterId) {
    konzols.forEach((konzol) => {
      WallManager.GetItemsInPos(state, konzol.pos).map(
        (item) => (item.clusterId = clusterId)
      )
    })
  },
  HaveKonzolsAnyMatch(state, firstKonzols, secondKonzols) {
    let match = false
    let firstIds = firstKonzols.map((konzol) => {
      return konzol.id
    })
    let secondIds = secondKonzols.map((konzol) => {
      return konzol.id
    })
    firstIds.forEach((firstId) => {
      if (secondIds.includes(firstId)) match = true
    })
    return match
  },
  GetClusterIdInPos: function (state, pos) {
    let konzol = WallManager.GetKonzolInPos(state, pos)
    if (konzol !== undefined) {
      return konzol.clusterId
    } else {
      return -1
    }
  },
  GetConnectedKonzolsToKonzol(state, konzol) {
    let connectedItems = this.GetConnectedItemsToPos(state, konzol.pos)

    let connectedPoints = []

    connectedItems.forEach((item) => {
      if (WallManager.HasItemTwoPoints(state, item)) {
        connectedPoints.push(item.pos)
        connectedPoints.push(WallManager.GetItemsSecondPos(state, item))
      }
    })

    connectedPoints = connectedPoints.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.x === value.x && t.y === value.y)
    )

    let connectedKonzols = []

    connectedPoints.forEach((point) =>
      connectedKonzols.push(WallManager.GetKonzolInPos(state, point))
    )

    return connectedKonzols
  },
  GetConnectedItemsToPos(state, pos) {
    let connectedItems = state.items.filter(
      (item) => item.pos.x === pos.x && item.pos.y === pos.y
    )
    state.items.forEach((item) => {
      let itemData = WallManager.GetItemData(state, item)
      let itemEndPos = GridHelper.GetPointInDir(
        state,
        item.pos,
        itemData.dimension
      )
      if (itemEndPos.x === pos.x && itemEndPos.y === pos.y)
        connectedItems.push(item)
    })
    connectedItems = connectedItems.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    )

    return connectedItems
  },
  UpdateClusterOnWall: function (state, oldClusterId, newClusterId) {
    state.items.map((item) => {
      if (item.clusterId === oldClusterId) item.clusterId = newClusterId
    })
  },

  AddClusterIdsForItems: function (state, addedItems) {
    let nonKonzolItem = addedItems.filter((item) => item.type !== '10K')[0]

    if (typeof nonKonzolItem === 'undefined') return addedItems

    const maxClusterIdOnWall = this.GetMaxClusterIdOnWall(state) + 1

    let firstClusterId = this.GetClusterIdInPos(state, nonKonzolItem.pos)

    let secondClusterId

    if (WallManager.HasItemTwoPoints(state, nonKonzolItem)) {
      let secondPos = WallManager.GetItemsSecondPos(state, nonKonzolItem)
      secondClusterId = this.GetClusterIdInPos(state, secondPos)
    } else {
      if (typeof firstClusterId === 'undefined' || firstClusterId === -1) {
        addedItems.map((item) => {
          item.clusterId = maxClusterIdOnWall
        })
      } else {
        addedItems.map((item) => {
          item.clusterId = firstClusterId
        })
      }
    }

    // If TWOPOINT Both Undefined
    if (
      (typeof firstClusterId === 'undefined' || firstClusterId === -1) &&
      (typeof secondClusterId === 'undefined' || secondClusterId === -1)
    ) {
      addedItems.map((item) => {
        item.clusterId = maxClusterIdOnWall
      })
    } else if (firstClusterId !== secondClusterId) {
      // If TWOPOINT only First Undefined
      if (typeof firstClusterId === 'undefined' || firstClusterId === -1) {
        addedItems.map((item) => {
          item.clusterId = secondClusterId
        })
      }
      // If TWOPOINT only Second Undefined
      else if (
        typeof secondClusterId === 'undefined' ||
        secondClusterId === -1
      ) {
        addedItems.map((item) => {
          item.clusterId = firstClusterId
        })
      }
      // If TWOPOINT but different clusterId -> Merge
      else {
        addedItems.map((item) => {
          item.clusterId = firstClusterId
        })
        this.UpdateClusterOnWall(state, secondClusterId, firstClusterId)
      }
    }

    return addedItems
  },
  UpdateItemsAfterDelete: function (state, deletedItem) {
    // IF deletedItem has only one point -> NO SPLIT return []
    if (!WallManager.HasItemTwoPoints(state, deletedItem)) {

      return
    }
    // ELSE SPLIT
    else {
      let secondSplitKonzols = this.GetKonzolsToSplitToOtherCluster(
        state,
        deletedItem
      )
      if (
        typeof secondSplitKonzols !== 'undefined' &&
        secondSplitKonzols.length > 0
      ) {
        let newClusterId = this.GetMaxClusterIdOnWall(state) + 1
        this.UpdateItemsClusterIdsConnectedToKonzols(
          state,
          secondSplitKonzols,
          newClusterId
        )
      }
    }
  },
  GetEndKonzolsInCluster(state, clusterId) {
    let endKonzols = []
    let konzols = this.GetAllKonzolsInCluster(state, clusterId)
    if (typeof konzols === 'undefined' || konzols.length === 0) {
      let items = this.GetAllItemsInCluster(state, clusterId)
      if (
        typeof items !== 'undefined' &&
        items.length === 1 &&
        items[0].type === '10KL'
      ) {
        return items
      }
    }
    if (konzols.length === 1) {
      return konzols
    }
    konzols.forEach((konzol) => {
      let connectedKonzols = this.GetConnectedKonzolsToKonzol(state, konzol)

      if (connectedKonzols.length === 2) {
        endKonzols.push(konzol)
      }
    })
    return endKonzols
  },
  GetHighestPosInKonzols(state, konzols) {
    let highestPos = konzols[0].pos.y
    konzols.forEach((konzol) => {
      if (konzol.pos.y < highestPos) highestPos = konzol.pos.y
    })
    return highestPos
  },
  GetSortedClustersByHighestKonzolPos(state) {
    let clusterIds = state.items
      .map((item) => item.clusterId)
      .filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      )
    let sortedClusters = []
    clusterIds.forEach((clusterId) => {
      let items = this.GetAllItemsInCluster(state, clusterId)
      if (
        typeof items !== 'undefined' &&
        items.length === 1 &&
        items[0].type === '10KL'
      ) {
        sortedClusters.push({
          clusterId: clusterId,
          highestPos: items[0].pos.y,
        })
      } else {
        let konzols = this.GetAllKonzolsInCluster(state, clusterId)
        let highestPos = this.GetHighestPosInKonzols(state, konzols)
        sortedClusters.push({
          clusterId: clusterId,
          highestPos: highestPos,
        })
      }
    })
    sortedClusters = sortedClusters.sort((a, b) =>
      a.highestPos > b.highestPos ? 1 : -1
    )
    return sortedClusters.map((cluster) => (cluster = cluster.clusterId))
  },
  IsClusterStepOnly(state, endKonzols) {
    if (
      typeof endKonzols !== 'undefined' &&
      endKonzols.length === 1 &&
      endKonzols[0].type === '10KL'
    )
      return true
  },
  IsClusterSingleKonzol(state, endKonzols) {
    if (
      typeof endKonzols !== 'undefined' &&
      endKonzols.length === 1 &&
      endKonzols[0].type !== '10KL'
    )
      return true
  },
  GetAllItemsInClusterWithEqualOrHigherOrderId(state, clusterId, orderId) {
    let itemsConnectingToHigherOrderId = []
    let konzolsInCluster = this.GetAllKonzolsInCluster(state, clusterId)
    konzolsInCluster.forEach((konzol) => {
      if (konzol.orderId >= orderId) {
        let connectedItems = this.GetConnectedItemsToPos(state, konzol.pos)
        connectedItems.forEach((item) =>
          itemsConnectingToHigherOrderId.push(item)
        )
      }
    })
    itemsConnectingToHigherOrderId = itemsConnectingToHigherOrderId.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    )

    return itemsConnectingToHigherOrderId
  },
  GetAllItemsInClusterWithHigherOrderId(state, clusterId, orderId) {
    let itemsConnectingToHigherOrderId = []
    let konzolsInCluster = this.GetAllKonzolsInCluster(state, clusterId)
    konzolsInCluster.forEach((konzol) => {
      if (konzol.orderId > orderId) {
        let connectedItems = this.GetConnectedItemsToPos(state, konzol.pos)
        connectedItems.forEach((item) =>
          itemsConnectingToHigherOrderId.push(item)
        )
      }
    })
    itemsConnectingToHigherOrderId = itemsConnectingToHigherOrderId.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    )
    return itemsConnectingToHigherOrderId
  },
  UpdateOrderIds(state) {
    let konzolsToUpdate = []
    let stepsToUpdate = []
    let orderId = 1
    let clusterIds = this.GetSortedClustersByHighestKonzolPos(state)

    clusterIds.forEach((clusterId) => {
      let orderedKonzols = []
      let endKonzols = this.GetEndKonzolsInCluster(state, clusterId)
      if (this.IsClusterStepOnly(state, endKonzols)) {
        stepsToUpdate.push(endKonzols[0])
      } else if (this.IsClusterSingleKonzol(state, endKonzols)) {
        endKonzols.map((konzol) => (konzol.orderId = orderId++))
        orderedKonzols.push(endKonzols[0])
      } else {
        let firstKonzol
        if (endKonzols.length === 2) {
          firstKonzol =
            endKonzols[0].pos.y <= endKonzols[1].pos.y
              ? endKonzols[0]
              : endKonzols[1]
        }
        orderedKonzols.push(firstKonzol)

        let remainingClusterKonzols = this.GetAllKonzolsInCluster(
          state,
          clusterId
        ).filter((konzol) => konzol.id !== firstKonzol.id)

        while (remainingClusterKonzols.length > 0) {
          remainingClusterKonzols.forEach((konzol) => {
            let connectedKonzols = this.GetConnectedKonzolsToKonzol(
              state,
              konzol
            )
            if (
              this.HaveKonzolsAnyMatch(state, orderedKonzols, connectedKonzols)
            ) {
              orderedKonzols.push(konzol)
              remainingClusterKonzols = remainingClusterKonzols.filter(
                (remain) => remain.id !== konzol.id
              )
            }
          })
        }
        orderedKonzols.map((konzol) => (konzol.orderId = orderId++))
        konzolsToUpdate.concat(orderedKonzols)
      }
    })
    stepsToUpdate.forEach((step) => (step.orderId = orderId++))

    return konzolsToUpdate
  },
}
