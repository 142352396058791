import { ClusterHelper } from './ClusterHelper'
import { GridHelper } from './GridHelper'
import { WallManager } from './WallManager'

const shelvesLeft = ['10PVB', '10PTB', '10PLB', '10KO2B', '10KO3B']
const shelvesRight = ['10PVJ', '10PTJ', '10PLJ', '10KO2J', '10KO3J']

export var ConnectionHelper = {
  GetSlotsAndShiftsToUpdateAfterAdd: function (state, addedItems) {
    let addedFabric = addedItems.filter((item) => item.type.includes('10V'))[0]

    if (typeof addedFabric !== 'undefined') {
      if (addedFabric.type.includes('_V')) {
        return this.GetShelvesOnWallToUpdateSlotsAfterVerticalFabricAdd(
          state,
          addedFabric
        )
      } else
        return this.GetShelvesOnWallToUpdateSlotsAfterNotVerticalFabricAdd(
          state,
          addedFabric
        )
    } else if (this.IsAddShelf(state, addedItems)) {
      let shelf = this.GetShelf(state, addedItems)
      return this.GetShelvesOnWallToUpdateShiftsAfterShelfAdd(state, shelf)
    } else return []
  },
  SwitchVertical80FabricCrossing: function (state, addedItems) {
    let addedHorFabric = addedItems.filter(
      (item) => item.type.includes('10V') && !item.type.includes('_V')
    )[0]
    if (typeof addedHorFabric !== 'undefined') {
      this.SwitchVertical80FabricCrossingDueToFabric(state, addedHorFabric)
    } else if (this.IsAddShelf(state, addedItems)) {
      let shelf = this.GetShelf(state, addedItems)
      return this.SwitchVertical80FabricCrossingDueToShelf(state, shelf)
    } else return []
  },
  SwitchVertical80FabricCrossingDueToFabric: function (state, addedFabric) {
    let itemData = WallManager.GetItemData(state, addedFabric)
    let firstPos = addedFabric.pos
    let firstCrossing = WallManager.IsVertical80FabricCrossing(
      state,
      firstPos,
      'right'
    )

    if (firstCrossing.result) {
      ConnectionHelper.SwitchSideVerticalFabricAndUpdateSlots(
        state,
        firstCrossing.crossing
      )
    }
    if (itemData.dimension.x !== 0 || itemData.dimension.y !== 0) {
      let secondPos = GridHelper.GetPointInDir(
        state,
        firstPos,
        itemData.dimension
      )
      let secondCrossing = WallManager.IsVertical80FabricCrossing(
        state,
        secondPos,
        'left'
      )

      if (secondCrossing.result) {
        ConnectionHelper.SwitchSideVerticalFabricAndUpdateSlots(
          state,
          secondCrossing.crossing
        )
      }
    }
  },
  SwitchVertical80FabricCrossingDueToShelf: function (state, addedShelf) {
    let itemData = WallManager.GetItemData(state, addedShelf)
    let firstPos = addedShelf.pos
    let firstCrossing
    if (shelvesLeft.includes(addedShelf.type)) {
      firstCrossing = WallManager.IsVertical80FabricCrossing(
        state,
        firstPos,
        'left'
      )
    } else {
      firstCrossing = WallManager.IsVertical80FabricCrossing(
        state,
        firstPos,
        'right'
      )
    }

    if (firstCrossing.result) {
      ConnectionHelper.SwitchSideVerticalFabricAndUpdateSlots(
        state,
        firstCrossing.crossing
      )
    }
    if (itemData.dimension.x !== 0 || itemData.dimension.y !== 0) {
      let secondPos = GridHelper.GetPointInDir(
        state,
        firstPos,
        itemData.dimension
      )
      let secondCrossing = WallManager.IsVertical80FabricCrossing(
        state,
        secondPos,
        'left'
      )

      if (secondCrossing.result) {
        ConnectionHelper.SwitchSideVerticalFabricAndUpdateSlots(
          state,
          secondCrossing.crossing
        )
      }
    }
  },
  UpdateConnectionOnKonzol: function (state, pos, nonKonzolItem, itemList) {
    let konzolItemsToInsert = itemList.filter((item) => item.type === '10K')
    let konzol = WallManager.GetItemInPosInInputList(
      state,
      pos,
      '10K',
      konzolItemsToInsert
    )
    if (konzol !== undefined) {
      let konzolIndex = itemList.findIndex((obj) => obj.id === konzol.id)
      let connections = itemList[konzolIndex].connections
      if (!connections.includes(nonKonzolItem.id))
        connections.push(nonKonzolItem.id)
      itemList[konzolIndex].connections = connections
    }
    return itemList
  },
  UpdateConnectionsOnItemsToInsert: function (state, itemsToInsert) {
    let nonKonzolItemsToInsert = itemsToInsert.filter(
      (item) => item.type !== '10K'
    )
    nonKonzolItemsToInsert.forEach((nonKonzolItemToInsert) => {
      //First Pos
      itemsToInsert = this.UpdateConnectionOnKonzol(
        state,
        nonKonzolItemToInsert.pos,
        nonKonzolItemToInsert,
        itemsToInsert
      )
      //Second Pos
      let nonKonzolData = WallManager.GetItemData(state, nonKonzolItemToInsert)
      if (nonKonzolData.dimension.x !== 0 || nonKonzolData.dimension.y !== 0) {
        let secondPos = GridHelper.GetPointInDir(
          state,
          nonKonzolItemToInsert.pos,
          nonKonzolData.dimension
        )
        itemsToInsert = this.UpdateConnectionOnKonzol(
          state,
          secondPos,
          nonKonzolItemToInsert,
          itemsToInsert
        )
      }
    })
    return itemsToInsert
  },
  GetShelvesOnWallToUpdateShiftsAfterShelfAdd: function (state, addedShelf) {
    // shelf
    let shelfData = WallManager.GetItemData(state, addedShelf)
    let firstPos = addedShelf.pos
    let firstVerticalFabric = ClusterHelper.GetConnectedItemsToPos(
      state,
      firstPos
    ).filter((item) => item.type.includes('10V') && item.type.includes('_V'))[0]
    // if firstpos has vertical fabric
    if (typeof firstVerticalFabric !== 'undefined') {
      let firstRightSlot = this.GetSlotFromShelf(
        state,
        addedShelf,
        firstPos,
        'right'
      )
      let firstLeftSlot = this.GetSlotFromShelf(
        state,
        addedShelf,
        firstPos,
        'left'
      )
      if (firstVerticalFabric.type.includes('_VJ')) {
        if (typeof firstRightSlot !== 'undefined') {
          //Nothing to see here, take the slot and move on
          addedShelf = this.UpdateShelfSlot(
            state,
            addedShelf,
            firstRightSlot,
            false
          )
        } else {
          // Is fabric side switch possible -> then switch side
          if (
            this.IsVerticalFabricSwithcSideAvailableDueToHorizontalItem(
              state,
              firstVerticalFabric,
              addedShelf
            )
          ) {
            this.SwitchSideVerticalFabricAndUpdateSlots(
              state,
              firstVerticalFabric
            )
          }
          addedShelf = this.UpdateShelfSlot(
            state,
            addedShelf,
            firstLeftSlot,
            false
          )
        }
      } else if (firstVerticalFabric.type.includes('_VB')) {
        if (typeof firstLeftSlot !== 'undefined') {
          //Nothing to see here, take the slot and move on
          addedShelf = this.UpdateShelfSlot(
            state,
            addedShelf,
            firstLeftSlot,
            false
          )
        } else {
          // Is fabric side switch possible -> then shift
          if (
            this.IsVerticalFabricSwithcSideAvailableDueToHorizontalItem(
              state,
              firstVerticalFabric,
              addedShelf
            )
          ) {
            this.SwitchSideVerticalFabricAndUpdateSlots(
              state,
              firstVerticalFabric
            )
          }
          addedShelf = this.UpdateShelfSlot(
            state,
            addedShelf,
            firstRightSlot,
            false
          )
        }
      }
    }

    if (shelfData.dimension.x !== 0 || shelfData.dimension.y !== 0) {
      let secondPos = GridHelper.GetPointInDir(
        state,
        firstPos,
        shelfData.dimension
      )
      let secondVerticalFabric = ClusterHelper.GetConnectedItemsToPos(
        state,
        secondPos
      ).filter(
        (item) => item.type.includes('10V') && item.type.includes('_V')
      )[0]
      // if secondPos has vertical fabric
      if (typeof secondVerticalFabric !== 'undefined') {
        // check whether in wrong slot
        let secondRightSlot = this.GetSlotFromShelf(
          state,
          addedShelf,
          secondPos,
          'right'
        )
        let secondLeftSlot = this.GetSlotFromShelf(
          state,
          addedShelf,
          secondPos,
          'left'
        )
        if (secondVerticalFabric.type.includes('_VJ')) {
          if (typeof secondRightSlot !== 'undefined') {
            //Nothing to see here, take the slot and move on
            addedShelf = this.UpdateShelfSlot(
              state,
              addedShelf,
              secondRightSlot,
              false
            )
          } else {
            // Is fabric side switch possible -> then shift
            if (
              this.IsVerticalFabricSwithcSideAvailableDueToHorizontalItem(
                state,
                secondVerticalFabric,
                addedShelf
              )
            ) {
              this.SwitchSideVerticalFabricAndUpdateSlots(
                state,
                secondVerticalFabric
              )
            }
            addedShelf = this.UpdateShelfSlot(
              state,
              addedShelf,
              secondLeftSlot,
              false
            )
          }
        } else if (secondVerticalFabric.type.includes('_VB')) {
          if (typeof secondLeftSlot !== 'undefined') {
            //Nothing to see here, take the slot and move on
            addedShelf = this.UpdateShelfSlot(
              state,
              addedShelf,
              secondLeftSlot,
              false
            )
          } else {
            // Is fabric side switch possible -> then shift
            if (
              this.IsVerticalFabricSwithcSideAvailableDueToHorizontalItem(
                state,
                secondVerticalFabric,
                addedShelf
              )
            ) {
              this.SwitchSideVerticalFabricAndUpdateSlots(
                state,
                secondVerticalFabric
              )
            }
            addedShelf = this.UpdateShelfSlot(
              state,
              addedShelf,
              secondRightSlot,
              false
            )
          }
        }
      }
    }
    return [addedShelf]
  },
  GetShelvesOnWallToUpdateSlotsAfterNotVerticalFabricAdd: function (
    state,
    addedFabric
  ) {
    let shelvesOnWallToUpdate = []
    let data = this.GetShelvesWithSlots(state, addedFabric)

    // If left-right slots are free add fabric and move on
    if (
      this.IsSlotFree(state, data.firstRightSlot) &&
      this.IsSlotFree(state, data.secondLeftSlot)
    ) {
      data.firstShelf = this.UpdateShelfSlot(
        state,
        data.firstShelf,
        data.firstRightSlot,
        false
      )
      data.secondShelf = this.UpdateShelfSlot(
        state,
        data.secondShelf,
        data.secondLeftSlot,
        false
      )
    }
    // if first right slot is not free it must be a vertical fabric
    else if (!this.IsSlotFree(state, data.firstRightSlot)) {
      let verticalFabric = this.GetConnectedFabricsToTop(
        state,
        data.firstShelf
      ).filter((item) => item.id !== addedFabric.id)[0]

      if (
        this.IsVerticalFabricSwithcSideAvailableDueToHorizontalItem(
          state,
          verticalFabric,
          addedFabric
        )
      ) {
        this.SwitchSideVerticalFabricAndUpdateSlots(state, verticalFabric)
      }
      data.secondShelf = this.UpdateShelfSlot(
        state,
        data.secondShelf,
        data.secondLeftSlot,
        false
      )
      data.firstShelf = this.UpdateShelfAllSlotsFree(
        state,
        data.firstShelf,
        false
      )
    }
    // if second left slot is not free it must be a vertical fabric
    else if (!this.IsSlotFree(state, data.secondLeftSlot)) {
      let verticalFabric = this.GetConnectedFabricsToTop(
        state,
        data.secondShelf
      ).filter((item) => item.id !== addedFabric.id)[0]

      if (
        this.IsVerticalFabricSwithcSideAvailableDueToHorizontalItem(
          state,
          verticalFabric,
          addedFabric
        )
      ) {
        this.SwitchSideVerticalFabricAndUpdateSlots(state, verticalFabric)
      }
      data.firstShelf = this.UpdateShelfSlot(
        state,
        data.firstShelf,
        data.firstRightSlot,
        false
      )
      data.secondShelf = this.UpdateShelfAllSlotsFree(
        state,
        data.secondShelf,
        false
      )
    }

    // if there is no free slot in one of the pos -> pos not available
    shelvesOnWallToUpdate.push(state, data.firstShelf)
    shelvesOnWallToUpdate.push(state, data.secondShelf)
    return shelvesOnWallToUpdate
  },
  SwitchSideVerticalFabricAndUpdateSlots(state, verticalFabric) {
    let data = this.GetShelvesWithSlots(state, verticalFabric)

    if (verticalFabric.type.includes('_VJ')) {
      this.UpdateShelfSlot(state, data.firstShelf, data.firstRightSlot, true)
      this.UpdateShelfSlot(state, data.firstShelf, data.firstLeftSlot, false)
      this.UpdateShelfSlot(state, data.secondShelf, data.secondRightSlot, true)
      this.UpdateShelfSlot(state, data.secondShelf, data.secondLeftSlot, false)
    } else if (verticalFabric.type.includes('_VB')) {
      this.UpdateShelfSlot(state, data.firstShelf, data.firstLeftSlot, true)
      this.UpdateShelfSlot(state, data.firstShelf, data.firstRightSlot, false)
      this.UpdateShelfSlot(state, data.secondShelf, data.secondLeftSlot, true)
      this.UpdateShelfSlot(state, data.secondShelf, data.secondRightSlot, false)
    }
    this.SwitchVerticalFabricSide(state, verticalFabric)
  },
  SwitchVerticalFabricSide(state, verticalFabric) {
    let itemData = WallManager.GetItemData(state, verticalFabric)
    state.items.map((item) => {
      if (item.id === verticalFabric.id) {
        item.type = itemData.switch.side
      }
    })
  },

  IsVerticalFabricSwithcSideAvailableDueToHorizontalItem(
    state,
    verticalFabric,
    horizontalItem
  ) {
    let verticalFabricData = WallManager.GetItemData(state, verticalFabric)
    if (verticalFabric.pos.y === horizontalItem.pos.y) {
      let endItems = WallManager.GetItemsInPos(
        state,
        GridHelper.GetPointInDir(
          state,
          verticalFabric.pos,
          verticalFabricData.dimension
        )
      )
      let topInEndPos = endItems.filter((item) => item.type === '10T')[0]
      if (typeof topInEndPos === 'undefined') {
        return false
      } else {
        // Hor and Vert start in same pos
        if (verticalFabric.pos.x === horizontalItem.pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'left'
          )
        }
        // Vert starts at the end of Hor
        if (verticalFabric.pos.x !== horizontalItem.pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'right'
          )
        }
      }
    } else if (verticalFabric.pos.y !== horizontalItem.pos.y) {
      let endItems = WallManager.GetItemsInPos(state, verticalFabric.pos)
      let topInEndPos = endItems.filter((item) => item.type === '10T')[0]
      if (typeof topInEndPos === 'undefined') {
        return false
      } else {
        // Hor and Vert start in same line horizontally
        if (verticalFabric.pos.x === horizontalItem.pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'left'
          )
        }
        // Vert starts at line the end of the horizontal fabric
        if (verticalFabric.pos.x !== horizontalItem.pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'right'
          )
        }
      }
    }
  },
  IsVerticalFabricSwitchSideAvailable(state, verticalFabric) {
    let result = true
    let verticalFabricData = WallManager.GetItemData(state, verticalFabric)
    let endItems = WallManager.GetItemsInPos(
      state,
      GridHelper.GetPointInDir(
        state,
        verticalFabric.pos,
        verticalFabricData.dimension
      )
    )
    let topInEndPos = endItems.filter((item) => item.type === '10T')[0]
    if (typeof topInEndPos === 'undefined') {
      result = false
    } else {
      // Hor and Vert start in same pos
      if (
        verticalFabric.type.includes('_VJ') &&
        !this.IsOppositeEndSlotFreeForVerticalFabric(state, topInEndPos, 'left')
      ) {
        result = false
      }
      // Vert starts at the end of Hor
      else if (
        verticalFabric.type.includes('_VB') &&
        !this.IsOppositeEndSlotFreeForVerticalFabric(
          state,
          topInEndPos,
          'right'
        )
      ) {
        result = false
      }
    }
    let startItems = WallManager.GetItemsInPos(state, verticalFabric.pos)
    let topInStartPos = startItems.filter((item) => item.type === '10T')[0]
    if (typeof topInStartPos === 'undefined') {
      result = false
    } else {
      // Hor and Vert start in same line horizontally
      if (
        verticalFabric.type.includes('_VJ') &&
        !this.IsOppositeEndSlotFreeForVerticalFabric(
          state,
          topInStartPos,
          'left'
        )
      ) {
        result = false
      }
      // Vert starts at line the end of the horizontal fabric
      if (
        verticalFabric.type.includes('_VB') &&
        !this.IsOppositeEndSlotFreeForVerticalFabric(
          state,
          topInStartPos,
          'right'
        )
      ) {
        result = false
      }
    }
    return result
  },
  IsOppositeEndSlotFreeForVerticalFabric(state, endTop, direction) {
    let endTopSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      endTop,
      endTop.pos,
      direction
    )
    let isFree = ConnectionHelper.IsSlotFree(state, endTopSlot)
    return isFree
  },

  GetShelvesWithSlots(state, item) {
    let res = {
      firstShelf: {},
      secondShelf: {},
      firstRightSlot: {},
      firstLeftSlot: {},
      secondRightSlot: {},
      secondLeftSlot: {},
    }
    let itemData = WallManager.GetItemData(state, item)
    let firstPos = item.pos
    let secondPos = GridHelper.GetPointInDir(
      state,
      firstPos,
      itemData.dimension
    )
    res.firstShelf = WallManager.GetShelfWithSlotInPos(state, firstPos)
    res.secondShelf = WallManager.GetShelfWithSlotInPos(state, secondPos)

    res.firstRightSlot = this.GetSlotFromShelf(
      state,
      res.firstShelf,
      firstPos,
      'right'
    )
    res.firstLeftSlot = this.GetSlotFromShelf(
      state,
      res.firstShelf,
      firstPos,
      'left'
    )
    res.secondRightSlot = this.GetSlotFromShelf(
      state,
      res.secondShelf,
      secondPos,
      'right'
    )
    res.secondLeftSlot = this.GetSlotFromShelf(
      state,
      res.secondShelf,
      secondPos,
      'left'
    )
    return res
  },

  GetSlotFromShelf: function (state, shelf, slotPos, slotDirection) {
    if (typeof shelf !== 'undefined')
      return shelf.slots.filter((slot) => {
        if (
          slot.pos.x === slotPos.x &&
          slot.pos.y === slotPos.y &&
          slot.direction === slotDirection
        ) {
          return true
        }
      })[0]
  },

  IsSlotFree: function (state, slot) {
    return typeof slot !== 'undefined' && slot.free
  },
  UpdateShelfSlot: function (state, shelf, slotToUpdate, slotFree) {
    return shelf.slots.map((slot) => {
      if (
        typeof slotToUpdate !== 'undefined' &&
        slot.direction === slotToUpdate.direction &&
        slot.pos.x === slotToUpdate.pos.x &&
        slot.pos.y === slotToUpdate.pos.y
      ) {
        slot.free = slotFree
      }
      return slot
    })
  },

  UpdateShelfAllSlotsFree: function (state, shelf, slotFree) {
    return shelf.slots.map((slot) => {
      slot.free = slotFree
      return slot
    })
  },
  EnsureVerticalFabricSide(state, verticalFabric, side) {
    let baseType = verticalFabric.type.split('_')[0]
    if (side === 'right') {
      let newType = baseType + '_VJ'

      state.items.map((item) => {
        if (item.id === verticalFabric.id) {
          item.type = newType
        }
      })
    } else {
      let newType = baseType + '_VB'

      state.items.map((item) => {
        if (item.id === verticalFabric.id) {
          item.type = newType
        }
      })
    }
  },
  GetShelvesOnWallToUpdateSlotsAfterVerticalFabricAdd: function (
    state,
    addedFabric
  ) {
    let shelvesOnWallToUpdate = []
    let fabricData = WallManager.GetItemData(state, addedFabric)
    let firstPos = addedFabric.pos
    let secondPos = GridHelper.GetPointInDir(
      state,
      firstPos,
      fabricData.dimension
    )

    let firstShelf = WallManager.GetShelfWithSlotInPos(state, firstPos)
    if (typeof firstShelf === 'undefined') {
    }
    let secondShelf = WallManager.GetShelfWithSlotInPos(state, secondPos)
    if (typeof secondShelf === 'undefined') {
    }

    let firstRightSlot = this.GetSlotFromShelf(
      state,
      firstShelf,
      firstPos,
      'right'
    )
    let secondRightSlot = this.GetSlotFromShelf(
      state,
      secondShelf,
      secondPos,
      'right'
    )
    let firstLeftSlot = this.GetSlotFromShelf(
      state,
      firstShelf,
      firstPos,
      'left'
    )
    let secondLeftSlot = this.GetSlotFromShelf(
      state,
      secondShelf,
      secondPos,
      'left'
    )

    // Check free slots in first and second pos
    // if both right free -> occupy right slot
    if (
      this.IsSlotFree(state, firstRightSlot) &&
      this.IsSlotFree(state, secondRightSlot)
    ) {
      //Ensure vertical fabric is right
      this.EnsureVerticalFabricSide(state, addedFabric, 'right')
      firstShelf = this.UpdateShelfSlot(
        state,
        firstShelf,
        firstRightSlot,
        false
      )
      secondShelf = this.UpdateShelfSlot(
        state,
        secondShelf,
        secondRightSlot,
        false
      )
    }
    // if both left free -> occupy left slot
    else if (
      this.IsSlotFree(state, firstLeftSlot) &&
      this.IsSlotFree(state, secondLeftSlot)
    ) {
      this.EnsureVerticalFabricSide(state, addedFabric, 'left')
      firstShelf = this.UpdateShelfSlot(state, firstShelf, firstLeftSlot, false)
      secondShelf = this.UpdateShelfSlot(
        state,
        secondShelf,
        secondLeftSlot,
        false
      )
    }
    shelvesOnWallToUpdate.push(state, firstShelf)
    shelvesOnWallToUpdate.push(state, secondShelf)
    shelvesOnWallToUpdate.push(state, addedFabric)
    return shelvesOnWallToUpdate
  },
  GetSlotsAndShiftsToUpdateAfterAdd: function (state, addedItems) {
    let addedFabric = addedItems.filter((item) => item.type.includes('10V'))[0]

    if (typeof addedFabric !== 'undefined') {
      if (addedFabric.type.includes('_V')) {
        return this.GetShelvesOnWallToUpdateSlotsAfterVerticalFabricAdd(
          state,
          addedFabric
        )
      } else
        return this.GetShelvesOnWallToUpdateSlotsAfterNotVerticalFabricAdd(
          state,
          addedFabric
        )
    } else if (this.IsAddShelf(state, addedItems)) {
      let shelf = this.GetShelf(state, addedItems)
      return this.GetShelvesOnWallToUpdateShiftsAfterShelfAdd(state, shelf)
    } else return []
  },
  GetShelvesOnWallToUpdateSlotsAfterFabricDelete(state, removedFabric) {
    let fabricData = WallManager.GetItemData(state, removedFabric)
    return fabricData.type.includes('_V')
      ? this.GetShelvesOnWallToUpdateSlotsAfterVerticalFabricDelete(
          state,
          removedFabric
        )
      : this.GetShelvesOnWallToUpdateSlotsAfterHorizontalFabricDelete(
          state,
          removedFabric
        )
  },
  GetShelvesOnWallToUpdateSlotsAfterHorizontalFabricDelete(
    state,
    removedFabric
  ) {
    let fabricData = WallManager.GetItemData(state, removedFabric)
    let shelvesOnWallToUpdate = []
    //First Pos
    let firstSlotShelf = WallManager.GetShelfWithSlotInPos(
      state,
      removedFabric.pos
    )
    if (firstSlotShelf !== undefined) {
      firstSlotShelf.slots.map((slot) => {
        if (slot.direction === 'right') {
          slot.free = true
        }
        return slot
      })
    }
    //Second Pos
    let secondPos = GridHelper.GetPointInDir(
      state,
      removedFabric.pos,
      fabricData.dimension
    )
    let secondSlotShelf = WallManager.GetShelfWithSlotInPos(state, secondPos)
    if (secondSlotShelf !== undefined) {
      secondSlotShelf.slots.map((slot) => {
        if (slot.direction === 'left') {
          slot.free = true
        }
        return slot
      })
    }
    return shelvesOnWallToUpdate
  },
  GetShelvesOnWallToUpdateSlotsAfterVerticalFabricDelete(state, removedFabric) {
    let fabricData = WallManager.GetItemData(state, removedFabric)
    let shelvesOnWallToUpdate = []
    //First Pos
    let firstSlotShelf = WallManager.GetShelfWithSlotInPos(
      state,
      removedFabric.pos
    )
    if (firstSlotShelf !== undefined) {
      firstSlotShelf.slots.map((slot) => {
        if (slot.direction === 'right' && fabricData.type.includes('_VJ')) {
          slot.free = true
        }
        if (slot.direction === 'left' && fabricData.type.includes('_VB')) {
          slot.free = true
        }
        return slot
      })
    }
    //Second Pos
    let secondPos = GridHelper.GetPointInDir(
      state,
      removedFabric.pos,
      fabricData.dimension
    )
    let secondSlotShelf = WallManager.GetShelfWithSlotInPos(state, secondPos)
    if (secondSlotShelf !== undefined) {
      secondSlotShelf.slots.map((slot) => {
        if (slot.direction === 'right' && fabricData.type.includes('_VJ')) {
          slot.free = true
        }
        if (slot.direction === 'left' && fabricData.type.includes('_VB')) {
          slot.free = true
        }
        return slot
      })
    }
    return shelvesOnWallToUpdate
  },

  GetKonzolsOnWallToUpdateAfterAdd: function (state, itemsToInsert) {
    let nonKonzolItems = itemsToInsert.filter((item) => item.type !== '10K')

    let konzolsOnWallToUpdate = []

    nonKonzolItems.forEach((nonKonzolItem) => {
      //First Pos
      let firstKonzolOnWall = WallManager.GetKonzolInPos(
        state,
        nonKonzolItem.pos
      )
      if (firstKonzolOnWall !== undefined) {
        if (!firstKonzolOnWall.connections.includes(nonKonzolItem.id))
          firstKonzolOnWall.connections.push(nonKonzolItem.id)

        konzolsOnWallToUpdate.push(firstKonzolOnWall)
      }
      //Second Pos
      if (WallManager.HasItemTwoPoints(state, nonKonzolItem)) {
        let secondPos = WallManager.GetItemsSecondPos(state, nonKonzolItem)
        let secondKonzolOnWall = WallManager.GetKonzolInPos(state, secondPos)
        if (secondKonzolOnWall !== undefined) {
          if (!secondKonzolOnWall.connections.includes(nonKonzolItem.id))
            secondKonzolOnWall.connections.push(nonKonzolItem.id)
          konzolsOnWallToUpdate.push(secondKonzolOnWall)
        }
      }
    })
    return konzolsOnWallToUpdate
  },
  GetConnectedFabricsToTop(state, top) {
    let connectedItems = ClusterHelper.GetConnectedItemsToPos(state, top.pos)
    let connectedFabrics = connectedItems.filter((item) =>
      item.type.includes('10V')
    )
    return connectedFabrics
  },
  GetShelf(state, addedItems) {
    let res = addedItems.filter(
      (item) => item.type !== '10T' && WallManager.HasItemSlot(state, item.type)
    )[0]
    return res
  },
  IsAddShelf(state, addedItems) {
    let isAddShelf = false
    addedItems.forEach((item) => {
      if (item.type !== '10T' && WallManager.HasItemSlot(state, item.type))
        isAddShelf = true
    })
    return isAddShelf
  },
}
