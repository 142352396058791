import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Image, Col, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';

const NotFoundScreen = () => {
  return (
    <>
      <Helmet>
        <title>404 - A keresett oldal nem található</title>
        <meta name="description" content="A keresett oldal nem található." />
      </Helmet>
      <div className='page-container'>

        <Container className='header-margin'>
          <Row className='align-items-center'>
            <Col md={6}>
              <h1>404 - A keresett oldal nem található</h1>
              <Link to="/" className='big'>
                <Button className='lg btn-outline-dark rounded-pill offers button-text' variant='success' size='sm'>
                  Vissza a főoldalra
                </Button>

              </Link>
            </Col>
            <Col md={6}>
              <Image
                className='contact-image'
                src='/images/404.webp'
                alt='Cathletic 404'
              />
            </Col>
          </Row>
        </Container >
      </div>
    </>
  );
}

export default NotFoundScreen;