import { useDispatch } from 'react-redux'
import { React, useState } from 'react'
import { CLEAR_WALLITEM_LIST } from '../../../../actions/WD/wallActions'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, OverlayTrigger, Modal, Tooltip } from 'react-bootstrap'

function ClearWall() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const dispatch = useDispatch()
  const clearWall = () => {
    dispatch(CLEAR_WALLITEM_LIST())
    handleClose()
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>MÁSZÓFAL TÖRLÉS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            Biztosan törölni szeretnéd az összes elemet a falról?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='default' onClick={handleClose}>
            Nem
          </Button>
          <Button variant='warning' onClick={() => clearWall()}>
            Igen
          </Button>
        </Modal.Footer>
      </Modal>
      <OverlayTrigger
        placement='top'
        delay={{ show: 650, hide: 200 }}
        overlay={<Tooltip>Fal törlése</Tooltip>}
      >
        <Button
          className='btn-success mx-1  px-3 py-2'
          onClick={() => handleShow()}
        >
          <FontAwesomeIcon size='lg' icon={faTrashAlt} />
        </Button>
      </OverlayTrigger>
    </>
  )
}

export default ClearWall
