import React, { useState } from 'react'
import { Container, Form, Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { savePaymentMethod } from '../actions/cartActions'

const PaymentScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  if (!shippingAddress) {
    history.push('/shipping')
  }

  const [paymentMethod, setPaymentMethod] = useState('PayPal')

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    history.push('/placeorder')
  }

  return (
    <div className='page-container'>
      <Container className='header-margin py-3 col-md-10'>
        <FormContainer>
          <CheckoutSteps step1 step2 step3 step4 />
          <h1>Fizetés</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Form.Label as='legend'>Válaszd ki a fizetési módot</Form.Label>
              <Col>
                <Form.Check
                  type='radio'
                  label='Bankkártyás fizetés vagy PayPal'
                  value='PayPal'
                  checked={paymentMethod === 'PayPal'}
                  onChange={(e) => setPaymentMethod('PayPal')}
                ></Form.Check>
                <Form.Check
                  type='radio'
                  label='Utánvét készpénz vagy bankkártya (+ 1 490 Ft a futárszolgálat díja)'
                  value='Utánvét'
                  checked={paymentMethod === 'Utánvét'}
                  onChange={(e) => setPaymentMethod('Utánvét')}
                ></Form.Check>
              </Col>
            </Form.Group>
            <Button type='submit' variant='primary'>
              Tovább
            </Button>
          </Form>
        </FormContainer>
      </Container>
    </div>
  )
}

export default PaymentScreen
