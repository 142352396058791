import React from 'react'
import { Container, Row, Image, Col } from 'react-bootstrap'

import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer bg-dark'>
      <Container>
        <Row className='align-items-center text-center'>
            <Col className='text-center'>
              <Image
                className='footer-image my-3'
                variant='top'
                src='/images/light.webp'
                alt='Cathletic Logo'
              />
              <p className='text-light mb-3'>
                <a
                  href='https://www.facebook.com/cathletic/'
                  style={{ color: '#4267B2' }}
                >
                  <i className='fab fa-facebook-square fa-2x px-2'></i>
                </a>
                <a
                  href='https://www.instagram.com/cathletic_cat_wall/'
                  style={{ color: '#fb3958' }}
                >
                  <i className='fab fa-instagram fa-2x px-2'></i>
                </a>
                <a
                  href='https://www.youtube.com/@cathletic'
                  style={{ color: '#FF0000' }}
                >
                  <i className='fab fa-youtube fa-2x px-2'></i>
                </a>
                <a
                  href='https://www.tiktok.com/@cathletic_cat_walls'
                  style={{ color: '#fff' }}
                >
                  <i className='fab fa-tiktok fa-2x px-2' alt='TikTok'></i>
                </a>
                <a
                  href='https://www.etsy.com/shop/CathleticShop'
                  style={{ color: '#F56400' }}
                >
                  <i className='fab fa-etsy fa-2x px-2'></i>
                </a>
                <a
                  href='https://pinterest.com/cathleticshop/'
                  style={{ color: '#E60023' }}
                >
                  <i className='fab fa-pinterest fa-2x px-2'></i>
                </a>
              </p>
              <p className='text-light'>Copyright &copy; CATHLETIC</p>
              <p className='text-light'>
                <Link to='/aszf'>
                  <strong className='text-light px-1'>ÁSZF</strong>
                </Link>
                <Link to='/dataprotection'>
                  <strong className='text-light px-1'>Adatkezelés</strong>
                </Link>
              </p>
            </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
