import React, { useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet-async'

const PartnersScreen = ({ match, location }) => {
  return (
    <>
      <Helmet>
        <title>Cathletic Macskabútor - Partnereink</title>
        <meta name="description"
          content="Számos kiemelkedő vállalkozással, macskamenhellyel és kultúrális szervezettel alakult ki közeli kapcsolatunk az évek során." />
        <link rel="canonical" href="/partners" />
      </Helmet>
      <div className='page-container'>
        <Container className=''>
          <Row className='align-items-center'>
            <h1 className='black-chango '>Partnereink</h1>
            <p className=''>
              Megalakulásunk óta számos kiemelkedő munkát végző szervezettel
              alakult ki közeli partneri kapcsolatunk. Olyan menhelyekkel,
              alapítványokkal, vállalkozásokkal, vendéglátó és kultúrális
              helyekkel kerestünk együttműködési lehetőségeket, akikkel közös
              pont a macskák szeretete.
            </p>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/catmuseumbudapest.webp'
                alt='Cat Museum'
              />
            </Col>
            <Col md={9}>
              <h2>Cat Museum Budapest</h2>
              <p className=''>
                Fedezd fel művészeti kiállításukat, izgalmas játékokat és az
                ajándékboltot különleges cicafajták társaságában, a macskák
                paradicsomában! Cicatartással kapcsolatos témákban állatorvosi
                előadások helyszíneként is szolgálnak, valamint festő
                workshopokat tartanak havi rendszerességgel.
              </p>
              <p className=''>
                A galérián találkozhatsz a Cathletic macskabútorral és
                kaparófákkal, könnyű lesz észrevenni :) Szimba, Vattacukor, Maki
                és Kalóz megszállták a mászófalat.
              </p>
              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/catmuseumbudapest'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='https://catmuseumbudapest.eu/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/catcafebudapest.webp'
                alt='Cat Cafe'
              />
            </Col>
            <Col md={9}>
              <h2>Cat Cafe Budapest</h2>
              <p className=''>
                A Cat Café Budapest kávéház Budapest belvárosának szívében
                található. Jelenleg 13 cicalakója van, köztük fajtatiszta main
                coon cicák és menhelyről befogadott, mentett cicák is.
              </p>
              <p className=''>
                Három Cathletic cicabútort is megnézhetsz náluk élőben, egy
                finom kávé mellett, rengeteg cuki cica társaságában.
              </p>
              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/CatCafeBudapest'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='https://catcafebudapest.hu/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/terapias.webp'
                alt='Terápiás'
              />
            </Col>
            <Col md={9}>
              <h2>Terápiás és Segítő Macskákért Alapítvány</h2>
              <p className=''>
                A Terápiás és Segítő Macskákért Alapítvány Magyarország első
                olyan szervezete, mely macskák tréningezésével, terápiás
                alkalmazásával foglalkozik. Tevékenységük innovatív, szerteágazó
                és társadalmi szempontból is kiemelt fontosságú.
              </p>
              <p className=''>
                Fontos, hiszen a terápiás macskák képzése és alkalmazása örömöt,
                motivációt és segítséget kínál az embereknek.
              </p>
              <p className=''>
                Fontos, hiszen Alapítványunk szakmai műhely is az
                állatasszisztált intervenciókkal dolgozó szakembereknek.
              </p>
              <p className=''>
                Ha szívesen állsz melléjük, kérlek segítsd munkájukat
                adományoddal:
                <br />
                Bankszámlaszám: 11713184-21457500 (OTP)
              </p>

              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/profile.php?id=100088898241985'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='https://www.terapiasmacska.hu/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/macskavilag.webp'
                alt='Macskavilág'
              />
            </Col>
            <Col md={9}>
              <h2>Macskavilág Alapítvány</h2>
              <p className=''>
                A Macskavilág Alapítványt azért hozták létre anno, hogy a
                jelenleg utcán kóborló, felbecsülhetetlen számú kóbor macska
                populáció további felesleges szaporodását és a feleslegesen
                születő egyedek számára a kilátástalan jövő okozta szenvedést,
                valamint a macskabetegségek terjedését megelőzzék és hogy
                munkájukkal segítsük az utcán élő kóbor cicák gazdához
                juttatását.
              </p>
              <p className=''>
                Ha örökbefogadnál náluk cicát, akkor biztosan találkozhatsz a
                Cathletic bútorokkal és kaparófákkal :) Minden cicát
                szerződéssel, oltási ivartalanítási kötelezettséggel adnak
                örökbe, kizárólag benti tartásra.
              </p>
              <p>
                2012-ben már közhasznúak lettek, 2014-től pedig jogosultak az 1%
                gyűjtésére. Adószámuk: 18211360-1-13
              </p>
              <p className=''>
                Az anyagi jellegű felajánlásokat az alábbi számlaszámokon
                fogadják: <br />
                ERSTE Bank: 11600006-00000000-48501767 <br />
                MagNet Bank: 16200199-00243254
                <br />
                Erste Bank BIC kód: GIBAHUHB <br />
                Nemzetközi bankszámlaszám (IBAN): HU38 1160 0006 0000 0000 4850
                1767
              </p>
              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/Macskavilag/'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='http://www.macskavilag.com/wp/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/maved.webp'
                alt='MAVED'
              />
            </Col>
            <Col md={9}>
              <h2>Magyar Macskavédő Alapítvány</h2>
              <p className=''>
                Elsődleges tevékenységük a kóbor macskák ivartalanítása: hogy a
                kóbor állatok létszámának csökkentését a TNR / BIV
                (befogás-ivartalanítás-visszaengedés) módszer nyújtotta
                gyors-hatékonysággal tudják elérni.
              </p>

              <p className=''>
                Óriási cica kalandpark készült hozzájuk is kaparófával,
                hidakkal, egyedi bútorokkal :)
              </p>
              <p className=''>
                Ha támogatnád a munkájukat, itt megteheted: <br />
                Bankszámlaszámuk: 11712073-21455041 <br />
                Külföldről: <br />
                IBAN: HU52 11712073-21455041-00000000 <br />
                SWIFT: OTPVHUHB
              </p>
              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/mavedalapitvany/'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='http://maved.hu/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/macskamentok.webp'
                alt='Macskamentők'
              />
            </Col>
            <Col md={9}>
              <h2>Macskamentők Alapítvány</h2>
              <p className=''>
                Macskamentők alapítvány 2010-től működik. Céljuk: a kóbor
                macskák létszámának csökkentése. Ezért ivarérett macskákat csak
                ivartalanitva adnak gazdához. Illetve mióta 1%-ot tudnak
                gyűjteni ennek egy részét a lakosság felé, mint kedvezményes
                ivartalanítás, visszafordítják.
              </p>
              <p className=''>
                Élőben is találkozhatsz náluk egy egyedileg hozzájuk tervezett
                Cathletic fali macskabútorral.
              </p>
              <p className=''>
                Ha támogatnád a munkájukat, itt megteheted: <br />
                Virtuális örökbefogadási lehetőség:
                <br />
                Bankszámlaszámuk: 12012307-01195906-00100004 <br />
                Közlemény: *cica neve*
                <br />
                Kedvezményezett: Macskamentők Alapítvány
              </p>
              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/Macskamentok'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='https://www.macskamentok.hu/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/pomazi.webp'
                alt='Pomázi'
              />
            </Col>
            <Col md={9}>
              <h2>Pomázi Állatvédő Egyesület</h2>
              <p className=''>
                Egyesületük 1995-ben alakult, és 1998 óta működik közhasznú
                szervezetként. Kutyaotthonuk 2001-ben nyitotta meg kapuit.
                Második menedéket, a cica részleget, 2020-ban elsősorban TNR
                módszerrel (befogás-ivartalanítás-visszaengedés) hozták létre.
                Ennek ellére örökbefogadható cicusok is vannak szép számmal.
              </p>
              <p className=''>
                Egyedi cicafal készült hozzájuk is, a kaparófák voltak náluk a
                legfontosabbak :)
              </p>
              <p className=''>
                Ha támogatnád a munkájukat, itt megteheted: <br />
                Virtuális örökbefogadási lehetőség:
                <br />
                Bankszámlaszámuk: 11705008-20441069 <br />
                Külföldről: <br />
                IBAN: HU34 11705008 20441069 00000000 <br />
                SWIFT: OTPVHUHB
              </p>
              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/pomazi.kutyamenhely/'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='https://pomazi-menhely.hu/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/catnet.webp'
                alt='Catnet'
              />
            </Col>
            <Col md={9}>
              <h2>Cat Net Macskaháló</h2>
              <p className=''>
                Biztonság, gyorsaság, precizitás. Több éve kisállat biztonsági
                hálózással és galamb elleni hálók telepítésével foglalkozunk.
                Vállaljuk kertek, kenelek, erkélyek, ablakok teljes körű
                cicabiztosítását, rövid határidővel, garanciával.
              </p>
              <Row className=''>
                <Col md={1}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={11}>
                  <a href='https://www.facebook.com/catnetmacskahalo'>
                    <p>Facebook</p>
                  </a>
                </Col>
              </Row>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='https://www.macskahalo.com/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-2 '>
            <Col md={3}>
              <Image
                className='contact-image rounded-image'
                src='images/partners/cicaorokbe.webp'
                alt='Cica Örökbefogadás'
              />
            </Col>
            <Col md={9}>
              <h2>Cica Örökbefogadás</h2>
              <p className=''>
                Ingyen elvihető cicák - segíts egy cicának gazdira találni!
                Rengeteg macskának segítettek otthonra lelni. A weboldalt a
                Magyar Macskavédő Alapítvány üzemelteti, ráadásul a
                hirdetésfeladás náluk teljesen ingyenes!
              </p>
              <Row className=''>
                <Col md={1}>
                  <FontAwesomeIcon icon={faGlobe} size='2x' color='#599C76' />
                </Col>
                <Col md={11}>
                  <a href='https://www.cicaorokbefogadas.hu/'>
                    <p>Weboldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div >
    </>
  )
}

export default PartnersScreen
