import React from 'react'
import { Container, Row } from 'react-bootstrap'
import WebshopComplex from '../components/WebshopComplex'
import { Helmet } from 'react-helmet-async'

const CatDreamWallScreen = ({ match }) => {
  return (
    <>
      <Helmet>
        <title>Cathletic Álmaid Macskabútora | Rendelj még ma! </title>
        <meta name="description"
          content="Álmodd meg álmaid macskabútorát, és mi megvalósítjuk! Szabd cicádra macska kaparófád, hogy tökéletesen passzoljon cicád és otthonod igényeihez. Garantált szórakozás és pihenés | Egészség, biztonság és aktivitás." />
        <link rel="canonical" href="/dreamcatwall" />
      </Helmet>
      <div className='page-container pb-0'>
        <Container className='text-center'>
          <Row className=' text-center'>
            <h1 className='black-chango'>Cicád Álom Mászófala</h1>
            <b className='big py-2'><strong>Válaszd ki a rátok illő jellemzőket, hogy hozzátok tökéletesen passzoló macskabútort rendelhess!
            </strong></b>
            <p>Elrendezésük, színeik, kiegészítők, vásznak és polcok méretei szabadon változtathatóak.</p>
            <WebshopComplex match={match} />
          </Row>
        </Container>
      </div >
    </>
  )
}

export default CatDreamWallScreen
