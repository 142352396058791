import { GridHelper } from './GridHelper'
import { ConnectionHelper } from './ConnectionHelper'
import { ItemToBlockMapper } from './ItemToBlockMapper'
import { ClusterHelper } from './ClusterHelper'

const shelvesLeft = ['10PVB', '10PTB', '10PLB', '10KO2B', '10KO3B']
const shelvesRight = ['10PVJ', '10PTJ', '10PLJ', '10KO2J', '10KO3J']

export var WallManager = {
  CreateTopToInsertWithoutCheck: function (state, top) {
    let topWithId = this.AddIdsForItems(state, [top])
    topWithId = ClusterHelper.AddClusterIdsForItems(state, topWithId)
    return this.UpdateConnections(state, topWithId)
  },

  CollectItemsToAdd: function (state, selectedItem) {
    let itemsToInsert = ItemToBlockMapper.DetermineItemsToInsert(
      state,
      selectedItem
    )

    itemsToInsert = this.AddIdsForItems(state, itemsToInsert)
    itemsToInsert = ClusterHelper.AddClusterIdsForItems(state, itemsToInsert)
    itemsToInsert = this.AddSlotsDataToItems(state, itemsToInsert)
    return this.UpdateConnections(state, itemsToInsert)
  },

  AddSlotsDataToItems: function (state, itemsToInsert) {
    itemsToInsert.forEach((item) => {
      let slots = this.GetItemData(state, item).slots
      if (typeof slots !== 'undefined') {
        if (slots.length > 0) {
          let tempSlots = []
          slots.forEach((slot) => {
            let slotPos = GridHelper.GetPointInDir(
              state,
              item.pos,
              slot.dimension
            )
            slot.pos = slotPos
            tempSlots.push({ ...slot })
          })
          item.slots = tempSlots
        }
      }
    })

    const newItems = itemsToInsert.map((item) => ({
      ...item,
      slots: item.hasOwnProperty('slots')
        ? item.slots.map((slot) => ({
            ...slot,
            pos: { ...slot.pos },
            free: slot.free,
          }))
        : {},
    }))

    return newItems
  },

  GetKonzolsToUpdateAfterRemovedConnection: function (state, removedItem) {
    let konzolsToUpdate = []

    removedItem.forEach((removedItem) => {
      //First pos
      let konzol = this.GetItemInPosInInputList(
        state,
        removedItem.pos,
        '10K',
        state.items
      )
      if (typeof konzol === 'undefined') return konzolsToUpdate
      konzol.connections = konzol.connections.filter(
        (id) => id !== removedItem.id
      )
      konzolsToUpdate.push(konzol)

      //Second Pos
      let removedItemData = this.GetItemData(state, removedItem)
      if (
        removedItemData.dimension.x !== 0 ||
        removedItemData.dimension.y !== 0
      ) {
        let secondPos = GridHelper.GetPointInDir(
          state,
          removedItem.pos,
          removedItemData.dimension
        )
        let secondKonzol = this.GetItemInPosInInputList(
          state,
          secondPos,
          '10K',
          state.items
        )
        secondKonzol.connections = secondKonzol.connections.filter(
          (id) => id !== removedItem.id
        )
        konzolsToUpdate.push(secondKonzol)
      }
    })
    return konzolsToUpdate
  },

  GetRemovableKonzolsAndTops: function (state) {

    let removableKonzols = state.items.filter(
      (item) => item.type === '10K' && item.connections.length === 0
    )

    let oneChildKonzols = state.items.filter(
      (item) => item.type === '10K' && item.connections.length === 1
    )
    let removableTops = []
    oneChildKonzols.forEach((konzol) => {
      let singleTops = state.items.filter(
        (item) => item.type === '10T' && item.id === konzol.connections[0]
      )
      if (singleTops.length > 0) {
        removableTops.push(singleTops[0])
        removableKonzols.push(konzol)
      }
    })

    return removableKonzols.concat(removableTops)
  },

  GetRemovableTopsAfterAdd: function (state, addedItems) {
    let removableTops = []

    let nonKonzolItemsAdded = addedItems.filter((item) => item.type !== '10K')
    let topItemsAdded = addedItems.filter((item) => item.type === '10T')

    nonKonzolItemsAdded.forEach((item) => {
      //First Pos
      let topOnWallInPos = this.GetItemInPosOnWall(state, item.pos, '10T')
      if (typeof topOnWallInPos !== 'undefined') {
        if (
          !removableTops.includes(topOnWallInPos.id) &&
          topItemsAdded.filter((added) => added.id === topOnWallInPos.id)
            .length === 0
        ) {
          removableTops.push(topOnWallInPos)
        }
      }
      //Second Pos
      let nonKonzolData = this.GetItemData(state, item)
      if (nonKonzolData.dimension.x !== 0 || nonKonzolData.dimension.y !== 0) {
        let secondPos = GridHelper.GetPointInDir(
          state,
          item.pos,
          nonKonzolData.dimension
        )
        topOnWallInPos = this.GetItemInPosOnWall(state, secondPos, '10T')
        if (typeof topOnWallInPos !== 'undefined') {
          if (
            !removableTops.includes(topOnWallInPos.id) &&
            topItemsAdded.filter((added) => added.id === topOnWallInPos.id)
              .length === 0
          ) {
            removableTops.push(topOnWallInPos)
          }
        }
      }
    })
    return removableTops
  },

  AddIdsForItems: function (state, itemsToInsert) {
    let maxId = this.GetMaxIdOnWall(state)
    itemsToInsert.forEach((item) => (item.id = ++maxId))
    return itemsToInsert
  },

  UpdateConnections: function (state, itemsToInsert) {

    let itemsToInsertWithConnections =
      ConnectionHelper.UpdateConnectionsOnItemsToInsert(state, itemsToInsert)

    return itemsToInsertWithConnections
  },

  SwitchSideWallItem: function (state, item) {
    let itemListWithoutSelf = this.GetWallItemListWithoutSelf(state, item)
    let itemData = this.GetItemData(state, item)
    if (item.type.includes('10V')) {
      if (ConnectionHelper.IsVerticalFabricSwitchSideAvailable(state, item)) {
        ConnectionHelper.SwitchSideVerticalFabricAndUpdateSlots(state, item)
        return []
      }
    } else if (
      this.IsPosAvailableWithoutSelf(
        state,
        itemListWithoutSelf,
        item.pos,
        itemData.switch.side
      )
    ) {
      item.type = itemData.switch.side
      return item
    }
    return item
  },

  SwitchColorWallItem: function (state, item) {
    let itemData = state.itemsData.filter(function (wallItem) {
      return wallItem.type === item.type
    })[0]
    item.type = itemData.switch.color

    return item
  },
  GetItemDataByType: function (state, type) {
    return state.itemsData.filter(function (wallItem) {
      return wallItem.type === type
    })[0]
  },
  GetItemData: function (state, item) {
    return state.itemsData.filter(function (wallItem) {
      return wallItem.type === item.type
    })[0]
  },
  GetItemInPosInInputList: function (state, pos, itemType, itemList) {
    return itemList.filter(
      (item) =>
        item.pos.x === pos.x && item.pos.y === pos.y && item.type === itemType
    )[0]
  },
  GetItemsInPos: function (state, pos) {
    return state.items.filter(
      (item) => item.pos.x === pos.x && item.pos.y === pos.y
    )
  },
  GetShelfWithSlotInPos: function (state, pos) {
    let res = state.items.filter((itemOnWall) => {
      let isInPos = false
      if (
        typeof itemOnWall.slots !== 'undefined' &&
        itemOnWall.slots.length > 0
      ) {
        itemOnWall.slots.forEach((slot) => {
          if (slot.pos.x === pos.x && slot.pos.y === pos.y) {
            isInPos = true
          }
        })
      }
      return isInPos
    })
    return res[0]
  },
  GetItemInPosOnWall: function (state, pos, itemType) {
    return state.items.filter(
      (itemOnWall) =>
        itemOnWall.pos.x === pos.x &&
        itemOnWall.pos.y === pos.y &&
        itemOnWall.type === itemType
    )[0]
  },
  GetKonzolInPos: function (state, pos) {
    return this.GetItemInPosOnWall(state, pos, '10K')
  },
  GetMaxIdOnWall: function (state) {
    return state.items.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    )
  },
  // GetMaxClusterIdOnWall: function (state) {
  //   return state.items.reduce(
  //     (acc, item) => (acc = acc > item.clusterId ? acc : item.clusterId),
  //     0
  //   )
  // },
  HasItemTwoPoints: function (state, item) {
    let itemData = this.GetItemData(state, item)
    return itemData.dimension.x > 0 || itemData.dimension.y > 0
  },
  HasItemTypeTwoPoints: function (state, type) {
    let itemData = this.GetItemDataByType(state, type)
    return itemData.dimension.x > 0 || itemData.dimension.y > 0
  },
  GetItemsSecondPos: function (state, item) {
    let itemData = this.GetItemData(state, item)
    return GridHelper.GetPointInDir(state, item.pos, itemData.dimension)
  },
  GetWallItemHighest: function (state, items) {
    if (typeof items !== 'undefined' && items.length !== 0) {
      let highestPos = items[0].pos.y
      items.forEach((item) => {
        if (item.pos.y < highestPos) highestPos = item.pos.y
      })
      return highestPos
    } else return 0
  },
  GetWallItemLeftest: function (state, items) {
    if (typeof items !== 'undefined' && items.length !== 0) {
      let leftestPos = items[0].pos.x
      items.forEach((item) => {
        if (item.pos.x < leftestPos) leftestPos = item.pos.x
      })
      return leftestPos
    } else return 0
  },
  GetWallItemLowest: function (state, items) {
    if (typeof items !== 'undefined' && items.length !== 0) {
      let lowestPos = items[0].pos.y
      items.forEach((item) => {
        if (item.pos.y > lowestPos) lowestPos = item.pos.y
      })
      return lowestPos
    } else return 0
  },
  GetWallItemRightest: function (state, items) {
    if (typeof items !== 'undefined' && items.length !== 0) {
      let rightestPos = items[0].pos.x
      items.forEach((item) => {
        if (item.pos.x > rightestPos) rightestPos = item.pos.x
      })
      return rightestPos
    } else return 0
  },
  GetWallItemListWithoutSelf: function (state, item) {
    return state.items.filter(function (wallItem) {
      if (wallItem.pos.x !== item.pos.x || wallItem.pos.y !== item.pos.y)
        return true
      else if (wallItem.type !== item.type) return true
      else return false
    })
  },
  IsPosAvailableWithoutSelf: function (state, itemList, pos, type) {
    let conflictItemList = []
    let wallItemData = state.itemsData.filter(function (wallItem) {
      return wallItem.type === type
    })[0]

    wallItemData.conflicts.forEach((conflict) => {
      let conflictCoordinate = GridHelper.GetPointInDir(
        state,
        pos,
        conflict.dimension
      )

      let itemsInConflictCoordinate = itemList.filter(
        (item) =>
          item.pos.x === conflictCoordinate.x &&
          item.pos.y === conflictCoordinate.y
      )

      let conflictItemsinPos = itemsInConflictCoordinate.filter(
        (itemInConflictCoordinate) =>
          conflict.items.includes(itemInConflictCoordinate.type)
      )

      if (conflictItemsinPos.length != 0)
        conflictItemList.push(conflictItemsinPos)
    })

    if (conflictItemList.length == 0) return true
    else return false
  },
  IsCircle(state, pos, type) {
    let itemData = this.GetItemDataByType(state, type)
    if (itemData.dimension.x === 0 && itemData.dimension.y === 0) {
      return false
    }

    let endPos = GridHelper.GetPointInDir(state, pos, itemData.dimension)
    let startKonzol = this.GetKonzolInPos(state, pos)
    let endKonzol = this.GetKonzolInPos(state, endPos)

    if (
      typeof startKonzol === 'undefined' ||
      typeof endKonzol === 'undefined'
    ) {
      // If start or end is not on wall, no circle is possible
      return false
    }
    if (startKonzol.clusterId === endKonzol.clusterId) {
      return true
    } else return false
  },
  IsConflict(state, pos, type) {
    let conflictItemList = []
    let wallItemData = state.itemsData.filter(function (wallItem) {
      return wallItem.type === type
    })[0]

    wallItemData.conflicts.forEach((conflict) => {
      let conflictCoordinate = GridHelper.GetPointInDir(
        state,
        pos,
        conflict.dimension
      )

      let itemsInConflictCoordinate = state.items.filter(
        (item) =>
          item.pos.x === conflictCoordinate.x &&
          item.pos.y === conflictCoordinate.y
      )

      let conflictItemsinPos = itemsInConflictCoordinate.filter(
        (itemInConflictCoordinate) =>
          conflict.items.includes(itemInConflictCoordinate.type)
      )

      if (conflictItemsinPos.length != 0)
        conflictItemList.push(conflictItemsinPos)
    })
    if (conflictItemList.length > 0) return true
    else return false
  },
  IsPosAvailableMain: function (state, pos, type) {
    // is there conflict
    if (this.IsConflict(state, pos, type)) return false
    // is there circle
    else if (this.IsCircle(state, pos, type)) return false
    // if there more slots taken then available in new item
    else if (!this.AreSlotsAvailableMain(state, pos, type)) return false
    // if shift occurs
    else if (this.IsShiftError(state, pos, type)) return false
    // if crossing vertical 80 fabric switch side in not available only shift
    else if (this.IsShiftCrossingError(state, pos, type)) return false
    else return true
  },
  HasItemSlot(state, type) {
    let itemData = this.GetItemDataByType(state, type)
    if (typeof itemData.slots !== 'undefined' && itemData.slots.length > 0)
      return true
    return false
  },
  AreSlotsAvailableForFabric(state, pos, type) {
    return true
  },
  AreSlotsAvailableForShelf(state, pos, type) {
    let firstPosResult = false

    // First pos
    let firstPos = pos
    // Is top in first pos
    let topInFirstPos = this.GetItemsInPos(state, firstPos).filter(
      (item) => item.type === '10T'
    )[0]
    // if no return true
    if (typeof topInFirstPos === 'undefined') firstPosResult = true
    else {
      let countFreeSlotsInTop = topInFirstPos.slots.filter(
        (slot) => slot.free
      ).length
      // if top with 2 slots taken (no free slot) return false
      if (countFreeSlotsInTop === 0) return false
      // if top with 1 slot taken only return yes
      else if (countFreeSlotsInTop === 1) firstPosResult = true
      // if top with 0 slot taken return yes (NOT POSSIBLE)
      else if (countFreeSlotsInTop === 2) firstPosResult = true
      else return false
    }
    // Second pos
    if (this.HasItemTypeTwoPoints(state, type)) {
      let secondPosResult = false
      let itemData = this.GetItemDataByType(state, type)
      let secondPos = GridHelper.GetPointInDir(
        state,
        firstPos,
        itemData.dimension
      )
      // Is top in first pos
      let topInSecondPos = this.GetItemsInPos(state, secondPos).filter(
        (item) => item.type === '10T'
      )[0]
      // if no return true
      if (typeof topInSecondPos === 'undefined') secondPosResult = true
      else {
        let countFreeSlotsInTop = topInSecondPos.slots.filter(
          (slot) => slot.free
        ).length
        // if top with 2 slots taken (no free slot) return false
        if (countFreeSlotsInTop === 0) return false
        // if top with 1 slot taken only return yes
        else if (countFreeSlotsInTop === 1) secondPosResult = true
        // if top with 0 slot taken return yes (NOT POSSIBLE)
        else if (countFreeSlotsInTop === 2) secondPosResult = true
        else return false
      }
      if (firstPosResult && secondPosResult) return true
      else return false
    } else if (firstPosResult) return true
    else return false
  },

  AreSlotsAvailableMain(state, pos, type) {
    if (type.includes('10V')) {
      return this.AreSlotsAvailableForFabric(state, pos, type)
    } else if (this.HasItemSlot(state, type)) {
      return this.AreSlotsAvailableForShelf(state, pos, type)
    } else return true
  },
  IsShiftError(state, pos, type) {
    console.log('+++++++++++++++++ IsShiftError +++++++++++++++++', type)
    if (type.includes('10V') && type.includes('_V')) {
      return this.IsShiftErrorVerticalFabric(state, pos, type)
    } else if (type.includes('10V') && type.includes('_V') === false) {
      return this.IsShiftErrorHorizontalFabric(state, pos, type)
    } else if (this.HasItemSlot(state, type)) {
      return this.IsShiftErrorShelf(state, pos, type)
    } else return false
  },
  // Is there a vertical 80 fabric in pos y:-1
  IsVertical80FabricCrossing(state, pos, direction) {
    console.log('+++IsVertical80FabricCrossing ++++')
    // First Point
    let pointToCheck = GridHelper.GetPointInDir(state, pos, { x: 0, y: -1 })
    console.log('pointToCheck', pointToCheck)
    let itemsInPos = WallManager.GetItemsInPos(state, pointToCheck)
    console.log('itemsInPos', itemsInPos)
    let isThereVerticalDoubleFabric = false
    let crossingVertical80Fabric
    itemsInPos.forEach((item) => {
      if (direction === 'right') {
        if (item.type.includes('10V80') && item.type.includes('_VJ')) {
          isThereVerticalDoubleFabric = true
          crossingVertical80Fabric = item
        }
      } else if (direction === 'left') {
        if (item.type.includes('10V80') && item.type.includes('_VB')) {
          isThereVerticalDoubleFabric = true
          crossingVertical80Fabric = item
        }
      }
    })
    return {
      result: isThereVerticalDoubleFabric,
      crossing: crossingVertical80Fabric,
    }
  },
  IsShiftCrossingError(state, pos, type) {
    console.log(
      '+++++++++++++++++ IsShiftCrossingError +++++++++++++++++',
      type
    )
    if (type !== '10K' && type !== '10T') {
      let itemData = WallManager.GetItemDataByType(state, type)
      let firstPos = pos
      let firstCrossing
      if (shelvesLeft.includes(type)) {
        firstCrossing = this.IsVertical80FabricCrossing(state, firstPos, 'left')
      } else {
        firstCrossing = this.IsVertical80FabricCrossing(
          state,
          firstPos,
          'right'
        )
      }
      console.log('firstCrossing.result', firstCrossing.result)
      console.log('firstCrossing.crossing', firstCrossing.crossing)
      if (firstCrossing.result) {
        if (
          !ConnectionHelper.IsVerticalFabricSwitchSideAvailable(
            state,
            firstCrossing.crossing
          )
        ) {
          return true
        }
      }
      if (itemData.dimension.x !== 0 || itemData.dimension.y !== 0) {
        let secondPos = GridHelper.GetPointInDir(
          state,
          firstPos,
          itemData.dimension
        )
        let secondCrossing = this.IsVertical80FabricCrossing(
          state,
          secondPos,
          'left'
        )
        console.log('secondCrossing.result', secondCrossing.result)
        console.log('secondCrossing.crossing', secondCrossing.crossing)

        if (secondCrossing.result) {
          if (
            !ConnectionHelper.IsVerticalFabricSwitchSideAvailable(
              state,
              secondCrossing.crossing
            )
          ) {
            return true
          }
        }
      }
    }
    return false
  },
  GetConnectedFabricsToTop(state, top) {
    let connectedItems = ClusterHelper.GetConnectedItemsToPos(state, top.pos)
    let connectedFabrics = connectedItems.filter((item) =>
      item.type.includes('10V')
    )
    return connectedFabrics
  },
  IsShiftErrorHorizontalFabric(state, pos, type) {
    console.log(
      '+++++++++++++++++ IsShiftErrorHorizontalFabric +++++++++++++++++',
      type
    )
    let fabricData = WallManager.GetItemDataByType(state, type)
    let firstPos = pos
    let secondPos = GridHelper.GetPointInDir(
      state,
      firstPos,
      fabricData.dimension
    )

    let firstShelf = WallManager.GetShelfWithSlotInPos(state, firstPos)
    if (typeof firstShelf === 'undefined') {
      console.log('firstShelf is undefined')
    }
    let secondShelf = WallManager.GetShelfWithSlotInPos(state, secondPos)
    if (typeof secondShelf === 'undefined') {
      console.log('secondShelf is undefined')
    }

    let firstRightSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      firstShelf,
      firstPos,
      'right'
    )
    let secondRightSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      secondShelf,
      secondPos,
      'right'
    )
    let firstLeftSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      firstShelf,
      firstPos,
      'left'
    )
    let secondLeftSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      secondShelf,
      secondPos,
      'left'
    )
    console.log('firstRightSlot', firstRightSlot)
    console.log('secondLeftSlot', secondLeftSlot)

    // If left-right slots are free add fabric and move on
    if (
      (ConnectionHelper.IsSlotFree(state, firstRightSlot) ||
        typeof firstRightSlot === 'undefined') &&
      (ConnectionHelper.IsSlotFree(state, secondLeftSlot) ||
        typeof secondLeftSlot === 'undefined')
    ) {
      console.log('left-right slots are free add fabric and move on')
    }
    // if first right slot is not free it must be a vertical fabric
    else if (
      !ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
      typeof firstRightSlot !== 'undefined'
    ) {
      console.log('first right slot is not free it must be a vertical fabric')
      let verticalFabric = this.GetConnectedFabricsToTop(
        state,
        firstShelf
      ).filter((item) => item.type.includes('_V'))[0]
      console.log('verticalFabric', verticalFabric)

      if (typeof verticalFabric === 'undefined') return false
      else if (
        this.IsVerticalFabricSwithcSideAvailable(
          state,
          verticalFabric,
          firstPos
        )
      ) {
        console.log('IsVerticalFabricSwithcSideAvailable true')
      }
      // else shift vertical start point
      else {
        console.log('+++++++++++++++++ SHIFTSHIFTSHIFT +++++++++++++++++')
        return true
      }
    }
    // if second left slot is not free it must be a vertical fabric
    else if (
      !ConnectionHelper.IsSlotFree(state, secondLeftSlot) &&
      typeof secondLeftSlot !== 'undefined'
    ) {
      console.log('second left slot is not free it must be a vertical fabric')
      let verticalFabric = this.GetConnectedFabricsToTop(
        state,
        secondShelf
      ).filter((item) => item.type.includes('_V'))[0]
      console.log('verticalFabric', verticalFabric)

      if (typeof verticalFabric === 'undefined') return false
      else if (
        this.IsVerticalFabricSwithcSideAvailable(
          state,
          verticalFabric,
          secondPos
        )
      ) {
        console.log('IsVerticalFabricSwithcSideAvailable true')
      }
      // else shift vertical start point
      else {
        console.log('+++++++++++++++++ SHIFTSHIFTSHIFT +++++++++++++++++')
        return true
      }
    }
    return false
  },
  IsShiftErrorVerticalFabric(state, pos, type) {
    console.log(
      '+++++++++++++++++ IsShiftErrorVerticalFabric +++++++++++++++++'
    )
    let fabricData = WallManager.GetItemDataByType(state, type)
    let firstPos = pos
    let secondPos = GridHelper.GetPointInDir(
      state,
      firstPos,
      fabricData.dimension
    )

    let firstShelf = WallManager.GetShelfWithSlotInPos(state, firstPos)
    if (typeof firstShelf === 'undefined') {
      console.log('firstShelf is undefined')
    }
    let secondShelf = WallManager.GetShelfWithSlotInPos(state, secondPos)
    if (typeof secondShelf === 'undefined') {
      console.log('secondShelf is undefined')
    }

    let firstRightSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      firstShelf,
      firstPos,
      'right'
    )
    let secondRightSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      secondShelf,
      secondPos,
      'right'
    )
    let firstLeftSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      firstShelf,
      firstPos,
      'left'
    )
    let secondLeftSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      secondShelf,
      secondPos,
      'left'
    )

    // Check free slots in first and second pos
    // if both right free -> no shift
    if (
      ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
      ConnectionHelper.IsSlotFree(state, secondRightSlot)
    ) {
    }
    // if both left free ->  no shift
    else if (
      ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
      ConnectionHelper.IsSlotFree(state, secondLeftSlot)
    ) {
    }

    // if both pos have free slots, but only different dir -> shift
    else if (
      ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
      ConnectionHelper.IsSlotFree(state, secondLeftSlot) &&
      !ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
      !ConnectionHelper.IsSlotFree(state, secondRightSlot)
    ) {
      console.log('+++++++++++++++++ SHIFTSHIFTSHIFT +++++++++++++++++')
      return true
    } else if (
      ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
      ConnectionHelper.IsSlotFree(state, secondRightSlot) &&
      !ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
      !ConnectionHelper.IsSlotFree(state, secondLeftSlot)
    ) {
      console.log('+++++++++++++++++ SHIFTSHIFTSHIFT +++++++++++++++++')
      return true
    }
    return false
  },
  GetSlotFromShelfData: function (
    state,
    shelfData,
    slotDimension,
    slotDirection
  ) {
    if (typeof shelfData !== 'undefined')
      return shelfData.slots.filter((slot) => {
        if (
          slot.dimension.x === slotDimension.x &&
          slot.dimension.y === slotDimension.y &&
          slot.direction === slotDirection
        ) {
          return true
        }
      })[0]
  },
  IsOppositeEndSlotFreeForVerticalFabric(state, endTop, direction) {
    let endTopSlot = ConnectionHelper.GetSlotFromShelf(
      state,
      endTop,
      endTop.pos,
      direction
    )
    console.log('endTopSlot', endTopSlot)
    let isFree = ConnectionHelper.IsSlotFree(state, endTopSlot)
    console.log('isFree', isFree)
    return isFree
  },

  IsVerticalFabricSwithcSideAvailable(state, verticalFabric, pos) {
    let verticalFabricData = WallManager.GetItemData(state, verticalFabric)
    if (verticalFabric.pos.y === pos.y) {
      let endItems = WallManager.GetItemsInPos(
        state,
        GridHelper.GetPointInDir(
          state,
          verticalFabric.pos,
          verticalFabricData.dimension
        )
      )
      console.log('endItems', endItems)
      let topInEndPos = endItems.filter((item) => item.type === '10T')[0]
      if (typeof topInEndPos === 'undefined') {
        return false
      } else {
        // Hor and Vert start in same pos
        if (verticalFabric.pos.x === pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'left'
          )
        }
        // Vert starts at the end of Hor
        if (verticalFabric.pos.x !== pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'right'
          )
        }
      }
    } else if (verticalFabric.pos.y !== pos.y) {
      let endItems = WallManager.GetItemsInPos(state, verticalFabric.pos)
      console.log('endItems', endItems)
      let topInEndPos = endItems.filter((item) => item.type === '10T')[0]
      if (typeof topInEndPos === 'undefined') {
        return false
      } else {
        // Hor and Vert start in same line horizontally
        if (verticalFabric.pos.x === pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'left'
          )
        }
        // Vert starts at line the end of the horizontal fabric
        if (verticalFabric.pos.x !== pos.x) {
          return this.IsOppositeEndSlotFreeForVerticalFabric(
            state,
            topInEndPos,
            'right'
          )
        }
      }
    }
  },
  IsShiftNecessary(state, slot, verticalFabric, pos) {
    if (typeof slot !== 'undefined') {
      console.log('Nothing to see here, slot exists and is available')
    } else {
      if (
        this.IsVerticalFabricSwithcSideAvailable(state, verticalFabric, pos)
      ) {
        console.log(
          'Nothing to see here, vertical fabric switch side is available'
        )
      } else {
        console.log('Shift is necessary')
        return true
      }
    }
  },
  IsShiftErrorShelf(state, pos, type) {
    let shelfData = WallManager.GetItemDataByType(state, type)
    // if firstpos has vertical fabric in conflict slot
    // if Swap is available return false
    // if Swap in unavailble the shift would be return TRUE
    let firstPos = pos
    let vertFabric = ClusterHelper.GetConnectedItemsToPos(
      state,
      firstPos
    ).filter((item) => item.type.includes('10V') && item.type.includes('_V'))[0]
    // if firstpos has vertical fabric
    if (typeof vertFabric !== 'undefined') {
      let rightSlot = this.GetSlotFromShelfData(
        state,
        shelfData,
        { x: 0, y: 0 },
        'right'
      )
      let leftSlot = this.GetSlotFromShelfData(
        state,
        shelfData,
        { x: 0, y: 0 },
        'left'
      )
      if (vertFabric.type.includes('_VJ')) {
        console.log('firstVerticalFabric is right')
        if (this.IsShiftNecessary(state, rightSlot, vertFabric, firstPos)) {
          return true
        }
      } else if (vertFabric.type.includes('_VB')) {
        console.log('firstVerticalFabric is left')
        if (this.IsShiftNecessary(state, leftSlot, vertFabric, firstPos)) {
          return true
        }
      }
    }

    if (shelfData.dimension.x !== 0 || shelfData.dimension.y !== 0) {
      let secondPos = GridHelper.GetPointInDir(
        state,
        firstPos,
        shelfData.dimension
      )
      vertFabric = ClusterHelper.GetConnectedItemsToPos(
        state,
        secondPos
      ).filter(
        (item) => item.type.includes('10V') && item.type.includes('_V')
      )[0]
      console.log('vertFabric', vertFabric)
      // if secondPos has vertical fabric
      if (typeof vertFabric !== 'undefined') {
        let rightSlot = this.GetSlotFromShelfData(
          state,
          shelfData,
          { x: shelfData.dimension.x, y: shelfData.dimension.y },
          'right'
        )
        let leftSlot = this.GetSlotFromShelfData(
          state,
          shelfData,
          { x: shelfData.dimension.x, y: shelfData.dimension.y },
          'left'
        )

        if (vertFabric.type.includes('_VJ')) {
          console.log('vertFabric is right')
          if (this.IsShiftNecessary(state, rightSlot, vertFabric, secondPos)) {
            return true
          }
        } else if (vertFabric.type.includes('_VB')) {
          console.log('vertFabric is left')
          if (this.IsShiftNecessary(state, leftSlot, vertFabric, secondPos)) {
            return true
          }
        }
      }
    }

    return false
  },
  CheckSlotsFree: function (state, pos, type) {
    let itemData = this.GetItemDataByType(state, type)
    let firstPos = pos
    let secondPos = GridHelper.GetPointInDir(
      state,
      firstPos,
      itemData.dimension
    )
    // if type is fabric -> check slots in start and end
    if (type.includes('10V')) {
      let firstShelf = this.GetShelfWithSlotInPos(state, firstPos)
      let secondShelf = this.GetShelfWithSlotInPos(state, secondPos)
      let firstRightSlot = ConnectionHelper.GetSlotFromShelf(
        state,
        firstShelf,
        firstPos,
        'right'
      )
      let secondRightSlot = ConnectionHelper.GetSlotFromShelf(
        state,
        secondShelf,
        secondPos,
        'right'
      )
      let firstLeftSlot = ConnectionHelper.GetSlotFromShelf(
        state,
        firstShelf,
        firstPos,
        'left'
      )
      let secondLeftSlot = ConnectionHelper.GetSlotFromShelf(
        state,
        secondShelf,
        secondPos,
        'left'
      )
      // check vertical
      if (type.includes('_V')) {
        if (
          (ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
            ConnectionHelper.IsSlotFree(state, secondRightSlot)) ||
          (ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
            ConnectionHelper.IsSlotFree(state, secondLeftSlot)) ||
          (ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
            ConnectionHelper.IsSlotFree(state, secondLeftSlot) &&
            !ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
            !ConnectionHelper.IsSlotFree(state, secondRightSlot)) ||
          (ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
            ConnectionHelper.IsSlotFree(state, secondRightSlot) &&
            !ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
            !ConnectionHelper.IsSlotFree(state, secondLeftSlot))
        ) {
          return true
        } else return false
      }
      // check not vertical
      else {
        if (
          (ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
            ConnectionHelper.IsSlotFree(state, secondRightSlot)) ||
          (ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
            ConnectionHelper.IsSlotFree(state, secondLeftSlot)) ||
          (ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
            ConnectionHelper.IsSlotFree(state, secondLeftSlot) &&
            !ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
            !ConnectionHelper.IsSlotFree(state, secondRightSlot)) ||
          (ConnectionHelper.IsSlotFree(state, firstLeftSlot) &&
            ConnectionHelper.IsSlotFree(state, secondRightSlot) &&
            !ConnectionHelper.IsSlotFree(state, firstRightSlot) &&
            !ConnectionHelper.IsSlotFree(state, secondLeftSlot))
        ) {
          return true
        } else return false
      }
    }
    return true
  },
  GetCountOfTakenSlotsInPos(state, pos) {
    let shelf = this.GetShelfWithSlotInPos(state, pos)
    if (typeof shelf !== 'undefined') {
      let takenSlotsInPos = shelf.slots.filter((slot) => {
        if (slot.pos.x === pos.x && slot.pos.y === pos.y && !slot.free) {
          return true
        }
      })
      if (typeof takenSlotsInPos !== 'undefined') {
        return takenSlotsInPos.length
      } else {
        return 0
      }
    } else {
      return 0
    }
  },
  IsFreeSlotInPosInDirection: function (state, pos, direction) {
    let shelf = this.GetShelfWithSlotInPos(state, pos)
    if (typeof shelf !== 'undefined') {
      let freeSlotInPos = shelf.slots.filter((slot) => {
        if (
          slot.pos.x === pos.x &&
          slot.pos.y === pos.y &&
          slot.direction === direction &&
          slot.free
        ) {
          return true
        }
      })[0]
      if (typeof freeSlotInPos !== 'undefined') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  },
  IsFreeSlotInPos: function (state, pos) {
    let shelf = this.GetShelfWithSlotInPos(state, pos)
    if (typeof shelf !== 'undefined') {
      let freeSlotInPos = shelf.slots.filter((slot) => {
        if (slot.pos.x === pos.x && slot.pos.y === pos.y && slot.free) {
          return true
        }
      })[0]
      if (typeof freeSlotInPos !== 'undefined') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  },
  AreMainItemsPosAvailable: function (state, items) {
    let conflictCount = 0
    let mainItems = items.filter((item) => item.isMain === true)
    if (mainItems.length === 0) return true
    for (let i = 0; i < mainItems.length; i++) {
      let isPosAvailable = this.IsPosAvailableMain(
        state,
        mainItems[i].pos,
        mainItems[i].type
      )
      if (!isPosAvailable) {
        conflictCount++
      }
    }
    return conflictCount > 0 ? false : true
  },

  RemoveIncorrectItems: function (state) {
    let incorrectItems = []

    let topItems = state.items.filter((item) => item.type === '10T')
    topItems.forEach((top) => {
      if (!this.IsPosAvailableMain(state, top.pos, top.type)) {
        incorrectItems.push(top)
      }
    })
    return incorrectItems
  },
}
