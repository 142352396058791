import { useState } from 'react'
import { Gallery } from 'react-grid-gallery'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { images } from '../components/Gallery'
import { Row, Container, } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'

const GalleryScreen = ({ match, location }) => {
  const [index, setIndex] = useState(-1)

  const currentImage = images[index]
  const nextIndex = (index + 1) % images.length
  const nextImage = images[nextIndex] || currentImage
  const prevIndex = (index + images.length - 1) % images.length
  const prevImage = images[prevIndex] || currentImage

  const handleClick = (index, CustomImage) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  return (
    <>
      <Helmet>
        <title>Cathletic Egyedi Macskabútor Képek </title>
        <meta name="description"
          content="Nézz körül az elkészült falaink között, hogy ötletet meríthess! Utána álmodd meg álmaid macskabútorát, és mi megvalósítjuk. | Modern macskabútor | Falra szerelhető | Cicabútor | Fali macskabútor | Macska kaparófa" />
        <link rel="canonical" href="/gallery" />
      </Helmet>
      <div className='page-container'>
        <Container className='header-margin'>
          <Row className=' align-items-center'>
            <h1 className='black-chango'>Képek - A macskák birodalma</h1>
            <p>Kíváncsi vagy, hogyan töltik idejüket a cicák a macska mászófalainkon? Akkor kukkants be a képek közé, és fedezd fel a macskák boldogságának titkát!</p>
            <p>Itt csupa boldog cicát találsz a macskabútorokon, akik kényük-kedvük szerint másznak, pihennek, kaparnak, szórakoznak a moduláris macska mászófalainkon.</p>
            <p>Nézd meg, hogyan | Hódítják meg a legmagasabb csúcsokat | Kényelmesen szundítanak a puha pihenőhelyeken | Vidáman használják a macskakaparót, kaparófát</p>
            <p>Inspirálódj a képekből, és teremtsd meg a tökéletes fali macskabútort a te cicádnak is!</p>
          </Row>
          <Gallery
            rowHeight={350}
            images={images}
            onClick={handleClick}
            enableImageSelection={false}
          />
          {!!currentImage && (
            <Lightbox
              mainSrc={currentImage.original}
              imageTitle={currentImage.caption}
              mainSrcThumbnail={currentImage.src}
              nextSrc={nextImage.original}
              nextSrcThumbnail={nextImage.src}
              prevSrc={prevImage.original}
              prevSrcThumbnail={prevImage.src}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
        </Container>
      </div>
    </>
  )
}

export default GalleryScreen
