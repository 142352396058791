import { Image } from 'react-grid-gallery'
import ImgBorisz1 from '../images/gallery/Borisz_4.webp'
import ImgBorisz2 from '../images/gallery/Borisz_5.webp'
import ImgBorisz3 from '../images/gallery/Borisz_6.webp'
import ImgBorisz4 from '../images/gallery/Borisz_7.webp'
import Img1 from '../images/gallery/1.webp'
import Img2 from '../images/gallery/2.webp'
import Img3 from '../images/gallery/3.webp'
import Img4 from '../images/gallery/4.webp'
import Img5 from '../images/gallery/5.webp'
import Img6 from '../images/gallery/6.webp'
import Img7 from '../images/gallery/7.webp'
import Img8 from '../images/gallery/8.webp'
import Img9 from '../images/gallery/9.webp'
import Img10 from '../images/gallery/10.webp'
import Img11 from '../images/gallery/11.webp'
import Img12 from '../images/gallery/12.webp'
import Img13 from '../images/gallery/13.webp'
import Img14 from '../images/gallery/14.webp'
import Img15 from '../images/gallery/15.webp'
import Img16 from '../images/gallery/16.webp'
import Img17 from '../images/gallery/17.webp'
import Img18 from '../images/gallery/18.webp'
import Img19 from '../images/gallery/19.webp'
import Img20 from '../images/gallery/20.webp'
import Img21 from '../images/gallery/21.webp'
import Img22 from '../images/gallery/22.webp'
import Img23 from '../images/gallery/23.webp'
import Img24 from '../images/gallery/24.webp'
import Img25 from '../images/gallery/25.webp'
import Img26 from '../images/gallery/26.webp'
import Img27 from '../images/gallery/27.webp'
import Img28 from '../images/gallery/28.webp'
import Img29 from '../images/gallery/29.webp'
import Img30 from '../images/gallery/30.webp'
import Img31 from '../images/gallery/31.webp'
import Img32 from '../images/gallery/32.webp'
import Img33 from '../images/gallery/33.webp'
import Img34 from '../images/gallery/34.webp'
import Img35 from '../images/gallery/35.webp'
import Img36 from '../images/gallery/36.webp'
import Img37 from '../images/gallery/37.webp'
import Img38 from '../images/gallery/38.webp'
import Img39 from '../images/gallery/39.webp'
import Img40 from '../images/gallery/40.webp'
import Img41 from '../images/gallery/41.webp'
import Img42 from '../images/gallery/42.webp'
import Img43 from '../images/gallery/43.webp'
import Img44 from '../images/gallery/44.webp'
import Img45 from '../images/gallery/45.webp'
import Img46 from '../images/gallery/46.webp'
import Img47 from '../images/gallery/47.webp'
import Img48 from '../images/gallery/48.webp'

export const images = [
  
  {
    src: Img40,
    original: Img40,
    width: 1199,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img41,
    original: Img41,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img42,
    original: Img42,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img43,
    original: Img43,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img44,
    original: Img44,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img45,
    original: Img45,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img46,
    original: Img46,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img47,
    original: Img47,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img48,
    original: Img48,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: ImgBorisz1,
    original: ImgBorisz1,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: ImgBorisz2,
    original: ImgBorisz2,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: ImgBorisz3,
    original: ImgBorisz3,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: ImgBorisz4,
    original: ImgBorisz4,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img1,
    original: Img1,
    width: 1500,
    height: 1477,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img2,
    original: Img2,
    width: 1500,
    height: 998,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img3,
    original: Img3,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img4,
    original: Img4,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img5,
    original: Img5,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img6,
    original: Img6,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img7,
    original: Img7,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img8,
    original: Img8,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img9,
    original: Img9,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img10,
    original: Img10,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img11,
    original: Img11,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img12,
    original: Img12,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img13,
    original: Img13,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img14,
    original: Img14,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img15,
    original: Img15,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img16,
    original: Img16,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img17,
    original: Img17,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img18,
    original: Img18,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img19,
    original: Img19,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img20,
    original: Img20,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  
  {
    src: Img21,
    original: Img21,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img22,
    original: Img22,
    width: 1218,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img23,
    original: Img23,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img24,
    original: Img24,
    width: 1340,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img25,
    original: Img25,
    width: 1500,
    height: 1111,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img26,
    original: Img26,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img27,
    original: Img27,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img28,
    original: Img28,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img29,
    original: Img29,
    width: 1500,
    height: 1125,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img30,
    original: Img30,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img31,
    original: Img31,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img32,
    original: Img32,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img33,
    original: Img33,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img34,
    original: Img34,
    width: 1500,
    height: 1436,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img35,
    original: Img35,
    width: 1500,
    height: 785,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img36,
    original: Img36,
    width: 1498,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img37,
    original: Img37,
    width: 1491,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img38,
    original: Img38,
    width: 1125,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
  {
    src: Img39,
    original: Img39,
    width: 1500,
    height: 1500,
    alt: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
    caption: "Cathletic Macskabútor Kaparófa Falra szerelhető Macskafal Macskamászóka Macskakaparó",
  },
]
