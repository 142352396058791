import React from 'react'
import { Layer, Circle, Line } from 'react-konva'
import { useSelector } from 'react-redux'
import '../../../WallDesigner.css'

export default function Slots(props) {
  const wall = useSelector((state) => state.wall)
  function IsCursorOnEnpoint() {
    let result = false
    wall.fabric.endPoints.forEach((end) => {
      if (
        end.pos.x === wall.canvas.cursor.x &&
        end.pos.y === wall.canvas.cursor.y
      ) {
        result = true
      }
    })
    return result
  }
  function CalcSlotPosition(slot) {
    if (slot.direction === 'left') {
    }
  }
  return (
    <>
      {typeof props.slots !== 'undefined' &&
        props.slots.length > 0 &&
        props.slots.map((slot) => {
          return (
            <>
              <Circle
                radius={10}
                x={
                  slot.direction == 'left'
                    ? slot.pos.x + props.offsetX
                    : slot.pos.x + 52 + props.offsetX
                }
                y={slot.pos.y - 9}
                fill={slot.free ? '#5BB069' : '#F28482'}
              />
            </>
          )
        })}
    </>
  )
}
