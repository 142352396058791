import React from 'react'
import { Link } from 'react-router-dom'
import Rating from './Rating'
import { Container, Card, Button, Row, Col, Image } from 'react-bootstrap'

const InstallPart = ({ product }) => {
  return (
    <>
      <Row className=' text-center'>
        <Col md={6}>
          <a href='manuals/parts/Hid_osszeszerelesi_V3.0.pdf' target='_blank'>
            <Card border='light' className='my-1'>
              <Card.Img src='manuals/parts/hid.webp' />
              <Card.Body>
                <p>
                  <strong>Híd szerelési útmutató</strong>
                </p>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col md={6}>
          <a href='manuals/parts/Kaparo_osszeszerelesi_V4.0.pdf' target='_blank'>
            <Card border='light' className='my-1'>
              <Card.Img src='manuals/parts/kaparo.webp' />
              <Card.Body>
                <p>
                  <strong>Kaparóoszlop szerelési útmutató</strong>
                </p>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col md={6}>
          <a href='manuals/parts/Kaparo_lepes_osszeszerelesi_V3.0.pdf' target='_blank'>
            <Card border='light' className='my-1'>
              <Card.Img src='manuals/parts/kaparo_lepes.webp' />
              <Card.Body className='align-items-center'>
                <p>
                  <strong>Kaparó lépés szerelési útmutató</strong>
                </p>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col md={6}>
          <a href='manuals/parts/Polc_osszeszerelesi_V3.0.pdf' target='_blank'>
            <Card border='light' className='my-1'>
              <Card.Img src='manuals/parts/polc.webp' />
              <Card.Body className='align-items-center'>
                <p>
                  <strong>Polc szerelési útmutató</strong>
                </p>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col md={6}>
          <a href='manuals/parts/Kis_Polc_osszeszerelesi_V3.0.pdf' target='_blank'>
            <Card border='light' className='my-1'>
              <Card.Img src='manuals/parts/kispolc.webp' />
              <Card.Body className='align-items-center'>
                <p>
                  <strong>
                    Virágtartó, Etető, Polc lépés szerelési útmutató
                  </strong>
                </p>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col md={6}>
          <a href='manuals/parts/Vaszonpolc_osszeszerelesi_V3.0.pdf' target='_blank'>
            <Card border='light' className='my-1'>
              <Card.Img src='manuals/parts/vaszonpolc.webp' />
              <Card.Body className='align-items-center'>
                <p>
                  <strong>Vászonpolc szerelési útmutató</strong>
                </p>
              </Card.Body>
            </Card>
          </a>
        </Col>
      </Row>
    </>
  )
}

export default InstallPart
