const shelvesLeft = ['10PVB', '10PTB', '10PLB', '10KO2B', '10KO3B']
const shelvesRight = ['10PVJ', '10PTJ', '10PLJ', '10KO2J', '10KO3J']
const shelves40 = ['10P40', '10P40OJ', '10P40OB']
const shelves80 = ['10P80', '10P80OJ', '10P80OB', '10H1K']
const fabrics40 = ['10V40W_H', '10V40B_H']
const fabrics50Down = ['10V50W_DOWN', '10V50B_DOWN']
const fabrics50Up = ['10V50W_UP', '10V50B_UP']
const fabrics40V = ['10V40W_VB', '10V40W_VJ', '10V40B_VJ', '10V40B_VB']
const fabrics80V = ['10V80W_VJ', '10V80W_VB', '10V80B_VJ', '10V80B_VB']
const fabrics50 = fabrics50Down.concat(fabrics50Up)
const fabrics80 = ['10V80W_H', '10V80B_H', '10H0K']
const fabricsHorAll = fabrics40.concat(fabrics50, fabrics80)
const fabricsVertAll = fabrics40V.concat(fabrics80V)
const pillows = ['10PP40W', '10PP40B']

const shelvesAll = ['10T', '10KL'].concat(
  shelvesLeft,
  shelvesRight,
  shelves40,
  shelves80
)
const polesDouble = ['10KO2B', '10KO2J']
const polesTriple = ['10KO3B', '10KO3J']
const polesLeft = ['10KO2B', '10KO3B']
const polesRight = ['10KO2J', '10KO3J']
const polesAll = polesLeft.concat(polesRight)
const shelvesAllButTop = shelvesAll.filter((item) => item !== '10T')
const horizontal40Items = fabrics40.concat(shelves40)
const horizontal80Items = ['10H1K'].concat(shelves80, fabrics80)
const allItems = pillows.concat(fabricsHorAll, shelvesAll, polesAll)

const _10PP40 = [
  {
    dimension: { x: 1, y: -3 },
    items: polesTriple,
  },
  {
    dimension: { x: 1, y: -2 },
    items: ['10KO2B', '10KO3B', '10KO2J'],
  },
  {
    dimension: { x: 1, y: -1 },
    items: ['10KO2B', '10KO3B'],
  },
  {
    dimension: { x: 0, y: -3 },
    items: polesTriple,
  },
  {
    dimension: { x: 0, y: -2 },
    items: ['10KO2J', '10KO2B', '10KO3J'],
  },
  {
    dimension: { x: 0, y: -1 },
    items: ['10KO2J', '10KO3J'],
  },
  {
    dimension: { x: 0, y: 0 },
    items: shelvesAllButTop.concat(horizontal40Items, horizontal80Items),
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items.concat(shelves40),
  },
  {
    dimension: { x: 1, y: 0 },
    items: shelvesAllButTop,
  },
  {
    dimension: { x: -2, y: 0 },
    items: shelves80,
  },
]
const _10V40_VB = [
  {
    dimension: { x: -1, y: 1 },
    items: horizontal80Items.concat(fabrics50Up, shelves40),
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items.concat(fabrics50Down, shelves40),
  },
  {
    dimension: { x: -2, y: 1 },
    items: shelves80,
  },
  {
    dimension: { x: -2, y: 0 },
    items: shelves80,
  },
  {
    dimension: { x: 0, y: 0 },
    items: polesAll.concat(fabrics50Down, fabricsVertAll, shelvesLeft),
  },
  {
    dimension: { x: 0, y: 1 },
    items: fabrics50Up.concat(shelvesLeft),
  },
  {
    dimension: { x: 0, y: -1 },
    items: polesAll.concat(fabrics80V),
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesTriple,
  },
]
const _10V40_VJ = [
  {
    dimension: { x: -1, y: 1 },
    items: horizontal80Items.concat(fabrics50Up),
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items.concat(fabrics50Down),
  },
  {
    dimension: { x: 0, y: 0 },
    items: polesAll.concat(
      fabrics50Down,
      fabricsVertAll,
      shelves40,
      shelves80,
      shelvesRight
    ),
  },
  {
    dimension: { x: 0, y: 1 },
    items: fabrics50Up.concat(shelves40, shelves80, shelvesRight),
  },
  {
    dimension: { x: 0, y: -1 },
    items: polesAll.concat(fabrics80V),
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesTriple,
  },
]
const _10V40_H = [
  {
    dimension: { x: 1, y: -3 },
    items: ['10KO3B'],
  },
  {
    dimension: { x: 1, y: -2 },
    items: polesLeft,
  },
  {
    dimension: { x: 1, y: -1 },
    items: polesLeft,
  },
  {
    dimension: { x: 0, y: -3 },
    items: ['10KO3J'],
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesRight,
  },
  {
    dimension: { x: 0, y: -1 },
    items: polesRight.concat(fabrics50Down),
  },
  {
    dimension: { x: 0, y: 0 },
    items: horizontal40Items.concat(
      ['10KL'],
      shelvesRight,
      fabrics50,
      horizontal80Items
    ),
  },
  {
    dimension: { x: 0, y: 1 },
    items: fabrics50Up,
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items,
  },
  {
    dimension: { x: 1, y: 0 },
    items: shelvesLeft.concat(['10KL']),
  },
]
const _10V50_DOWN = [
  {
    dimension: { x: 1, y: -2 },
    items: ['10KO3B'],
  },
  {
    dimension: { x: 1, y: -1 },
    items: polesLeft,
  },
  {
    dimension: { x: 1, y: 0 },
    items: polesLeft.concat(fabrics40V),
  },
  {
    dimension: { x: 0, y: -3 },
    items: ['10KO3J'],
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesRight,
  },
  {
    dimension: { x: 0, y: -1 },
    items: polesRight,
  },
  {
    dimension: { x: 0, y: 1 },
    items: horizontal80Items.concat(fabrics50Up, horizontal40Items),
  },
  {
    dimension: { x: 0, y: 2 },
    items: fabrics50Up,
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items,
  },
  {
    dimension: { x: -1, y: 1 },
    items: horizontal80Items,
  },
  {
    dimension: { x: 0, y: 0 },
    items: horizontal80Items.concat(
      fabricsHorAll,
      fabrics40V,
      horizontal40Items,
      shelvesRight
    ),
  },
  {
    dimension: { x: 1, y: 1 },
    items: shelvesLeft,
  },
]
const _10V50_UP = [
  {
    dimension: { x: 1, y: -4 },
    items: ['10KO3B'],
  },
  {
    dimension: { x: 1, y: -3 },
    items: polesLeft,
  },
  {
    dimension: { x: 1, y: -2 },
    items: polesLeft,
  },
  {
    dimension: { x: 1, y: -1 },
    items: shelvesLeft.concat(fabrics40V),
  },
  {
    dimension: { x: -1, y: -1 },
    items: horizontal80Items,
  },
  {
    dimension: { x: 0, y: -3 },
    items: ['10KO3J'],
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesRight.concat(fabrics50Down),
  },
  {
    dimension: { x: 0, y: -1 },
    items: horizontal80Items.concat(
      fabrics50Down,
      fabrics40V,
      horizontal40Items,
      polesRight
    ),
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items,
  },
  {
    dimension: { x: 0, y: 0 },
    items: horizontal80Items.concat(fabrics50, horizontal40Items, shelvesRight),
  },
]
const _10V80_H = [
  {
    dimension: { x: 2, y: -3 },
    items: ['10KO3B'],
  },
  {
    dimension: { x: 2, y: -2 },
    items: polesLeft,
  },
  {
    dimension: { x: 2, y: -1 },
    items: polesLeft,
  },
  {
    dimension: { x: 1, y: -3 },
    items: polesTriple,
  },
  {
    dimension: { x: 1, y: -2 },
    items: polesAll,
  },
  {
    dimension: { x: 1, y: -1 },
    items: polesAll.concat(fabrics50Down),
  },
  {
    dimension: { x: 1, y: 1 },
    items: fabrics50Up,
  },
  {
    dimension: { x: 0, y: -3 },
    items: ['10KO3J'],
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesRight,
  },
  {
    dimension: { x: 0, y: -1 },
    items: polesRight,
  },
  {
    dimension: { x: 0, y: 0 },
    items: horizontal80Items.concat(
      ['10KL'],
      shelvesRight,
      shelves40,
      shelves80,
      horizontal40Items
    ),
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items,
  },
  {
    dimension: { x: 1, y: 0 },
    items: ['10K', '10KL'].concat(shelvesAll),
  },
  {
    dimension: { x: 2, y: 0 },
    items: shelvesLeft.concat(['10KL']),
  },
]
const _10V80_VB = [
  {
    dimension: { x: -2, y: 0 },
    items: horizontal80Items,
  },
  {
    dimension: { x: -2, y: 1 },
    items: horizontal80Items,
  },
  {
    dimension: { x: -2, y: 2 },
    items: horizontal80Items,
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items.concat(fabrics50Down, horizontal40Items),
  },
  {
    dimension: { x: -1, y: 1 },
    items: horizontal80Items.concat(
      fabrics50Up,
      fabrics50Down,
      horizontal40Items
    ),
  },
  {
    dimension: { x: -1, y: 2 },
    items: horizontal80Items.concat(fabrics50Up, horizontal40Items),
  },
  {
    dimension: { x: 0, y: 0 },
    items: polesAll.concat(fabrics40V, shelvesLeft),
  },
  {
    dimension: { x: 0, y: 1 },
    items: fabrics50Up.concat(fabrics50Down, polesAll, shelvesLeft),
  },
  {
    dimension: { x: 0, y: 2 },
    items: fabrics50Up.concat(shelvesLeft),
  },
  {
    dimension: { x: 0, y: -1 },
    items: polesAll.concat(fabrics80V),
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesTriple,
  },
]
const _10V80_VJ = [
  {
    dimension: { x: -1, y: 2 },
    items: horizontal80Items.concat(fabrics50Up),
  },
  {
    dimension: { x: -1, y: 1 },
    items: horizontal80Items.concat(fabrics50Up, fabrics50Down),
  },
  {
    dimension: { x: -1, y: 0 },
    items: horizontal80Items.concat(fabrics50Down),
  },
  {
    dimension: { x: 0, y: 0 },
    items: polesAll.concat(
      fabrics40V,
      shelvesRight,
      horizontal40Items,
      horizontal80Items,
      fabrics50
    ),
  },
  {
    dimension: { x: 0, y: 1 },
    items: fabrics50Up.concat(
      fabrics50Down,
      fabrics40V,
      polesAll,
      shelvesRight,
      horizontal40Items,
      horizontal80Items
    ),
  },
  {
    dimension: { x: 0, y: 2 },
    items: fabrics50Up.concat(
      fabrics50Down,
      shelvesRight,
      horizontal40Items,
      horizontal80Items
    ),
  },
  {
    dimension: { x: 0, y: -1 },
    items: polesAll.concat(fabrics80V),
  },
  {
    dimension: { x: 0, y: -2 },
    items: polesTriple,
  },
]

export const conflicts = [
  // 10K
  {
    type: '10K',
    conflicts: [
      { dimension: { x: 0, y: 0 }, items: ['10K', '10KL'] },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items,
      },
    ],
  },
  // 10T
  {
    type: '10T',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: polesDouble,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  // 10KO2B
  {
    type: '10KO2B',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: ['10KO3B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3B'],
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2B', '10KO3J', '10KO3B'],
      },
      {
        dimension: { x: 0, y: 0 },
        items: pillows.concat(shelvesAllButTop, polesAll),
      },
      {
        dimension: { x: 0, y: 1 },
        items: shelvesLeft.concat('10KL', '10KO2B', '10KO3B'),
      },
      {
        dimension: { x: 0, y: 2 },
        items: allItems.filter(
          (item) => item !== '10T' && !fabricsHorAll.includes(item)
        ),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -1, y: 1 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -1, y: 2 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -2, y: 1 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -2, y: 2 },
        items: horizontal80Items,
      },
    ],
  },
  // 10KO2J
  {
    type: '10KO2J',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: ['10KO3B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J', '10KO3B'],
      },
      {
        dimension: { x: 0, y: 0 },
        items: allItems.filter((item) => item !== '10T'),
      },
      {
        dimension: { x: 0, y: 1 },
        items: shelvesRight.concat(
          horizontal40Items,
          horizontal80Items,
          '10KL',
          '10KO2J',
          '10KO3J'
        ),
      },
      {
        dimension: { x: 0, y: 2 },
        items: allItems.filter((item) => item !== '10T'),
      },
      {
        dimension: { x: -1, y: 0 },
        items: shelves40.concat(horizontal80Items),
      },
      {
        dimension: { x: -1, y: 1 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -1, y: 2 },
        items: shelves40.concat(horizontal80Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
      {
        dimension: { x: -2, y: 1 },
        items: [],
      },
      {
        dimension: { x: -2, y: 2 },
        items: shelves80,
      },
    ],
  },
  // 10KO3B
  {
    type: '10KO3B',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: ['10KO3B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3B'],
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2B', '10KO3J', '10KO3B'],
      },
      {
        dimension: { x: 0, y: 0 },
        items: pillows.concat(shelvesAllButTop, polesAll),
      },
      {
        dimension: { x: 0, y: 1 },
        items: shelvesLeft.concat('10KL'),
      },
      {
        dimension: { x: 0, y: 2 },
        items: shelvesLeft.concat('10KL'),
      },
      {
        dimension: { x: 0, y: 3 },
        items: allItems.filter(
          (item) => item !== '10T' && !fabricsHorAll.includes(item)
        ),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -1, y: 1 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -1, y: 2 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -1, y: 3 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -2, y: 1 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -2, y: 2 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -2, y: 3 },
        items: horizontal80Items,
      },
    ],
  },
  // 10KO3J
  {
    type: '10KO3J',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: ['10KO3B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J', '10KO3B'],
      },
      {
        dimension: { x: 0, y: 0 },
        items: allItems.filter((item) => item !== '10T'),
      },
      {
        dimension: { x: 0, y: 1 },
        items: shelvesRight.concat(
          horizontal40Items,
          horizontal80Items,
          '10KL',
          '10KO2J',
          '10KO3J'
        ),
      },
      {
        dimension: { x: 0, y: 2 },
        items: shelvesRight.concat(
          horizontal40Items,
          horizontal80Items,
          '10KL',
          '10KO2J',
          '10KO3J'
        ),
      },
      {
        dimension: { x: 0, y: 3 },
        items: allItems.filter((item) => item !== '10T'),
      },
      {
        dimension: { x: -1, y: 0 },
        items: shelves40.concat(horizontal80Items),
      },
      {
        dimension: { x: -1, y: 1 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -1, y: 2 },
        items: horizontal80Items,
      },
      {
        dimension: { x: -1, y: 3 },
        items: shelves40.concat(horizontal80Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
      {
        dimension: { x: -2, y: 1 },
        items: [],
      },
      {
        dimension: { x: -2, y: 2 },
        items: [],
      },
      {
        dimension: { x: -2, y: 3 },
        items: shelves80,
      },
    ],
  },
  // 10P40
  {
    type: '10P40',
    conflicts: [
      {
        dimension: { x: 1, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 1, y: -2 },
        items: ['10KO2B', '10KO3B', '10KO2J'],
      },
      {
        dimension: { x: 1, y: -1 },
        items: ['10KO2B', '10KO3B'],
      },
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J'].concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop.concat(
          horizontal40Items,
          horizontal80Items,
          fabrics50
        ),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: 1, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10P40OB',
    conflicts: [
      {
        dimension: { x: 1, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 1, y: -2 },
        items: ['10KO2B', '10KO3B', '10KO2J'],
      },
      {
        dimension: { x: 1, y: -1 },
        items: ['10KO2B', '10KO3B'],
      },
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J'].concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 0, y: 0 },
        items: pillows.concat(
          shelvesAllButTop,
          horizontal40Items,
          horizontal80Items,
          fabrics50
        ),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: 1, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10P40OJ',
    conflicts: [
      {
        dimension: { x: 1, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 1, y: -2 },
        items: ['10KO2B', '10KO3B', '10KO2J'],
      },
      {
        dimension: { x: 1, y: -1 },
        items: ['10KO2B', '10KO3B'],
      },
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J'].concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 0, y: 0 },
        items: pillows.concat(
          shelvesAllButTop,
          horizontal40Items,
          horizontal80Items,
          fabrics50
        ),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: 1, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10P80',
    conflicts: [
      {
        dimension: { x: 2, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 2, y: -2 },
        items: polesDouble.concat(['10KO3B']),
      },
      {
        dimension: { x: 2, y: -1 },
        items: polesLeft,
      },
      {
        dimension: { x: 1, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 1, y: -2 },
        items: polesAll,
      },
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 1, y: -1 },
        items: polesAll.concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J'].concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 1, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop.concat(fabricsHorAll),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: 1, y: 0 },
        items: shelvesAll.concat(fabricsHorAll),
      },
      {
        dimension: { x: 2, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10P80OB',
    conflicts: [
      {
        dimension: { x: 2, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 2, y: -2 },
        items: polesDouble.concat(['10KO3B']),
      },
      {
        dimension: { x: 2, y: -1 },
        items: polesLeft,
      },
      {
        dimension: { x: 1, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 1, y: -2 },
        items: polesAll,
      },
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 1, y: -1 },
        items: polesAll.concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J'].concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 1, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop.concat(pillows, fabricsHorAll),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: 1, y: 0 },
        items: shelvesAll.concat(fabricsHorAll),
      },
      {
        dimension: { x: 2, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10P80OJ',
    conflicts: [
      {
        dimension: { x: 2, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 2, y: -2 },
        items: polesDouble.concat(['10KO3B']),
      },
      {
        dimension: { x: 2, y: -1 },
        items: polesLeft,
      },
      {
        dimension: { x: 1, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 1, y: -2 },
        items: polesAll,
      },
      {
        dimension: { x: 1, y: -1 },
        items: polesAll.concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: -1 },
        items: ['10KO2J', '10KO3J'].concat(fabrics50Down),
      },
      {
        dimension: { x: 0, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 1, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: ['10KO2J', '10KO2B', '10KO3J'],
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop.concat(fabricsHorAll),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: 1, y: 0 },
        items: shelvesAll.concat(pillows, fabricsHorAll),
      },
      {
        dimension: { x: 2, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10PVB',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: polesLeft.concat(['10KO2J']),
      },
      {
        dimension: { x: 0, y: -1 },
        items: polesLeft,
      },
      {
        dimension: { x: -1, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: -1, y: -1 },
        items: fabrics50Down,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(horizontal40Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: horizontal80Items,
      },
    ],
  },
  {
    type: '10PVJ',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: polesRight.concat(['10KO2B']),
      },
      {
        dimension: { x: 0, y: -1 },
        items: polesRight,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop.concat(fabricsHorAll),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10PTB',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: polesLeft.concat(['10KO2J']),
      },
      {
        dimension: { x: 0, y: -1 },
        items: polesLeft,
      },
      {
        dimension: { x: -1, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: -1, y: -1 },
        items: fabrics50Down,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(horizontal40Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: horizontal80Items,
      },
    ],
  },
  {
    type: '10PTJ',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: polesRight.concat(['10KO2B']),
      },
      {
        dimension: { x: 0, y: -1 },
        items: polesRight,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop.concat(fabricsHorAll),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10PLJ',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: polesRight.concat(['10KO2B']),
      },
      {
        dimension: { x: 0, y: -1 },
        items: polesRight,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop.concat(fabricsHorAll),
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
    ],
  },
  {
    type: '10PLB',
    conflicts: [
      {
        dimension: { x: 0, y: -3 },
        items: polesTriple,
      },
      {
        dimension: { x: 0, y: -2 },
        items: polesLeft.concat(['10KO2J']),
      },
      {
        dimension: { x: -1, y: 1 },
        items: fabrics50Up,
      },
      {
        dimension: { x: -1, y: -1 },
        items: fabrics50Down,
      },
      {
        dimension: { x: 0, y: -1 },
        items: polesLeft,
      },
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(horizontal40Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: horizontal80Items,
      },
    ],
  },
  {
    type: '10V40W_H',
    conflicts: _10V40_H,
  },
  {
    type: '10V40W_VB',
    conflicts: _10V40_VB,
  },
  {
    type: '10V40W_VJ',
    conflicts: _10V40_VJ,
  },
  {
    type: '10V50W_UP',
    conflicts: _10V50_UP,
  },
  {
    type: '10V50W_DOWN',
    conflicts: _10V50_DOWN,
  },
  {
    type: '10V80W_H',
    conflicts: _10V80_H,
  },
  {
    type: '10V80W_VB',
    conflicts: _10V80_VB,
  },
  {
    type: '10V80W_VJ',
    conflicts: _10V80_VJ,
  },
  {
    type: '10V40B_H',
    conflicts: _10V40_H,
  },
  {
    type: '10V40B_VB',
    conflicts: _10V40_VB,
  },
  {
    type: '10V40B_VJ',
    conflicts: _10V40_VJ,
  },
  {
    type: '10V50B_UP',
    conflicts: _10V50_UP,
  },
  {
    type: '10V50B_DOWN',
    conflicts: _10V50_DOWN,
  },
  {
    type: '10V80B_H',
    conflicts: _10V80_H,
  },
  {
    type: '10V80B_VB',
    conflicts: _10V80_VB,
  },
  {
    type: '10V80B_VJ',
    conflicts: _10V80_VJ,
  },
  {
    type: 'cat',
    conflicts: [],
  },
  {
    type: '10KL',
    conflicts: [
      {
        dimension: { x: 0, y: 0 },
        items: allItems,
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal40Items.concat(horizontal80Items),
      },
      {
        dimension: { x: -2, y: 0 },
        items: horizontal80Items,
      },
    ],
  },
  {
    type: '10PP40W',
    conflicts: _10PP40,
  },
  {
    type: '10PP40B',
    conflicts: _10PP40,
  },
  {
    type: '10H0K',
    conflicts: _10V80_H,
  },
  {
    type: '10H1K',
    conflicts: [
      {
        dimension: { x: 0, y: 0 },
        items: shelvesAllButTop,
      },
      {
        dimension: { x: -1, y: 0 },
        items: horizontal80Items.concat(shelves40),
      },
      {
        dimension: { x: -2, y: 0 },
        items: shelves80,
      },
      {
        dimension: { x: 1, y: 0 },
        items: allItems,
      },
      {
        dimension: { x: 2, y: 0 },
        items: shelvesAllButTop,
      },
    ],
  },
]
