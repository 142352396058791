export const SELECT_WALLITEM_TOOL = (item) => {
  return {
    type: 'SELECT_WALLITEM_TOOL',
    payload: item,
  }
}
export const UPDATE_CANVAS_VIEW = (item) => {
  return {
    type: 'UPDATE_CANVAS_VIEW',
    payload: item,
  }
}
export const WALL_LOADER_SELECTED = (item) => {
  return {
    type: 'WALL_LOADER_SELECTED',
    payload: item,
  }
}
export const KEY_DOWN = (item) => {
  return {
    type: 'KEY_DOWN',
    payload: item,
  }
}
export const CANVAS_ZOOM_OUT = (item) => {
  return {
    type: 'CANVAS_ZOOM_OUT',
    payload: item,
  }
}
export const CANVAS_ZOOM_IN = (item) => {
  return {
    type: 'CANVAS_ZOOM_IN',
    payload: item,
  }
}
export const CANVAS_DEFAULT_ZOOM = (item) => {
  return {
    type: 'CANVAS_DEFAULT_ZOOM',
    payload: item,
  }
}
export const MOUSE_WHEEL_CHANGED = (item) => {
  return {
    type: 'MOUSE_WHEEL_CHANGED',
    payload: item,
  }
}
export const UPDATE_SCALE_BASED_ON_WALL_SIZE = (item) => {
  return {
    type: 'UPDATE_SCALE_BASED_ON_WALL_SIZE',
    payload: item,
  }
}
export const CANVAS_MOUSE_MOVED = (item) => {
  return {
    type: 'CANVAS_MOUSE_MOVED',
    payload: item,
  }
}
export const CART_DETAILED_VIEW_CLICKED = (item) => {
  return {
    type: 'CART_DETAILED_VIEW_CLICKED',
    payload: item,
  }
}
export const CART_SIMPLE_VIEW_CLICKED = (item) => {
  return {
    type: 'CART_SIMPLE_VIEW_CLICKED',
    payload: item,
  }
}
export const SET_CANVAS_BACKGROUND_COLOR = (item) => {
  return {
    type: 'SET_CANVAS_BACKGROUND_COLOR',
    payload: item,
  }
}
export const CANVAS_CLICKED = (item) => {
  return {
    type: 'CANVAS_CLICKED',
    payload: item,
  }
}
export const ADD_WALLITEM = (item) => {
  return {
    type: 'ADD_WALLITEM',
    payload: item,
  }
}
export const DELETE_WALLITEM = (item) => {
  return {
    type: 'DELETE_WALLITEM',
    payload: item,
  }
}
export const UPDATE_WALLITEM = (item) => {
  return {
    type: 'UPDATE_WALLITEM',
    payload: item,
  }
}
export const SELECT_WALLITEM = (item) => {
  return {
    type: 'SELECT_WALLITEM',
    payload: item,
  }
}
export const DESELECT_WALLITEMS = (item) => {
  return {
    type: 'DESELECT_WALLITEMS',
    payload: item,
  }
}
export const CLEAR_WALLITEM_LIST = () => {
  return {
    type: 'CLEAR_WALLITEM_LIST',
  }
}
export const SWITCH_COLOR_WALLITEM = (item) => {
  return {
    type: 'SWITCH_COLOR_WALLITEM',
    payload: item,
  }
}
export const SWITCH_SIDE_WALLITEM = (item) => {
  return {
    type: 'SWITCH_SIDE_WALLITEM',
    payload: item,
  }
}
export const SWITCH_SIZE_WALLITEM = (item) => {
  return {
    type: 'SWITCH_SIZE_WALLITEM',
    payload: item,
  }
}
