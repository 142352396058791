import React, { useState, } from 'react'
import { Container, Card, Button, Row, Col, Image } from 'react-bootstrap'
import InstallPart from '../components/InstallPart'
import { Helmet } from 'react-helmet-async'

const InstallScreen = ({ match, location }) => {

  return (
    <>
      <Helmet>
        <title>Cathletic Macskabútor Szerelési útmutatók</title>
        <meta name="description"
          content="Hogyan szereld új fali macska kaparófád és macskabútorod? Nézz körül részletes útmutatóink között, és nem marad több kérdésed. Profi leírás, prémium szerelési eszközök, hogy gond nélkül szerelhesd fel cicád új játszóterét." />
        <link rel="canonical" href="/install" />
      </Helmet>
      <div className='page-container'>
        <Container className=''>
          <Row className='align-items-center '>
            <Col className='py-0' md={6}>
              <h1 className='black-chango '>Szerelési útmutatók</h1>
              <h4>Terhelhetőség</h4>
              <p>
                A fali konzolokon terheléses teszteket végeztünk - a legnagyobb
                cicákra is gondolva - 40 kg-os súllyal.
              </p>
              <p>
                A teszteket porotherm, Ytong és tömör tégla falakon végeztük,
                Fischer DuoPower 8x65 tiplik használatával.
              </p>
              <Row className='justify-content-center'>
                <Col md={6} className='text-center'>
                  <Image className='tipli-image' src='images/duopower.webp'
                    alt='Cathletic Fischer DuoPower Tipli' />
                </Col>
              </Row>
              <p>
                A fali macskabútorokhoz adunk Fischer DuoPower 8x65-ös tipliket is,
                melyek a piacon kapható legmagasabb minőségű tiplik. Ez a típus
                kiemelkedő terhelési mutatókkal bír tömör téglában, porotherm
                téglában, Ytongban és betonban egyaránt.
              </p>
              <p className=''>
                Minden mászófalhoz küldünk fali csavarokat, hozzájuk tartozó
                bitfejet, egy 10 cm-es hosszabbítót a csavarbehajtóhoz és egy 3D
                nyomtatott szerelési segédet a könnyebb szerelés érdekében.
              </p>
            </Col>
            <Col md={6}>
              <Image
                variant='top'
                className='tool-image pb-5'
                src='images/cat_tools_3.webp'
                alt='Cathletic Szerelési Eszközök'
              />
            </Col>
          </Row>
          <Col>
            <Row className=''>
              <Col>
                <Card border='danger' className='mb-3'>
                  <Card.Header>
                    <h5 className='text-danger'>figyelmeztetés!</h5>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>
                      Gipszkarton falba, egy vagy két réteg esetén sem ajánljuk
                      a beépítést, mivel szilárdságuk messze elmarad a fent
                      említett faltípusoktól.
                    </Card.Title>
                    <Card.Text>
                      <h5>
                        Az egyes faltípusokhoz megfelelő tiplik kiválasztásához
                        kérd szakember segítségét!
                      </h5>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <p>
                  Ha bármilyen kérdésed merülne fel a szereléssel kapcsolatban
                  kereshetsz minket is a megadott elérhetőségek egyikén, de{' '}
                  <strong>
                    {' '}
                    a felszereléshez és a helyes tipli megválasztásához kérjed
                    szakember segítségét.
                  </strong>
                </p>
              </Col>
            </Row>
            <Row className=' text-center'>
              <InstallPart />
            </Row>
          </Col>
        </Container>
      </div>
    </>
  )
}

export default InstallScreen
