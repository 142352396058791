import { Image } from 'react-grid-gallery'
import Img14 from '../images/GalleryRenders/14.webp'
import Img15 from '../images/GalleryRenders/15.webp'
import Img16 from '../images/GalleryRenders/16.webp'
import Img17 from '../images/GalleryRenders/17.webp'
import Img18 from '../images/GalleryRenders/18.webp'
import Img19 from '../images/GalleryRenders/19.webp'
import Img20 from '../images/GalleryRenders/20.webp'
import Img21 from '../images/GalleryRenders/21.webp'
import Img22 from '../images/GalleryRenders/22.webp'
import Img23 from '../images/GalleryRenders/23.webp'
import Img24 from '../images/GalleryRenders/24.webp'
import Img25 from '../images/GalleryRenders/25.webp'
import Img26 from '../images/GalleryRenders/26.webp'
import Img27 from '../images/GalleryRenders/27.webp'
import Img28 from '../images/GalleryRenders/28.webp'
import Img29 from '../images/GalleryRenders/29.webp'
import Img30 from '../images/GalleryRenders/30.webp'
import Img31 from '../images/GalleryRenders/31.webp'
import Img32 from '../images/GalleryRenders/32.webp'
import Img33 from '../images/GalleryRenders/33.webp'
import Img34 from '../images/GalleryRenders/34.webp'
import Img1 from '../images/GalleryRenders/1.webp'
import Img2 from '../images/GalleryRenders/2.webp'
import Img3 from '../images/GalleryRenders/3.webp'
import Img4 from '../images/GalleryRenders/4.webp'
import Img5 from '../images/GalleryRenders/5.webp'
import Img6 from '../images/GalleryRenders/6.webp'
import Img7 from '../images/GalleryRenders/7.webp'
import Img8 from '../images/GalleryRenders/8.webp'
import Img9 from '../images/GalleryRenders/9.webp'
import Img10 from '../images/GalleryRenders/10.webp'
import Img11 from '../images/GalleryRenders/11.webp'
import Img12 from '../images/GalleryRenders/12.webp'
import Img13 from '../images/GalleryRenders/13.webp'

export const images = [
  
  {
    src: Img14,
    original: Img14,
    width: 1900,
    height: 1500,
  },
  {
    src: Img15,
    original: Img15,
    width: 1800,
    height: 1500,
  },
  {
    src: Img16,
    original: Img16,
    width: 1000,
    height: 1400,
  },
  {
    src: Img17,
    original: Img17,
    width: 1000,
    height: 1400,
  },
  {
    src: Img18,
    original: Img18,
    width: 1000,
    height: 1400,
  },
  {
    src: Img19,
    original: Img19,
    width: 1500,
    height: 1500,
  },
  {
    src: Img20,
    original: Img20,
    width: 1500,
    height: 1800,
  },
  {
    src: Img21,
    original: Img21,
    width: 2000,
    height: 1500,
  },
  {
    src: Img22,
    original: Img22,
    width: 1500,
    height: 1800,
  },
  {
    src: Img23,
    original: Img23,
    width: 2000,
    height: 1400,
  },
  {
    src: Img24,
    original: Img24,
    width: 2000,
    height: 1200,
  },
  {
    src: Img25,
    original: Img25,
    width: 2000,
    height: 1400,
  },
  {
    src: Img26,
    original: Img26,
    width: 1300,
    height: 1750,
  },
  {
    src: Img27,
    original: Img27,
    width: 1500,
    height: 2000,
  },
  {
    src: Img28,
    original: Img28,
    width: 2000,
    height: 1400,
  },
  {
    src: Img29,
    original: Img29,
    width: 1800,
    height: 1400,
  },
  {
    src: Img30,
    original: Img30,
    width: 1500,
    height: 2100,
  },
  {
    src: Img31,
    original: Img31,
    width: 2000,
    height: 1600,
  },
  {
    src: Img32,
    original: Img32,
    width: 1600,
    height: 2000,
  },
  {
    src: Img33,
    original: Img33,
    width: 1500,
    height: 1250,
  },
  {
    src: Img34,
    original: Img34,
    width: 1500,
    height: 1250,
  },
  {
    src: Img1,
    original: Img1,
    width: 1800,
    height: 1000,
  },
  {
    src: Img2,
    original: Img2,
    width: 3000,
    height: 2000,
  },
  {
    src: Img3,
    original: Img3,
    width: 2000,
    height: 1500,
  },
  {
    src: Img4,
    original: Img4,
    width: 2000,
    height: 1200,
  },
  {
    src: Img5,
    original: Img5,
    width: 3024,
    height: 4032,
  },
  {
    src: Img6,
    original: Img6,
    width: 2000,
    height: 1500,
  },
  {
    src: Img7,
    original: Img7,
    width: 1500,
    height: 2000,
  },
  {
    src: Img8,
    original: Img8,
    width: 2000,
    height: 1500,
  },
  {
    src: Img9,
    original: Img9,
    width: 1920,
    height: 1920,
  },
  {
    src: Img10,
    original: Img10,
    width: 2000,
    height: 1200,
  },
  {
    src: Img11,
    original: Img11,
    width: 2000,
    height: 1000,
  },
  {
    src: Img12,
    original: Img12,
    width: 1920,
    height: 1080,
  },
  {
    src: Img13,
    original: Img13,
    width: 2000,
    height: 1300,
  },
]
