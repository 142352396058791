import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Container } from 'react-bootstrap'
import { listProducts } from '../actions/productActions'
import Product from './Product'
import Message from './Message'
import Loader from './Loader'
import CatWallFilter from '../components/CatWallFilter'
import { Link } from 'react-router-dom'

const WebshopComplex = ({ match }) => {
  const dispatch = useDispatch();
  const [selectedLabels, setSelectedLabels] = useState({
    numberOfCats: null,
    age: null,
    sizeCat: null,
    wallSize: null,
    scratcher: null,
  });

  const { loading, error, products } = useSelector((state) => state.productList);

  useEffect(() => {
    dispatch(listProducts(match.params.keyword));
  }, [dispatch, match.params.keyword]);

  const handleLabelChange = (category, label) => {
    setSelectedLabels((prevLabels) => ({
      ...prevLabels,
      [category]: prevLabels[category] === label ? null : label,
    }));
  };

  const filterProducts = (product) => {
    return Object.entries(selectedLabels).every(([category, label]) => {
      if (label === null) {
        return true;
      }
      return product.labels.includes(label);
    });
  };

  const filteredProducts = products
    .filter((product) => product.category === 'complex')
    .filter(filterProducts)
    .sort((a, b) => a.price - b.price);

  return (
    <>
      <CatWallFilter className='' selectedLabels={selectedLabels} onLabelChange={handleLabelChange} />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : filteredProducts.length === 0 ? (
        <Message variant='warning' className='py-2'>
          Nincs találat a kiválasztott szűrőkkel, de ne csüggedj! Ingyenesen tervezünk egyedi igények szerint, személyre szabott mászófalakat látványtervekkel.
          <Link as={Link} to='/offers'>
            <Button
              className='lg offers rounded-pill button-text'
              variant='outline-primary'
            >
              Macskafal látványtervet kérek
            </Button>
          </Link>
        </Message>
      ) : (
        <Container>
          <Row className='footer-padding'>
            {filteredProducts.map((product) => (
              <Col key={product.id} xl={6}>
                <Product product={product} />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  )
}

export default WebshopComplex