import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCat, faCubes, faGift, faPalette, faLandmark, faClinicMedical, faDraftingCompass } from '@fortawesome/free-solid-svg-icons';

const NewsBar = () => {
  const newsBarContentRef = useRef(null);

  useEffect(() => {
    const newsBarContent = newsBarContentRef.current;
    const newsBarContentWidth = newsBarContent.offsetWidth;
    const newsBarWidth = newsBarContent.parentElement.offsetWidth;

    const scrollDuration = 10000; // Duration of the scroll animation in seconds
    const scrollSpeed = newsBarContentWidth / scrollDuration; // Pixels per second

    let currentPosition = 0;
    let scrollInterval;

    const startScroll = () => {
      scrollInterval = setInterval(() => {
        currentPosition -= scrollSpeed;
        newsBarContent.style.transform = `translateX(${currentPosition}px)`;

        if (currentPosition <= -newsBarContentWidth / 2) {
          currentPosition = 0;
        }
      }, 1000 / 120
      ); // 60 frames per second
    };

    const stopScroll = () => {
      clearInterval(scrollInterval);
    };

    if (newsBarContentWidth > newsBarWidth) {
      startScroll();
    } else {
      stopScroll();
    }

    return () => {
      stopScroll();
    };
  }, []);

  return (
    <div className="news-bar">
      <div className="news-bar-content" ref={newsBarContentRef}>
        <span>
          <FontAwesomeIcon icon={faCat} /> Több mint 200 elégedett cicaügyfél
        </span>
        <span>
          <FontAwesomeIcon icon={faCubes} /> Több millió falkombináció
        </span>
        <span>
          <FontAwesomeIcon icon={faGift} /> Személyre szabott kedvezmények
        </span>
        <span>
          <FontAwesomeIcon icon={faPalette} /> Választható színek - párna, vászon, kaparó és kaspó
        </span>
        <span>
          <FontAwesomeIcon icon={faLandmark} /> Cat Museum Budapest és Cat Cafe Budapest bemutató falak
        </span>
        <span>
          <FontAwesomeIcon icon={faClinicMedical} /> Állatorvosok ajánlásával - egészség | biztonság | hangulatjavítás | aktivitás | agresszió csökkentés
        </span>
        <span>
          <FontAwesomeIcon icon={faDraftingCompass} /> Ingyenes faltervezés
        </span>

        {/* Duplicate the content to create a seamless loop */}
        <span>
          <FontAwesomeIcon icon={faCat} /> Több mint 200 elégedett cicaügyfél
        </span>
        <span>
          <FontAwesomeIcon icon={faCubes} /> Több millió falkombináció
        </span>
        <span>
          <FontAwesomeIcon icon={faGift} /> Személyre szabott kedvezmények
        </span>
        <span>
          <FontAwesomeIcon icon={faPalette} /> Választható színek - párna, vászon, kaparó és kaspó
        </span>
        <span>
          <FontAwesomeIcon icon={faLandmark} /> Cat Museum Budapest és Cat Cafe Budapest bemutató falak
        </span>
        <span>
          <FontAwesomeIcon icon={faClinicMedical} /> Állatorvosok ajánlásával - egészség | biztonság | hangulatjavítás | aktivitás | agresszió csökkentés
        </span>
        <span>
          <FontAwesomeIcon icon={faDraftingCompass} /> Ingyenes faltervezés
        </span>
      </div>
    </div>
  );
};

export default NewsBar;