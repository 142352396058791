import React from 'react'
import { Image, Col, Row } from 'react-bootstrap'

const CustomizeItem = ({ customize }) => {
    return (
        < Row className='my-3 d-flex align-items-center'>
            <Col md={9}>
                {customize && customize.sisal === 'natural' && (
                    <>
                        < Image
                            src='/images/sisal_natural.jpg'
                            className='swatch-disabled checkedSwatch'
                        /> &nbsp;
                    </>
                )}
                {customize && customize.sisal === 'black' && (
                    <>
                        <Image
                            src='/images/sisal_black.jpg'
                            className='swatch-disabled checkedSwatch '
                        /> &nbsp;
                    </>
                )}

                {/* Fabric buttons */}
                {customize && customize.fabric === 'white' && (
                    <>
                        < Image
                            src='/images/fabric_white.jpg'
                            className='checkedSwatch swatch-disabled'
                        /> &nbsp;
                    </>
                )}
                {customize && customize.fabric === 'black' && (
                    <>
                        <Image
                            src='/images/fabric_black.jpg'
                            className='checkedSwatch swatch-disabled'
                        /> &nbsp;
                    </>
                )}

                {/* Pillow buttons */}
                {/* {customize && customize.pillow === 'white' && (
                    <>
                        <Image
                            src='/images/pillow_white.jpg'
                            className='checkedSwatch swatch-disabled'
                        /> &nbsp;
                    </>
                )} */}
                {/* {customize && customize.pillow === 'black' && (
                    <>
                        <Image
                            src='/images/pillow_black.jpg'
                            className='checkedSwatch swatch-disabled'
                        /> &nbsp;
                    </>
                )}
                {customize && customize.pillow === 'green' && (
                    <>
                        <Image
                            src='/images/pillow_green.jpg'
                            className='checkedSwatch swatch-disabled'
                        /> &nbsp;
                    </>
                )} */}
                {/* {customize && customize.pillow === 'yellow' && (
                    <>
                        <Image
                            src='/images/pillow_yellow.jpg'
                            className='checkedSwatch swatch-disabled'
                        /> &nbsp;
                    </>
                )} */}
                {customize && customize.pillow === 'blue' && (
                    <>
                        <Image
                            src='/images/pillow_blue.jpg'
                            className='checkedSwatch swatch-disabled'
                        /> &nbsp;
                    </>
                )}
            </Col>
        </Row>
    )
}

export default CustomizeItem
