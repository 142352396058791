import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DELETE_WALLITEM,
  SWITCH_COLOR_WALLITEM,
  SWITCH_SIDE_WALLITEM,
} from '../../../../actions/WD/wallActions'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faPalette } from '@fortawesome/free-solid-svg-icons'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

function ItemEditor() {
  const dispatch = useDispatch()
  let wall = useSelector((state) => state.wall)

  let selectedItem = GetSelectedItem()

  function GetSelectedItem() {
    let selectedItems = wall.items.filter(function (item) {
      return item.selected == true
    })
    if (selectedItems.length > 0) {
      return selectedItems[0]
    }
  }

  function GetItemData(item) {
    return wall.itemsData.filter(function (wallItem) {
      return wallItem.type === item.type
    })[0]
  }

  function IsSwitchAvailable(switchType) {
    let itemData = GetItemData(selectedItem)
    if (itemData.hasOwnProperty('switch') === false) return false
    else if (itemData.switch.hasOwnProperty(switchType)) return true
    else return false
  }

  if (typeof selectedItem !== 'undefined') {
    return (
      <>
        <h5 className='mt-3'>
          <span class='tab-space my-3'>
            <FontAwesomeIcon icon={faEdit} />
          </span>
          Szerkesztés
        </h5>
        <OverlayTrigger
          placement='right'
          delay={{ show: 650, hide: 200 }}
          overlay={
            <Tooltip id={'tooltip-right'}>Kiválasztott elem törlése</Tooltip>
          }
        >
          <button
            className='btn btn-outline-dark my-2 py-1 px-2'
            onClick={() => dispatch(DELETE_WALLITEM(GetSelectedItem()))}
          >
            <FontAwesomeIcon size='lg mr-2' icon={faTrashAlt} /> Törlés
          </button>
        </OverlayTrigger>
        <br />
        {/* {IsSwitchAvailable('color') && (
          <>
            <button
              className='btn btn-outline-dark my-1 '
              onClick={() => dispatch(SWITCH_COLOR_WALLITEM())}
            >
              <span class='tab-space'>
                <FontAwesomeIcon icon={faPalette} />
              </span>
              Színcsere
            </button>
            <br />
          </>
        )} */}

        {IsSwitchAvailable('side') && (
          <>
            <button
              className='btn btn-outline-dark py-1 px-2'
              onClick={() => dispatch(SWITCH_SIDE_WALLITEM())}
            >
              <span class='tab-space'>
                <FontAwesomeIcon icon={faExchangeAlt} />
              </span>
              Oldalváltás
            </button>
            <br />
          </>
        )}
      </>
    )
  } else {
    return <></>
  }
}

export default ItemEditor
