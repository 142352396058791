import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Button } from 'react-bootstrap'
import WebshopPart from '../components/WebshopPart'
import { listProducts } from '../actions/productActions'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const WebshopPartScreen = ({ match }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listProducts(keyword))
  }, [dispatch, keyword])

  return (
    <>
      <Helmet>
        <title>Cathletic Macskabútor - Önálló Elemeink</title>
        <meta name="description"
          content="Cathletic Macskabútor és kaparófa. Egyedi bútoraink, személyre szabva." />
        <link rel="canonical" href="/webshoppart" />
      </Helmet>
      <div className='page-container pb-0'>
        <Container>
          <Row >
            <Link as={Link} to='/webshop'>
              <Button className='' variant='outline-primary' size='sm'>
                Vissza a Termékekhez
              </Button>
            </Link>
          </Row>
          <Row className=' text-center footer-padding'>
            <h1 className='black-chango p-0 '>önálló elemek</h1>
            <p className='big'>
              Építsd meg álmaid cica kalandparkját!
            </p>
            <p className=''>
              Lepd meg cicádat egy új játszótérrel!
            </p>
            <p>
              Óriási választékunkban rengeteg pihenő párna, polc, kaparó, vászon, híd megtalálható. Továbbá számos szín közül választhatsz.</p>
            <WebshopPart match={match} />
          </Row>
        </Container>
      </div >
    </>
  )
}

export default WebshopPartScreen
