import { useDispatch, useSelector } from 'react-redux'
import {
  faPlus,
  faMinus,
  faExpand,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CANVAS_ZOOM_OUT,
  CANVAS_ZOOM_IN,
  CANVAS_DEFAULT_ZOOM,
} from '../../../../actions/WD/wallActions'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './Resizer.css'
import '../Sidebar.css'

export default function Resizer() {
  const dispatch = useDispatch()
  const canvas = useSelector((state) => state.wall.canvas)

  return (
    <>
      <div className='App-menu_bottom'>
        <div className='resize-island'>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>Kicsinyítés - Egér görgő</Tooltip>}
          >
            <Button
              className='btn-dark btn-resize py-1 px-2'
              onClick={() => dispatch(CANVAS_ZOOM_OUT())}
            >
              <FontAwesomeIcon size='lg' icon={faMinus} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>Nagyítás - Egér görgő</Tooltip>}
          >
            <Button
              className='btn-dark btn-resize ml-1 py-1 px-2'
              onClick={() => dispatch(CANVAS_ZOOM_IN())}
            >
              <FontAwesomeIcon size='lg' icon={faPlus} />
            </Button>
          </OverlayTrigger>
          <Button disabled className='btn-light btn-resize ml-2 py-1 px-2'>
            <font>{canvas.zoom}%</font>
          </Button>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>Alap nézet</Tooltip>}
          >
            <Button
              className='btn-dark btn-resize ml-2 py-1 px-2'
              onClick={() => dispatch(CANVAS_DEFAULT_ZOOM())}
            >
              <FontAwesomeIcon size='lg' icon={faExpand} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                A balra, jobbra, lefelé, felfelé billentyűk lenyomásával
                mozgathatod a falat. Az egér görgővel és a +/- gombokkal
                kicsinyítheted és nagyíthatod.'
              </Tooltip>
            }
          >
            <Button className='btn-warning btn-resize ml-2 py-1 px-2'>
              <FontAwesomeIcon size='lg' icon={faQuestion} />
            </Button>
          </OverlayTrigger>
        </div>
      </div>
    </>
  )
}
