import React from 'react'
import { Button, Row, Col, ButtonGroup, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBabyCarriage, faSignal, faExpand, faCouch, faListOl } from '@fortawesome/free-solid-svg-icons';

const CatWallFilter = ({ selectedLabels, onLabelChange }) => {
  return (
    <Container className='text-center'>
      <Row className=''>
        <Col>
          <Row className=''>
            <Col className="d-flex flex-column align-items-center justify-content-center component-container">
              <h5 className='catdreamwall-h5 mb-1'>
                <span style={{ color: '#599C76', marginRight: '0.5rem' }}>
                  <FontAwesomeIcon className='catdreamwall-icon' icon={faListOl} />
                </span>
                Cicáim száma
              </h5>
              <div className="d-flex align-items-center">
                <label className="modern-radio-label me-3">
                  <input
                    type="radio"
                    name="numberOfCats"
                    checked={selectedLabels.numberOfCats === 'onecat'}
                    onClick={() => {
                      if (selectedLabels.numberOfCats === 'onecat') {
                        onLabelChange('numberOfCats', null);
                      } else {
                        onLabelChange('numberOfCats', 'onecat');
                      }
                    }}
                  />
                  <span className="modern-radio-button"></span>
                  EGY
                </label>
                <label className="modern-radio-label">
                  <input
                    type="radio"
                    name="numberOfCats"
                    checked={selectedLabels.numberOfCats === 'morecat'}
                    onClick={() => {
                      if (selectedLabels.numberOfCats === 'morecat') {
                        onLabelChange('numberOfCats', null);
                      } else {
                        onLabelChange('numberOfCats', 'morecat');
                      }
                    }}
                  />
                  <span className="modern-radio-button"></span>
                  TÖBB
                </label>
              </div>
            </Col>
            {/* <Col className="d-flex flex-column align-items-center justify-content-center component-container">
              <h5 className='catdreamwall-h5 mb-1'>
                <span style={{ color: '#599C76', marginRight: '0.5rem' }}>
                  <FontAwesomeIcon className='catdreamwall-icon' icon={faBabyCarriage} />
                </span>
                Cicám életkora
              </h5>
              <div className="d-flex align-items-center">
                <label className="modern-radio-label me-3">
                  <input
                    type="radio"
                    name="age"
                    checked={selectedLabels.age === 'young'}
                    onClick={() => onLabelChange('age', 'young')}
                  />
                  <span className="modern-radio-button"></span>
                  KÖLYÖK
                </label>
                <label className="modern-radio-label me-3">
                  <input
                    type="radio"
                    name="age"
                    checked={selectedLabels.age === 'adult'}
                    onClick={() => onLabelChange('age', 'adult')}
                  />
                  <span className="modern-radio-button"></span>
                  FELNŐTT
                </label>
                <label className="modern-radio-label">
                  <input
                    type="radio"
                    name="age"
                    checked={selectedLabels.age === 'senior'}
                    onClick={() => onLabelChange('age', 'senior')}
                  />
                  <span className="modern-radio-button"></span>
                  Idős
                </label>
              </div>
            </Col> */}
            <Col className="d-flex flex-column align-items-center justify-content-center component-container">
              <h5 className='catdreamwall-h5 mb-1'>
                <span style={{ color: '#599C76', marginRight: '0.5rem' }}>
                  <FontAwesomeIcon className='catdreamwall-icon' icon={faSignal} />
                </span>
                Cicám mérete
              </h5>
              <div className="d-flex align-items-center">
                <label className="modern-radio-label me-3">
                  <input
                    type="radio"
                    name="sizeCat"
                    checked={selectedLabels.sizeCat === 'small'}
                    onClick={() => onLabelChange('sizeCat', 'small')}
                  />
                  <span className="modern-radio-button"></span>
                  KICSI
                </label>
                <label className="modern-radio-label me-3">
                  <input
                    type="radio"
                    name="sizeCat"
                    checked={selectedLabels.sizeCat === 'normal'}
                    onClick={() => onLabelChange('sizeCat', 'normal')}
                  />
                  <span className="modern-radio-button"></span>
                  NORMÁL
                </label>
                <label className="modern-radio-label">
                  <input
                    type="radio"
                    name="sizeCat"
                    checked={selectedLabels.sizeCat === 'big'}
                    onClick={() => onLabelChange('sizeCat', 'big')}
                  />
                  <span className="modern-radio-button"></span>
                  NAGY
                </label>
              </div>
            </Col>

            <Col className="d-flex flex-column align-items-center justify-content-center component-container">
              <h5 className='catdreamwall-h5 mb-1'>
                <span style={{ color: '#599C76', marginRight: '0.5rem' }}>
                  <FontAwesomeIcon className='catdreamwall-icon' icon={faExpand} />
                </span>
                Szabad falfelületem
              </h5>
              <div className="d-flex align-items-center">
                <label className="modern-radio-label me-3">
                  <input
                    type="radio"
                    name="wallSize"
                    checked={selectedLabels.wallSize === 'smallWall'}
                    onClick={() => onLabelChange('wallSize', 'smallWall')}
                  />
                  <span className="modern-radio-button"></span>
                  KICSI
                </label>
                <label className="modern-radio-label">
                  <input
                    type="radio"
                    name="wallSize"
                    checked={selectedLabels.wallSize === 'bigWall'}
                    onClick={() => onLabelChange('wallSize', 'bigWall')}
                  />
                  <span className="modern-radio-button"></span>
                  NAGY
                </label>
              </div>
            </Col>
            <Col className="d-flex flex-column align-items-center justify-content-center component-container">
              <h5 className='catdreamwall-h5 mb-1'>
                <span style={{ color: '#599C76', marginRight: '0.5rem' }}>
                  <FontAwesomeIcon className='catdreamwall-icon' icon={faCouch} />
                </span>
                Kaparót
              </h5>
              <div className="d-flex align-items-center">
                <label className="modern-radio-label me-3">
                  <input
                    type="radio"
                    name="scratcher"
                    checked={selectedLabels.scratcher === 'scratch'}
                    onClick={() => onLabelChange('scratcher', 'scratch')}
                  />
                  <span className="modern-radio-button"></span>
                  KÉREK
                </label>
                <label className="modern-radio-label">
                  <input
                    type="radio"
                    name="scratcher"
                    checked={selectedLabels.scratcher === 'noScratch'}
                    onClick={() => onLabelChange('scratcher', 'noScratch')}
                  />
                  <span className="modern-radio-button"></span>
                  NEM KÉREK
                </label>
              </div>
            </Col>
          </Row>
        </Col>
      </Row >
    </Container >
  )
}

export default CatWallFilter