import React, { useState, useEffect } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { images } from '../components/GalleryRenders'
import ContactForm from '../components/ContactForm'
import { Gallery } from 'react-grid-gallery'
import Lightbox from 'react-image-lightbox'
import { Helmet } from 'react-helmet-async'

const OffersScreen = ({ match, location }) => {
  const [index, setIndex] = useState(-1)

  const currentImage = images[index]
  const nextIndex = (index + 1) % images.length
  const nextImage = images[nextIndex] || currentImage
  const prevIndex = (index + images.length - 1) % images.length
  const prevImage = images[prevIndex] || currentImage

  const handleClick = (index, CustomImage) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  return (
    <>
      <Helmet>
        <title>Cathletic Macskabútor - Egyedi Ajánlatok</title>
        <meta name="description"
          content="Ingyenes Látványtervek | Egyedi Macskabútorok" />
        <link rel="canonical" href="/offers" />
      </Helmet>
      <div className='page-container'>
        <Container className='header-margin'>
          <Row className='align-items-center text-center  '>
            <h1 className='black-chango '>INGYENES LÁTVÁNYTERVEZÉS</h1>
            <h5 className="mb-4 text-center" style={{ color: '#000' }}>
              Küldj egy képet a faladról, add meg a méreteket, és a többit
              bízd ránk!
            </h5>
          </Row>
          <Row>
            <Col md={6}>
              <ContactForm submitButtonText={'INGYENES LÁTVÁNYTERVET KÉREK'} />
            </Col>
            <Col md={6}>
              <Image
                className='contact-image rounded-image'
                src='images/banner.webp'
                alt='Cathletic Macskafal Mozgó'
              />
            </Col>
          </Row>
          <Row className='align-items-center px-3 mt-3 pt-3'>
            <Col md={12}>
              <p className=''>
                3D látványterveink segítenek, hogy az új kalandpark
                tökéletesen illeszkedjen otthonod stílusához és a macskád
                igényeihez. Ne habozz tovább, lépj velünk kapcsolatba még ma!
              </p>
              <p>
                Messengeren is írhatsz, a jobb alsó sarokban találod a chatet{' '}
                <span style={{ color: '#599c76' }} className=''>
                  <i class='fab fa-facebook-messenger'></i>
                </span>
                . Írj ránk, és válaszolunk, ahogy tudunk.
              </p>
            </Col>
          </Row>
          <Row className='align-items-center px-3'>
            <p className='pt-2'>
              Itt körülnézhetsz a már elkészült terveink közül. Ezek a képek nem a
              felszerelt macskafalakról készültek, hanem a 3D látványtervek,
              amiket a nekünk küldött fotókból készítettünk. Ezek a tervek
              segítettek vevőinknek, hogy a rendelés előtt, élőben lássák, hogy az
              új mászófal hogyan illeszkedik a lakásba. Így nem csak a cica kap
              egy új kalandparkot, hanem a gazdi is egy pontos képet, hogy a
              cicabútor tökéletesen illeszkedjen az otthonához.
            </p>
            <a href='/gallery'>
              <p className='bold'>
                Ha az elkészült falak érdekelnének használat közben, akkor itt
                nézz körül :)
              </p>
            </a>
          </Row>
        </Container>
        <Gallery
          rowHeight={350}
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>
    </>
  )
}

export default OffersScreen
