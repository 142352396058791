import { useDispatch } from 'react-redux'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WALL_LOADER_SELECTED } from '../../../../actions/WD/wallActions'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function WallLoader() {
  const dispatch = useDispatch()

  const matra = require('../../../../reducers/WD/Walls/matra.json')
  const borzsony = require('../../../../reducers/WD/Walls/borzsony.json')
  const bukk = require('../../../../reducers/WD/Walls/bukk.json')
  const mont_blanc = require('../../../../reducers/WD/Walls/mont_blanc.json')
  const dolomitok = require('../../../../reducers/WD/Walls/dolomitok.json')
  const olympos = require('../../../../reducers/WD/Walls/olympos.json')
  const kilimandzsaro = require('../../../../reducers/WD/Walls/kilimandzsaro.json')
  const fudzsi = require('../../../../reducers/WD/Walls/fudzsi.json')

  const WallLoaderSelected = (wall) => {
    dispatch(WALL_LOADER_SELECTED(wall))
  }

  return (
    <>
      <Dropdown>
        <OverlayTrigger
          placement='top'
          delay={{ show: 650, hide: 200 }}
          overlay={<Tooltip>Fal megnyitása</Tooltip>}
        >
          <Dropdown.Toggle className='btn btn-success mx-1 px-2 py-2'>
            <FontAwesomeIcon size='lg' className='mr-2' icon={faFolderOpen} />
          </Dropdown.Toggle>
        </OverlayTrigger>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => WallLoaderSelected(kilimandzsaro)}>
            Kilimandzsáró
          </Dropdown.Item>
          <Dropdown.Item onClick={() => WallLoaderSelected(fudzsi)}>
            Fudzsi
          </Dropdown.Item>
          <Dropdown.Item onClick={() => WallLoaderSelected(olympos)}>
            Olympos
          </Dropdown.Item>
          <Dropdown.Item onClick={() => WallLoaderSelected(dolomitok)}>
            Dolomitok
          </Dropdown.Item>
          <Dropdown.Item onClick={() => WallLoaderSelected(mont_blanc)}>
            Mont Blanc
          </Dropdown.Item>
          <Dropdown.Item onClick={() => WallLoaderSelected(bukk)}>
            Bükk
          </Dropdown.Item>
          <Dropdown.Item onClick={() => WallLoaderSelected(borzsony)}>
            Börzsöny
          </Dropdown.Item>
          <Dropdown.Item onClick={() => WallLoaderSelected(matra)}>
            Mátra
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
