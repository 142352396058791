import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Button, Row, Col } from 'react-bootstrap'

const Post = ({ post }) => {
  return (
    <Card className='my-3 p-3 rounded'>
      <Link to={`/post/${post.id}`}>
        <Card.Img
          src={post.image}
          variant='top'
        />
      </Link>
      <Card.Body>
        <Link to={`/post/${post.id}`}>
          <Card.Title as='H5'>{post.name}</Card.Title>
          <Card.Subtitle className="my-2"></Card.Subtitle>
          <Row className=' mb-0'>
              <Col md={8}>
                <Button className='mb-0' variant='outline-success' size='sm'>
                  Elolvasom a cikket
                </Button>
              </Col>
              <Col md={4}>
                <p>{post.date}</p>
              </Col>
            </Row>
        </Link>
      </Card.Body>
    </Card>
  )
}

export default Post
