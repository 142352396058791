import { GridHelper } from './GridHelper'
import { WallManager } from './WallManager'

export var ItemToBlockMapper = {
  DetermineItemsToInsertFromArray: function (state, items) {
    if (!WallManager.AreMainItemsPosAvailable(state, items)) return []
    else {
      let validItems = []
      items.forEach((item) => {
        if (WallManager.IsPosAvailableMain(state, item.pos, item.type))
          item.type === '10K'
            ? validItems.push({
                type: item.type,
                pos: item.pos,
                connections: [],
              })
            : validItems.push({
                type: item.type,
                pos: item.pos,
              })
      })
      return validItems
    }
  },
  DetermineItemsToInsert: function (state, selectedItem) {
    {
      let itemsToInsert
      let secondPos

      switch (selectedItem.type) {
        case '10T':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10T' },
          ])
          break
        case '10K':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
          ])
          break
        case '10PVJ':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PVJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
          ])
          break
        case '10PVB':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PVB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
          ])
          break
        case '10PTJ':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PTJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
          ])
          break
        case '10PTB':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PTB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
          ])
          break
        case '10PLB':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PLB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
          ])
          break
        case '10PLJ':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PLJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
          ])
          break
        case '10P40':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10P40', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10P40OB':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10P40OB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10P40OJ':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10P40OJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10P80':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 2,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10P80', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10P80OB':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 2,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10P80OB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10P80OJ':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 2,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10P80OJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10V40W_VB':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V40W_VB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V40W_VJ':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V40W_VJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V40W_H':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V40W_H', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V50W_UP':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: -1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V50W_UP', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V50W_DOWN':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V50W_DOWN', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V80W_H':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 2,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V80W_H', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V80W_VB':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 2,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V80W_VB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V80W_VJ':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 2,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V80W_VJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V40B_VB':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V40B_VB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V40B_VJ':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V40B_VJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V40B_H':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V40B_H', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V50B_UP':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: -1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V50B_UP', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V50B_DOWN':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 1,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V50B_DOWN', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V80B_H':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 2,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V80B_H', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V80B_VB':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 2,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V80B_VB', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10V80B_VJ':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 2,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10V80B_VJ', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10T' },
            { pos: secondPos, type: '10K' },
            { pos: secondPos, type: '10T' },
          ])
          break
        case '10KO2B':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 2,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10KO2B', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10KO2J':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 2,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10KO2J', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10KO3B':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 3,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10KO3B', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10KO3J':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 0,
            y: 3,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10KO3J', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10KL':
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10KL' },
          ])
          break
        case '10PP40B':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PP40B', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10P40' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10PP40W':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 1,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10PP40W', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: selectedItem.pos, type: '10P40' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10H1K':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 2,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10H1K', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case '10H0K':
          secondPos = GridHelper.GetPointInDir(state, selectedItem.pos, {
            x: 2,
            y: 0,
          })
          itemsToInsert = this.DetermineItemsToInsertFromArray(state, [
            { pos: selectedItem.pos, type: '10H0K', isMain: true },
            { pos: selectedItem.pos, type: '10K' },
            { pos: secondPos, type: '10K' },
          ])
          break
        case 'cat':
          itemsToInsert.push({ pos: selectedItem.pos, name: 'cat' })
      }
      return itemsToInsert
    }
  },
}
