import React, { useState, useEffect } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  FormCheck,
  Card,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { Link } from 'react-router-dom'
import CustomizeItem from '../components/CustomizeItem'
import Loader from '../components/Loader'
import {
  createOrder,
  initCreateOrderSuccess,
  resetOrderDetails,
} from '../actions/orderActions'
import { emptyCart } from '../actions/cartActions'

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  )
  cart.shippingPrice = addDecimals(cart.itemsPrice >= 30000 ? 0 : 2990)
  cart.taxPrice = addDecimals(Number((0.27 * cart.itemsPrice).toFixed(2)))
  cart.totalPrice = Number(cart.itemsPrice) + Number(cart.shippingPrice) - Number(cart.couponDiscount);

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error } = orderCreate
  const [acceptedAszf, setAcceptedAszf] = useState(false)

  useEffect(() => {
    if (success) {
      dispatch(resetOrderDetails())
      dispatch(initCreateOrderSuccess())
      dispatch(emptyCart())
      history.push(`/order/${order._id}`)
    }
  }, [history, success])

  const placeOrderHandler = (e) => {
    e.preventDefault()
    console.log('cart.cartItems :', cart.cartItems)

    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        billingAddress: cart.billingAddress,
        paymentMethod: cart.paymentMethod,
        shippingPrice: cart.shippingPrice,
        totalPrice: cart.totalPrice,
      })
    )
  }

  return (
    <>
      <div className='page-container'>
        <Container className='header-margin py-3'>
          <CheckoutSteps step1 step2 step3 step4 step5 />
          <Row>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h2>Szállítási adatok</h2>
                  <p><strong><b>
                    Kiszállítási idő általában 4-8 hét, de ha korábban
                    megtörténne a feladás, akkor a megadott email címen
                    értesítünk.
                  </b></strong>
                  </p> <p>
                    <strong>Név: </strong>
                    {cart.shippingAddress.name}
                  </p>
                  <p>
                    <strong>Cím: </strong>
                    {cart.shippingAddress.address},{cart.shippingAddress.city},
                    {cart.shippingAddress.postalCode},
                    {cart.shippingAddress.country},
                  </p>
                  <p>
                    <strong>Email: </strong>
                    {cart.shippingAddress.email}
                  </p>
                  <p>
                    <strong>Telefonszám: </strong>
                    {cart.shippingAddress.phone}
                  </p>
                  <p className='mb-3'>
                    30 000 Ft felett ingyenes a kiszállítás.
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h2>Számlázási adatok</h2>
                  <p>
                    <strong>Cím: </strong>
                    {cart.billingAddress.address},{cart.billingAddress.city},
                    {cart.billingAddress.postalCode},
                    {cart.billingAddress.country},
                  </p>
                  <p>
                    <strong>Email: </strong>
                    {cart.shippingAddress.email}
                  </p>
                  <p>
                    <strong>Telefonszám: </strong>
                    {cart.shippingAddress.phone}
                  </p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <h2>Fizetés</h2>
                  <strong>Fizetési mód: </strong>
                  {cart.paymentMethod === 'PayPal'
                    ? 'Bankkártyás fizetés vagy PayPal'
                    : 'Utánvét'}
                </ListGroup.Item>

                <ListGroup.Item>
                  <h2>Megrendelt termékek</h2>
                  {cart.cartItems.length === 0 ? (
                    <Message>Üres a kosár</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {cart.cartItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col xs={6} sm={6} md={5} lg={4} xl={3}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                              />
                            </Col>
                            <Col xs={6} sm={6} md={7} lg={8} xl={9}>
                              <Row>
                                <Col md={6}>
                                  <Link to={`/product/${item.product}`}>
                                    {item.name}
                                  </Link>
                                </Col>
                                <Col md={6}>
                                  {item.qty} x
                                  {Intl.NumberFormat('hu-HU', {
                                    style: 'currency',
                                    currency: 'HUF',
                                    maximumFractionDigits: 0,
                                  }).format(item.price)}
                                  {'  '}=
                                  {Intl.NumberFormat('hu-HU', {
                                    style: 'currency',
                                    currency: 'HUF',
                                    maximumFractionDigits: 0,
                                  }).format(item.qty * item.price)}{' '}
                                </Col>
                              </Row>
                              <Row className='my-2'>
                                <Col >

                                  <CustomizeItem customize={item.customize} />
                                </Col>
                              </Row>
                            </Col>

                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <h2>Rendelés összegzés</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={7}>Termékek</Col>
                      <Col md={5}>
                        {Intl.NumberFormat('hu-HU', {
                          style: 'currency',
                          currency: 'HUF',
                          maximumFractionDigits: 0,
                        }).format(cart.itemsPrice)}{' '}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={7}>Szállítás</Col>
                      {cart.itemsPrice >= 30000 ? (
                        <Col md={5}>Ingyenes</Col>
                      ) : (
                        <Col md={5}>
                          {Intl.NumberFormat('hu-HU', {
                            style: 'currency',
                            currency: 'HUF',
                            maximumFractionDigits: 0,
                          }).format(2990)}
                        </Col>
                      )}
                    </Row>
                  </ListGroup.Item>
                  {cart.paymentMethod.includes('Utánvét') ? (
                    <ListGroup.Item>
                      <Row>
                        <Col md={7}>Utánvét</Col>
                        <Col md={5}>1 490 Ft</Col>
                      </Row>
                    </ListGroup.Item>
                  ) : null}
                  {cart.couponDiscount && cart.couponDiscount > 0 &&
                    <ListGroup.Item>
                      <Row>
                        <Col md={7}>Kupon</Col>
                        <Col md={5}>
                          {'-'}{Intl.NumberFormat('hu-HU', {
                            style: 'currency',
                            currency: 'HUF',
                            maximumFractionDigits: 0,
                          }).format(cart.couponDiscount)}
                        </Col>
                      </Row>
                    </ListGroup.Item>}
                  <ListGroup.Item>
                    <Row>
                      <Col md={7}><h5>Összesen</h5></Col>
                      <Col md={5}><h5>
                        {cart.paymentMethod.includes('Utánvét')
                          ? Intl.NumberFormat('hu-HU', {
                            style: 'currency',
                            currency: 'HUF',
                            maximumFractionDigits: 0,
                          }).format(cart.totalPrice + 1490)
                          : Intl.NumberFormat('hu-HU', {
                            style: 'currency',
                            currency: 'HUF',
                            maximumFractionDigits: 0,
                          }).format(cart.totalPrice)}</h5>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {error && (
                    <ListGroup.Item>
                      <Message variant='danger'>{error}</Message>
                    </ListGroup.Item>
                  )}

                  <Form onSubmit={placeOrderHandler}>
                    <ListGroup.Item>
                      <Form.Check
                        required
                        label={
                          <span>
                            Elfogadom az{' '}
                            <Link to='/aszf'>
                              <b>Általános Szerződési Feltételeket</b>
                            </Link>
                            .
                          </span>
                        }
                        feedback='Rendeléshez el kell fogadni a szerződési feltételeket.'
                        feedbackType='invalid'
                        onChange={(e) => setAcceptedAszf(e.target.checked)}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      {acceptedAszf ? (
                        <Button
                          type='submit'
                          className='btn-block'
                          disabled={cart.cartItems === 0}
                        >
                          Rendelés leadása
                        </Button>
                      ) : (
                        <Button variant='outline-danger' disabled>
                          Rendeléshez el kell fogadni a szerződési feltételeket.
                        </Button>
                      )}
                    </ListGroup.Item>
                  </Form>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default PlaceOrderScreen
