export const cart = {
  view: 'simple',
  discounts: [
    { totalPrice: 50000, discountRatio: 0.05 },
    { totalPrice: 100000, discountRatio: 0.1 },
    { totalPrice: 150000, discountRatio: 0.15 },
  ],
  totalPrice: 0,
  discountPrice: 0,
  discountAmount: 0,
  message: '',
  groups: [
    {
      name: 'konzol',
      displayName: 'Konzol',
      count: 0,
      totalPrice: 0,
    },
    {
      name: 'shelf',
      displayName: 'Polc',
      count: 0,
      totalPrice: 0,
    },
    {
      name: 'bridge',
      displayName: 'Híd',
      count: 0,
      totalPrice: 0,
    },
    {
      name: 'step',
      displayName: 'Lépés',
      count: 0,
      totalPrice: 0,
    },
    {
      name: 'pole',
      displayName: 'Kaparó',
      count: 0,
      totalPrice: 0,
    },
    {
      name: 'fabric',
      displayName: 'Vászon',
      count: 0,
      totalPrice: 0,
    },
    // {
    //   group: 'pillow',
    //   displayName: 'Párna',
    //   count: 0,
    //   totalPrice: 0,
    // },
  ],
  items: [
    {
      type: '10K',
      id: '10K',
      group: 'konzol',
      displayName: 'Konzol',
      count: 0,
      unitPrice: 4000,
    },
    {
      type: '10T',
      id: '10T',
      group: 'shelf',
      displayName: 'Top polc',
      count: 0,
      unitPrice: 3200,
    },
    {
      type: '10P40',
      id: '10P40',
      group: 'shelf',
      displayName: 'Polc 40 cm',
      count: 0,
      unitPrice: 5600,
    },
    {
      type: '10P40O',
      id: '10P40O',
      group: 'shelf',
      displayName: 'Polc 40 cm lukas',
      count: 0,
      unitPrice: 6000,
    },
    {
      type: '10P80',
      id: '10P80',
      group: 'shelf',
      displayName: 'Polc 80 cm',
      count: 0,
      unitPrice: 8000,
    },
    {
      type: '10P80O',
      id: '10P80O',
      group: 'shelf',
      displayName: 'Polc 80 cm lukas',
      count: 0,
      unitPrice: 8600,
    },
    {
      type: '10POA',
      id: '10POA',
      group: 'shelf',
      displayName: 'Polc kaparó alsó',
      count: 0,
      unitPrice: 4200,
    },
    {
      type: '10POB',
      id: '10POB',
      group: 'shelf',
      displayName: 'Polc kaparó felső',
      count: 0,
      unitPrice: 4200,
    },
    {
      type: '10PV',
      id: '10PV',
      group: 'shelf',
      displayName: 'Polc virágtartó',
      count: 0,
      unitPrice: 4300,
    },
    {
      type: '10PT',
      id: '10PT',
      group: 'shelf',
      displayName: 'Polc táltartó',
      count: 0,
      unitPrice: 4200,
    },
    {
      type: '10PL',
      id: '10PL',
      group: 'shelf',
      displayName: 'Polc lépés',
      count: 0,
      unitPrice: 3500,
    },
    {
      type: '10V40W',
      id: '10V40W',
      group: 'fabric',
      displayName: 'Vászon 40 cm natúr',
      count: 0,
      unitPrice: 3100,
    },
    {
      type: '10V50W',
      id: '10V50W',
      group: 'fabric',
      displayName: 'Vászon 50 cm natúr',
      count: 0,
      unitPrice: 3400,
    },
    {
      type: '10V80W',
      id: '10V80W',
      group: 'fabric',
      displayName: 'Vászon 80 cm natúr',
      count: 0,
      unitPrice: 4100,
    },
    {
      type: '10KO2',
      id: '10KO2',
      group: 'pole',
      displayName: 'Kaparó oszlop 70 cm',
      count: 0,
      unitPrice: 10200,
    },
    {
      type: '10KL',
      id: '10KL',
      group: 'step',
      displayName: 'Kaparó lépés',
      count: 0,
      unitPrice: 8500,
    },
    {
      type: '10H1K',
      id: '10H1K',
      group: 'bridge',
      displayName: 'Híd korláttal',
      count: 0,
      unitPrice: 26500,
    },
    // {
    //   type: '10KO3',
    //   id: '10KO3',
    //   group: 'pole',
    //   displayName: 'Szizál kaparó 105cm',
    //   count: 0,
    //   unitPrice: 10480,
    // },

    // {
    //   type: '10V40B',
    //   id: '10V40B',
    //   group: 'fabric',
    //   displayName: 'Vászon szimpla fekete',
    //   count: 0,
    //   unitPrice: 2080,
    // },
    // {
    //   type: '10V50B',
    //   id: '10V50B',
    //   group: 'fabric',
    //   displayName: 'Vászon ferde fekete',
    //   count: 0,
    //   unitPrice: 2320,
    // },
    // {
    //   type: '10V80B',
    //   id: '10V80B',
    //   group: 'fabric',
    //   displayName: 'Vászon dupla fekete',
    //   count: 0,
    //   unitPrice: 2960,
    // },
    // {
    //   type: '10PP40W',
    //   id: '10PP40W',
    //   group: 'pillow',
    //   displayName: 'Pihenő Párna Világos',
    //   count: 0,
    //   unitPrice: 4813,
    // },
    // {
    //   type: '10PP40B',
    //   id: '10PP40B',
    //   group: 'pillow',
    //   displayName: 'Pihenő Párna Sötét',
    //   count: 0,
    //   unitPrice: 4813,
    // },
    // {
    //   type: '10H0K',
    //   id: '10H0K',
    //   group: 'bridge',
    //   displayName: 'Híd korlát nélkül',
    //   count: 0,
    //   unitPrice: 11840,
    // },
  ],
}
