import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Post from '../components/Post'
import { Container, Row, Col } from 'react-bootstrap'
import { listPosts } from '../actions/postActions'
import { Helmet } from 'react-helmet-async'

const BlogScreen = ({ match }) => {

  const keyword = match.params.keyword
  const dispatch = useDispatch()

  const postList = useSelector((state) => state.postList)
  const { loading, error, posts } = postList

  useEffect(() => {
    dispatch(listPosts(keyword))
  }, [dispatch, keyword])

  console.log(posts)
  return (
    <>
      <Helmet>
        <title>Cathletic Blog | Tippek, Trükkök, Tanácsok</title>
        <meta name="description"
          content="Hasznos tippek, érdekes cikkek, rengeteg interjú és bemutatkozó. Állatorvosok, menhelyek és szakértők." />
        <link rel="canonical" href="/blog" />
      </Helmet>
      <div className='page-container'>
        <Container className='header-margin'>

          <h1 className='black-chango'>tippek, trükkök, tanácsok</h1>
          <p>Szeretnéd jobban megérteni cicád viselkedését és igényeit? Szeretnél hasznos tippeket kapni a macskaneveléshez, az egészségügyi ellátáshoz és a táplálkozáshoz? Akkor jó helyen jársz!

          </p>
          <p>
            Blogunkon mindent megtalálsz, amit tudnod kell a macskákról. Szakértői cikkeket, hasznos tanácsokat és inspiráló történeteket osztunk meg, amelyek segítenek a cicád boldog és egészséges gondozásában.
          </p>
          <p>Csatlakozz a macskás közösségünkhöz, és fedezzük fel együtt a macskák lenyűgöző világát!</p>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Row className='align-items-center'>
              {posts
                .sort((a, b) => (a.date > b.date ? -1 : (a.date < b.date ? 1 : 0)))
                .map((post) => (
                  <Col key={post.id} xl={6} md={6}>
                    <Post post={post} />
                  </Col>
                ))}
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}

export default BlogScreen
