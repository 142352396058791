import axios from 'axios'

import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_EMPTY,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_BILLING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  APPLY_COUPON_DISCOUNT
} from '../constants/cartConstants'

export const addToCart = (id, qty, customize = {}) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${id}`)
  console.log('id', id)
  console.log('qty', qty)
  console.log('customize', customize)

  console.log('data', data)
  // Set default values for customize fields
  const defaultCustomize = { fabric: '', sisal: '', pillow: '', }

  // Merge the provided customize object with the default values
  const customizeOptions = { ...defaultCustomize, ...customize }
  console.log('customize', customize)

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data.id,
      name: data.name,
      image: data.images[0],
      price: data.priceDisc,
      countInStock: data.countInStock,
      qty,
      customize: customizeOptions,
    },
  })
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const emptyCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_EMPTY,
  })
  localStorage.setItem('cartItems', [])
}

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })
  localStorage.setItem('shippingAddress', JSON.stringify(data))
}
export const saveBillingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_BILLING_ADDRESS,
    payload: data,
  })
  localStorage.setItem('billingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })
  localStorage.setItem('paymentMethod', JSON.stringify(data))
}
export const applyCouponDiscount = (discountAmount) => (dispatch) => {
  dispatch({
    type: APPLY_COUPON_DISCOUNT,
    payload: discountAmount,
  })
  localStorage.setItem('couponDiscount', JSON.stringify(discountAmount));
};

