import React, { useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ContactForm from '../components/ContactForm'
import { Helmet } from 'react-helmet-async'

const ContactScreen = ({ match, location }) => {
  return (
    <>
      <Helmet>
        <title>Cathletic Egyedi Macskabútor Kapcsolat </title>
        <meta name="description"
          content="Kérdésed van? Írj ránk!" />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <div className='page-container'>
        <Container className='header-margin'>
          <Row className='align-items-center'>
            <Col md={6}>
              <h1 className='black-chango '>Kapcsolat</h1>
              <h4 className='pb-3'>
                Ha szeretnél tőlünk rendelni, akkor ne habozz, írj ránk, amint tudunk válaszolunk.
              </h4>
              <Row className='m-1 py-1'>
                <Col md={2}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fas fa-envelope-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={10}>
                  <a href='mailto:cathletic.shop@gmail.com'>
                    <p>cathletic.shop@gmail.com</p>
                  </a>
                </Col>
              </Row>
              <Row className='m-1 py-1'>
                <Col md={2}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fas fa-phone fa-2x'></i>
                  </span>
                </Col>
                <Col md={10}>
                  <a href='tel:0036-30-4991286'><p>+36 30 499 1286</p></a>
                </Col>
              </Row>
              <Row className='m-1 py-1'>
                <Col md={2}>
                  <span style={{ color: '#599C76' }}>
                    <i className='fab fa-facebook-square fa-2x'></i>
                  </span>
                </Col>
                <Col md={10}>
                  <a href='https://www.facebook.com/cathletic/'>
                    <p>Cathletic Facebook Oldal</p>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Image className='contact-image' src='images/cat_contact.webp'
                alt='Kapcsolat Cathletic' />
            </Col>
          </Row>
          <hr />
          <Row className='align-items-center mb-3 pb-3'>
            <Col md={12}>
              <h2 className='black-chango '>Rendelés és Kapcsolatfelvétel</h2>
              <ContactForm submitButtonText={'ÜZENET KÜLDÉSE'} />
            </Col>
          </Row>
        </Container >
      </div >
    </>
  )
}

export default ContactScreen
