export var CartHelper = {
  UpdateCart(state) {
    state.cart.items.forEach((item) => {
      item.count = this.GetItemCount(state, item.id)
    })
    this.UpdateGroups(state)

    state.cart.totalPrice = this.CalcTotalPrice(state)
    // let discount = this.CalcDiscount(state)
    let discountAmount = state.cart.totalPrice * 0.2
    state.cart.discountPrice = state.cart.totalPrice - discountAmount
    state.cart.discountAmount = discountAmount

    // this.UpdateMessage(state)
  },
  CalcTotalPrice(state) {
    let totalPrice = 0
    state.cart.items.forEach((item) => {
      totalPrice += item.count * item.unitPrice
    })
    return totalPrice
  },
  // CalcDiscount(state) {
  //   let discount = { price: 0, amount: 0 }
  //   if (state.cart.totalPrice >= 150000) {
  //     discount.price = Math.round(state.cart.totalPrice * 0.85)
  //     discount.amount = Math.round(state.cart.totalPrice * 0.15)
  //   } else if (state.cart.totalPrice >= 100000) {
  //     discount.price = Math.round(state.cart.totalPrice * 0.9)
  //     discount.amount = Math.round(state.cart.totalPrice * 0.1)
  //   } else if (state.cart.totalPrice >= 50000) {
  //     discount.price = Math.round(state.cart.totalPrice * 0.95)
  //     discount.amount = Math.round(state.cart.totalPrice * 0.05)
  //   }
  //   return discount
  // },
  UpdateGroups(state) {
    state.cart.groups.forEach((group) => {
      group.count = 0
      group.totalPrice = 0
      let groupItems = state.cart.items.filter(
        (item) => item.group === group.name
      )
      groupItems.forEach((item) => {
        group.count += item.count
        group.totalPrice += this.GetItemTotalPrice(state, item.type)
      })
    })
  },

  // UpdateMessage(state) {
  //   if (state.cart.totalPrice >= 150000) {
  //     state.cart.message = {
  //       text: 'Gratulálunk! Elérted a 15%-os kedvezmény határt :)',
  //       type: 'happy',
  //     }
  //   } else if (state.cart.totalPrice >= 100000) {
  //     let untilNextDiscount = 150000 - state.cart.totalPrice
  //     state.cart.message = {
  //       text: `Gratulálunk! Elérted a 10%-os kedvezmény határt.
  //     Ha még ${Intl.NumberFormat('hu-HU', {
  //       style: 'currency',
  //       currency: 'HUF',
  //       maximumFractionDigits: 0,
  //     }).format(untilNextDiscount)} értékben vásárolsz,
  //     akkor 15% kedvezményre leszel jogosult.`,
  //       type: 'happy',
  //     }
  //   } else if (state.cart.totalPrice >= 50000) {
  //     let untilNextDiscount = 100000 - state.cart.totalPrice
  //     state.cart.message = {
  //       text: `Gratulálunk! Elérted az 5%-os kedvezmény határt.
  //     Ha még ${Intl.NumberFormat('hu-HU', {
  //       style: 'currency',
  //       currency: 'HUF',
  //       maximumFractionDigits: 0,
  //     }).format(untilNextDiscount)} értékben vásárolsz,
  //     akkor 10% kedvezményre leszel jogosult.`,
  //       type: 'happy',
  //     }
  //   } else {
  //     let untilNextDiscount = 50000 - state.cart.totalPrice
  //     state.cart.message = {
  //       text: `Ha még ${Intl.NumberFormat('hu-HU', {
  //         style: 'currency',
  //         currency: 'HUF',
  //         maximumFractionDigits: 0,
  //       }).format(untilNextDiscount)} értékben vásárolsz,
  //     akkor 5% kedvezményre leszel jogosult.`,
  //       type: 'info',
  //     }
  //   }
  // },
  GetItemTotalPrice(state, type) {
    let item = state.cart.items.filter((item) => item.type === type)[0]
    if (typeof item !== 'undefined') {
      return item.count * item.unitPrice
    }
  },
  GetItemCount(state, cartId) {
    if (cartId === '10POA' || cartId === '10POB') {
      return state.items.filter(function (item) {
        return item.type.includes('10KO2') || item.type.includes('10KO3')
      }).length
    } else if (cartId === '10KO2') {
      return state.items.filter(function (item) {
        return item.type.includes('10KO2')
      }).length
    } else if (cartId === '10KO3') {
      return state.items.filter(function (item) {
        return item.type.includes('10KO3')
      }).length
    } else if (cartId === '10PL') {
      return state.items.filter(function (item) {
        return item.type.includes('10PL')
      }).length
    } else if (cartId === '10P40O') {
      return state.items.filter(function (item) {
        return item.type.includes('10P40O')
      }).length
    } else if (cartId === '10P80O') {
      return state.items.filter(function (item) {
        return item.type.includes('10P80O')
      }).length
    } else if (cartId === '10PV') {
      return state.items.filter(function (item) {
        return item.type.includes('10PV')
      }).length
    } else if (cartId === '10PT') {
      return state.items.filter(function (item) {
        return item.type.includes('10PT')
      }).length
    } else if (cartId === '10V40W') {
      return state.items.filter(function (item) {
        return item.type.includes('10V40W')
      }).length
    } else if (cartId === '10V50W') {
      return state.items.filter(function (item) {
        return item.type.includes('10V50W')
      }).length
    } else if (cartId === '10V80W') {
      return state.items.filter(function (item) {
        return item.type.includes('10V80W')
      }).length
      // } else if (cartId === '10V40B') {
      //   return state.items.filter(function (item) {
      //     return item.type === '10V40B_V' || item.type === '10V40B_H'
      //   }).length
      // } else if (cartId === '10V50B') {
      //   return state.items.filter(function (item) {
      //     return item.type === '10V50B_UP' || item.type === '10V50B_DOWN'
      //   }).length
    } else {
      return state.items.filter(function (item) {
        return item.type === cartId
      }).length
    }
  },
  Cleanup(state) {
    state.cart.totalPrice = 0
    state.cart.discountPrice = 0
    state.cart.discountAmount = 0
    state.cart.message = ''
    state.cart.groups.forEach((group) => {
      group.count = 0
      group.totalPrice = 0
    })
    state.cart.items.forEach((item) => {
      item.count = 0
    })
  },
}
