import React, { useState } from 'react'
import { Container, Form, Button, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveBillingAddress, saveShippingAddress } from '../actions/cartActions'

const ShippingScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const [name, setName] = useState(shippingAddress.name)
  const [address, setAddress] = useState(shippingAddress.address)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [email, setEmail] = useState(shippingAddress.email)
  const [sameBillingAddress, setSameBillingAddress] = useState(
    shippingAddress.sameBillingAddress
  )

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      saveShippingAddress({
        name,
        phone,
        email,
        address,
        city,
        postalCode,
        country,
        sameBillingAddress,
      })
    )
    if (sameBillingAddress) {
      dispatch(
        saveBillingAddress({
          name,
          phone,
          email,
          address,
          city,
          postalCode,
          country,
          sameBillingAddress,
        })
      )

      history.push('/payment')
    } else {
      history.push('/billing')
    }
  }

  return (
    <div className='page-container'>
      <Container className='header-margin py-3 col-md-10'>
        <FormContainer>
          <CheckoutSteps step1 step2 />
          <h1>Szállítási adatok</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Név</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Gipsz Jakab'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='phone'>
              <Form.Label>Telefonszám</Form.Label>
              <Form.Control
                required
                type='phnoe'
                placeholder='+36301234567'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type='email'
                placeholder='pelda@gmail.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='address'>
              <Form.Label>Cím</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Utca, házszám, emelet, ajtó'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='city'>
              <Form.Label>Város</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Város'
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='postalCode'>
              <Form.Label>Irányítószám</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Irányítószám'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='country'>
              <Form.Label>Ország</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Ország'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Check
              className='m-2'
              type='checkbox'
              checked={sameBillingAddress}
              onChange={(e) => setSameBillingAddress(e.target.checked)}
              label='Számlázási adatok megegyeznek a szállítási adatokkal'
            />
            <p className='mb-3'>
              Minden mászófalunkra ingyenes a kiszállítás.
            </p>
            <p>
              Kiszállítási idő általában 4-8 hét, de ha korábban megtörténne a
              feladás, akkor a megadott email címen értesítünk.
            </p>
            <Button type='submit' variant='primary' className='mb-3'>
              Tovább
            </Button>
          </Form>
        </FormContainer>
      </Container>
    </div>
  )
}

export default ShippingScreen
