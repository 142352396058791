import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Alert,
  Button,
  Card,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CustomizeItem from '../components/CustomizeItem'
import { addToCart, removeFromCart, applyCouponDiscount } from '../actions/cartActions'
import { Helmet } from 'react-helmet-async'
import axios from 'axios';

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id

  const searchParams = new URLSearchParams(location.search)
  const qty = searchParams.get('qty') ? Number(searchParams.get('qty')) : 1
  const fabric = searchParams.get('fabric') || ''
  const sisal = searchParams.get('sisal') || ''
  const pillow = searchParams.get('pillow') || ''

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty, { fabric, sisal, pillow }))
    }
  }, [dispatch, productId, qty, fabric, sisal, pillow])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
    if (cartItems.length === 1) {
      dispatch(applyCouponDiscount(0));
    }
  }

  const checkoutHandler = () => {
    // history.push('/login?redirect=shipping')
    history.push('/shipping')
  }

  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const couponDiscount = useSelector((state) => state.cart.couponDiscount || 0)

  const applyCoupon = async () => {
    try {
      const { data } = await axios.post('/api/coupons/validate', { code: couponCode });
      if (data.valid) {
        let discountAmount = 0;
        if (data.coupon.discountType === 'percentage') {
          discountAmount = cartItems.reduce(
            (acc, item) => acc + item.qty * item.price,
            0
          ) * (data.coupon.discountValue / 100);
        } else {
          discountAmount = data.coupon.discountValue;
        }
        dispatch(applyCouponDiscount(discountAmount));
        setCouponError('');
      } else {
        dispatch({ type: 'APPLY_COUPON_DISCOUNT', payload: 0 });
        setCouponError(data.message);
      }
    } catch (error) {
      console.error('Error validating coupon:', error);
      dispatch(applyCouponDiscount(0));
      setCouponError('An error occurred while validating the coupon.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Cathletic Kosár</title>
        <meta name="description"
          content="Álmaid kalandparkja, már a kosaradban. Rendeld meg még ma!" />
        <link rel="canonical" href="/cart" />
      </Helmet>
      <div className='page-container footer-padding'>
        <Container className='header-margin'>
          <Row>
            <Col md={8}>
              <Link to='/webshop'>Vissza a termékekhez</Link>
              <h1 className='black-chango'>
                <span>
                  <i className='fas fa-shopping-cart pr-2'></i>
                </span>Kosár</h1>
              {cartItems.length === 0 ? (
                <Message>
                  Üres a kosár: <Link to='/webshop'>Irány a termékekhez</Link>
                </Message>
              ) : (
                <ListGroup variant='flush'>
                  {cartItems.map((item) => (
                    <ListGroup.Item key={item.product}>
                      <Row>
                        <Col xs={6} sm={6} md={5} lg={4} xl={3}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          ></Image>
                        </Col>
                        <Col xs={6} sm={6} md={7} lg={8} xl={9}>
                          <Row>
                            <Col md={4}>
                              <Link to={`/product/${item.product}`}>{item.name}</Link>
                            </Col>
                            <Col md={4}>{item.price} Ft</Col>
                            <Col md={2}>
                              <Form.Control
                                as='select'
                                value={item.qty}
                                onChange={(e) =>
                                  dispatch(
                                    addToCart(item.product, Number(e.target.value))
                                  )
                                }
                              >
                                {[...Array(item.countInStock).keys()].map((x) => (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                            <Col md={2}>
                              <Button
                                type='button'
                                variant='light'
                                onClick={() => removeFromCartHandler(item.product)}
                              >
                                <i className='fas fa-trash'></i>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <CustomizeItem customize={item.customize} />
                          </Row>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Col>

            <Col md={4}>
              <Card className='row-bg-light mt-2'>
                <ListGroup variant='flush' className='row-bg-light'>
                  <ListGroup.Item>
                    <h5>
                      Összesen ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) tétel
                    </h5>
                    <p className='mt-2'>{Intl.NumberFormat('hu-HU', {
                      style: 'currency',
                      currency: 'HUF',
                      maximumFractionDigits: 0,
                    }).format(
                      cartItems.reduce((acc, item) => acc + item.qty * item.price, 0)
                    )}{' '}</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Form.Group>
                      <Form.Label><h5>
                        <span>
                          <i className='fas fa-tags pr-2'></i>
                        </span>Kuponkód</h5></Form.Label>
                      <Form.Control
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder="Írd be a kuponkódot"
                      />
                    </Form.Group>
                    <Button variant="outline-primary" size="sm"
                      className="btn-block rounded-pill" onClick={applyCoupon}>
                      Kupon alkalmazása
                    </Button>
                    {couponError && <Alert variant="danger" className='my-2'>{couponError}</Alert>}
                    {couponDiscount > 0 && (
                      <Alert variant="success" className='my-2'>
                        Kedvezmény:{' '}
                        {Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF' }).format(
                          couponDiscount
                        )}
                      </Alert>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h5>Végösszeg</h5>
                    <p className='mt-2'>
                      {Intl.NumberFormat('hu-HU', {
                        style: 'currency',
                        currency: 'HUF',
                        maximumFractionDigits: 0,
                      }).format(
                        cartItems.reduce((acc, item) => acc + item.qty * item.price, 0) -
                        couponDiscount
                      )}</p>
                    <p className='p-small mt-3'>A feltüntetett ár a termék bruttó ára, amely az Általános Forgalmi Adót (ÁFA) is tartalmazza!</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Button
                      type="button"
                      className="btn-block rounded-pill"
                      disabled={cartItems.length === 0}
                      onClick={checkoutHandler}
                    >
                      Tovább a kasszához
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </>
  )
}

export default CartScreen
