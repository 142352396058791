import React from 'react'
import { Circle, Text } from 'react-konva'
import { useSelector } from 'react-redux'
import '../../../WallDesigner.css'

export default function ClusterId(props) {
  return (
    <>
      {(props.type === '10K' || props.type === '10KL') && (
        <>
          <Circle
            radius={30}
            x={props.x - 50}
            y={props.y + 50}
            fill='#ff5555'
            opacity={0.5}
          />
          <Text
            x={props.x - 80}
            y={props.y + 20}
            fontSize={30}
            width={60}
            height={60}
            text={props.clusterId}
            align='center'
            verticalAlign='middle'
            fontFamily='Calibri'
          />
        </>
      )}
    </>
  )
}
