import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Card,
  Image,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap'

import { Link } from 'react-router-dom'
import Testimonial1 from '../images/testimonials/test_1.webp'
import Testimonial2 from '../images/testimonials/test_2.webp'
import Testimonial3 from '../images/testimonials/test_3.webp'
import zemplen from '../images/zemplen.jpg'
import mecsek from '../images/mecsek.jpg'
import borzsony from '../images/borzsony.jpg'
import { listProducts } from '../actions/productActions'
import Rating from '../components/Rating'
import { Helmet } from 'react-helmet-async'
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const isValidEmail = (email) => {
  // Basic email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const HomeScreen = ({ match }) => {

  const keyword = match.params.keyword;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  useEffect(() => {
    dispatch(listProducts(keyword));
  }, [dispatch, keyword]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setEmailError('Kérlek adj meg egy valós email címet.');
    } else {
      setEmailError('');
      setSubscriptionSuccess(true);
    }
  };


  useEffect(() => {
    dispatch(listProducts(keyword))
  }, [dispatch, keyword])

  return (
    <>
      <Helmet>
        <title>Cathletic Egyedi Macskafal és Macskabútor </title>
        <meta name="description"
          content="Álmodd meg álmaid macska kalandparkját, és mi megvalósítjuk! | Modern macskabútor | Falra szerelhető | Cicabútor | Fali macskabútor | Macska kaparófa" />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className='page-container footer-padding'>
        {/* <Banner /> */}
        <span className='row-bg-light'>
          <Row className=' row-with-padding align-items-center'>
            <Col className='' md={6}>

              <Link as={Link} to='/product/gerecse'>
                <Image
                  width='100%'
                  className='rounded-image'
                  src='images/banner.webp'
                  alt='Kilimanjaro Macskanútor Kaparófa'
                />
              </Link>
            </Col>
            <Col className="pl-4 align-middle text-center" md={6}>
              <div className="modern-macskabútor-section">
                <h1 className="black-chango-large pb-0">Modern Macskabútor</h1>

                <div className="d-grid gap-2 pb-4 text-center">
                  <Link as={Link} to="/webshop">
                    <Button
                      className="lg red-button rounded-pill offers button-text font-weight-bold"
                      variant="success"
                      size="sm"
                    >
                      <h2 className="black-chango pb-0">VÉGKIÁRUSÍTÁS</h2>
                      Irány a WEBSHOP!
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          {/* 
          <Row className='row-with-padding text-center row-bg-yellow justify-content-md-center'>
            <Col md={12} className='py-2'>
              <h1 className='black-chango text-center'>NYÁRI KÉSZLETKISÖPRÉS</h1>
              <p className='text-black'>Óriási kedvezménnyel varázsolhatod otthonod cica kalandparkká. Legújabb mászófalaink:
              </p>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Link as={Link} to="/product/zemplen">
                <Card border='dark' className='my-2'>
                  <Card.Img variant='top' src={zemplen} />
                  <Card.Body>
                    <Card.Title>Zemplén Mászófal</Card.Title>
                    <Rating value={5} />
                    <Card.Text>
                      <Button className='btn-block rounded mt-3' type='button'>
                        <span style={{ color: '#ffffff' }}>
                          <i className='fas fa-shopping-cart pr-2'></i>
                        </span>
                        MEGNÉZEM
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Link as={Link} to="/product/mecsek">
                <Card border='dark' className='my-2'>
                  <Card.Img variant='top' src={mecsek} />
                  <Card.Body>
                    <Card.Title>Mecsek Játszótér</Card.Title>
                    <Rating value={5} />
                    <Card.Text>
                      <Button className='btn-block rounded mt-3' type='button'>
                        <span style={{ color: '#ffffff' }}>
                          <i className='fas fa-shopping-cart pr-2'></i>
                        </span>
                        MEGNÉZEM
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Link as={Link} to="/product/borzsony">
                <Card border='dark' className='my-2'>
                  <Card.Img variant='top' src={borzsony} />
                  <Card.Body>
                    <Card.Title>Börzsöny Kalandpark</Card.Title>
                    <Rating value={5} />
                    <Card.Text>
                      <Button className='btn-block rounded mt-3' type='button'>
                        <span style={{ color: '#ffffff' }}>
                          <i className='fas fa-shopping-cart pr-2'></i>
                        </span>
                        MEGNÉZEM
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row> */}

          <Row className=' row-with-padding bg-dark text-center text-lightgray'>
            <Col md={12} className='py-2'>
              <h2 className='white-chango text-center'>Miért szeretnek a magasban lenni a cicák?</h2>
            </Col>
            <Col xs={12} md={6} lg={6} className='py-1'>
              <span style={{ color: '#ffc933' }}>
                <i className='fas fa-heart fa-3x'></i>
              </span>
              <h2 className='text-lightgray'>
                Egészség
              </h2>
              <p>
                A mászás természetes ösztönük, ami segít fittségük és egészségük megőrzésében.
              </p>
            </Col>
            <Col xs={12} md={6} lg={6} className='py-1'>
              <span style={{ color: '#F28482' }}>
                <i className='fas fa-shield-alt fa-3x'></i>
              </span>
              <h2 className='text-lightgray'>
                Biztonság
              </h2>
              <p>
                A magasból a cica dominanciát mutathat erőszak nélkül, csökkentve a konfliktusokat. Biztonságban érezheti magát, miközben rálát a környezetére.
              </p>
            </Col>
            <Col xs={12} md={6} lg={6} className='py-1'>
              <span style={{ color: '#f5cac3' }}>
                <i className='fas fa-spa fa-3x'></i>
              </span>
              <h2 className='text-lightgray'>
                Nyugalom
              </h2>
              <p>
                A fali bútorok ideálisak kisgyermekes, több macskás vagy kutyás háztartásokban is, ahol a cica zavartalanul pihenhet és ehet, konfliktusok nélkül.
              </p>
            </Col>
            <Col xs={12} md={6} lg={6} className='py-1'>
              <span style={{ color: '#599C76' }}>
                <i className='fas fa-cat fa-3x'></i>
              </span>
              <h2 className='text-lightgray'>
                Játék és pihenés
              </h2>
              <p>
                Puha pihenőhelyeket biztosít, ahol a cicád kényelmesen szundíthat. A mászófal felfedezése, ugrálás, mászás, rohangálás, bújócska és kaparó órákon át tartó szórakozási lehetőséget nyújtanak.
              </p>
            </Col>
          </Row>

          <Row className='row-with-padding text-center justify-content-md-center'>
            <Col md={12} className='py-2'>
              <h1 className='black-chango text-center'>RÓLUNK ÍRTÁK</h1>
              <p>Több 100 cica életét tettük színesebbé és izgalmasabbá az évek során. Rengeteg gazditól kaptunk visszajelzést, képeket és videókat. Ezeket a cicákat már név szerint ismerjük, és folyamatosan bővül a Cathletic közösség.
              </p>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Card border='dark' className='my-2'>
                <Card.Img variant='top' src={Testimonial2} />
                <Card.Body>
                  <Card.Title>Margó</Card.Title>
                  <Rating value={5} />
                  <Card.Text>
                    A macskabútor, mászófal összeszerelése nem jelentett gondot a rajzok
                    átnézése után. Marci jót alszik, Befike pedig már volt is
                    fent a Mont Blanc tetején.{' '}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Card border='dark' className='my-2'>
                <Card.Img variant='top' src={Testimonial3} />
                <Card.Body>
                  <Card.Title>Carolin</Card.Title>
                  <Rating value={5} />
                  <Card.Text>
                    Nagyon elégedettek vagyunk a mászófallal, az összeszerelés
                    gond nélkül ment, és a cicáink nagyon gyorsan belakták az új
                    játszóterüket
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Card border='dark' className='my-2'>
                <Card.Img variant='top' src={Testimonial1} />
                <Card.Body>
                  <Card.Title>Orsi</Card.Title>
                  <Rating value={5} />
                  <Card.Text>Te jó ég! De jól néz ki!</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className=' row-with-padding bg-dark text-center text-lightgray'>
            <Col xs={12} md={6} lg={4}>

              <span style={{ color: '#ffc933' }}>
                <i className='fas fa-hand-holding-heart fa-3x'></i>
              </span>
              <h2 className='text-lightgray'>
                Kézzel készített

              </h2>
              <p>
                A macska kaparófák, fali macskabútorok gondos odafigyeléssel, kézzel készülnek.
                Beszállítóink és partnereink kiválasztásánál is fontos szempont
                volt a szakértelem, és a minőség iránti elköteleződés.
              </p>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <span style={{ color: '#F28482' }}>
                <i className='fas fa-gem fa-3x'></i>
              </span>
              <h2 className='text-lightgray'>
                Magas minőség
              </h2>
              <p>
                Az anyagok, vasalatok, csomagolás, csiszolás és felületkezelés
                megválasztásánál is a magas minőséget helyeztük előtérbe, a
                vásárlói ár-érték arányt szem előtt tartva.
              </p>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <span style={{ color: '#599C76' }}>
                <i className='fas fa-leaf fa-3x'></i>
              </span>
              <h2 className='text-lightgray'>
                Természetes
              </h2>
              <p>
                Kezdetektől fontos szempont volt az ökotudatos, természetes
                anyagok használata és az újrahasznosítás. A fa
                felületkezelésétől kezdve a csomagolásig igyekszünk zöld
                megoldásokat választani.
              </p>
            </Col>
          </Row>
        </span>

        <Row className='row-bg-light row-with-padding text-center align-items-center bg-white '>
          <Col className=' text-center pb-4' md={6}>
            <span style={{ color: '#F28482' }}>
              <i className='fas fa-question-circle fa-3x pb-4'></i>
            </span>
            <h4 className='text-dark'>
              Segítségre van szükséged a választásban?
            </h4>
            <p className='text-dark-50'>
              Keress bátran minket bármilyen felmerülő kérdéssel. Anyagokkal,
              méretekkel vagy szállítással kapcsolatban merült fel kérdésed?
              Legjobb tudásunknak megfelelően igyekszünk segíteni. Írj ránk,
              amint tudunk válaszolunk.
            </p>
            <LinkContainer to='/contact'>
              <Button className='lg btn-success rounded-pill'>Kapcsolat</Button>
            </LinkContainer>
          </Col>
          <Col md={6}>
            <Image
              // variant='top'
              width='100%'
              src='images/cat_contact.webp'
              alt='Kapcsolat Cathletic'
            />
          </Col>
        </Row>{' '}

        {/*         
        <div className="newsletter-signup-container footer-padding">
          <div className="newsletter-signup-content">
            {!subscriptionSuccess ? (
              <>
                <h2 className="newsletter-signup-title">Iratkozz fel hírlevelünkre</h2>
                <MailchimpSubscribe
                  url="https://cathletic.us11.list-manage.com/subscribe/post?u=d4e42ed77cd377fe29d4c286c&amp;id=308828571b&amp;f_id=0089ace0f0"
                  render={({ subscribe, status, message }) => (
                    <div>
                      <>
                        <p style={{ color: 'black', padding: '15px' }}>
                          Iratkozz fel még ma és mi üdvözlésképpen{' '}
                          <bold
                            style={{
                              fontFamily: 'Monserrat-Black',
                              fontSize: '1.5rem',
                              color: '#ffc933',
                              backgroundColor: 'black',
                              padding: '5px',
                            }}
                          >
                            3000 Ft
                          </bold>
                          -os kupont küldünk ajándékba.
                        </p>
                        <input
                          type="email"
                          placeholder="Email címem"
                          onChange={(e) => setEmail(e.target.value)}
                          style={{
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            padding: '0 16px',
                            height: '35px',
                            width: '100%',
                            textAlign: 'left',
                            color: '#000',
                            fontFamily: 'Montserrat-Medium',
                            fontSize: '14px',
                            fontWeight: '400',
                            letterSpacing: '0px',
                            backgroundColor: '#fff',
                            border: '1px solid #000',
                            marginBottom: '10px',
                          }}
                        />
                        {emailError && (
                          <div style={{ color: 'red', marginBottom: '10px' }}>
                            {emailError}
                          </div>
                        )}
                        <button
                          type="submit"
                          onClick={() => {
                            if (isValidEmail(email)) {
                              subscribe({ EMAIL: email });
                              setSubscriptionSuccess(true);
                            } else {
                              setEmailError('Kérlek adj meg egy valós email címet.');
                            }
                          }}
                          style={{
                            background: '#000',
                            borderRadius: '5px',
                            border: 'none',
                            color: '#fff',
                            fontFamily: 'Montserrat-Medium',
                            fontSize: '16px',
                            fontWeight: '700',
                            letterSpacing: '0px',
                            lineHeight: 1,
                            whiteSpace: 'normal',
                            marginBottom: '15px',
                            textAlign: 'center',
                            wordBreak: 'break-word',
                            alignSelf: 'flex-end',
                            cursor: 'pointer',
                            height: '50px',
                            width: '100%',
                          }}
                        >
                          KÉREM A KEDVEZMÉNYT
                        </button>
                      </>

                      {status === 'sending' && <div>sending...</div>}
                      {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}
                    </div>
                  )}
                />



                <p className="newsletter-signup-description">
                  Személyre szóló ajánlatainkról és egyedi kedvezményekről első kézből
                  értesülj!
                </p>
                <div className="newsletter-signup-consent">
                  <p className="newsletter-signup-consent-text">
                    Hozzájárulok a hírlevél küldéséhez és az azzal kapcsolatos személyes
                    adataim kezeléséhez. A személyes adatok feldolgozásával kapcsolatos
                    további információk{' '}
                    <a
                      className="newsletter-signup-consent-link"
                      href="/dataprotection"
                    >
                      itt
                    </a>{' '}
                    találhatók.
                  </p>
                </div>
              </>
            ) : (
              <h2 className="newsletter-signup-title">Köszönjük a feliratkozást!</h2>
            )}
          </div>
        </div> */}
      </div >
    </>
  )
}

export default HomeScreen
