import { WallManager } from './WallManager'

export var GridHelper = {
  CoordinateToGridDimension: function (state, coordinate) {
    return {
      x: Math.round(coordinate.x / state.canvas.gridStep.x),
      y: Math.round(coordinate.y / state.canvas.gridStep.y),
    }
  },
  GetPointToSnap: function (state, coordinate) {
    return {
      x:
        Math.round(
          coordinate.x / state.canvas.scale.x / state.canvas.gridStep.x
        ) * state.canvas.gridStep.x,
      y:
        Math.round(
          coordinate.y / state.canvas.scale.y / state.canvas.gridStep.y
        ) * state.canvas.gridStep.y,
    }
  },
  GetPointInDir: function (state, coordinate, direction) {
    return {
      x: coordinate.x + direction.x * state.canvas.gridStep.x,
      y: coordinate.y + direction.y * state.canvas.gridStep.y,
    }
  },
  GetSnappedCursor: function (state, cursor) {
    let cursorSnapped = {
      x:
        Math.round(cursor.x / state.canvas.gridStep.x) *
        state.canvas.gridStep.x,
      y:
        Math.round(cursor.y / state.canvas.gridStep.y) *
        state.canvas.gridStep.y,
    }
    // let konzolInPos = WallManager.GetKonzolInPos(state, cursorSnapped)
    // if (typeof konzolInPos !== 'undefined') {
    //   cursorSnapped.x += konzolInPos.offsetShift
    // }
    return cursorSnapped
  },
  GetCursorSide: function (state, cursor, cursorSnapped) {
    return cursor.x <= cursorSnapped.x ? 'left' : 'right'
  },
}
