import React from 'react'
import '../../../WallDesigner.css'
import { Image } from 'react-konva'
import useImage from 'use-image'
import { useSelector } from 'react-redux'

function WallItemFloating() {
  const wall = useSelector((state) => state.wall)
  const [image] = useImage(wall.floatingWallItem.imageSrc)

  return (
    <>
      <Image
        id={wall.floatingWallItem.id}
        image={image}
        x={wall.floatingWallItem.x + wall.floatingWallItem.offsetX}
        y={wall.floatingWallItem.y + wall.floatingWallItem.offsetY}
        width={wall.floatingWallItem.width}
        height={wall.floatingWallItem.height}
        opacity={0.5}
        shadowColor='black'
        shadowBlur={10}
        shadowOffset={{ x: -10, y: 10 }}
        shadowOpacity={0.3}
      />
    </>
  )
}

export default WallItemFloating
