import React from 'react'
import PropTypes from 'prop-types'

const Rating = ({ value, text, color }) => {
  return (
    <div className="rating">
      {[0, 1, 2, 3, 4].map((star) => (
        <i
          key={star}
          style={{ color }}
          className={`fas fa-star${value > star + 0.5
            ? ''
            : value > star
              ? '-half-alt'
              : value === star
                ? '-empty' // Added for full star display
                : ''
            }`}
        />
      ))}
      {text && <span>{text}</span>}
    </div>
  );
};

Rating.defaultProps = {
  color: '#ffc933',
}

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default Rating
