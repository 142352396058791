import React, { useState, useEffect } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Image,
  Card,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'

const AszfScreen = ({ match, location }) => {


  return (
    <div className='page-container'>
      <Container className='header-margin py-4'>
        <Row className='py-4'>
          <Col>
            <h1>Általános Szerződési Feltételek</h1>
            <h2>Preambulum</h2>
            <p>
              Üdvözöljük honlapunkon! Köszönjük, hogy vásárlása során bennünket
              tisztel meg bizalmával!
            </p>
            <p>
              Ha a jelen Általános Szerződési Feltételekkel, a honlap
              használatával, az egyes termékekkel, a vásárlás menetével
              kapcsolatban kérdése merült fel, vagy amennyiben egyedi igényét
              szeretné velünk megbeszélni, úgy kérjük, vegye fel munkatársunkkal
              a kapcsolatot a megadott elérhetőségeken!
            </p>

            <h2>Impresszum: a Szolgáltató (Eladó, Vállalkozás) adatai</h2>
            <p>Név: Fodor Tamás (egyéni vállalkozó)</p>

            <p>Székhely: 1133 Budapest Ronyva utca 6.</p>

            <p>Levelezési cím: 1133 Budapest Ronyva utca 6.</p>

            <p>Nyilvántartási szám: 57756934</p>

            <p>Adószám: 59709189141</p>

            <p>Képviselő: Fodor Tamás</p>

            <p>Telefonszám: 06304991286</p>

            <p>E-mail: cathletic.shop@gmail.com</p>

            <p>Honlap: http://www.cathletic.hu</p>

            <p>Bankszámlaszám: 12010635-01519323-00100003</p>

            <h2>Tárhelyszolgáltató adatai</h2>
            <p>Név: BlazeArts Kft.</p>

            <p>Székhely: 1096 Budapest, Thaly Kálmán utca 39.</p>

            <p>Elérhetőség: , admin@forpsi.hu</p>

            <p>Weboldal: https://www.forpsi.hu/</p>

            <h2>Fogalmak</h2>
            <p>
              Áru: a Honlap kínálatában szereplő, a Honlapon értékesítésre
              szánt:
            </p>
            <ul>
              <li>
                ingó dolog, ideértve a tartályban, palackban vagy egyéb módon
                korlátozott mennyiségben vagy meghatározott űrtartalommal
                kiszerelt víz, gáz és villamos energia, valamint
              </li>
              <li>
                ingó dolog, amely digitális tartalmat vagy digitális
                szolgáltatást foglal magában vagy azzal össze van kapcsolva,
                olyan módon, hogy az érintett digitális tartalom vagy digitális
                szolgáltatás hiányában az áru nem tudná betölteni funkcióit (a
                továbbiakban: a digitális elemeket tartalmazó áru)
              </li>
              <li>
                Digitális elemeket tartalmazó áru: ingó dolog, amely digitális
                tartalmat vagy digitális szolgáltatást foglal magában, vagy
                azzal össze van kapcsolva, olyan módon, hogy az érintett
                digitális tartalom vagy digitális szolgáltatás hiányában az áru
                nem tudná betölteni funkcióit
              </li>
            </ul>
            <p>Felek: Eladó és Vevő együttesen</p>

            <p>
              Fogyasztó: a szakmája, önálló foglalkozása vagy üzleti
              tevékenysége körén kívül eljáró természetes személy
            </p>

            <p>
              Fogyasztói szerződés: olyan szerződés, melynek egyik alanya
              fogyasztónak minősül
            </p>

            <p>
              Funkcionalitás: a digitális elemeket tartalmazó áru, a digitális
              tartalom vagy a digitális szolgáltatás azon képessége, hogy a
              céljának megfelelő funkciókat betöltse
            </p>

            <p>
              Gyártó: az Áru előállítója, importált Áru esetén az Árut az
              Európai Unió területére behozó importőr, valamint bármely olyan
              személy, aki vagy amely az Árun nevének, védjegyének vagy egyéb
              megkülönböztető jelzésének feltüntetésével önmagát gyártóként
              tünteti fel
            </p>

            <p>
              Interoperabilitás: a digitális elemeket tartalmazó áru, a
              digitális tartalom vagy a digitális szolgáltatás azon képessége,
              hogy együtt tudjon működni olyan hardverrel és szoftverrel, amely
              eltér attól, amellyel az azonos típusú árukat, digitális tartalmat
              vagy digitális szolgáltatásokat általában együtt használják
            </p>

            <p>
              Kompatibilitás: a digitális elemeket tartalmazó áru, a digitális
              tartalom vagy a digitális szolgáltatás azon képessége, hogy -
              átalakítás szükségessége nélkül - együtt tudjon működni olyan
              hardverrel vagy szoftverrel, amellyel az azonos típusú árukat,
              digitális tartalmat vagy digitális szolgáltatásokat általában
              együtt használják
            </p>

            <p>
              Honlap: a jelen weboldal, amely a szerződés megkötésére szolgál
            </p>

            <p>
              Szerződés: Eladó és Vevő között a Honlap és elektronikus levelezés
              igénybevételével létrejövő adásvételi szerződés
            </p>

            <p>
              Tartós adathordozó: bármely olyan eszköz, amely lehetővé teszi a
              fogyasztó vagy a vállalkozás számára a személyesen neki címzett
              adatoknak a jövőben is hozzáférhető módon és az adat céljának
              megfelelő ideig történő tárolását, valamint a tárolt adatok
              változatlan formában történő megjelenítését
            </p>

            <p>
              Távollévők közötti kommunikációt lehetővé tévő eszköz: olyan
              eszköz, amely alkalmas a felek távollétében – szerződés megkötése
              érdekében – szerződési nyilatkozat megtételére. Ilyen eszköz
              különösen a címzett vagy a címzés nélküli nyomtatvány, a
              szabványlevél, a sajtótermékben közzétett hirdetés
              megrendelőlappal, a katalógus, a telefon, a telefax és az
              internetes hozzáférést biztosító eszköz
            </p>

            <p>
              Távollévők között kötött szerződés: olyan fogyasztói szerződés,
              amelyet a szerződés szerinti Áru vagy szolgáltatás nyújtására
              szervezett távértékesítési rendszer keretében a felek egyidejű
              fizikai jelenléte nélkül úgy kötnek meg, hogy a szerződés
              megkötése érdekében a szerződő felek kizárólag távollévők közötti
              kommunikációt lehetővé tévő eszközt alkalmaznak
            </p>

            <p>
              Vállalkozás: a szakmája, önálló foglalkozása vagy üzleti
              tevékenysége körében eljáró személy
            </p>

            <p>
              Vevő/Ön: a Honlapon keresztül vételi ajánlatot tevő szerződést
              kötő személy
            </p>

            <p>
              Jótállás: A fogyasztó és a vállalkozás között kötött szerződések
              esetén (a továbbiakban: fogyasztói szerződés) a Polgári
              Törvénykönyv szerinti, a szerződés teljesítéséért vállalt
              jótállás, amelyet a vállalkozás a szerződés megfelelő
              teljesítéséért a jogszabályi kötelezettségén túlmenően vagy annak
              hiányában önként vállal, valamint a jogszabályon alapuló kötelező
              jótállás
            </p>
            <p>
              Vételár: az Áruért, valamint a digitális tartalom szolgáltatásáért
              fizetendő ellenszolgáltatás.
            </p>

            <h2>Vonatkozó jogszabályok</h2>
            <h3>
              A Szerződésre a magyar jog előírásai az irányadóak, és különösen
              az alábbi jogszabályok vonatkoznak:
            </h3>
            <p>1997. évi CLV. törvény a fogyasztóvédelemről</p>
            <p>
              2001. évi CVIII. törvény az elektronikus kereskedelmi
              szolgáltatások, valamint az információs társadalommal összefüggő
              szolgáltatások egyes kérdéseiről
            </p>
            <p>2013. évi V. törvény a Polgári Törvénykönyvről</p>
            <p>
              151/2003. (IX.22.) kormányrendelet a tartós fogyasztási cikkekre
              vonatkozó kötelező jótállásról
            </p>
            <p>
              45/2014. (II.26.) kormányrendelet a fogyasztó és a vállalkozás
              közötti szerződések részletes szabályairól{' '}
            </p>
            <p>
              19/2014. (IV.29.) NGM rendelet a fogyasztó és vállalkozás közötti
              szerződés keretében eladott dolgokra vonatkozó szavatossági és
              jótállási igények intézésének eljárási szabályairól
            </p>
            <p>1999. évi LXXVI. törvény a szerzői jogról</p>
            <p>
              2011. évi CXII. törvény az információs önrendelkezési jogról és az
              információszabadságról
            </p>
            <p>
              AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2018/302 RENDELETE (2018.
              február 28.) a belső piacon belül a vevő állampolgársága,
              lakóhelye vagy letelepedési helye alapján történő indokolatlan
              területi alapú tartalomkorlátozással és a megkülönböztetés egyéb
              formáival szembeni fellépésről, valamint a 2006/2004/EK és az (EU)
              2017/2394 rendelet, továbbá a 2009/22/EK irányelv módosításáról
            </p>
            <p>
              AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016.
              április 27.) a természetes személyeknek a személyes adatok
              kezelése tekintetében történő védelméről és az ilyen adatok szabad
              áramlásáról, valamint a 95/46/EK rendelet hatályon kívül
              helyezéséről (általános adatvédelmi rendelet)
            </p>
            <p>
              A fogyasztó és vállalkozás közötti, az áruk adásvételére, valamint
              a digitális tartalom szolgáltatására és digitális szolgáltatások
              nyújtására irányuló szerződések részletes szabályairól szóló
              373/2021. (VI. 30.) Korm. rendelet
            </p>
            <h2>Az ÁSZF hatálya, elfogadása</h2>
            <p>
              A közöttünk létrejövő szerződés tartalmát – a vonatkozó kötelező
              érvényű jogszabályok rendelkezései mellett – a jelen Általános
              Szerződési Feltételek (a továbbiakban: ÁSZF) határozzák meg. Ennek
              megfelelően tartalmazza a jelen ÁSZF az Önt és bennünket illető
              jogokat és kötelezettségeket, a szerződés létrejöttének
              feltételeit, a teljesítési határidőket, a szállítási és fizetési
              feltételeket, a felelősségi szabályokat, valamint az elállási jog
              gyakorlásának feltételeit.
            </p>

            <p>
              A Honlap használatához szükséges azon technikai tájékoztatást,
              melyet jelen ÁSZF nem tartalmaz, a Honlapon elérhető egyéb
              tájékoztatások nyújtják.
            </p>

            <p>
              Ön a megrendelése véglegesítése előtt köteles megismerni a jelen
              ÁSZF rendelkezéseit.{' '}
            </p>

            <h2>A szerződés nyelve, a szerződés formája</h2>
            <p>
              A jelen ÁSZF hatálya alá tartozó szerződések nyelve a magyar
              nyelv.
            </p>

            <p>
              A jelen ÁSZF hatálya alá tartozó szerződések nem minősülnek írásba
              foglalt szerződéseknek, azokat az Eladó nem iktatja.
            </p>
            <h2>E-számlázás</h2>
            <p>
              Társaságunk a 2007. évi CXXVII. törvény 175. §. szerinti
              elektromos számlát alkalmaz. Ön a jelen ÁSZF elfogadásával
              belegyezését adja az elektronikus számla alkalmazásához.
            </p>
            <h2>Árak</h2>
            <p>
              A termékekre vonatkozóan megjelenített árak bruttó árak, az alanyi
              adómentesség miatt mentes az ÁFA alól ( a vásárlásról alanyi
              adómentes (AAM), 0 Ft ÁFÁ-t tartalmazó számlát állítunk ki).
              (Kiskereskedelem esetén mindig bruttó árat kell megjeleníteni!).
              Nem zárható ki annak a lehetősége, hogy üzletpolitikai okból az
              Eladó az árakat módosítsa. Az árak módosítása nem terjed ki a már
              megkötött szerződésekre. Amennyiben Eladó az árat hibásan tüntette
              fel, és megrendelés érkezett az Árura, de szerződést még nem
              kötöttek a felek, úgy az ÁSZF „Eljárás hibás ár” pontja alapján
              jár el az Eladó.
            </p>

            <h2>Eljárás hibás ár esetén</h2>
            <p>Nyilvánvalóan hibásan feltüntetett árnak minősül:</p>

            <p>0 Ft-os ár,</p>
            <p>
              kedvezménnyel csökkentett, de a kedvezményt tévesen feltüntető ár
              (pl.: 1000 Ft-os Áru esetén a 20 %-os kedvezmény feltüntetése
              mellett 500 Ft-ért kínált Áru).
            </p>
            <p>
              Hibás ár feltüntetése esetén Eladó felajánlja az Áru valós áron
              történő megvásárlásának lehetőségét, mely információ birtokában a
              Vásárló eldöntheti, hogy megrendeli valós áron az Árut vagy minden
              hátrányos jogkövetkezmény nélkül lemondja a megrendelést.
            </p>

            <h2>Panaszügyintézés és jogérvényesítési lehetőségek</h2>
            <p>
              A fogyasztó az Áruval vagy az Eladó tevékenységével kapcsolatos
              fogyasztói kifogásait az alábbi elérhetőségeken terjesztheti elő:
            </p>

            <p>Telefon: 06304991286</p>
            <p>Internet cím: http://www.cathletic.hu</p>
            <p>E-mail: cathletic.shop@gmail.com</p>

            <p>
              A fogyasztó szóban vagy írásban közölheti a vállalkozással a
              panaszát, amely a vállalkozásnak, illetve a vállalkozás érdekében
              vagy javára eljáró személynek az áru fogyasztók részére történő
              forgalmazásával, illetve értékesítésével közvetlen kapcsolatban
              álló magatartására, tevékenységére vagy mulasztására vonatkozik.
            </p>
            <p>
              Telefonon vagy egyéb elektronikus hírközlési szolgáltatás
              felhasználásával közölt szóbeli panasz esetén a fogyasztónak
              legkésőbb 30 napon belül - az írásbeli panaszra adott válaszra
              vonatkozó előírásoknak megfelelően - az érdemi válasszal
              egyidejűleg megküldeni. Egyebekben pedig az írásbeli panaszra
              vonatkozóan az alábbiak szerint köteles eljárni. Az írásbeli
              panaszt a vállalkozás - ha az Európai Unió közvetlenül
              alkalmazandó jogi aktusa eltérően nem rendelkezik - a beérkezését
              követően harminc napon belül köteles írásban érdemben
              megválaszolni és intézkedni annak közlése iránt. Ennél rövidebb
              határidőt jogszabály, hosszabb határidőt törvény állapíthat meg. A
              panaszt elutasító álláspontját a vállalkozás indokolni köteles. A
              telefonon vagy elektronikus hírközlési szolgáltatás
              felhasználásával közölt szóbeli panaszt a vállalkozás köteles
              egyedi azonosítószámmal ellátni.
            </p>

            <h5>
              A panaszról felvett jegyzőkönyvnek tartalmaznia kell az
              alábbiakat:
            </h5>

            <p>a fogyasztó neve, lakcíme,</p>
            <p>a panasz előterjesztésének helye, ideje, módja,</p>
            <p>
              a fogyasztó panaszának részletes leírása, a fogyasztó által
              bemutatott iratok, dokumentumok és egyéb bizonyítékok jegyzéke,
            </p>
            <p>
              a vállalkozás nyilatkozata a fogyasztó panaszával kapcsolatos
              álláspontjáról, amennyiben a panasz azonnali kivizsgálása
              lehetséges,
            </p>
            <p>
              a jegyzőkönyvet felvevő személy és - telefonon vagy egyéb
              elektronikus hírközlési szolgáltatás felhasználásával közölt
              szóbeli panasz kivételével - a fogyasztó aláírása,
            </p>
            <p>a jegyzőkönyv felvételének helye, ideje,</p>
            <p>
              telefonon vagy egyéb elektronikus hírközlési szolgáltatás
              felhasználásával közölt szóbeli panasz esetén a panasz egyedi
              azonosítószáma.
            </p>
            <p>
              A vállalkozás a panaszról felvett jegyzőkönyvet és a válasz
              másolati példányát három évig köteles megőrizni, és azt az
              ellenőrző hatóságoknak kérésükre bemutatni.
            </p>

            <p>
              A panasz elutasítása esetén a vállalkozás köteles a fogyasztót
              írásban tájékoztatni arról, hogy panaszával - annak jellege
              szerint - mely hatóság vagy békéltető testület eljárását
              kezdeményezheti. A tájékoztatásnak tartalmaznia kell továbbá az
              illetékes hatóság, illetve a fogyasztó lakóhelye vagy tartózkodási
              helye szerinti békéltető testület székhelyét, telefonos és
              internetes elérhetőségét, valamint levelezési címét. A
              tájékoztatásnak arra is ki kell terjednie, hogy a vállalkozás a
              fogyasztói jogvita rendezése érdekében igénybe veszi-e a békéltető
              testületi eljárást. Amennyiben az Eladó és a fogyasztó között
              esetlegesen fennálló fogyasztói jogvita a tárgyalások során nem
              rendeződik, az alábbi jogérvényesítési lehetőségek állnak nyitva a
              fogyasztó számára:
            </p>

            <h5>Fogyasztóvédelmi eljárás</h5>
            <p>
              Panasztétel a fogyasztóvédelmi hatóságoknál lehetséges. Amennyiben
              a fogyasztó fogyasztói jogainak megsértését észleli, jogosult
              panasszal fordulni a lakóhelye szerint illetékes fogyasztóvédelmi
              hatósághoz. A panasz elbírálását követően a hatóság dönt a
              fogyasztóvédelmi eljárás lefolytatásáról. A fogyasztóvédelmi
              elsőfokú hatósági feladatokat a fogyasztó lakóhelye szerint
              illetékes fővárosi és megyei kormányhivatalok látják el, ezek
              listája itt található: http://www.kormanyhivatal.hu/
            </p>

            <h5>Bírósági eljárás</h5>
            <p>
              Az ügyfél jogosult a fogyasztói jogvitából származó követelésének
              bíróság előtti érvényesítésére polgári eljárás keretében a Polgári
              Törvénykönyvről szóló 2013. évi V. törvény, valamint a Polgári
              Perrendtartásról szóló 2016. évi CXXX. törvény rendelkezései
              szerint.
            </p>

            <h5>Békéltető testületi eljárás</h5>
            <p>
              Tájékoztatjuk, hogy Ön velünk szemben fogyasztói panasszal élhet.
              Amennyiben az Ön fogyasztói panaszát elutasítjuk, úgy Ön jogosult
              az Ön lakóhelye vagy tartózkodási helye szerint illetékes
              Békéltető Testülethez is fordulni: a békéltető testület eljárása
              megindításának feltétele, hogy a fogyasztó az érintett
              vállalkozással közvetlenül megkísérelje a vitás ügy rendezését. Az
              eljárásra - a fogyasztó erre irányuló kérelme alapján - az
              illetékes testület helyett a fogyasztó kérelmében megjelölt
              békéltető testület illetékes.
            </p>

            <p>
              A vállalkozást a békéltető testületi eljárásban együttműködési
              kötelezettség terheli.
            </p>

            <p>
              Ennek keretében fennáll a vállalkozásoknak a békéltető testület
              felhívására történő válaszirat megküldési kötelezettsége, továbbá
              kötelezettségként kerül rögzítésre a békéltető testület előtti
              megjelenési kötelezettség („meghallgatáson egyezség
              létrehozatalára feljogosított személy részvételének biztosítása”).
            </p>

            <p>
              Amennyiben a vállalkozás székhelye vagy telephelye nem a
              területileg illetékes békéltető testületet működtető kamara
              szerinti megyébe van bejegyezve, a vállalkozás együttműködési
              kötelezettsége a fogyasztó igényének megfelelő írásbeli
              egyezségkötés lehetőségének felajánlására terjed ki.
            </p>

            <p>
              A békéltető testület hatáskörébe tartozik a fogyasztói jogvita
              bírósági eljáráson kívüli rendezése. A békéltető testület
              feladata, hogy megkísérelje a fogyasztói jogvita rendezése
              céljából egyezség létrehozását a felek között, ennek
              eredménytelensége esetén az ügyben döntést hoz a fogyasztói jogok
              egyszerű, gyors, hatékony és költségkímélő érvényesítésének
              biztosítása érdekében. A békéltető testület a fogyasztó vagy a
              vállalkozás kérésére tanácsot ad a fogyasztót megillető jogokkal
              és a fogyasztót terhelő kötelezettségekkel kapcsolatban.
            </p>

            <p>
              A békéltető testület eljárása a fogyasztó kérelmére indul. A
              kérelmet a békéltető testület elnökéhez kell írásban benyújtani:
              az írásbeliség követelményének levél, távirat, távgépíró vagy
              telefax útján, továbbá bármely egyéb olyan eszközzel is eleget
              lehet tenni, amely a címzett számára lehetővé teszi a neki címzett
              adatoknak az adat céljának megfelelő ideig történő tartós
              tárolását, és a tárolt adatok változatlan formában és tartalommal
              történő megjelenítését. A kérelemnek tartalmaznia kell
            </p>

            <p>a fogyasztó nevét, lakóhelyét vagy tartózkodási helyét,</p>
            <p>
              a fogyasztói jogvitával érintett vállalkozás nevét, székhelyét
              vagy érintett telephelyét,
            </p>
            <p>
              ha a fogyasztó az illetékességet az illetékes békéltető testület
              helyett kérelmezett testület megjelölését,
            </p>
            <p>
              a fogyasztó álláspontjának rövid leírását, az azt alátámasztó
              tényeket és azok bizonyítékait,
            </p>
            <p>
              a fogyasztó nyilatkozatát arról, hogy a fogyasztó az érintett
              vállalkozással közvetlenül megkísérelte a vitás ügy rendezését
            </p>
            <p>
              a fogyasztó nyilatkozatát arra nézve, hogy az ügyben más békéltető
              testület eljárását nem kezdeményezte, közvetítői eljárás nem
              indult, keresetlevél beadására, illetve fizetési meghagyás
              kibocsátása iránti kérelem előterjesztésére nem került sor,
            </p>
            <p>a testület döntésére irányuló indítványt,</p>
            <p>a fogyasztó aláírását.</p>
            <p>
              A kérelemhez csatolni kell azt az okiratot, illetve annak
              másolatát (kivonatát), amelynek tartalmára a fogyasztó
              bizonyítékként hivatkozik, így különösen a vállalkozás írásbeli
              nyilatkozatát a panasz elutasításáról, ennek hiányában a fogyasztó
              rendelkezésére álló egyéb írásos bizonyítékot az előírt egyeztetés
              megkísérléséről.
            </p>

            <p>
              Ha a fogyasztó meghatalmazott útján jár el, a kérelemhez csatolni
              kell a meghatalmazást.
            </p>

            <p>
              A Békéltető Testületekről bővebb információ itt érhető el:
              http://www.bekeltetes.hu
            </p>
            <p>
              A területileg illetékes Békéltető Testületekről bővebb információ
              itt érhető el:
            </p>
            <p>https://bekeltetes.hu/index.php?id=testuletek</p>

            <h2>Online vitarendezési platform</h2>
            <p>
              Az Európai Bizottság létrehozott egy honlapot, amelybe a
              fogyasztók beregisztrálhatnak, így ezen keresztül lehetőségük
              nyílik arra, hogy online vásárláshoz kapcsolódó jogvitáikat ezen
              keresztül rendezzék egy kérelem kitöltésével, elkerülve a bírósági
              eljárást. Így a fogyasztók tudják érvényesíteni jogaikat anélkül,
              hogy például a távolság meggátolná őket ebben.
            </p>

            <p>
              Ha Ön panaszt kíván tenni egy, az interneten vásárolt Áruval vagy
              szolgáltatással kapcsolatban, és nem akar feltétlenül bírósághoz
              fordulni, igénybe veheti az online vitarendezés eszközét.
            </p>
            <p>
              A portálon Ön és a kereskedő, akivel szemben panasszal élt,
              közösen kiválaszthatják a panasz kezelésével megbízni kívánt
              vitarendezési testületet.
            </p>

            <p>
              Az online vitarendezési platform itt érhető el:
              https://webgate.ec.europa.eu/odr/main/?event=main.home.show&lng=HU
            </p>

            <h2>Szerzői jogok</h2>
            <p>
              A szerzői jogról szóló 1999. évi LXXVI. törvény (továbbiakban:
              Szjt.) 1. § (1) bekezdése értelmében a weboldal szerzői műnek
              minősül, így annak minden része szerzői jogi védelem alatt áll. Az
              Szjt. 16. § (1) bekezdése alapján tilos a weboldalon található
              grafikai és szoftveres megoldások, számítógépi programalkotások
              engedély nélküli felhasználása, illetve bármely olyan alkalmazás
              használata, amellyel a weboldal, vagy annak bármely része
              módosítható. A weboldalról és annak adatbázisából bármilyen
              anyagot átvenni a jogtulajdonos írásos hozzájárulása esetén is
              csak a weboldalra való hivatkozással, forrás feltüntetésével
              lehet. A jogtulajdonos: Fodor Tamás (egyéni vállalkozó).
            </p>

            <h2>Részleges érvénytelenség, magatartási kódex</h2>
            <p>
              Ha az ÁSZF valamely pontja jogilag hiányos vagy hatálytalan, attól
              még a szerződés további pontjai érvényben maradnak és a
              hatálytalan vagy hibás rész helyett a vonatkozó jogszabályok
              rendelkezései alkalmazandóak.
            </p>

            <p>
              Az Eladónak nincs a fogyasztókkal szembeni tisztességtelen
              kereskedelmi gyakorlat tilalmáról szóló törvény szerinti
              magatartási kódexe.
            </p>

            <h2>
              Tájékoztatás a digitális elemeket tartalmazó áruk működéséről,
              valamint az alkalmazandó műszaki védelmi intézkedésről
            </h2>
            <p>
              A weboldalon megjelenő adatokat szolgáltató szerverek elérhetősége
              évi 99,9% feletti. Rendszeresen mentés készül a teljes
              adattartalomról, így probléma esetén az eredeti adattartalom
              visszaállítható. A weboldalon megjelenő adatokat NoSql Mongo
              adatbázisban tároljuk. Az érzékeny adatok megfelelő erősségű
              titkosítással vannak tárolva. Adatokat harmadik fél részére nem
              adunk ki.
            </p>
            <h2>Az Áruk lényeges tulajdonságaira vonatkozó tájékoztatás</h2>
            <p>
              A honlapon a megvásárolható Áruk lényeges tulajdonságairól az
              egyes Áruknál szereplő leírásokban adunk tájékoztatást.
            </p>
            <h2>
              Az adatbeviteli hibák javítása - Felelősség a megadott adatok
              valóságáért
            </h2>
            <p>
              Önnek a megrendelés során a megrendelés véglegesítése előtt
              folyamatosan lehetősége van az Ön által bevitt adatok módosítására
              (a böngészőben a vissza gombra kattintva az előző oldal nyílik
              meg, így akkor is javíthatóak a bevitt adatok, ha Ön már a
              következő oldalra lépett). Felhívjuk a figyelmét, hogy az Ön
              felelőssége, hogy az Ön által megadott adatok pontosan kerüljenek
              bevitelre, hiszen az Ön által megadott adatok alapján kerül
              számlázásra, illetve szállításra az Áru. Felhívjuk a figyelmét
              arra, hogy a rosszul megadott e-mail cím vagy a postafiókhoz
              tartozó tárhely telítettsége a visszaigazolás kézbesítésének
              hiányát eredményezheti és meggátolhatja a szerződés létrejöttét.
              Amennyiben a Vásárló véglegesítette rendelését és a megadott
              adatokban hibát fedez fel, akkor a lehető legrövidebb időn belül
              kezdeményeznie kell a rendelése módosítását. A hibás rendelés
              módosítását a megrendeléskor megadott e-mail címről küldött
              levéllel, vagy telefonhívással jelezheti Vásárló az Eladó felé.
            </p>
            <h2>A honlap használata </h2>
            <p>A vásárlás nem regisztrációhoz kötött.</p>

            <h4>A vásárlás folyamata</h4>
            <h5>A Termék kiválasztása</h5>

            <p>
              A honlapon szereplő termékkategóriákra kattintva választhatja ki a
              kívánt termékcsaládot, és ezen belül az egyes termékeket. Az egyes
              termékekre kattintva találja a termék fotóját, cikkszámát,
              ismertetőjét, árát. Önnek vásárlás esetén a honlapon szereplő árat
              kell megfizetnie.{' '}
            </p>

            <h5>Kosárba helyezés</h5>

            <p>
              A Termék kiválasztása után Ön a "Kosárba" gombra kattintva
              helyezhet - tetszőleges számú - terméket a kosárba anélkül, hogy
              ezzel Önnek vásárlási- vagy fizetési kötelezettsége keletkezne,
              mivel a kosárba helyezés nem minősül ajánlattételnek.
            </p>

            <p>
              Javasoljuk, hogy akkor is helyezze a kosárba a terméket, ha nem
              biztos abban, hogy az adott terméket meg kívánja vásárolni, mert
              ezzel az Ön számára egy kattintással áttekinthetővé válik, hogy az
              adott pillanatban melyek az Ön által kiválasztott termékek, és
              azokat egy képernyőn megjelenítve tudja megtekinteni és
              összehasonlítani. A Kosár tartalma a megrendelés véglegesítéséig –
              a "Rendelés véglegesítése" gomb megnyomásáig – szabadon
              módosítható, a kosárból tetszés szerinti termékek eltávolíthatóak,
              a kosárba tetszés szerint újabb termékek helyezhetőek, illetve a
              kíván termékszám megváltoztatható.
            </p>

            <p>
              Amennyiben Ön a kiválasztott terméket a Kosárba helyezi, úgy
              automatikusan a Kosár oldalra navigál az oldal, ahol láthatja a
              kosárba elhelyett árut a tételek között.
            </p>

            <h5>A Kosár megtekintése</h5>

            <p>
              A honlap használata során Ön a honlap tetején található „Kosár"
              menüpontra kattintva bármikor ellenőrizheti a kosár tartalmát. Itt
              lehetősége van a kiválasztott termékeket eltávolítani a kosárból,
              illetve a termék darabszámát megváltoztatni.
            </p>
            <p>
              Amennyiben Ön nem kíván további termékeket kiválasztani és a
              kosárba helyezni, úgy a „TOVÁBB A KASSZÁHOZ” gomb lenyomásával
              folytathatja a vásárlást.
            </p>

            <h5>Vásárlói adatok megadása</h5>

            <p>
              „TOVÁBB A KASSZÁHOZ” gomb lenyomását követően megjelenik a
              Szállítási Adatok oldal, ahol megadhatja a szállítási adatait.
              Amennyiben a szállítási és számlázási adatok megegyeznek, akkor a
              "Számlázási adatok megegyeznek a szállítási adatokkal" rubrika
              kipipálásával jelezheti az eladó felé. Ha ez a rubrika be van
              pipálva, akkor a Számlázási adatok megadása vásárlási lépést át is
              ugorja a rendszer. "TOVÁBB" gombra kattintva a Számlázás vagy
              Fizetés lépés nyíik meg, az előbbi rubrikától függően.{' '}
            </p>
            <p>
              Amennyiben számlázási és szállítási adatai eltérnek, akkor a
              Számlázási Adatok lépés során adhatja meg az adataid. Szállítási
              Adatok oldal, ahol megadhatja a szállítási adatait.
            </p>
            <h5>Fizetési mód kiválasztása</h5>
            <p>
              A Fizetési mód lépésben kiválaszthatja, hogy Bankkártyával, vagy
              PayPallal szeretne fizetni, de lehetőség van Utánvét
              kiválasztásásra is.
            </p>
            <h5>Rendelés áttekintése</h5>
            <p>
              A RENDELÉS ÖSSZEGZÉS lépésben ellenőrizheti a megadott adatait,
              amennyiben Ön meggyőződött arról, hogy a kosár tartalma megfelel
              az Ön által megrendelni kívánt Áruknak, valamint az Ön adatai
              helyesen szerepelnek, úgy az Általános Szerződési Feltételek
              elfogadása után a RENDELÉS LEADÁSA gombra kattintva zárhatja le
              megrendelését. A honlapon közölt információk nem minősülnek az
              Eladó részéről szerződés megkötésére vonatkozó ajánlatnak. A jelen
              ÁSZF hatálya alá tartozó megrendelések esetén Ön minősül
              ajánlattevőnek.
            </p>
            <p>
              Ön a "RENDELÉS LEADÁSA" gomb megnyomásával kifejezetten tudomásul
              veszi, hogy ajánlatát megtettnek kell tekinteni, és nyilatkozata –
              az Eladó jelen ÁSZF szerinti visszaigazolása esetén - fizetési
              kötelezettséget von maga után. Önt az ajánlata 48 órás
              időtartamban köti. Amennyiben az Ön ajánlatát a jelen általános
              szerződési feltételek szerint 48 órán belül nem igazolja vissza az
              Eladó, Ön mentesül az ajánlati kötöttsége alól.
            </p>

            <h2>Rendelés feldolgozása, a szerződés létrejötte</h2>
            <p>
              Önnek bármilyen időpontban lehetősége van a megrendelés leadására.
              Az Eladó legkésőbb az Ön ajánlatának elküldését követő munkanap
              során e-mail útján visszaigazolja az Ön ajánlatát. A szerződés
              akkor jön létre, amikor az Eladó által küldött visszaigazoló email
              az Ön számára a levelezőrendszerében hozzáférhetővé válik.
            </p>

            <p>Fizetési módok</p>
            {/* <p>Banki átutalás</p>
            <p>
              A termékek ellenértékét banki átutalás útján is rendezheti.
              Megrendelést követően, telefonos egyeztetés után, díjbekérő
              "Proforma" számlát küldünk ki a megrendelés közben megadott e-mail
              címre. Fontos, tekintse át a díjbekérő számlát, kimondottan a vevő
              részt figyelmesen áttekinteve, a fizetést követően a djbekérő
              alapján készűl el az eredeti számla. Az átutalt összeg
              megérkezését követően legkésöbb 3 munkanapon belűl kiküldjük az
              "Eredeti" számlát, amely teljesítést már nem igényel és FIZETETT
              felirattal van ellátva.Szintén a megadott e-mail címre érkezik.
              Fontos megemlíteni, hogy a számla eredetiben CSAK EGYSZER
              nyomtatható ki. Amennyiben Ön megnézi telefonon illetve tableten
              vagy olyan számítógépen amelyen nem tudja kinyomtatni, akkor
              késöbb már nem tudja nyomtatni a számlát. Ha ilyen történne, vegye
              fel velünk a kapcsolatot és küldünk Önnek egy eredetivel mindenben
              megeggyező számla másolatot.
            </p> */}

            <h5>Bankkártyás vagy PayPalos fizetés</h5>
            <p>
              Webáruházunkban gyorsan és biztonságosan fizethet bankkártyával
              vagy PayPal felhasználó fiókkal. A bankkártyával vagy PayPallal
              fizetett összeg megérkezését követően legkésöbb 3 munkanapon belűl
              kiküldjük az "Eredeti" számlát, amely teljesítést már nem igényel
              és FIZETETT felirattal van ellátva. A megadott e-mail címre
              érkezik. Fontos megemlíteni, hogy a számla eredetiben CSAK EGYSZER
              nyomtatható ki. Amennyiben Ön megnézi telefonon illetve tableten
              vagy olyan számítógépen amelyen nem tudja kinyomtatni, akkor
              késöbb már nem tudja nyomtatni a számlát. Ha ilyen történne, vegye
              fel velünk a kapcsolatot és küldünk Önnek egy eredetivel mindenben
              megeggyező számla másolatot.
            </p>

            <h5>Utánvétel</h5>
            <p>
              Amennyiben a rendelés értékét a csomag kézhezvételekor kívánja
              kiegyenlíteni, akkor válassza az "Utánvétel" fizetési módot.
              Utánvétes fizetés estén tranzakciós díjat szómolunk fel ami a
              mindenkori banki díjakkal megeggyező, hiszen ezt a bank terheli a
              vásárlóra.{' '}
            </p>

            <h2>Átvételi módok, átvételi díjak</h2>
            <p>Házhozszállítás a Cathletic által.</p>
            <p>Egyedi ajánlat.</p>
            <p>
              Minden estben egyedi ajánlatot adunk a szállítani kívánt
              termékekre. Szállítási díj függ a termékek mennyiségétől,
              méretétől, rendeletetési helyétől.
            </p>
            <p>Bizonyos összeghatár felett ingyenes!</p>
            <p>
              Válassza bátran ezt a szállítási módot, biztos, hogy a
              legkedvezőtt szállítási összegen kapja mega termékeket.
            </p>
            <h2>Teljesítési határidő</h2>
            <p>
              A megrendelésre vonatkozóan az általános teljesítési határidő a
              rendelés visszaigazolásától számított legfeljebb 30 nap. Az Eladó
              késedelme esetén a Vevő jogosult póthatáridőt tűzni. Ha az Eladó a
              póthatáridőn belül nem teljesít, a Vevő jogosult a szerződéstől
              elállni.
            </p>

            <h2>Jogfenntartás, tulajdonjogi kikötés</h2>
            <p>
              Amennyiben Ön korábban már úgy rendelt Árut, hogy azt a
              kiszállítás során nem vette át (ide nem értve azt az esetet,
              amikor elállási jogával élt), vagy az Áru nem kereste jelzéssel
              érkezett vissza az eladóhoz, az Eladó a megrendelés teljesítését a
              vételár és a szállítási költségek előre történő megfizetéséhez
              köti.
            </p>

            <p>
              Eladó visszatarthatja az Áru átadását, ameddig nem győződik meg
              arról, hogy az Áru árának kifizetése sikeresen megtörtént az
              elektronikus fizetési megoldás használatával (ideértve azt az
              esetet is, amikor az átutalással fizetett Áru esetén Vásárló a
              tagállama szerinti pénznemben utalja el a vételárat és az
              átváltás, valamint a banki jutalékok, költségek miatt Eladó nem
              kapja meg teljes mértékben a vételár és a szállítási díj
              összegét). Amennyiben az Áru ára nem került teljes mértékben
              kifizetésre, az Eladó a vételár kiegészítésére hívhatja fel a
              Vásárlót.
            </p>

            <h2>Külföldre történő értékesítés</h2>
            <p>
              Az Eladó nem különbözteti meg a Honlap használatával Magyarország
              területén és az azon kívül az Európai Unió területén belüli
              Vásárlókat. A jelen ÁSZF eltérő rendelkezése hiányában az Eladó
              Magyarország területén biztosítja a megrendelt Áruk
              kiszállítását/átvételét.
            </p>

            <p>
              A Magyarországon kívüli vásárlásra is jelen ÁSZF rendelkezései az
              irányadóak azzal, hogy a vonatkozó rendelet előírásai alapján
              jelen pont értelmezésében vevőnek az a fogyasztó minősül, aki
              valamely tagállam állampolgára, vagy valamely tagállamban
              lakóhellyel rendelkezik, vagy az a vállalkozás, amely valamely
              tagállamban letelepedési hellyel rendelkezik, és az Európai Unión
              belül kizárólag végfelhasználás céljából vásárol árut vagy vesz
              igénybe szolgáltatást, vagy ilyen szándékkal jár el. Fogyasztónak
              az a természetes személy minősül, aki olyan célból jár el, amely
              kívül esik kereskedelmi, ipari, kézműipari vagy szakmai
              tevékenysége körén.
            </p>

            <p>
              A kommunikáció és a vásárlás nyelve elsősorban a magyar nyelv, az
              Eladó nem köteles a Vásárló tagállami szerinti nyelven
              kommunikálni a Vásárlóval.
            </p>

            <p>
              Az Eladó nem köteles megfeleljen a Vásárló tagállama szerinti
              nemzeti jogban az érintett Áruval kapcsolatban meghatározott
              szerződésen kívüli követelményeknek, például címkézési vagy
              ágazatspecifikus követelményeknek, vagy hogy tájékoztassa a
              Vásárlót ezekről a követelményekről.
            </p>
            <p>
              A Vásárló a jelen ÁSZF szerint élhet jogérvényesítési
              lehetőségeivel.
            </p>

            <p>
              Elektronikus fizetési megoldás alkalmazása esetén a fizetés az
              Eladó által meghatározott pénznemben valósul meg,
            </p>

            <p>
              Az Eladó visszatarthatja az Áru átadását ameddig nem győződik meg
              arról, hogy az Áru árának és a szállítási díjnak kifizetése
              sikeresen és maradéktalanul megtörtént az elektronikus fizetési
              megoldás használatával (ideértve azt az esetet is, amikor az
              átutalással fizetett Áru esetén a Vásárló a tagállama szerinti
              pénznemben utalja el a vételárat (szállítási díjat) és az
              átváltás, valamint a banki jutalékok, költségek miatt az Eladó nem
              kapja meg teljes mértékben a vételár összegét). Amennyiben az Áru
              ára nem került teljes mértékben kifizetésre, az Eladó a vételár
              kiegészítésére hívhatja fel a Vásárlót.
            </p>

            <p>
              Az Eladó az Áru átadása érdekében a magyar Vásárlókat megillető
              átadási lehetőségeket biztosítja a nem magyarországi Vásárlóknak
              is.
            </p>

            <p>
              Amennyiben a Vásárló az ÁSZF szerint kérheti az Áru szállítását
              Magyarország területére, vagy bármely más Európai Uniós tagállam
              területére, ezt kérheti a nem magyarországi vásárló is bármely az
              ÁSZF-ben megjelölt szállítási módon.
            </p>

            <p>
              Amennyiben a Vásárló az ÁSZF szerint választhatja az Áru személyes
              átvételét az Eladónál, ezzel élhet a nem magyarországi Vásárló is.
            </p>

            <p>
              Egyebekben a Vásárló kérheti, hogy az Áru szállítását saját
              költségén oldhassa meg külföldre. Magyar Vásárlót ez a jog nem
              illet meg.
            </p>

            <p>
              Az Eladó a szállítási díj megfizetését követően teljesíti a
              megrendelést, amennyiben a Vásárló a szállítási díjat nem fizeti
              meg az Eladó számára, vagy a saját szállítást nem oldja meg az
              előre egyezetetett időpontig, az Eladó a szerződést felmondja és
              az előre megfizetett vételárat visszafizeti a Vásárló számára.
            </p>

            <h2>Fogyasztói tájékoztató</h2>
            <p>Tájékoztató a fogyasztó vevőt megillető elállási jogról</p>
            <p>
              Fogyasztónak a Ptk. 8:1. § 1. bekezdés 3. pontja szerint csak a
              szakmája, önálló foglalkozása vagy üzleti tevékenysége körén kívül
              eljáró természetes személy minősül, így jogi személyek nem
              élhetnek az indokolás nélküli elállási joggal!
            </p>

            <p>
              A fogyasztót a 45/2014. (II. 26.) Korm. rendelet 20. § szerint
              megilleti az indokolás nélküli elállás joga. A fogyasztó az
              elállási jogát
            </p>

            <p>a) Áru adásvételére irányuló szerződés esetén</p>
            <p>aa) az Árunak,</p>
            <p>
              ab) több Áru adásvételekor, ha az egyes Áruk szolgáltatása eltérő
              időpontban történik, az utoljára szolgáltatott Árunak,
            </p>
            <p>
              a fogyasztó vagy az általa megjelölt, a fuvarozótól eltérő
              harmadik személy általi átvételének napjától számított határidőn
              belül gyakorolhatja, mely határidő 14 nap.
            </p>

            <p>
              A 45/2014. (II. 26.) Korm. rendelet által biztosított elállási idő
              14 nap, a jelen ÁSZF-ben az Eladó által vállalt ezen felüli
              elállási idő önkéntes vállalás a jogszabályan írtakon felül.
            </p>

            <p>
              A jelen pontban foglaltak nem érintik a fogyasztó azon jogát, hogy
              az e pontban meghatározott elállási jogát a szerződés megkötésének
              napja és az Áru átvételének napja közötti időszakban is
              gyakorolja.
            </p>

            <p>
              Ha a szerződés megkötésére a fogyasztó tett ajánlatot, a
              fogyasztót a szerződés megkötése előtt megilleti az ajánlat
              visszavonásának joga, ami a szerződés megkötésére kiterjedő
              ajánlati kötöttséget megszünteti.
            </p>

            <h2>
              Elállási nyilatkozat, a fogyasztót megillető elállási vagy
              felmondási jog gyakorlása
            </h2>
            <p>
              A fogyasztó a 45/2014. (II. 26.) Korm. rendelet 20. §-ban
              biztosított jogát az erre vonatkozó egyértelmű nyilatkozat útján
              gyakorolhatja.
            </p>

            <h2>A fogyasztó elállási nyilatkozatának érvényessége</h2>
            <p>
              Az elállási jogot határidőben érvényesítettnek kell tekinteni, ha
              a fogyasztó nyilatkozatát határidőn belül elküldi. A határidő 14
              nap.
            </p>

            <p>
              Írásban történő elállás vagy felmondás esetén elegendő az elállási
              vagy felmondási nyilatkozatot elküldeni 14 napon belül.
            </p>

            <p>
              A 45/2014. (II. 26.) Korm. rendelet által biztosított elállási idő
              14 nap, a jelen ÁSZF-ben az Eladó által vállalt ezen felüli
              elállási idő önkéntes vállalás a jogszabályan írtakon felül.
            </p>

            <p>
              A fogyasztót terheli annak bizonyítása, hogy az elállás jogát e
              rendelkezéssel összhangban gyakorolta.
            </p>

            <h2>
              Az Eladó a fogyasztó elállási nyilatkozatát annak megérkezését
              követően köteles elektronikus adathordozón visszaigazolni.{' '}
            </h2>

            <p>Az Eladó kötelezettségei a fogyasztó elállása esetén</p>
            <p>Az Eladó visszatérítési kötelezettsége</p>
            <p>
              Ha a fogyasztó a 45/2014. (II. 26.) Korm. rendelet 22. §-nak
              megfelelően eláll a szerződéstől, az Eladó legkésőbb az elállásról
              való tudomásszerzésétől számított tizennégy napon belül
              visszatéríti a fogyasztó által ellenszolgáltatásként megfizetett
              teljes összeget, ideértve a teljesítéssel összefüggésben felmerült
              költségeket, így a szállítási díjat is. Felhívjuk a figyelmét,
              hogy ez a rendelkezés nem vonatkozik a legkevésbé költséges
              szokásos fuvarozási módtól eltérő fuvarozási mód választásával
              okozott többletköltségekre.
            </p>

            <p>Az Eladó visszatérítési kötelezettségének módja</p>
            <p>
              A 45/2014. (II. 26.) Korm. rendelet 22. §-nak megfelelő elállás
              vagy felmondás esetén az Eladó a fogyasztónak visszajáró összeget
              a fogyasztó által igénybe vett fizetési móddal megegyező módon
              téríti vissza. A fogyasztó kifejezett beleegyezése alapján az
              Eladó a visszatérítésre más fizetési módot is alkalmazhat, de a
              fogyasztót ebből adódóan semmilyen többletdíj nem terhelheti. A
              Fogyasztó által hibásan és/vagy pontatlanul megadott
              bankszámlaszám vagy postai cím következtében történő késedelem
              miatt az Eladó-t felelősség nem terheli.
            </p>

            <p>Többletköltségek</p>
            <p>
              Ha a fogyasztó kifejezetten a legkevésbé költséges szokásos
              fuvarozási módtól eltérő fuvarozási módot választ, az Eladó nem
              köteles visszatéríteni az ebből eredő többletköltségeket. Ilyen
              esetben a feltüntetett általános szállítási díjtételek erejéig áll
              fenn visszatérítési kötelezettségünk.{' '}
            </p>

            <p>Visszatartási jog</p>
            <p>
              Az Eladó mindaddig visszatarthatja a fogyasztónak visszajáró
              összeget, amíg a fogyasztó az Árut vissza nem szolgáltatta, vagy
              kétséget kizáróan nem igazolta, hogy azt visszaküldte; a kettő
              közül a korábbi időpontot kell figyelembe venni. Utánvéttel vagy
              portósan feladott küldeményeket nem áll módunkban elfogadni.{' '}
            </p>

            <h2>A fogyasztó kötelezettségei elállása vagy felmondása esetén</h2>
            <p>Az Áru visszaszolgáltatása</p>
            <p>
              Ha a fogyasztó a 45/2014. (II. 26.) Korm. rendelet 22. §-nak
              megfelelően eláll a szerződéstől, köteles az Árut haladéktalanul,
              de legkésőbb az elállás közlésétől számított tizennégy napon belül
              visszaküldeni, illetve az Eladónak vagy az Eladó által az Áru
              átvételére meghatalmazott személynek átadni. A visszaküldés
              határidőben teljesítettnek minősül, ha a fogyasztó az Árut a
              határidő lejárta előtt elküldi.
            </p>

            <p>
              Az Áru visszaszolgáltatásával kapcsolatos közvetlen költségek
              viselése
            </p>
            <p>
              A fogyasztó viseli az Áru visszaküldésének közvetlen költségét. Az
              Árut az Eladó címére kell visszaküldeni. Ha a fogyasztó a
              teljesítés megkezdését követően felmondja az üzlethelyiségen kívül
              vagy távollévők között kötött - szolgáltatás nyújtásra vonatkozó -
              szerződést, köteles a felmondás vállalkozással való közlése
              időpontjáig teljesített szolgáltatással arányos díjat a
              vállalkozás számára megfizetni. A fogyasztó által arányosan
              fizetendő összeget a szerződésben megállapított ellenszolgáltatás
              adóval növelt teljes összege alapján kell megállapítani. Ha a
              fogyasztó bizonyítja, hogy az ily módon megállapított teljes
              összeg túlzottan magas, az arányos összeget a szerződés
              megszűnésének időpontjáig teljesített szolgáltatások piaci értéke
              alapján kell kiszámítani. Kérjük vegye figyelembe, hogy utánvéttel
              vagy portósan visszaküldött Árut nem áll módunkban átvenni.
            </p>

            <p>Fogyasztó felelőssége az értékcsökkenésért</p>
            <p>
              A fogyasztó az Áru jellegének, tulajdonságainak és működésének
              megállapításához szükséges használatot meghaladó használatból
              eredő értékcsökkenésért felel.
            </p>

            <h2>Az elállási jog az alábbi esetekben nem gyakorolható</h2>
            <p>
              Az Eladó kifejezetten felhívja az Ön figyelmét, hogy Ön nem
              gyakorolhatja elállási jogát a 45/2014 (II.26.) Korm. Rendelet 29.
              §. (1) bekezdésében foglalt esetekben:
            </p>

            <li>
              a szolgáltatás maradéktalan teljesítése után, azonban ha szerződés
              fizetési kötelezettséget keletkeztet a fogyasztó számára, jelen
              kivételi esetre csak akkor lehet hivatkozni, ha a teljesítés a
              fogyasztó kifejezett előzetes beleegyezésével és annak a fogyasztó
              általi tudomásulvételével kezdődött meg, hogy elveszíti elállási
              jogát, amint a vállalkozás maradéktalanul teljesítette a
              szerződést;
            </li>
            <li>
              olyan Áru vagy szolgáltatás tekintetében, amelynek ára, illetve
              díja a pénzpiac vállalkozás által nem befolyásolható, az elállási
              jog gyakorlására meghatározott határidő alatt is lehetséges
              ingadozásától függ;
            </li>
            <li>
              olyan nem előre gyártott Áru esetében, amelyet a fogyasztó
              utasítása alapján vagy kifejezett kérésére állítottak elő, vagy
              olyan Áru esetében, amelyet egyértelműen a fogyasztó személyére
              szabtak;
            </li>
            <li>
              romlandó vagy minőségét rövid ideig megőrző Áru tekintetében;
            </li>
            <li>
              olyan zárt csomagolású Áru tekintetében, amely egészségvédelmi
              vagy higiéniai okokból az átadást követő felbontása után nem
              küldhető vissza;
            </li>
            <li>
              olyan Áru tekintetében, amely jellegénél fogva az átadást követően
              elválaszthatatlanul vegyül más Áruval;
            </li>
            <li>
              olyan alkoholtartalmú ital tekintetében, amelynek tényleges értéke
              a vállalkozás által nem befolyásolható módon a piaci
              ingadozásoktól függ, és amelynek áráról a felek az adásvételi
              szerződés megkötésekor állapodtak meg, azonban a szerződés
              teljesítésére csak a megkötéstől számított harmincadik napot
              követően kerül sor;
            </li>
            <li>
              olyan vállalkozási szerződés esetében, amelynél a vállalkozás a
              fogyasztó kifejezett kérésére keresi fel a fogyasztót sürgős
              javítási vagy karbantartási munkálatok elvégzése céljából;
            </li>
            <li>
              lezárt csomagolású hang-, illetve képfelvétel, valamint
              számítógépes szoftver példányának adásvétele tekintetében, ha az
              átadást követően a fogyasztó a csomagolást felbontotta;
            </li>
            <li>
              hírlap, folyóirat és időszaki lap tekintetében, az előfizetéses
              szerződések kivételével;
            </li>
            <li>nyilvános árverésen megkötött szerződések esetében;</li>
            <li>
              lakáscélú szolgáltatás kivételével szállásnyújtásra irányuló
              szerződés, fuvarozás, személygépjármű-kölcsönzés, étkeztetés vagy
              szabadidős tevékenységekhez kapcsolódó szolgáltatásra irányuló
              szerződés esetében, ha a szerződésben meghatározott teljesítési
              határnapot vagy határidőt kötöttek ki;
            </li>
            <li>
              a nem tárgyi adathordozón nyújtott digitális tartalom
              tekintetében, ha az Eladó a fogyasztó kifejezett, előzetes
              beleegyezésével kezdte meg a teljesítést, és a fogyasztó e
              beleegyezésével egyidejűleg nyilatkozott annak tudomásul
              vételéről, hogy a teljesítés megkezdését követően elveszíti az
              elállási jogát, továbbá a vállalkozás visszaigazolást küldött a
              fogyasztó részére.
            </li>
            <h2>A szerződésszerű teljesítés követelményei</h2>
            <p>
              A szerződésszerű teljesítés követelményei általánosan fogyasztói
              szerződés keretében eladott áruk és digitális elemet tartalmaz
              áruk esetében
            </p>
            <p>
              Az Árunak és a teljesítésnek a teljesítés időpontjában meg kell
              felelnie a 373/2021. (VI.30.) Korm. rendeletben foglalt
              követelményeknek.{' '}
            </p>

            <p>
              Ahhoz, hogy a teljesítés szerződésszerűnek minősüljön a szerződés
              tárgyát képező Árunak
            </p>

            <li>
              meg kell felelnie a szerződésben foglalt leírásnak, mennyiségnek,
              minőségnek, típusnak, valamint rendelkeznie kell a szerződésben
              meghatározott funkcionalitással, kompatibilitással,
              interoperabilitással és egyéb, a szerződés szerinti jellemzőkkel
            </li>
            <li>
              alkalmasnak kell lennie a fogyasztó által meghatározott bármely
              célra, amelyet a fogyasztó legkésőbb a szerződés megkötésekor az
              Eladó tudomására hozott, és amelyet az Eladó elfogadott
            </li>
            <li>
              rendelkeznie kell a szerződésben meghatározott valamennyi
              tartozékkal, használati útmutatóval - ideértve az üzembe
              helyezésre vonatkozó utasítást, a telepítési utasítást, valamint
              az ügyfélszolgálati támogatást - és
            </li>
            <li>
              biztosítania kell a szerződésben meghatározott frissítéseket.
            </li>
            <p>
              Ahhoz, hogy a teljesítés szerződésszerűnek minősüljön - továbbá- a
              szerződés tárgyát képező Árunak
            </p>

            <li>
              alkalmasnak kell lennie azokra a célokra, amelyeket azonos típusú
              Áru esetén jogszabály, műszaki szabvány vagy műszaki szabvány
              hiányában az irányadó magatartási kódex előír
            </li>
            <li>
              rendelkeznie kell a Fogyasztó által észszerűen elvárható azon
              mennyiséggel, minőséggel, teljesítmény- és egyéb jellemzőkkel -
              különösen a funkcionalitás, a kompatibilitás, a hozzáférhetőség, a
              folyamatosság és a biztonságosság tekintetében -, amely azonos
              típusú Áru esetén szokásos, figyelembe véve az Eladó, annak
              képviselője vagy az értékesítési láncban részt vevő más személy az
              Áru konkrét tulajdonságaira vonatkozó nyilvános - különösen
              hirdetésben vagy címkén tett - kijelentését
            </li>
            <li>
              rendelkeznie kell a fogyasztó által észszerűen elvárható
              tartozékokkal és útmutatókkal - ideértve a csomagolást, illetve az
              üzembe helyezésre vonatkozó utasítást - és
            </li>
            <li>
              meg kell felelnie a vállalkozás által a szerződéskötést megelőzően
              mintaként, modellként bemutatott vagy próbaverzióként elérhetővé
              tett Áru tulajdonságainak, leírásának.
            </li>
            <p>
              Az Árunak nem kell megfelelnie a fenti nyilvános kijelentésnek, ha
              az Eladó bizonyítja, hogy
            </p>

            <li>
              a nyilvános kijelentést nem ismerte, és azt nem is kellett
              ismernie
            </li>
            <li>
              a nyilvános kijelentést a szerződéskötés időpontjáig már megfelelő
              módon helyesbítették vagy
            </li>
            <li>
              a nyilvános kijelentés a jogosult szerződéskötési elhatározását
              nem befolyásolhatta.
            </li>
            <h5>
              A szerződésszerű teljesítés követelményei fogyasztói szerződés
              keretében eladott áru adásvétele esetében
            </h5>
            <p>
              Az Eladó hibásan teljesít, ha az áru hibája a szakszerűtlen üzembe
              helyezéséből fakad, feltéve, hogy{' '}
            </p>

            <li>
              a) az üzembe helyezés az adásvételi szerződés részét képezi, és
              azt az Eladó végezte el, vagy a az Eladó felelősségvállalása
              mellett végezték el; vagy
            </li>

            <li>
              b) az üzembe helyezést a fogyasztónak kellett elvégeznie, és a
              szakszerűtlen üzembe helyezés az Eladó által - illetve digitális
              elemeket tartalmazó áruk esetében a digitális tartalom vagy
              digitális szolgáltatás szolgáltatója által - rendelkezésre
              bocsátott üzembe helyezési utasítások hiányosságainak
              következménye.
            </li>

            <p>
              Ha az adásvételi szerződés szerint az árut az Eladó helyezi
              üzembe, vagy az üzembe helyezés a az Eladó felelősségvállalása
              mellett történik, a teljesítést akkor kell az Eladó által
              befejezettnek tekinteni, amikor az üzembe helyezés befejeződött.
            </p>

            <p>
              Ha a digitális elemeket tartalmazó áru esetében az adásvételi
              szerződés a digitális tartalom vagy digitális szolgáltatás
              meghatározott időtartamon keresztül történő folyamatos
              szolgáltatásáról rendelkezik, az Eladó felel az áru digitális
              tartalommal kapcsolatos hibájáért, amennyiben a hiba két évet meg
              nem haladó időtartamú folyamatos szolgáltatás esetén az áru
              teljesítésétől számított két éven belül; vagy következik be vagy
              válik felismerhetővé.
            </p>

            <h5>
              A szerződésszerű teljesítés követelményei fogyasztói szerződés
              keretében eladott digitális elemeket tartalmazó árú esetében
            </h5>
            <p>
              Digitális elemeket tartalmazó áru esetében az Eladónak
              biztosítania kell, hogy a fogyasztó értesítést kapjon az áru
              digitális tartalmának vagy az azzal kapcsolatos digitális
              szolgáltatás olyan frissítéseiről - ideértve a biztonsági
              frissítéseket is -, amelyek az áru szerződésszerűségének
              fenntartásához szükségesek, valamint biztosítania kell, hogy a
              fogyasztó meg is kapja azokat.{' '}
            </p>

            <p>
              Az Eladónak a frissítés elérhetővé tételét, ha az adásvételi
              szerződés
            </p>

            <li>
              a digitális tartalom vagy a digitális szolgáltatás egyszeri
              szolgáltatásáról rendelkezik, akkor az áru és a digitális elemek
              típusa és célja, valamint az egyedi körülmények és a szerződés
              jellege alapján a fogyasztó által észszerűen elvárható; vagy
            </li>
            <li>
              a digitális tartalom meghatározott időszakon keresztül történő
              folyamatos szolgáltatásról rendelkezik, akkor két évet meg nem
              haladó időtartamú folyamatos szolgáltatás esetén az áru
              teljesítésétől számított két éves időszakon keresztül kell
              biztosítania.
            </li>
            <p>
              Ha a fogyasztó nem telepíti észszerű határidőn belül a
              rendelkezésre bocsátott frissítéseket, az Eladó nem felel az áru
              hibájáért, ha az kizárólag a vonatkozó frissítés alkalmazásának
              hiányából fakad, feltéve, hogy
            </p>

            <li>
              a) az Eladó tájékoztatta a fogyasztót a frissítés elérhetőségéről
              és a telepítés fogyasztó általi elmulasztásának következményeiről;
              és
            </li>

            <li>
              b) a frissítés fogyasztó általi telepítésének elmulasztása vagy a
              frissítés fogyasztó általi helytelen telepítése nem az Eladó által
              biztosított telepítési útmutató hiányosságának tudható be.
            </li>

            <p>
              Nem állapítható meg hibás teljesítés, ha a szerződés megkötésekor
              a fogyasztó külön tájékoztatást kapott arról, hogy az áru valamely
              konkrét tulajdonsága eltér az itt leírtaktól, és az adásvételi
              szerződés megkötésekor a fogyasztó ezt az eltérést külön,
              kifejezetten elfogadta.
            </p>

            <h2>
              Az áruk megfelelőségének szavatolására vonatkozó
              termékszavatosságra, kellékszavatosságra vonatkozó tájékoztatás
            </h2>
            <p>
              A fogyasztói tájékoztató jelen pontja a 45/2014 (II.26.) Korm.
              rendelet 9. § (3) bekezdése felhatalmazása alapján a 45/2014
              (II.26.) Korm. rendelet 3. számú melléklet figyelembevételével
              készült.
            </p>

            <h2>Kellékszavatosság</h2>
            <h5>Milyen esetben élhet Ön a kellékszavatossági jogával?</h5>
            <p>
              Ön az Eladó hibás teljesítése esetén az Eladóval szemben
              kellékszavatossági igényt érvényesíthet a Polgári Törvénykönyv,
              valamint fogyasztói szerződés esetén a 373/2021 (VI.30.) Korm.
              rendelet szabályai szerint.
            </p>

            <h5>
              Milyen jogok illetik meg Önt kellékszavatossági igénye alapján?
            </h5>
            <h5>Kellékszavatossági jogok általános szabályai</h5>
            <p>
              Ön – választása szerint–az alábbi kellékszavatossági igényekkel
              élhet:
            </p>

            <p>
              Kérhet kijavítást vagy kicserélést, kivéve, ha az ezek közül az Ön
              által választott igény teljesítése lehetetlen vagy az Eladó
              számára más igénye teljesítéséhez képest aránytalan
              többletköltséggel járna. Ha a kijavítást vagy a kicserélést nem
              kérte, illetve nem kérhette, úgy igényelheti az ellenszolgáltatás
              arányos leszállítását vagy a hibát az Eladó költségére Ön is
              kijavíthatja, illetve mással kijavíttathatja vagy – végső esetben
              – a szerződéstől is elállhat.
            </p>

            <p>
              Választott kellékszavatossági jogáról egy másikra is áttérhet, az
              áttérés költségét azonban Ön viseli, kivéve, ha az indokolt volt,
              vagy arra az Eladó adott okot.
            </p>

            <p>
              Fogyasztói szerződés esetében ellenkező bizonyításig vélelmezni
              kell, hogy az áru és a digitális elemeket tartalmazó áru
              teljesítésének időpontjától számított egy éven belül felismert
              hiba már az áru teljesítésének időpontjában fennállt, kivéve, ha e
              vélelem az áru természetével vagy a hiba jellegével
              összeegyeztethetetlen.
            </p>

            <p>
              Használt Áruk esetén alapesetben az általános szabályoktól
              eltérően alakulnak a szavatossági, jótállási jogok. A használt
              Áruk esetében is beszélhetünk hibás teljesítésről, azonban
              figyelembe kell venni azokat a körülményeket, amelyek alapján a
              Vásárló bizonyos hibák előfordulására számíthatott. Az avulás
              folytán ugyanis egyes hibák jelentkezései egyre gyakoribbakká
              válnak, amelyek következtében azt, hogy egy használt Áru
              ugyanolyan minőséggel rendelkezhet, mint egy újonnan vásárolt, nem
              lehet feltételezni. Ennek alapján a Vásárló csak az olyan
              hiányosságok tekintetében érvényesítheti a szavatossági jogait,
              amelyek a használtságból eredő hibákon felüliek, és azoktól
              függetlenül keletkeztek. Ha a használt Áru hibás és erről a
              Fogyasztónak minősülő Vásárló a vásárláskor tájékoztatást kapott,
              az ismert hiba vonatkozásában a Szolgáltatónak nincs felelőssége.
            </p>

            <p>
              Fogyasztónak nem minősülő vásárlók esetében a kellékszavatossági
              jog érvényesítési határideje 1 év, amely a teljesítés (átadás)
              napján indul.
            </p>

            <h5>
              Kellékszavatossági jogok speciális szabályai fogyasztói szerződés
              keretében eladott az áruk és digitális elemet tartalmazó áru
              esetében
            </h5>
            <p>
              Fogyasztó és vállalkozás közötti - ingó dolognak minősülő áru
              adásvételére, digitális tartalom szolgáltatására irányuló -
              szerződés esetén a „Kellékszavatossági jogok általános szabályai”
              pontban írtak a Fogyasztót a jelen pont szerinti eltérésekkel
              illetik meg.
            </p>

            <p>
              Fogyasztó és vállalkozás közötti - ingó dolognak minősülő áru
              adásvételére, digitális tartalom szolgáltatására irányuló -
              szerződés esetén a Fogyasztó kellékszavatossági jogai gyakorlása
              keretében a hibát az Eladó költségére maga nem javíthatja ki,
              illetve mással sem javíttathatja ki azt.
            </p>

            <p>
              Az Eladó megtagadhatja az áru szerződésszerűvé tételét, ha a
              kijavítás, illetve a kicserélés lehetetlen, vagy ha az aránytalan
              többletköltséget eredményezne az Eladónak, figyelembe véve
              valamennyi körülményt, ideértve az Áru hibátlan állapotban
              képviselt értékét, valamint a szerződésszegés súlyát.
            </p>

            <p>
              A fogyasztó akkor is jogosult - a szerződésszegés súlyához
              igazodva - az ellenszolgáltatás arányos leszállítását igényelni,
              vagy az adásvételi szerződést megszüntetni, ha
            </p>

            <li>
              az Eladó nem végezte el a kijavítást vagy kicserélést, vagy
              elvégezte azt, de részben vagy egészben nem teljesítette az alábbi
              feltételeket
              <li>
                az Eladónak a saját költségére kell biztosítania a kicserélt áru
                visszavételét
              </li>
              <li>
                ha a kijavítás vagy kicserélés olyan áru eltávolítását teszi
                szükségessé, amelyet az áru jellegének és céljának megfelelően -
                a hiba felismerhetővé válása előtt - üzembe helyeztek, akkor a
                kijavításra vagy kicserélésre vonatkozó kötelezettség magában
                foglalja a nem megfelelő áru eltávolítását és a csereként
                szállított vagy kijavított áru üzembe helyezését vagy az
                eltávolítás, illetve üzembe helyezés költségeinek viselését.
              </li>
            </li>
            <li>megtagadta az áru szerződésszerűvé tételét</li>
            <li>
              ismételt teljesítési hiba merült fel, annak ellenére, hogy az
              Eladó megkísérelte az áru szerződésszerűvé tételét
            </li>
            <li>
              a teljesítés hibája olyan súlyú, hogy azonnali árleszállítást vagy
              az adásvételi szerződés azonnali megszüntetését teszi indokolttá,
              vagy
            </li>
            <li>
              az Eladó nem vállalta az áru szerződésszerűvé tételét, vagy a
              körülményekből nyilvánvaló, hogy a vállalkozás észszerű határidőn
              belül vagy a fogyasztónak okozott jelentős érdeksérelem nélkül nem
              fogja az árut szerződésszerűvé tenni.
            </li>
            <p>
              Ha a fogyasztó hibás teljesítésre hivatkozva kívánja megszüntetni
              az adásvételi szerződést, az Eladót terheli annak bizonyítása,
              hogy a hiba jelentéktelen.
            </p>

            <p>
              A Fogyasztó jogosult a vételár még fennmaradó részét - a
              szerződésszegés súlyához igazodva - részben vagy egészben
              visszatartani mindaddig, amíg az Eladó nem tesz eleget a
              teljesítés szerződésszerűségével és a hibás teljesítéssel
              kapcsolatos kötelezettségeinek.
            </p>

            <p>Általánosan érvényesülő szabály, hogy:</p>

            <li>
              az Eladónak a saját költségére kell biztosítania a kicserélt áru
              visszavételét
            </li>
            <li>
              ha a kijavítás vagy kicserélés olyan áru eltávolítását teszi
              szükségessé, amelyet az áru jellegének és céljának megfelelően - a
              hiba felismerhetővé válása előtt - üzembe helyeztek, akkor a
              kijavításra vagy kicserélésre vonatkozó kötelezettség magában
              foglalja a nem megfelelő áru eltávolítását és a csereként
              szállított vagy kijavított áru üzembe helyezését vagy az
              eltávolítás, illetve üzembe helyezés költségeinek viselését.
            </li>
            <p>
              Az áru kijavításának vagy kicserélésének elvégzésére nyitva álló
              észszerű határidőt attól az időponttól kell számítani, amikor a
              Fogyasztó közölte a hibát a vállalkozással.
            </p>

            <p>
              A fogyasztónak az árut a kijavítás vagy kicserélés teljesítése
              érdekében a vállalkozás rendelkezésére kell bocsátania.
            </p>

            <p>
              Az ellenszolgáltatás leszállítása akkor arányos, ha annak összege
              megegyezik a Fogyasztónak szerződésszerű teljesítés esetén járó,
              valamint a Fogyasztó által ténylegesen megkapott áru értékének
              különbözetével.
            </p>

            <p>
              A Fogyasztó adásvételi szerződés megszüntetésére vonatkozó
              kellékszavatossági joga az Eladónak címzett, a megszüntetésre
              vonatkozó döntést kifejező jognyilatkozattal gyakorolható.
            </p>

            <p>
              Ha a hibás teljesítés az adásvételi szerződés alapján
              szolgáltatott árunak csak meghatározott részét érinti, és azok
              tekintetében a szerződés megszüntetésére vonatkozó jog
              gyakorlásának feltételei fennállnak, a Fogyasztó az adásvételi
              szerződést csak a hibás áru tekintetében szüntetheti meg, de az
              azokkal együtt szerzett bármely egyéb áru vonatkozásában is
              megszüntetheti, ha a Fogyasztótól nem várható el észszerűen, hogy
              csak a szerződésnek megfelelő árukat tartsa meg.
            </p>

            <p>
              Ha a Fogyasztó az adásvételi szerződést teljes egészében vagy az
              adásvételi szerződés alapján szolgáltatott áruk egy része
              tekintetében szünteti meg, úgy
            </p>

            <li>
              a Fogyasztónak az Eladó költségére vissza kell küldenie az
              Eladónak az érintett árut és
            </li>
            <li>
              az Eladónak haladéktalanul vissza kell térítenie a Fogyasztó
              részére az érintett áru vonatkozásában teljesített vételárat,
              amint az árut vagy az áru visszaküldését alátámasztó igazolást
              átvette.
            </li>
            <h5>
              Milyen határidőben érvényesítheti Ön kellékszavatossági igényét?
            </h5>
            <p>
              Ön köteles a hibát annak felfedezése után haladéktalanul
              bejelenteni. A hiba felfedezésétől számított kettő hónapon belül
              közölt hibát késedelem nélkül közölt hibának kell tekinteni.
              Ugyanakkor felhívjuk a figyelmét, hogy a szerződés teljesítésétől
              számított két éves elévülési határidőn túl kellékszavatossági
              jogait már nem érvényesítheti. Ha a fogyasztó és a vállalkozás
              közötti szerződés tárgya használt dolog, a felek rövidebb
              elévülési időben is megállapodhatnak; egy évnél rövidebb elévülési
              határidő ebben az esetben sem köthető ki érvényesen.
            </p>

            <h5>Kivel szemben érvényesítheti kellékszavatossági igényét?</h5>
            <p>
              Ön az Eladóval szemben érvényesítheti kellékszavatossági igényét.
            </p>

            <h5>
              Milyen egyéb feltétele van kellékszavatossági jogai
              érvényesítésének?
            </h5>
            <p>
              A teljesítéstől számított hat hónapon (áru adásvétele esetén egy
              éven) belül a kellékszavatossági igénye érvényesítésének a hiba
              közlésén túl nincs egyéb feltétele, ha Ön igazolja, hogy az Árut,
              illetve a szolgáltatást az Eladó nyújtotta. A teljesítéstől
              számított hat hónap (áru adásvétele esetén egy év) eltelte után
              azonban már Ön köteles bizonyítani, hogy az Ön által felismert
              hiba már a teljesítés időpontjában is megvolt.
            </p>

            <h2>Termékszavatosság</h2>
            <h5>Milyen esetben élhet Ön a termékszavatossági jogával?</h5>
            <p>
              Ingó dolog (Áru) hibája esetén Ön – választása szerint – a
              kellékszavatossági igényt vagy termékszavatossági igényt
              érvényesíthet.
            </p>

            <h5>
              Milyen jogok illetik meg Önt termékszavatossági igénye alapján?
            </h5>
            <p>
              Termékszavatossági igényként Ön kizárólag a hibás Áru kijavítását
              vagy kicserélését kérheti.
            </p>

            <h5>Milyen esetben minősül az Áru hibásnak?</h5>
            <p>
              Az áru akkor hibás, ha az nem felel meg a forgalomba hozatalakor
              hatályos minőségi követelményeknek vagy pedig, ha nem rendelkezik
              a gyártó által adott leírásban szereplő tulajdonságokkal.
            </p>

            <h5>
              Milyen határidőben érvényesítheti Ön termékszavatossági igényét?
            </h5>
            <p>
              Termékszavatossági igényét Ön az Áru gyártó általi forgalomba
              hozatalától számított két éven belül érvényesítheti. E határidő
              elteltével e jogosultságát elveszti.
            </p>

            <h5>
              Kivel szemben és milyen egyéb feltétellel érvényesítheti
              termékszavatossági igényét?
            </h5>
            <p>
              Termékszavatossági igényét kizárólag az ingó dolog gyártójával
              vagy forgalmazójával szemben gyakorolhatja. Az Áru hibáját
              termékszavatossági igény érvényesítése esetén Önnek kell
              bizonyítania.
            </p>

            <h5>
              A gyártó (forgalmazó) milyen esetben mentesül termékszavatossági
              kötelezettsége alól?
            </h5>
            <p>
              A gyártó (forgalmazó) kizárólag akkor mentesül termékszavatossági
              kötelezettsége alól, ha bizonyítani tudja, hogy:
            </p>

            <li>
              az Árut nem üzleti tevékenysége körében gyártotta, illetve hozta
              forgalomba, vagy
            </li>
            <li>
              a hiba a tudomány és a technika állása szerint a forgalomba
              hozatal időpontjában nem volt felismerhető vagy
            </li>
            <li>
              az Áru hibája jogszabály vagy kötelező hatósági előírás
              alkalmazásából ered.
            </li>
            <p>
              A gyártónak (forgalmazónak) a mentesüléshez elegendő egy okot
              bizonyítania.
            </p>

            <p>
              Felhívom figyelmét, hogy ugyanazon hiba miatt kellékszavatossági
              és termékszavatossági igényt egyszerre, egymással párhuzamosan nem
              érvényesíthet. Termékszavatossági igényének eredményes
              érvényesítése esetén azonban a kicserélt Árura, illetve kijavított
              részre vonatkozó kellékszavatossági igényét a gyártóval szemben
              érvényesítheti.
            </p>

            <h2>Jótállás</h2>
            <h5>Milyen esetben élhet Ön a jótállási jogával?</h5>
            <p>
              Az egyes tartós fogyasztási cikkekre vonatkozó kötelező
              jótállásról szóló 151/2003. (IX. 22.) Korm. rendelet alapján az
              Eladó jótállásra köteles a rendelet 1. számú mellékletében
              felsorolt új tartós fogyasztási cikkek (pl.: műszaki cikkek,
              szerszámok, gépek), valamint az ott meghatározott körben azok
              tartozékai és alkotórészei (a továbbiakban - jelen pontban -
              együtt fogyasztási cikként hivatkozva) eladása esetén.
            </p>

            <p>
              Emellett az Eladó önként is jótállást vállalhat, amely esetben a
              Fogyasztónak minősülő vásárló számára jótállási nyilatkozatot kell
              átadnia.
            </p>

            <p>
              A jótállási nyilatkozatot tartós adathordozón kell a Fogyasztó
              rendelkezésére bocsátani, legkésőbb az áru teljesítésének
              időpontjában.
            </p>

            <p>A jótállási nyilatkozatban fel kell tüntetni:</p>

            <li>
              az arra vonatkozó egyértelmű nyilatkozatot, hogy az áru hibás
              teljesítése esetén a Fogyasztót a jogszabály szerinti
              kellékszavatossági jogok gyakorlása térítésmentesen megilleti, e
              jogait a jótállás nem érinti
            </li>
            <li>a jótállásra kötelezett nevét és címét</li>
            <li>
              a Fogyasztó által a jótállás érvényesítése érdekében követendő
              eljárást
            </li>
            <li>azon áru megjelölését, amelyre a jótállás vonatkozik és</li>
            <li>a jótállás feltételeit.</li>
            <h5>
              Önt milyen jogok és milyen határidőn belül illetik meg kötelező
              jótállás esetén?
            </h5>
            <h5>Jótállási jogok</h5>
            <p>
              A Vásárló jótállási joga alapján főszabályként kijavítási vagy
              kicserélési igénnyel élhet, illetve a hibát a kötelezett
              költségére maga kijavíthatja vagy kijavíttathatja, árleszállítást
              kérhet, vagy végső soron elállhat a szerződéstől, ha a kötelezett
              a kijavítást vagy a kicserélést nem vállalta, e kötelezettségének
              megfelelő határidőn belül, a jogosult érdekeit kímélve nem tud
              eleget tenni, vagy ha a jogosultnak a kijavításhoz vagy
              kicseréléshez fűződő érdeke megszűnt.
            </p>

            <p>
              A Vásárló a kijavítás iránti igényét választása szerint az Eladó
              székhelyén, bármely telephelyén, fióktelepén és az Eladó által a
              jótállási jegyen feltüntetett javítószolgálatnál közvetlenül is
              érvényesítheti.
            </p>

            <h5>Érvényesítési határidő</h5>
            <p>
              A jótállási igény a jótállás időtartama alatt érvényesíthető, a
              jótállás időtartama a 151/2003. (IX. 22.) Korm. rendelet szerint:
            </p>

            <li>
              10 000 forintot elérő, de 100 000 forintot meg nem haladó eladási
              ár esetén egy év,
            </li>
            <li>
              100 000 forintot meghaladó, de 250 000 forintot meg nem haladó
              eladási ár esetén két év,
            </li>
            <li>250 000 forint eladási ár felett három év.</li>
            <p>
              E határidők elmulasztása jogvesztéssel jár, azonban a fogyasztási
              cikk kijavítása esetén a jótállás időtartama meghosszabbodik a
              javításra átadás napjától kezdve azzal az idővel, amely alatt a
              Vásárló a fogyasztási cikket a hiba miatt rendeltetésszerűen nem
              használhatta.
            </p>

            <p>
              A jótállási határidő a fogyasztási cikk a Vásárló részére történő
              átadásakor indul, vagy ha az üzembe helyezést az Eladó, vagy annak
              megbízottja végzi, az üzembe helyezés napjával kezdődik.
            </p>

            <p>
              Ha a Vásárló a fogyasztási cikket az átadástól számított hat
              hónapon túl helyezteti üzembe, akkor a jótállási határidő kezdő
              időpontja a fogyasztási cikk átadásának napja.
            </p>

            <h5>Jótállási igény kezelésével kapcsolatos szabályok</h5>
            <p>
              A kijavítás kezelésekor az Eladónak törekedni kell arra, hogy a
              kijavítást 15 napon belül elvégezze. A kijavításra nyitva álló
              határidő a fogyasztási cikk átvételekor indul.
            </p>

            <p>
              Ha a kijavítás vagy a kicserélés időtartama a tizenöt napot
              meghaladja, akkor az Eladó a Vásárlót tájékoztatni köteles a
              kijavítás vagy a csere várható időtartamáról.
            </p>

            <p>
              Ha a jótállási időtartam alatt a fogyasztási cikk első alkalommal
              történő javítása során az Eladó részéről megállapítást nyer, hogy
              a fogyasztási cikk nem javítható, a vásárló eltérő rendelkezése
              hiányában az Eladó köteles a fogyasztási cikket nyolc napon belül
              kicserélni. Ha a fogyasztási cikk cseréjére nincs lehetőség, az
              Eladó köteles a fogyasztó által bemutatott, a fogyasztási cikk
              ellenértékének megfizetését igazoló bizonylaton - az általános
              forgalmi adóról szóló törvény alapján kibocsátott számlán vagy
              nyugtán - feltüntetett vételárat nyolc napon belül a vásárló
              részére visszatéríteni.
            </p>

            <p>
              A Vásárló az ÁSZF elfogadásával hozzájárul, hogy számára a
              tájékoztatást elektronikus úton vagy a Vásárló általi átvétel
              igazolására alkalmas más módon is megvalósulhasson.
            </p>

            <h5>
              Amennyiben az Eladó nem tudja a fogyasztási cikket 30 napon belül
              kijavítani:
            </h5>

            <li>
              ha a Vásárló ehhez hozzájárult, számára a kijavítás teljesíthető
              későbbi határidőben, vagy
            </li>
            <li>
              amennyiben a Vásárló nem járul hozzá a kijavítás későbbi
              teljesítéséhez, vagy ezzel kapcsolatban nem nyilatkozott, számára
              a fogyasztási cikket a harmincnapos határidő eredménytelen
              elteltét követő nyolc napon belül ki kell cserélni, vagy
            </li>
            <li>
              amennyiben a Vásárló nem járul hozzá a kijavítás későbbi
              teljesítéséhez, vagy ezzel kapcsolatban nem nyilatkozott, de a
              fogyasztási cikk cseréjére sincs lehetőség, a fogyasztási cikk
              számláján, vagy nyugtáján szereplő eladási árat kell számára a
              harmincnapos határidő eredménytelen elteltét követő nyolc napon
              belül visszatéríteni.
            </li>
            <h5>
              Amennyiben a fogyasztási cikk 4. alkalommal hibásodik meg a
              Vásárló jogosult:
            </h5>

            <li>az Eladó felé kijavítási igényel fordulni, vagy</li>
            <li>
              a kijavítási igény helyett a Polgári Törvénykönyvről szóló 2013.
              évi V. törvény 6:159. § (2) bekezdés b) pontja alapján a vételár
              arányos leszállítását kérni az Eladótól, vagy
            </li>
            <li>
              a kijavítási igény helyett a Polgári Törvénykönyvről szóló 2013.
              évi V. törvény 6:159. § (2) bekezdés b) pontja alapján a
              fogyasztási cikket az Eladó költségére kijavítani vagy mással
              kijavíttatani, vagy
            </li>
            <li>
              amennyiben a Vásárló ezen jogaival (kijavítás, árleszállítás és
              mással kijavíttatás az Eladó költségére) nem él, vagy ezekkel
              kapcsolatban nem nyilatkozott, számára 8. napon belül a
              fogyasztási cikket ki kell cserélni, ha a fogyasztási cikk
              cseréjére nincs lehetőség, a fogyasztási cikk számláján, vagy
              nyugtáján szereplő eladási árat kell nyolc napon belül
              visszatéríteni számára.
            </li>
            <h5>Kivételek</h5>
            <p>
              A „Jótállási igény kezelésével kapcsolatos szabályok” pont alatt
              írt előírások az elektromos kerékpárra, elektromos rollerre,
              quadra, motorkerékpárra, segédmotoros kerékpárra,
              személygépkocsira, lakóautóra, lakókocsira, utánfutós lakókocsira,
              utánfutóra, valamint a motoros vízi járműre nem vonatkoznak.
            </p>

            <p>
              Ezen Áruk esetében is köteles törekedni azonban az Eladó arra,
              hogy a kijavítási igényt 15 napon belül teljesítse.
            </p>

            <p>
              Ha a kijavítás vagy a kicserélés időtartama a tizenöt napot
              meghaladja, akkor az Eladó a Vásárlót tájékoztatni köteles a
              kijavítás vagy a csere várható időtartamáról.
            </p>

            <h5>Mi a viszonya a jótállásnak más szavatossági jogokkal?</h5>
            <p>
              A jótállás a szavatossági jogok (termék és kellékszavatosság)
              mellett érvényesül, alapvető különbség az általános szavatossági
              jogok és a jótállás között, hogy a jótállás esetén a fogyasztónak
              kedvezőbb a bizonyítási teher.{' '}
            </p>

            <p>
              A 151/2003 Korm. Rendelet szerinti kötelező jótállás alá eső
              rögzített bekötésű, illetve a 10 kg-nál súlyosabb, vagy
              tömegközlekedési eszközön kézi csomagként nem szállítható
              fogyasztási cikket - a járművek kivételével - az üzemeltetés
              helyén kell megjavítani. Ha a kijavítás az üzemeltetés helyén nem
              végezhető el, a le- és felszerelésről, valamint az el- és
              visszaszállításról a vállalkozás, vagy - a javítószolgálatnál
              közvetlenül érvényesített kijavítás iránti igény esetén - a
              javítószolgálat gondoskodik.
            </p>

            <p>
              Az Eladó vállalása a kötelező jótállás időtartama alatt nem
              tartalmazhat a fogyasztóra nézve olyan feltételeket, amelyek
              hátrányosabbak azoknál a jogoknál, amelyeket a kötelező jótállás
              szabályai biztosítanak. Ezt követően azonban az önkéntes jótállás
              feltételei szabadon állapíthatóak meg, azonban a jótállás ebben az
              esetben sem érintheti a fogyasztó jogszabályból eredő -így köztük
              a kellékszavatosságon alapuló jogainak fennállását.
            </p>

            <h5>Három munkanapon belüli csereigény</h5>
            <p>
              Webáruházon keresztüli értékesítés esetén is érvényesül a három
              munkanapon belüli csereigény intézménye. Három munkanapon belüli
              csereigényt a 151/2003. (IX. 22.) Korm. rendelet alá tartozó új
              tartós fogyasztási cikkek esetében lehet érvényesíteni, amely
              szerint, ha a 3 munkanapon belül érvényesíti valaki a csereigény
              intézményét, akkor az eladónak ezt úgy kell értelmeznie, hogy az
              Áru az eladáskor már hibás volt és minden további nélkül az Árut
              ki kell cserélnie.
            </p>

            <h5>Mikor mentesül az Eladó a jótállási kötelezettsége alól?</h5>
            <p>
              Az Eladó a jótállási kötelezettsége alól csak abban az esetben
              mentesül, ha bizonyítja, hogy a hiba oka a teljesítés után
              keletkezett.
            </p>

            <p>
              Felhívjuk a figyelmét, hogy ugyanazon hiba miatt
              kellékszavatossági és jótállási igényt, illetve termékszavatossági
              és jótállási igényt egyszerre, egymással párhuzamosan nem
              érvényesíthet, egyébként viszont Önt a jótállásból fakadó jogok a
              szavatossági jogosultságoktól függetlenül megilletik.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AszfScreen
