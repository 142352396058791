import React, { useState, useEffect, useRef } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Image,
  Carousel,
  ListGroup,
  Form,
  Button,
} from 'react-bootstrap'
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions'
import {
  addToCart,
} from '../actions/cartActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Rating from '../components/Rating'
import '../bootstrap.min_lux.css'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

const ProductScreen = ({ history, match }) => {
  const reviewRef = useRef(null);
  const [qty, setQty] = useState(1)
  const [name, setName] = useState(0)
  const [rating, setRating] = useState(0)
  const [title, setTitle] = useState(0)
  const [comment, setComment] = useState('')
  const [selectedFabricColor, setSelectedFabricColor] = useState('');
  const [selectedSisalColor, setSelectedSisalColor] = useState('');
  const [selectedPillowColor, setSelectedPillowColor] = useState('');

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productCreateReview = useSelector((state) => state.productCreateReview)
  const { success: successReview } = productCreateReview

  useEffect(() => {
    if (product.customize && product.customize.includes('fabric')) {
      setSelectedFabricColor('white');
    } else { setSelectedFabricColor(''); }
    if (product.customize && product.customize.includes('sisal')) {
      setSelectedSisalColor('natural');
    } else { setSelectedSisalColor(''); }
    if (product.customize && product.customize.includes('pillow')) {
      setSelectedPillowColor('yellow');
    } else { setSelectedPillowColor(''); }
  }, [product]);

  useEffect(() => {
    if (successReview) {
      alert('Köszönjük a véleményed!')
      setRating(0)
      setComment('')
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
    dispatch(listProductDetails(match.params.id))
  }, [dispatch, match, successReview])

  const addToCartHandler = () => {
    const customizeParams = `&fabric=${encodeURIComponent(selectedFabricColor)}&sisal=${encodeURIComponent(selectedSisalColor)}&pillow=${encodeURIComponent(selectedPillowColor)}`

    // dispatch(addToCart(match.params.id, qty, {
    //   fabric: selectedFabricColor,
    //   sisal: selectedSisalColor,
    //   pillow: selectedPillowColor,
    // }))

    history.push(`/cart/${match.params.id}?qty=${qty}${customizeParams}`)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const currentDate = new Date();
    // Format the date as "yyyy.mm.dd"
    const formattedDate = `${currentDate.getFullYear()}.${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getDate().toString().padStart(2, '0')}`;

    dispatch(createProductReview(match.params.id, { name, rating, title, comment, date: formattedDate }))
  }

  const handleDetailsClick = () => {
    // Scroll to the review element smoothly
    reviewRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const canonicalUrl = `/product/${product.id}`;

  return (
    <>
      <Helmet>
        <title>Cathletic Macskabútor - Prémium Termékeink</title>
        <meta name="description"
          content="Cathletic Macskabútor és kaparófa. Egyedi bútoraink, személyre szabva." />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className='page-container footer-padding'>
        <Container>
          <LinkContainer to='/webshop'>
            <Button className='mb-3' variant='outline-primary' size='sm'>
              Vissza a termékekhez
            </Button>
          </LinkContainer>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <>
              <Row className=' mb-3'>
                <Col md={7}>
                  {product.images && product.images.length > 0 ? (
                    <Carousel className='carousel-dark'>
                      {product.images.map((image, index) => (
                        <Carousel.Item key={index}>
                          <Image src={image} alt={`Cathletic Macskabútor Kaparófa`} fluid />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <div>No images available.</div>
                  )}
                </Col><Col md={5}>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      {product.category === 'complex' ? (
                        <h1 className='black-chango'>{product.name} Macskabútor</h1>
                      ) : (
                        <h1 className='black-chango'>{product.name}</h1>
                      )}
                      <p className='cursor-pointer' onClick={handleDetailsClick}>
                        {product.reviews && product.reviews.length > 0 && (
                          <Rating
                            value={product.reviews.reduce((acc, item) => item.rating + acc, 0) /
                              product.reviews.length}
                            text={` (${product.reviews.length}) értékelés`}
                          />
                        )}</p>
                    </ListGroup.Item>
                    {product.customize && (product.customize.includes('fabric') || product.customize.includes('pillow') || product.customize.includes('sisal')) && (
                      <ListGroup.Item>
                        <h5>Válassz egyedi színeket</h5>
                        {product.customize.includes('fabric') && (
                          < Row className='my-3 d-flex align-items-center'>
                            <Col md={3}>
                              <p className='my-0'>Vászon</p>
                            </Col>
                            <Col md={9}>
                              <Image
                                src='/images/fabric_white.jpg'
                                className={selectedFabricColor === 'white' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedFabricColor('white')}
                              /> &nbsp;
                              <Image
                                src='/images/fabric_black.jpg'
                                className={selectedFabricColor === 'black' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedFabricColor('black')}
                              /> &nbsp;
                            </Col>
                          </Row>
                        )}
                        {product.customize.includes('sisal') && (
                          <Row className='my-3 d-flex align-items-center'>
                            <Col md={3}>
                              <p className='my-0'>Kaparó</p>
                            </Col>
                            <Col md={9}>
                              <Image
                                src='/images/sisal_natural.jpg'
                                className={selectedSisalColor === 'natural' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedSisalColor('natural')}
                              /> &nbsp;
                              <Image
                                src='/images/sisal_black.jpg'
                                className={selectedSisalColor === 'black' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedSisalColor('black')}
                              /> &nbsp;
                            </Col>
                          </Row>
                        )}
                        {product.customize.includes('pillow') && (
                          <Row className='my-3 d-flex align-items-center'>
                            <Col md={3}>
                              <p className='my-0'>Párna</p>
                            </Col>
                            <Col md={9}>
                              <Image
                                src='/images/pillow_yellow.jpg'
                                className={selectedPillowColor === 'yellow' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedPillowColor('yellow')}
                              /> &nbsp;
                              <Image
                                src='/images/pillow_green.jpg'
                                className={selectedPillowColor === 'green' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedPillowColor('green')}
                              /> &nbsp;
                              <Image
                                src='/images/pillow_blue.jpg'
                                className={selectedPillowColor === 'blue' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedPillowColor('blue')}
                              /> &nbsp;
                              <Image
                                src='/images/pillow_black.jpg'
                                className={selectedPillowColor === 'black' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedPillowColor('black')}
                              /> &nbsp;
                              <Image
                                src='/images/pillow_white.jpg'
                                className={selectedPillowColor === 'white' ? 'checkedSwatch swatch' : 'swatch'}
                                onClick={() => setSelectedPillowColor('white')}
                              /> &nbsp;
                            </Col>
                          </Row>
                        )}

                      </ListGroup.Item>
                    )}
                    {/* {product.price > product.priceDisc ? (
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <p className='zerom '>Normál ár:</p>
                          </Col>
                          <Col>
                            <p className='zerom strike-through'>
                              {Intl.NumberFormat('hu-HU', {
                                style: 'currency',
                                currency: 'HUF',
                                maximumFractionDigits: 0,
                              }).format(product.price)}{' '}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className='big zerom text-red'>Most:</p>
                          </Col>
                          <Col>
                            <p className='big zerom text-red'>
                              {Intl.NumberFormat('hu-HU', {
                                style: 'currency',
                                currency: 'HUF',
                                maximumFractionDigits: 0,
                              }).format(product.priceDisc)}{' '}
                            </p>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ) : (
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <p className='big zerom'>Ár:</p>
                          </Col>
                          <Col>
                            <p className='big zerom'>
                              {Intl.NumberFormat('hu-HU', {
                                style: 'currency',
                                currency: 'HUF',
                                maximumFractionDigits: 0,
                              }).format(product.price)}{' '}
                            </p>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )} */}
                    {/* {product.countInStock > 0 && (
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <Form.Control
                              as='select'
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            >
                              {[...Array(product.countInStock).keys()].map(
                                (x) => (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                )
                              )}
                            </Form.Control>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )} */}
                    <ListGroup.Item>
                      <LinkContainer to='/contact'>
                        <Button
                          onClick={addToCartHandler}
                          className='btn-block rounded'
                          type='button'
                          disabled={product.countInStock === 0}
                        >
                          <span style={{ color: '#ffffff' }}>
                            <i className='fas fa-shopping-cart pr-2'></i>
                          </span>
                          Kapcsolatfelvétel
                        </Button>
                      </LinkContainer>
                    </ListGroup.Item>

                    {/* <ListGroup.Item>
                        <Button className='lg btn-success rounded-pill'></Button>
                    </ListGroup.Item> */}
                  </ListGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>

                  <h2 className='black-chango' ref={reviewRef}>Leírás</h2>
                  <p>{product.description}</p>
                  {product.category === 'part' ? (
                    <div>
                      <h5>Részletes elem lista</h5>
                      <p className='py-2'>{product.items}</p>{' '}
                    </div>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <h5>Méretek</h5>
                    <Col>
                      <p className='zerom'>Szélesség:</p>
                      <p className='zerom'>Magasság: </p>
                      <p className='zerom'>Mélység: </p>
                    </Col>
                    <Col>
                      <p className='zerom'> {product.x} cm</p>
                      <p className='zerom'> {product.y} cm</p>
                      <p className='zerom'> {product.z} cm</p>
                    </Col>
                    <p>Akár 1-2 méterrel is szélesebb lehet a mászófal, ha az elemeket széhúzzuk, ez a legkisebb szükséges falfelület.</p>
                  </Row>
                  <p className='my-2'>
                    <span style={{ color: '#000' }}>
                      <i className='fas fa-truck pr-2'></i>
                    </span>
                    30 000 Ft felett ingyenes a kiszállítás.
                  </p>
                  <p>
                    Kiszállítási idő általában 4-8 hét, de ha korábban
                    megtörténne a feladás, akkor a megadott email címen
                    értesítünk.
                  </p>

                  <hr />
                  <h2 className='black-chango'>Összeszerelés</h2>
                  <p className=''>
                    Minden mászófalhoz küldünk fali csavarokat, hozzájuk
                    tartozó bitfejet és imbuszkulcsot, egy 10 cm-es
                    hosszabbítót a csavarbehajtóhoz a könnyebb szerelés érdekében.
                  </p>
                  <p className=''>
                    A mászófalakhoz adunk Fischer DuoPower 8x65-ös
                    tipliket is, melyek a piacon kapható legmagasabb
                    minőségű tiplik. Ez a típus kiemelkedő terhelési
                    mutatókkal bír tömör téglában, porotherm téglában,
                    Ytongban és betonban egyaránt.
                  </p>

                  <Link as={Link} to='/install'>
                    <Button className='rounded-pill' variant='primary'>
                      SZERELÉSI ÚTMUTATÓK
                    </Button>
                  </Link>

                  {product.reviews && product.reviews.length > 0 && (
                    <>
                      <hr />
                      <h2 className='black-chango' ref={reviewRef}>Vélemények</h2>

                      <ListGroup variant='flush'>
                        {product.reviews.map((review) => (

                          <ListGroup.Item>
                            <strong>{review.name}</strong>
                            <Rating value={review.rating} />
                            <b><strong>{review.title}</strong></b>
                            <p>{review.comment}</p>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </>
                  )}
                  <hr />
                  <h2 className='black-chango' ref={reviewRef}>Vélemény írása</h2>
                  <Form onSubmit={submitHandler}>
                    <Form.Row>
                      <Form.Group as={Col} xs={12} sm={6} md={4} controlId="name">
                        <Form.Label>Név</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} xs={12} sm={6} md={4} controlId="rating">
                        <Form.Label>Értékelés</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          onChange={(e) => setRating(Number(e.target.value))}
                          required
                        >
                          <option value="5">5 - Kiváló</option>
                          <option value="4">4 - Nagyon jó</option>
                          <option value="3">3 - Jó</option>
                          <option value="2">2 - Elfogadható</option>
                          <option value="1">1 - Rossz</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} xs={12} md={4} controlId="title">
                        <Form.Label>Cím</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="comment">
                      <Form.Label>Leírás</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        size="sm"
                        onChange={(e) => setComment(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit" size="sm" block>
                      Küldés
                    </Button>
                  </Form>
                </Col>
              </Row>
            </>
          )
          }
        </Container >
      </div >
    </>
  )
}

export default ProductScreen
