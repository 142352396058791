import React from 'react'
import { Layer, Circle } from 'react-konva'
import { useSelector } from 'react-redux'
import '../../../WallDesigner.css'
import WallItemFloating from './WallItemFloating'

function Cursor() {
  const wall = useSelector((state) => state.wall)

  return (
    <Layer>
      {wall.floatingWallItem !== {} && wall.floatingWallItem.isAvailable && (
        <WallItemFloating />
      )}
      {
        <Circle
          radius={10}
          x={wall.canvas.cursor.x}
          y={wall.canvas.cursor.y}
          fill='#000'
        />
      }
      {
        <Circle
          radius={8}
          x={wall.canvas.cursor.x}
          y={wall.canvas.cursor.y}
          fill='#5BB069'
        />
      }
    </Layer>
  )
}

export default Cursor
