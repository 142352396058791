import { useDispatch } from 'react-redux'
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WALL_LOADER_SELECTED } from '../../../../actions/WD/wallActions'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function SaveToCart() {
  return (
    <>
      <OverlayTrigger
        placement='top'
        delay={{ show: 650, hide: 200 }}
        overlay={<Tooltip id={'tooltip-bottom'}>Fal kosárba mentése</Tooltip>}
      >
        <Button className='btn-to-cart btn-dark btn-rounded p-3 py-2'>
          <font>
            <FontAwesomeIcon
              className='mr-3'
              size='lg'
              icon={faCartArrowDown}
            />
            Kosárba
          </font>
        </Button>
      </OverlayTrigger>
    </>
  )
}
