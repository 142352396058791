import React, { useState, useEffect } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Image,
  Card,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'

const DataProtectionScreen = ({ match, location }) => {
  return (
    <div className='page-container'>
      <Container className='header-margin py-4'>
        <Row className='py-4'>
          <Col>
            <h1>Adatkezelési tájékoztató</h1>

            <p>
              Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016.
              április 27.) a természetes személyeknek a személyes adatok
              kezelése tekintetében történő védelméről és az ilyen adatok szabad
              áramlásáról, valamint a 95/46/EK rendelet hatályon kívül
              helyezéséről (általános adatvédelmi rendelet) (a továbbiakban:
              rendelet) és a személyes adatok védelméről szóló Tt. 18/2018. sz.
              törvény (a továbbiakban: törvény) értelmében
            </p>
            <p>Bevezetés</p>
            <p>
              Fodor Tamás (egyéni vállalkozó), Székhely: 1133 Budapest Ronyva
              utca 6., Nyilvántartási szám: 57756934, Adószám: 59709189141 mint
              Adatkezelő (a továbbiakban: adatkezelő) ezúton tájékoztatja a
              weboldal látogatóit a személyes adataik gyűjtéséről és
              kezeléséről. Jelen tájékoztató elsősorban vásárlóinknak, üzleti
              partnereinknek (vevőinknek és szállítóinknak) és azoknak a
              munkatársaiknak szól, akik a szerződéses jogviszonyok keretében a
              termékszállítással és szolgáltatásnyújtással kapcsolatos egyes
              kommunikációs területekért felelősek.
            </p>
            <p>Adatvédelmi szabályzat</p>
            <p>
              Személyes adatait kizárólag a rendeletben vagy törvényben
              meghatározott jogszabályi feltételek alapján kezeljük.
              Adatkezelőként felelősséggel tartozunk az Ön által a rendeletnek
              és a törvénynek megfelelően a jelen nyilatkozatban foglaltak
              szerinti körben és módon rendelkezésünkre bocsátott személyes
              adatai védelméért.
            </p>
            <p>
              Személyes adatait biztonságosan tároljuk és mentjük a biztonsági
              szabályzatunknak és adatfeldolgozóink biztonsági szabályzatának
              megfelelően, kizárólag az alábbiakban meghatározott ideig.
            </p>
            <p>
              Személyes adataihoz a címzettek és az általunk megbízott személyek
              férnek hozzá, akik az utasításaink alapján és biztonsági
              szabályzatunknak megfelelően kezelik személyes adatait.
              Adatkezelőként a rendeletben és a törvényben foglalt biztonsági
              követelményeknek megfelelően biztonsági másolatot kell készítenünk
              az adatokról.
            </p>
            <p> </p>
            <p>A személyes adatok forrása</p>
            <p>
              Személyes adatait elsősorban közvetlenül Öntől gyűjtjük, mégpedig
              akkor, amikor Ön önkéntesen átadja az adatait a termékeinkre
              vonatkozó megkereséssel vagy kéréssel kapcsolatban, amit Ön
              személyesen, telefonon, írásban postai úton vagy elektronikus
              úton, valamint webáruházunkon keresztül intéz hozzánk, mégpedig a
              kérése alapján.
            </p>
            <p>A személyes adatok kategóriái</p>
            <p>
              Általában a vásárlók és üzleti partnerek következő adatait
              kezeljük – beleértve a munkatársaik személyes adatait:
            </p>
            <p>
              Azonosító adatok és elérhetőségek: így különösen a név,
              kapcsolattartási és kézbesítési postacím, telefonos elérhetőségek,
              e-mail elérhetőségek, és abban az esetben, ha Ön vállalkozó
              természetes személy, a cégneve, telephelyének vagy székhelyének
              címe, azonosító száma és adószáma.
            </p>
            <p>
              Vásárlásokra és kedvezményekre vonatkozó adatok: a webáruházunkban
              leadott rendeléssel kapcsolatos információk (elsősorban a rendelés
              száma, a vásárlás időpontja, a megrendelt termékek típusa és
              mennyisége, a megrendelt termékek vételára, a szállítási és
              fizetési mód, a vásárlás során megadott e-mail-címre küldött
              automatikus vásárlási értesítésekre vonatkozó információk,
              rendelési státuszok).
            </p>
            <p>
              Banki, pénzügyi és tranzakciós adatok: kártyaszám, bankszámla
              adatok, fizetési adatok.
            </p>
            <p>
              A társaságunk által a vonatkozó szerződés létrejötte folytán
              létrehozott információk: így különösen a vevőszám, a kiállított
              számviteli és adóügyi bizonylat dátuma és sorszáma, a megvásárolt
              termék vagy szolgáltatás típusa és darabszáma, a megvásárolt
              termék vagy szolgáltatás vételára, a termék vagy szolgáltatás
              kifizetésének időpontja és módja, a termékszállítás vagy
              szolgáltatásnyújtás időpontja és módja, a termékre/szolgáltatásra
              nyújtott jótállás időtartama.
            </p>
            <p>
              A kölcsönös kapcsolattartásunkra vagy a szerződés létrejöttével és
              teljesítésével kapcsolatos egyéb kapcsolattartásra vonatkozó
              információk: így különösen az igénybe vett kommunikációs csatorna,
              a kommunikáció időpontja és tartalma, ügyfélszolgálati vonalak
              igénybevétele esetén a hangfelvételek.
            </p>
            <p>
              Távközlési eszköz segítségével kötött szerződéstől való esetleges
              elállással kapcsolatos információk (így különösen az elállás
              időpontja, a már megvalósított vásárlás elszámolásának módja).
            </p>
            <p>
              A megvásárolt termékkel/szolgáltatással kapcsolatban tett
              panasszal kapcsolatos információk (így különösen a panasztétel
              időpontja, a panasztételi dokumentumok sorszáma és adatai, a
              kifogásolt hiba leírása, a panaszkezelés igényelt módja, a
              panaszkezelés módja, a panaszkezelés időpontja).
            </p>
            <p>
              Az Ön által tett panasszal, fellebbezéssel kapcsolatos
              információk: így különösen a panaszt vagy fellebbezést benyújtó
              személy azonosító adatai és elérhetőségei, a panasz/fellebbezés
              indoklása, a panasz, fellebbezés vagy vita kezelésének módja.
            </p>
            <p>
              Marketingcélú információk, amelyeket társaságunk hozott létre a
              szolgáltatásaink igénybevétele alapján (így különösen a nálunk
              megvalósított vásárlásaira vonatkozó információk és az egyes
              vásárlói csoportokba való besorolása).
            </p>
            <p>
              Az Ön általi értékelésekre vonatkozó információk: webáruházunk és
              a megvásárolt termékek/szolgáltatások értékelése.
            </p>
            <p> </p>
            <p>A személyes adatok rendelkezésre bocsátásának szükségessége</p>
            <p>
              Feltétlenül szükségünk van az Ön személyes adataira, ugyanis ezek
              hiányában nem jöhet létre szerződéses jogviszony a szállító és a
              vevő között, az Ön mint szerződő fél azonosítása a szerződés
              lényeges elemei közé tartozik. Ha azonban úgy dönt, hogy nem
              bocsátja rendelkezésünkre a telefonszámát vagy e-mail-címét, ez
              nem akadálya a szerződéses jogviszony létrejöttének, kölcsönös
              kapcsolattartásunk azonban nem lesz olyan hatékony, mintha ezeket
              a rendelkezésünkre bocsátotta volna. Ha nem adja meg az
              e-mail-címét, akkor a Szerződés alapján kiállított elektronikus
              számlákat sem tudjuk elküldeni Önnek, és csak postai úton
              kézbesítjük.
            </p>
            <p>
              A személyazonosító okmány számának megadását kizárólag a vásárló
              által a szállítmányaink átvételére felhatalmazott személyektől
              fogjuk kérni. Ennek célja a termékeink átadás-átvétele során
              történő egyértelmű azonosítás szükségessége, és a más személy
              általi átvétel megakadályozása; továbbá az esetleges
              panaszkezelési eljárás sikeres lezárása.
            </p>
            <p>
              Továbbá, ha Ön nem bocsátja rendelkezésünkre személyes adatait, ez
              jelentősen megnehezítheti vagy akár teljesen ellehetetlenítheti a
              szerződések teljesítését, vagy megnehezítheti vagy akár teljesen
              ellehetetlenítheti a szerződő felek közötti kapcsolattartást.
            </p>
            <p> </p>
            <p>A személyes adatok címzettjei</p>
            <p>
              Személyes adatait belső rendszereinkben tároljuk, és különböző
              együttműködő szervezeteknek továbbítjuk. A személyes adatok
              továbbításának címzettjei lehetnek többek között:
            </p>
            <p>
              ellenőrző, felügyeleti és egyéb állami hatóságok a feladataik
              ellátása keretében a külön jogszabályok értelmében bíróságok és
              bűnüldöző hatóságok a megkeresésük alapján, vagy az adatkezelőnek
              a jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez
              kapcsolódó jogos érdekei alapján, szerződés alapján megbízott
              szolgáltatók, akik: technikailag biztosítják számunkra az üzleti
              és reklámanyagaink terjesztését, kezelési, tárhely- és
              szervizszolgáltatásokat nyújtanak számunkra az általunk használt
              szoftveralkalmazásokhoz, ügyfél-elégedettségi felmérést végeznek
              számunkra, biztosítják számunkra az Ön által megrendelt áruk
              kiszállítását (kézbesítők, futár), online fizetési
              szolgáltatásokat nyújtanak számunkra (fizetési átjárók
              üzemeltetői), részt vesznek az Ön által tett kifogások kezelésében
              és rendezésében (gyártók vagy beszállítók), jogi szolgáltatásokat
              nyújtanak számunkra, így különösen biztosítják a törvényben
              garantált érdekeink védelmét, és képviselnek bennünket a jogi
              igényeink megállapítása, védelme és érvényesítése során (ügyvédek,
              végrehajtók), vagy szolgáltatásokat nyújtanak számunkra, műszaki
              megoldásokat működtetnek, amelyeknek köszönhetően weboldalainkon
              és más weboldalakon az Ön számára releváns tartalmat és reklámot
              tudjuk megjeleníteni.
            </p>
            <p>
              egyéb címzettek, akiknek az adatkezelő külön jogszabály vagy jogos
              érdek alapján köteles továbbítani a személyes adatokat, többek
              között könyvvizsgálók, jogi tanácsadók, adóügyi és számviteli
              tanácsadók, biztosító társaságok, bankok, hitelnyilvántartások, a
              szerződéses partnereink szolgáltatásainak igénybevételét értékelő
              harmadik személyek, olyan személyek, akik a munkájuk ellátásához
              vagy jogaik gyakorlásához feltétlenül szükséges mértékben
              munkaviszonyban vagy más hasonló jogviszonyban állnak velünk, és
              akik a rendelkezésre bocsátott vagy hozzáférhetővé tett személyes
              adatok vonatkozásában a velük megkötött írásbeli szerződésben vagy
              az általánosan kötelező jogszabályokban rögzített mértékben és
              feltételekkel kötelesek az ilyen információkat bizalmasan kezelni.
              Társaságunk a weboldal látogatási élményének fokozása érdekében az
              alábbi cégekkel működik együtt: Google, Facebook Ireland Ltd. Az
              adatfeldolgozókkal szerződéses jogviszonyban állunk, amelynek
              alapján biztosítjuk az Ön személyes adatainak védelmét. Figyelmet
              fordítunk arra, hogy az adatfeldolgozók a személyes adatok
              védelmére vonatkozó hatályos jogszabályoknak megfelelően
              biztosítsák a személyes adatok megfelelő szintű védelmét.
            </p>
            <p>
              A székhelyünk címére vagy a cathletic.shop@gmail.com e-mail-címre
              küldött kérelme alapján tájékoztatást adunk Önnek az olyan konkrét
              üzleti partnerrel kapcsolatos információkról, akinek adott esetben
              rendelkezésre bocsátjuk az Ön személyes adatait.
            </p>
            <p> </p>
            <p>Az adatkezelés célja és időtartama</p>
            <p>Személyes adatait a következő célokból gyűjtjük és kezeljük:</p>
            <p> </p>
            <p>
              A webáruházban történő regisztráció és a webáruház működtetése A
              webáruházban történő regisztráció és a webáruház működésének
              biztosítása érdekében. A személyes adatok ilyen célú kezelésének
              jogalapja a rendelet 6. cikk (1) bek. b) pontja, azaz a szerződés
              megkötését megelőzően az érintett kérésére történő lépések
              megtétele az elektronikus kereskedelemről szóló Tt. 22/2004. sz.
              törvény értelmében. Az adatkezelés a webáruházban történő
              regisztráció és a webáruház működésének biztosításához szükséges.
            </p>
            <p>
              A webáruházban megadott személyes adatokat a webáruházban történő
              regisztráció időtartama alatt kezeljük.
            </p>
            <p>
              Termékek vásárlása a webáruházban, a termékek kiszállítása,
              panaszkezelés, biztosítási események kezelése A webáruházban
              leadott megrendelésére vonatkozó szerződéses kötelezettségek
              teljesítése, amelynek alapján termékeket szállítunk Önnek mint
              vásárlónknak, valamint a rendeléskezeléssel, a termékek
              átadás-átvételével, biztosítási események kezelésével,
              panaszkezeléssel, a termékszállítással és szolgáltatásnyújtással
              kapcsolatos számlázással, az ehhez kapcsolódó dokumentáció és
              nyilvántartás megőrzésével, a szerződő felek közötti kölcsönös
              kapcsolattartással stb. kapcsolatos további kötelezettségek
              teljesítése érdekében. A személyes adatai ilyen célú kezelésének
              jogalapja a rendelet 6. cikk (1) bek. b) és c) pontja, azaz a
              szerződés teljesítése és az adatkezelőre vonatkozó jogi
              kötelezettségek teljesítése, különösen a Szlovák Köztársaság
              Nemzeti Tanácsának a Kereskedelmi Törvénykönyvről szóló, többször
              módosított Tt. 513/1991. sz. törvénye értelmében. Ebben az esetben
              a személyes adatok érintett általi rendelkezésre bocsátása
              szerződéses kötelezettség. Az adatszolgáltatás elmaradása esetén
              az érintettel nem jöhet létre szerződéses jogviszony.
            </p>
            <p>
              Személyes adatait az adatkezelés céljának eléréséhez szükséges
              ideig, de legfeljebb a szerződéses jogviszony időtartama alatt
              kezeljük. A szerződéses jogviszony megszűnését követően személyes
              adatait a szerződés megszűnésétől számított 10 évig tároljuk,
              mivel az általánosan kötelező jogszabályok – így különösen a
              számviteli törvény – értelmében kötelesek vagyunk megőrizni a
              személyes adatait tartalmazó szerződést és a szerződéshez
              kapcsolódó számviteli és adóügyi bizonylatokat.
            </p>
            <p> </p>
            <p>
              Szerződéses kötelezettségek teljesítése Szerződéses
              kötelezettségek teljesítése vagy az Ön kérésére hozott, szerződést
              megelőző intézkedések végrehajtása, így különösen árajánlatok
              készítése és küldése, szerződések készítése és megkötése,
              szerződések és módosításaik belső rendszerünkben történő
              nyilvántartása, a szerződések és a szerződő felek
              kötelezettségeinek teljesítésére vonatkozó ellenőrzés elsősorban
              az adásvételi szerződések és keretszerződések keretében, továbbá a
              szerződéskezeléssel, a termékek átadás-átvételével, biztosítási
              események kezelésével, panaszok kezelésével, a termékszállítással
              és szolgáltatásnyújtással kapcsolatos számlázással, a kapcsolódó
              dokumentációk és nyilvántartások megőrzésével, a szerződő felek
              kölcsönös kapcsolattartásával stb. kapcsolatos egyéb
              kötelezettségek teljesítése érdekében. A személyes adatai ilyen
              célú kezelésének jogalapja a rendelet 6. cikk (1) bek. b) és c)
              pontja, azaz a szerződés teljesítése és az adatkezelőre vonatkozó
              jogi kötelezettségek teljesítése, különösen a Szlovák Köztársaság
              Nemzeti Tanácsának a Kereskedelmi Törvénykönyvről szóló, többször
              módosított Tt. 513/1991. sz. törvénye értelmében. Ebben az esetben
              a személyes adatok érintett általi rendelkezésre bocsátása
              szerződéses kötelezettség. Az adatszolgáltatás elmaradása esetén
              az érintettel nem jöhet létre szerződéses jogviszony.
            </p>
            <p>
              A szerződéses jogviszony megszűnését követően személyes adatait a
              szerződés megszűnésétől számított 10 évig tároljuk, mivel az
              általánosan kötelező jogszabályok – így különösen a számviteli
              törvény – értelmében kötelesek vagyunk megőrizni a személyes
              adatait tartalmazó szerződést és a szerződéshez kapcsolódó
              számviteli és adóügyi bizonylatokat.
            </p>
            <p>
              Telefonos és e-mailes ügyféltámogatás A társaság a telefonos és
              e-mailes ügyféltámogatás céljából elsősorban a vásárlók következő
              személyes adatait kezeli: családi név, utónév, telefonszám,
              e-mail-cím, megrendelés száma, telefonos ügyfélszolgálat esetén a
              telefonközpont hívásainak rögzítése.
            </p>
            <p>
              A személyes adatok ilyen célú kezelésének jogalapja a GDPR
              rendelet 6. cikk (1) bek. b) pontja, a telefonhívás rögzítésével
              kapcsolatban a GDPR rendelet 6. cikk (1) bek. a) pontja. A
              társaságunk által végzett adatkezelés az ügyféltámogatás
              nyújtásához szükséges, míg a hangfelvétel esetében a hozzájárulás
              és így a személyes adatok hangfelvétel útján történő kezelése
              önkéntes alapon történik.
            </p>
            <p> </p>
            <p>
              Az e-mailes ügyfélszolgálat keretében a személyes adatokat a
              megvásárolt termék jótállási ideje alatt (2 évig) kezeljük,
              telefonos ügyfélszolgálat esetén a telefonközpontban rögzített
              hangfelvételeket 3 hónapig őrizzük meg.
            </p>
            <p> </p>
            <p>
              Vásárlói panaszok kezelése Társaságunk a vásárlói panaszok
              kezelése céljából a következő személyes adatokat kezeli: családi
              név, utónév, a panaszban szereplő adatok, és a panasz
              benyújtásának módjától függően a vásárló címe, telefonszáma vagy
              e-mail-címe. A személyes adatok ilyen célú kezelésének jogalapja a
              GDPR rendelet 6. cikk (1) bek. b) pontja. A társaságunk által
              végzett adatkezelés a vásárlói panasz kezeléséhez szükséges.
            </p>
            <p>Kereskedelmi információk a termékekről és akciókról</p>
            <p>
              Hozzájárulása esetén e-mailben vagy más formában a termékekre és
              különböző akciókra vonatkozó kereskedelmi információkat küldünk
              Önnek. A webshopunkban történő regisztrációkor, ill. a megrendelés
              leadásakor eldöntheti, hogy szeretne-e rendszeresen tájékoztatást
              kapni az újdonságokról. A kereskedelmi információkat kizárólag
              olyan terjedelemben és gyakorisággal küldjük, hogy azok ne
              legyenek az Ön számára zavaróak. Felhasználói fiókjában bármikor
              beállíthatja és módosíthatja a hírlevélküldés gyakoriságát.
            </p>
            <p>
              Weboldalunkon az e-mail-címe megadásával, a webshopban történő
              regisztráció nélkül is feliratkozhat a hírlevelekre.{' '}
            </p>
            <p>
              A személyes adatai ilyen célú kezelésének jogalapja a rendelet 6.
              cikk (1) bek. a) pontja, vagyis az érintett hozzájárulása.
              Hozzájárulását bármikor visszavonhatja a közvetlenül az e-mail
              üzenetekben található hivatkozás segítségével, vagy kérje
              e-mailben a kereskedelmi értesítések küldésének leállítását a
              következő e-mail-címen: cathletic.shop@gmail.com. A hírlevelekről
              is egyszerűen és bármikor leiratkozhat felhasználói profiljában. A
              hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a
              visszavonás előtti adatkezelés jogszerűségét.{' '}
            </p>
            <p>
              Ha Ön a vásárlónk, jogos érdekünknek tekintjük, hogy alkalmanként
              SMS üzenetben vagy más formában tájékoztassuk Önt a különböző
              akciókról, elsősorban a kedvezményekről. Ön jogosult arra, hogy
              bármikor leállítsa az SMS üzenetek küldését úgy, hogy e-mailben
              kéri az SMS üzenetek küldésének leállítását a következő
              e-mail-címen: cathletic.shop@gmail.com.
            </p>
            <p>
              Az eladó e-mailben is tájékoztatja a vásárlót a megrendelése
              feldolgozásáról, a termékek elérhetőségéről, ill.
              elérhetetlenségéről. Az eladó alkalomszerűen (évente legfeljebb
              néhányszor) az eladó webáruházának működésével és a felhasználói
              fiók használatával kapcsolatos fontos értesítéseket küldhet a
              regisztrált vásárlóknak.{' '}
            </p>
            <p>
              Ha Ön bármikor tiltakozik személyes adatainak közvetlen
              üzletszerzési célú kezelése ellen, a továbbiakban e célból nem
              kezeljük a személyes adatait.{' '}
            </p>
            <p>
              Az érintettek jogainak gyakorlása Az adatkezelő jogi
              kötelezettsége, hogy biztosítsa az érintettek jogainak
              gyakorlására irányuló kérelmek megfelelő kezelését a rendeletben
              foglaltaknak megfelelően.
            </p>
            <p> </p>
            <p>
              Személyes adatait, amelyeket az érintetti jogok megfelelő kezelése
              érdekében kezelünk, az érintetti jogai gyakorlása esetén 5 évig
              kezelhetők.
            </p>
            <p> </p>
            <p>
              A társaság igényeinek érvényesítése A társaság a következő
              adatokat kezeli a társaság igényeinek érvényesítése céljából: a
              vevőkkel és szállítókkal kötött szerződésekben szereplő adatok, a
              panaszokban szereplő adatok, perindításához szükséges adatok, a
              társasággal szemben indított perben szereplő adatok, a rögzített
              lopások adatai, a könyvelésben szereplő adatok, továbbá a társaság
              igényeinek esetleges érvényesítéséhez vagy védelméhez szükséges
              adatok. A fenti célból végzett adatkezelés célja az adatkezelő
              jogos érdeke, az adatkezelés jogalapja pedig a GDPR rendelet 6.
              cikk (1) bek. f) pontja. A társaságunk által végzett adatkezelés a
              társaság jogos érdekeinek érvényesítéséhez szükséges. A társaság
              jogos érdeke a vagyonának védelme, valamint a társasággal szemben
              indokolatlanul érvényesített igényekkel szembeni védelem.
            </p>
            <p> </p>
            <p>
              Abban az esetben, ha bírósági vagy közigazgatási eljárás keretében
              jogi igényeket érvényesítünk Önnel szemben, vagy ha bírósági vagy
              közigazgatási eljárás keretében Ön érvényesíti jogi igényét velünk
              szemben, a személyes adatokat a jogi igények előterjesztése,
              érvényesítése, illetve védelme céljából az eljárás jogerős
              lezárásáig kezeljük.
            </p>
            <p> </p>
            <p>
              Abban az esetben, ha nem tett eleget társaságunkkal szembeni
              kötelezettségének, és az Ön hibájából kárunk keletkezik, vagy
              abban az esetben, ha éppen ellenkezőleg, jogi úton Ön érvényesíti
              az igényeit társaságunkkal szemben, vagy valósan fennáll annak a
              kockázata, hogy a jövőben sor kerül ilyen helyzetre, személyes
              adatait a fent említett körben társaságunk azon jogos érdeke
              alapján kezelhetjük, amely az igényeink érvényesítésében és/vagy a
              társaságunkat megillető jogok és törvényben garantált érdekek
              védelmében rejlik (pl. a jogi igényeknek bíróság vagy alternatív
              vitarendezési testület előtti megállapítása, védelme vagy
              érvényesítése). Ebből a célból személyes adatait a törvényes
              elévülési idő alatt tároljuk.
            </p>
            <p> </p>
            <p>
              Kamerarendszer segítségével megvalósított vagyonvédelem és
              fokozott üzembiztonság A társaság egyes üzlethelyiségeiben a
              fokozott biztonság és vagyonvédelem érdekében kamerarendszer
              segítségével videomegfigyelés történik, elsősorban a vagyon elleni
              bűncselekmények és egyéb társadalomellenes tevékenységek, többek
              között betörések, lopások, rongálások megelőzése, illetve a
              személyek, vagyonuk és egészségük védelme érdekében az adatkezelő
              üzlethelyiségeiben. A jogalap az adatkezelő jogos érdeke (GDPR 6.
              cikk (1) bek. f) pont).
            </p>
            <p> </p>
            <p>
              A kamerarendszerben rögzített videofelvételeket a rögzítéstől
              számított 14 napig tároljuk.
            </p>
            <p> </p>
            <p>
              A társaság jogi kötelezettségeinek teljesítése A társaság az a)–m)
              pontokban meghatározott személyes adatokat a társaság jogi
              kötelezettségeinek teljesítése céljából kezeli. Az adatkezelés
              jogalapja a GDPR rendelet 6. cikk (1) bek. c) pontja (pl. a
              számviteli törvény, általános forgalmi adóról szóló törvény,
              jövedelemadóról szóló törvény, fogyasztóvédelmi törvény, a
              levéltárakról és nyilvántartásokról szóló törvény). A társaságunk
              által végzett adatkezelés a társaság jogi kötelezettségeinek
              teljesítéséhez szükséges.
            </p>
            <p>
              A valós idejű videofelvételhez és a szerverünkön lokálisan tárolt
              videofelvételhez kizárólag a társaság által erre feljogosított
              személyek rendelkeznek távoli elektronikus hozzáféréssel a
              feladataik ellátásához szükséges mértékben. Gyanú vagy
              szabálysértési eljárás illetve bűncselekmény miatt folyamatban
              lévő eljárás esetén a társaság kamerarendszere segítségével
              rögzített felvételeket a rendőrség, ill. a büntetőeljárásban,
              közigazgatási és szabálysértési eljárásban és jogi ügyekben
              illetékes hatóságok rendelkezésére bocsátjuk, az adott felvétel
              által érintett ügy tisztázása, kivizsgálása, ellenőrzése vagy
              operatív vizsgálata céljából. Személyes adataihoz szükség esetén a
              kamerarendszer szervizelésével és karbantartásával megbízott
              szerződött cég is ideiglenesen hozzáférést szerezhet.
            </p>
            <p>
              Ebből a célból személyes adatait a társaságra vonatkozó
              jogszabályokban, így különösen a számviteli törvény, valamint a
              levéltárakról és nyilvántartásokról szóló törvényben meghatározott
              határidőknek megfelelően tároljuk.
            </p>
            <p> </p>
            <p>
              Harmadik országokba vagy nemzetközi szervezet részére történő
              adattovábbítás
            </p>
            <p>
              A többek között a weboldalaink teljesítményének értékelése, a
              felhasználók magatartásának elemzése és a weboldalon történő
              releváns hirdetések megjelenítése során használt egyes technikai
              megoldásokat olyan cégek biztosítják számunkra, amelyeknek az
              Európai Unión kívül található a székhelyük. Egyes adatok –
              elsősorban a cookie-k segítségével megszerzett adatok –
              feldolgozásának keretében ezért ezek az adatok az adott
              szolgáltatók Európai Unión kívül elhelyezett szervereire
              továbbíthatók.
            </p>
            <p> </p>
            <p>A hozzájárulás visszavonása:</p>
            <p>
              Ha személyes adatait hozzájárulás alapján is kezeljük, Ön jogosult
              arra, hogy adatkezelési hozzájárulását bármikor visszavonja. A
              hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a
              visszavonás előtti adatkezelés jogszerűségét. A hozzájárulás
              visszavonását a megadásával azonos módon teheti meg.
            </p>
            <p> </p>
            <p>Automatizált döntéshozatal beleértve a profilalkotást</p>
            <p>
              Személyes adatainak kezelése során nem kerül sor automatizált
              döntéshozatalra vagy profilalkotásra.
            </p>
            <p> </p>
            <p>Adatvédelem a weboldalainkon</p>
            <p>Önkéntesen megadott adatok</p>
            <p>
              Ön a weboldalunkon önkéntesen is megadhat bizonyos információkat
              egy adott célra, pl. a kapcsolatfelvételhez való hozzájáruláskor
              megadott bármilyen információkat. Ezek az adatok olyan személyes
              elérhetőségi adatokat is magukban foglalhatnak, mint például a
              név, cégnév, cím, telefonszám, e-mail-cím, beosztás vagy
              demográfiai adatok és bármilyen további, Ön által megadott
              információ.
            </p>
            <p>
              Az így gyűjtött adatokat az Önnel való kapcsolatfelvételhez
              használjuk fel. Emiatt külső partnerrel is megoszthatjuk az
              adatokat, ha ez a kérdései megválaszolásához szükséges.
            </p>
            <p>Cookie-k, használatra vonatkozó adatok és hasonló eszközök</p>
            <p>
              Weboldalunk látogatásakor automatizált eszközök segítségével
              bizonyos információkat gyűjtünk, ezek például a cookie fájlok,
              pixelcímkék, böngészőelemző eszközök stb.
            </p>
            <p>Hivatkozott weboldalak</p>
            <p>
              Weboldalunkon harmadik felek weboldalaira mutató hivatkozásokat
              helyezhetünk el („hivatkozott weboldalak”). A hivatkozott
              weboldalakat nem feltétlenül ellenőrizzük vagy vizsgáljuk. A
              hivatkozott weboldalak saját felhasználási feltételekkel és
              adatvédelmi szabályzatokkal rendelkeznek. Nem vállalunk
              felelősséget a hivatkozott weboldalak és az azokon található
              további hivatkozások szabályzataiért és gyakorlataiért, ezért
              felhívjuk a felhasználók figyelmét, hogy mielőtt használnának egy
              hivatkozott weboldalt, olvassák el annak szabályzatát és
              tájékoztatóját.
            </p>
            <p>
              Weboldalunk például a Facebook és Instagram közösségi portálokra
              mutató gombokat használ. A gombok az adott közösségi portálok
              logóit tartalmazzák. Ezek a gombok azonban nem szabványos
              közösségi bővítmények, vagyis nem a közösségi portálok által
              biztosított bővítmények, hanem gombikonokkal ellátott
              hivatkozások. A gombok aktiválása kizárólag szándékos művelettel
              (kattintással) történik. Ha Ön nem kattint ezekre a gombokra, a
              rendszer semmilyen adatot nem továbbít a közösségi portáloknak. A
              gombokra kattintva elfogadja a közösségi portál szervereivel való
              kommunikációt, ezáltal aktiválja a gombokat, és létrehoz egy
              összeköttetést. Ha nem szeretné, hogy a közösségi portálok
              adatokat gyűjtsenek Önről, ne kattintson a gombra.
            </p>
            <p>Gyerekek</p>
            <p>
              Weboldalaink nem gyerekekre irányuló oldalak, azokat nem
              használjuk arra, hogy tudatosan személyes adatokat kérjünk a
              gyerekektől, vagy hogy gyerekek számára értékesítsünk. Ha tudomást
              szerzünk arról, hogy egy gyermek a weboldalunk segítségével
              személyes adatokat adott meg, rendszereinkből eltávolítjuk ezeket
              az információkat.
            </p>
            <p> </p>
            <p>Az adatvédelemmel kapcsolatos további jogai</p>
            <p>
              Személyes adatainak kezelésével kapcsolatban a fentiek mellett az
              alábbi jogok illetik meg:
            </p>
            <p>
              a személyes adataihoz való hozzáférés joga (a rendelet 15. cikke);
              Ön jogosult arra, hogy visszajelzést kapjon tőlünk arra
              vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e,
              és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a
              személyes adatokhoz (ezek másolatához) és a rendelet 15. cikkében
              meghatározott kiegészítő információkhoz hozzáférést kapjon. A
              legtöbb esetben írásban, papír alapon bocsátjuk rendelkezésére a
              személyes adatai másolatát, kivéve, ha Ön másként kéri. Ha Ön
              elektronikus úton nyújtotta be kérelmét, a tájékoztatást – ha ez
              technikailag megvalósítható – elektronikus úton adjuk meg.
            </p>
            <p>
              Ha a kamerarendszer rögzítette az Ön arcképét, Ön jogosult arra,
              hogy másolatot kérjen és a rendelkezésére bocsássuk a személyes
              adatait tartalmazó felvételt. Az igényelt felvétel leírását –
              időpontját, időtartamát, a helyszín megjelölését tartalmazó
              írásbeli kérelem alapján indokolt esetben a rendelkezésére
              bocsátjuk a felvételt. Ha a felvételeken más személyek is
              láthatók, akkor azokat szerkesztett, pl. elhomályosított formában
              bocsátjuk rendelkezésére.
            </p>
            <p>
              a személyes adatai helyesbítéséhez való joga (a rendelet 16.
              cikke); ésszerű intézkedéseket teszünk, hogy biztosítsuk a
              rendelkezésünkre álló, Önre vonatkozó információk pontosságát,
              teljességét és időszerűségét. Ön azonban jogosult arra, hogy
              kérésére indokolatlan késedelem nélkül helyesbítsük az Önre
              vonatkozó pontatlan személyes adatokat, vagy kiegészítsük a
              pontatlan, hiányos vagy elavult személyes adatait. Felhívjuk
              figyelmét, hogy Ön kizárólag hiánytalan és pontos személyes
              adatokat köteles a rendelkezésünkre bocsátani, és Ön felel a
              megadott személyes adatok valódiságáért.
            </p>
            <p>
              a személyes adatai törléséhez való joga („elfeledtetéshez” való
              jog) (a rendelet 17. cikke), mégpedig indokolatlan késedelem
              nélkül e jog gyakorlását követően, például ha személyes adataira
              már nincs szükség abból a célból, amelyből azokat gyűjtöttük vagy
              kezeltük, ha Ön visszavonta az adatkezelés alapját képező
              hozzájárulását, és az adatkezelésnek nincs más jogalapja (például
              az Önnel kötött Szerződés/vagy más szerződések), ha Ön a rendelet
              21. cikk (1) bek. értelmében tiltakozik a személyes adatai
              kezelése ellen, vagy ha személyes adatait a rendelettel és a
              törvénnyel ellentétesen kezeljük. Ezt a jogát azonban minden
              lényeges körülményt figyelembe véve kell vizsgálni. Például
              fennállhatnak bizonyos jogi és szabályozási kötelezettségeink, ami
              azt jelenti, hogy nem tehetünk eleget a kérelmének.
            </p>
            <p>
              a személyes adatai kezelésének korlátozásához való joga (a
              rendelet 18. cikke), a törvényben meghatározott esetekben Ön
              jogosult arra, hogy kérésére megszüntessük a személyes adatai
              kezelését, pl. ha vitatja a rendelkezésünkre álló, Önre vonatkozó
              személyes adatok pontosságát, de csak arra az időtartamra, amely
              lehetővé teszi, hogy ellenőrizzük a személyes adatok pontosságát,
              ha Ön tiltakozik a személyes adatai automatizált döntéshozatal
              útján történő kezelése ellen, vagy az adatkezelés a rendelettel és
              a törvénnyel ellentétesen történik, és Ön ellenzi az adatok
              törlését, és ehelyett kéri azok felhasználásának korlátozását,
              vagy Ön ellenzi azoknak az adatoknak a törlését, amelyekre
              szállítóként már nincs szükségünk, és törölni szeretnénk, de Önnek
              például egy folyamatban lévő bírósági eljárásban van szüksége
              ezekre az adatokra, a személyes adatai hordozhatóságához való joga
              (a rendelet 20. cikke), Ön jogosult arra, hogy az Ön által
              korábban rendelkezésünkre bocsátott személyes adatait tagolt,
              széles körben használt, géppel olvasható formátumban megkapja,
              továbbá jogosult arra, hogy ezeket az adatokat a jogszabályi
              feltételek teljesítése esetén egy másik adatkezelőnek továbbítsuk;
              e jog gyakorlása nem érinti a személyes adatai szállító általi
              törléséhez való jogát. Az adathordozhatósághoz való jog azonban
              csak azokra a személyes adatokra vonatkozik, amelyeket
              hozzájárulás alapján vagy olyan szerződés alapján bocsátott
              rendelkezésünkre, amelyben Ön is szerződő fél.
            </p>
            <p>
              a személyes adatai kezelése elleni tiltakozási joga (a rendelet
              21. cikke), ha az adatkezelés jogszerű és jogos érdekünkön alapul,
              vagy ha személyes adatait közvetlen üzletszerzés céljából
              kezeljük, beleértve az adatkezelés során alkalmazott
              profilalkotást. Abban az esetben, ha Ön tiltakozik az adatkezelés
              ellen, és mi nem bizonyítjuk az adatkezelés alapjául szolgáló
              meggyőző, jogszerű és jogos érdekünket, vagy ha tiltakozik a
              közvetlen üzletszerzés céljából történő adatkezelés ellen,
              személyes adatait a továbbiakban nem kezeljük ebből a célból. Ön
              jogosult arra, hogy ne terjedjen ki Önre az olyan, kizárólag
              automatizált adatkezelésen – ideértve a profilalkotást is –
              alapuló döntés hatálya, ha az automatizált adatkezelés és
              profilalkotás Önre nézve joghatással járna vagy hasonlóképpen
              jelentős mértékben érintené Önt (a rendelet 22. cikke); de amint
              azt már említettük, személyes adatainak kezelése során részünkről
              nem kerül sor automatizált döntéshozatalra vagy profilalkotásra, a
              valamely felügyeleti hatósághoz címzett panasz benyújtásának joga
              (a rendelet 77. cikke); ha megítélése szerint az Önre vonatkozó
              személyes adatok kezelése megsérti a rendeletet vagy a törvényt,
              panaszt tehet a Nemzeti Adatvédelmi és Információszabadság
              Hatóságnál (https://www.naih.hu/, 1055 Budapest Falk Miksa utca
              9-11, telefonszám: +36 (30) 683-5969; e-mail:
              ugyfelszolgalat@naih.hu) (a törvény 100. §-a szerinti adatvédelmi
              hatósági eljárás megindítására irányuló kérelem).
            </p>
            <p>Jogait itt is gyakorolhatja.</p>
            <p> </p>
            <p>Záró rendelkezések</p>
            <p>
              Ha bármilyen kérdése van a személyes adatai kezelésével
              kapcsolatban, forduljon közvetlenül adatvédelmi koordinátorunkhoz
              a cathletic.shop@gmail.com e-mail-címen, vagy vegye fel velünk a
              kapcsolatot személyesen vagy írásban a székhelyünk címén.
            </p>
            <p>Jelen tájékoztató 2023.02.03 napjától aktuális és érvényes.</p>
            <p>
              Fenntartjuk a jogot arra, hogy bármikor megváltoztassuk,
              módosítsuk és frissítsük a jelen adatvédelmi szabályzatot. Kérjük,
              rendszeresen ellenőrizze, hogy megismerte-e legaktuálisabb
              tájékoztatónkat.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DataProtectionScreen
