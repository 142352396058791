import React from 'react'
import { faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Sidebar.css'
import { useDispatch, useSelector } from 'react-redux'
import { CART_DETAILED_VIEW_CLICKED } from '../../../actions/WD/wallActions'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function SizeInfo() {
  const wall = useSelector((state) => state.wall)
  const dispatch = useDispatch()

  return (
    <>
      <h5 className='my-2 mt-4'>
        <span class='tab-space'>
          <FontAwesomeIcon icon={faExpand} />
        </span>
        Fal Méretek
      </h5>
      <table class='table size-table'>
        <tbody>
          <tr>
            <td> Szélesség</td>
            <td>{wall.wallSize.x} cm</td>
          </tr>
          <tr>
            <td>Magasság</td>
            <td>{wall.wallSize.y} cm</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
