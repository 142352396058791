export const POST_LIST_REQUEST = 'POST_LIST_REQUEST'
export const POST_LIST_SUCCESS = 'POST_LIST_SUCCESS'
export const POST_LIST_FAIL = 'POST_LIST_FAIL'

export const POST_DETAILS_REQUEST = 'POST_DETAILS_REQUEST'
export const POST_DETAILS_SUCCESS = 'POST_DETAILS_SUCCESS'
export const POST_DETAILS_FAIL = 'POST_DETAILS_FAIL'

export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST'
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS'
export const POST_CREATE_FAIL = 'POST_CREATE_FAIL'
export const POST_CREATE_RESET = 'POST_CREATE_RESET'