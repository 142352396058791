import { React } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import './bootstrap.min_lux.css'
import './index.css'
import App from './App'
import { HelmetProvider } from 'react-helmet-async'


ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
)
