import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Image, Col, Button } from 'react-bootstrap'
import WebshopComplex from '../components/WebshopComplex'
import { listProducts } from '../actions/productActions'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const WebshopScreen = ({ match }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const [complex, setComplex] = useState(true)

  useEffect(() => {
    dispatch(listProducts(keyword))
  }, [dispatch, keyword])

  return (
    <>
      <Helmet>
        <title>Cathletic Webshop - Termékeink </title>
        <meta name="description"
          content="Cathletic Macskabútor és kaparófa. Szabd személyre macskabútorod! Egyedi bútoraink, személyre szabva." />
        <link rel="canonical" href="/webshop" />
      </Helmet>
      <div className='page-container pb-0'>
        <Container className='text-center'>
          <Row className=' text-center '>
            <h1 className='black-chango pb-2 '>webshop</h1>
            <p className='big'>
              Varázsold otthonod egy igazi macska paradicsommá!
            </p>
            <p className=''>
              Álmodd meg otthonod és cicád igényeihez illő mászófalat, és mi megvalósítjuk!
              Lepd meg cicádat egy új játszótérrel!
            </p>
          </Row>
          <Row className=''>
            <Col xl={4} lg={6} md={12} s={12} className='text-center'>
              <Link as={Link} to='/dreamcatwall'>
                <Button
                  onClick={() => setComplex(true)}
                  className='text-1rem offers rounded-corner-upper'
                  variant={complex ? 'primary' : 'outline-primary'}
                >
                  Macska mászófalat vásárolok
                </Button>
                <Image
                  role='button'
                  onClick={() => setComplex(true)}
                  width='100%'
                  className='rounded-corner-lower pb-2'
                  src='images/webshop_complex.jpg'
                  alt='Kilimanjaro Macskanútor Kaparófa'
                />
                <p className=''>
                  <strong>
                    Nézz szét a legnépszerűbb mászófalaink között.
                  </strong>
                </p>
              </Link>
            </Col>
            <Col xl={4} lg={6} md={12} s={12} className='text-center'>
              <Link as={Link} to='/webshoppart'>
                <Button
                  onClick={() => setComplex(false)}
                  className='text-1rem offers rounded-corner-upper'
                  variant={complex ? 'outline-primary' : 'primary'}
                >
                  Önálló elemeket vásárolok
                </Button>
                <Image
                  role='button'
                  onClick={() => setComplex(false)}
                  width='100%'
                  className='rounded-corner-lower pb-2'
                  src='images/webshop_part.jpg'
                  alt='Kilimanjaro Macskanútor Kaparófa'
                />
                <p className=''>
                  <strong>
                    Állítsd össze magadnak álmaid cica kalandparkját!
                  </strong>
                </p>
              </Link>
            </Col>
            <Col xl={4} lg={6} md={12} s={12} className='text-center'>
              <Link as={Link} to='/offers'>
                <Button
                  onClick={() => setComplex(true)}
                  className='text-1rem offers rounded-corner-upper'
                  variant='outline-primary'
                >
                  Mászófal látványtervet kérek
                </Button>
                <Image
                  width='100%'
                  className='rounded-corner-lower pb-2'
                  src='images/webshop_design.jpg'
                  alt='Kilimanjaro Macskanútor Kaparófa'
                />
                <p className=''>
                  <strong>
                    Kattints, és kérj ingyenesen egyedi látványtervet!
                  </strong>
                </p>
              </Link>
            </Col>
          </Row>
          <hr />
          <Row className=''>
            <WebshopComplex match={match} />
          </Row>
        </Container>
      </div >
    </>
  )
}

export default WebshopScreen
