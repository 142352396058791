import React from 'react'
import './Message.css'
import { Dropdown, Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { SELECT_WALLITEM_TOOL as ITEM_SELECTOR_CLICKED } from '../../../actions/WD/wallActions'

export default function Message() {
  const wall = useSelector((state) => state.wall)

  return (
    <>
      <div className='message-island'>
        <div className='message-top'>
          <font>{wall.message}</font>
        </div>
      </div>
    </>
  )
}
