import React from 'react'
import { Link } from 'react-router-dom'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Sidebar.css'

function Top() {
  return (
    <>
      <Link to='/' className='py-0'>
        <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
        <font>Vissza a főloldalra</font>
      </Link>
      <br />
    </>
  )
}
export default Top
