import { WallManager } from './WallManager/WallManager'
import { ConnectionHelper } from './WallManager/ConnectionHelper'
import { GridHelper } from './WallManager/GridHelper'
import { FabricHelper } from './WallManager/FabricHelper'
import { CartHelper } from './WallManager/CartHelper'
import { ClusterHelper } from './WallManager/ClusterHelper'

const canvas = require('./canvas.json')
const itemsData = require('./itemsData.js')
const cartData = require('./cartData.js')

const shelvesLeft = ['10PVB', '10PTB', '10PB', '10KO2B', '10KO3B']
const shelvesRight = ['10PVJ', '10PTJ', '10PJ', '10KO2J', '10KO3J']
const shelves40 = ['10P40', '10P40OJ', '10P40OB']
const shelves80 = ['10P80', '10P80OJ', '10P80OB', '10H1K']
const shelvesAll = ['10T', '10KL'].concat(
  shelvesLeft,
  shelvesRight,
  shelves40,
  shelves80
)
const fabrics40H = ['10V40W_H', '10V40B_H']
const fabrics40V = ['10V40W_VB', '10V40W_VJ', '10V40B_VB', '10V40B_VJ']
const fabrics50Down = ['10V50W_DOWN', '10V50B_DOWN']
const fabrics50Up = ['10V50W_UP', '10V50B_UP']
const fabrics50 = fabrics50Down.concat(fabrics50Up)
const fabrics80H = ['10V80W_H', '10V80B_H', '10H0K']
const fabrics80V = ['10V80W_VB', '10V80W_VJ', '10V80B_VB', '10V80B_VJ']
const fabricsAll = fabrics40H.concat(
  fabrics50,
  fabrics40V,
  fabrics80H,
  fabrics80V
)

const initialState = {
  canvas: canvas,
  items: [],
  selectedItem: '10VW',
  shifts: [],
  fabric: {
    ongoing: false,
    startPoint: {
      pos: {},
      isAvailable: false,
    },
    endPoints: [],
  },
  floatingWallItem: {},
  cart: cartData.cart,
  itemsData: itemsData.items,
  wallSize: { x: 0, y: 0 },
  message:
    'Kattints a falon egy kiemelt pontra, és el is kezdheted rajzolni a vásznat.',
  idVisible: false,
  clusterIdVisible: false,
  slotsVisible: false,
  orderVisible: false,
  gridVisible: true,
}

export default function wallReducer(state = initialState, action) {
  function AddItemsMain(itemToAdd) {
    let itemsToAdd = WallManager.CollectItemsToAdd(state, itemToAdd)

    itemsToAdd.forEach(AddItem)
    if (itemsToAdd.length > 0) {
      state.message = 'Sikeresen hozzáadtad az elemet a faladhoz.'
    }
    if (itemsToAdd.length === 0) {
      state.message =
        'Ez a hely már foglalt! Ha elérhető a hely, akkor automatikusan megjelenik az elem előnézetben a falon.'
    }
    UpdateKonzolsOnWallAfterAdd(itemsToAdd)
    RemoveObsoleteTops(itemsToAdd)

    ConnectionHelper.GetSlotsAndShiftsToUpdateAfterAdd(
      state,
      itemsToAdd
    ).forEach(UpdateItem)
    ConnectionHelper.SwitchVertical80FabricCrossing(state, itemsToAdd)
    ClusterHelper.UpdateOrderIds(state).forEach(UpdateItem)
    UpdateWallSize(state, state.items)
    // ShiftHelper.UpdateShiftsAfterAdd(state, itemsToAdd).forEach(UpdateItem)
  }

  function DeleteItemMain(itemToDelete) {
    //Delete item

    DeleteItem(itemToDelete)

    //Update konzols connections
    let konzolsToUpdate = WallManager.GetKonzolsToUpdateAfterRemovedConnection(
      state,
      [itemToDelete]
    )
    konzolsToUpdate.forEach(UpdateItem)

    //Remove parentless konzols
    WallManager.GetRemovableKonzolsAndTops(state).forEach(DeleteItem)

    //Add tops to konzols if they still have connection
    AddTopsAfterShelfRemoval(itemToDelete, konzolsToUpdate)
    UpdateSlotsAfterFabricRemoval(itemToDelete)
    CartHelper.UpdateCart(state)

    ClusterHelper.UpdateItemsAfterDelete(state, itemToDelete)
    UpdateWallSize(state)
    state.message = 'Sikeresen kitöröltél egy elemet.'
  }
  function UpdateWallSize(state, items) {
    let highestPos = WallManager.GetWallItemHighest(state, items)
    let leftestPos = WallManager.GetWallItemLeftest(state, items)
    let rightestPos = WallManager.GetWallItemRightest(state, items)
    let lowestPos = WallManager.GetWallItemLowest(state, items)

    //update state.wallsize
    state.wallSize.x = (rightestPos - leftestPos) / 10 + 5
    state.wallSize.y = ((lowestPos - highestPos) / 10 / 348) * 350 + 5
  }
  function DeleteItem(itemToDelete) {
    state.items = state.items.filter((item) => item.id !== itemToDelete.id)
    CartHelper.UpdateCart(state)
  }

  function DeleteSelectedItem() {
    let selectedItem = GetSelectedItem()
    if (typeof selectedItem !== 'undefined') DeleteItemMain(selectedItem)
    UpdateWallSize(state, state.items)
  }

  function UpdateItem(itemToUpdate) {
    // if (typeof itemToUpdate !== 'undefined') {
    state.items.map((item) => {
      var updatedItem = Object.assign({}, item)
      if (updatedItem.id === itemToUpdate.id) {
        updatedItem = { ...itemToUpdate }
      }
      return updatedItem
    })
    CartHelper.UpdateCart(state)
    // }
  }

  function AddItem(itemToAdd) {
    state.items.push({ ...itemToAdd, selected: false })
    CartHelper.UpdateCart(state)
  }

  function GetSelectedItem() {
    let selectedItems = state.items.filter(function (item) {
      return item.selected === true
    })
    if (selectedItems.length > 0) {

      return selectedItems[0]
    }
  }

  function DrawFabric() {
    if (state.fabric.ongoing === false) {
      state.fabric.ongoing = true
      FabricHelper.AddStartPoint(state, state.canvas.cursor)
      FabricHelper.AddEndPoints(state, state.canvas.cursor)
      if (FabricHelper.IsFabricStartAvailable(state, state.canvas.cursor))
        state.message =
          'Válaszd ki, hogy hol végződjön a vászon. Zöld pont jelöli az elérhető végeket.'
      else {
        state.message = 'Erről a pontról kiindulva nincs elérhető vászon.'
        FabricHelper.CleanupFabric(state)
      }
    } else {
      // Check clicked point is green
      if (FabricHelper.IsEndAvailable(state)) {
        // if green -> add Fabric
        let fabric = FabricHelper.DetermineFabricToAdd(state)
        AddItemsMain(fabric)
        FabricHelper.CleanupFabric(state)
        state.message = 'Sikeresen hozzáadtad a vásznat a faladhoz.'
      } else {
        // if not green -> state.fabric.ongoing = false
        FabricHelper.CleanupFabric(state)
        state.message =
          'Sajnos ez a hely már foglalt. Nem kerülhet ide a vászon.'
      }
    }
  }

  function UpdateKonzolsOnWallAfterAdd(addedItems) {
    ConnectionHelper.GetKonzolsOnWallToUpdateAfterAdd(
      state,
      addedItems
    ).forEach(UpdateItem)
  }

  function UpdateKonzolsAfterTopOrShelfRemoval(removedItems) {
    WallManager.GetKonzolsToUpdateAfterRemovedConnection(
      state,
      removedItems
    ).forEach(UpdateItem)
  }

  function UpdateNewShelfSlotsAfterRemovedTops(removedTops, addedItems) {
    addedItems.forEach((addedItem) => {
      if (addedItem.hasOwnProperty('slots') && addedItem.slots.length > 0) {
        addedItem.slots.map((addedItemSlot) => {
          let topInPos = removedTops.filter(
            (top) =>
              top.slots[0].pos.x === addedItemSlot.pos.x &&
              top.slots[0].pos.y === addedItemSlot.pos.y
          )[0]
          if (typeof topInPos !== 'undefined') {
            let copyTopSlotIsFree = topInPos.slots.filter(
              (slot) => slot.direction === addedItemSlot.direction
            )[0].free

            addedItemSlot.free = copyTopSlotIsFree
          }
        })
      }
    })
    addedItems.forEach(UpdateItem)
  }

  function UpdateNewTopSlotsAfterRemovedShelf(removedShelf, addedTops) {
    addedTops.map((addedTop) => {
      if (
        removedShelf.hasOwnProperty('slots') &&
        removedShelf.slots.length > 0
      ) {
        removedShelf.slots.map((removedSlot) => {
          if (
            addedTop.slots[0].pos.x === removedSlot.pos.x &&
            addedTop.slots[0].pos.y === removedSlot.pos.y
          ) {

            addedTop.slots.map((slot) => {
              if (slot.direction === removedSlot.direction) {
                slot.free = removedSlot.free
              }
            })
          }
        })
      }
    })
    addedTops.forEach(UpdateItem)
  }

  function RemoveObsoleteTops(addedItems) {
    let removableTops = WallManager.GetRemovableTopsAfterAdd(state, addedItems)
    removableTops.forEach(DeleteItem)
    UpdateKonzolsAfterTopOrShelfRemoval(removableTops)
    UpdateNewShelfSlotsAfterRemovedTops(removableTops, addedItems)
  }

  function SetSelectedOnItem(id, isSelected) {
    let selectedResult = false
    state.items = state.items.map((item) => {
      var itemToSelect = Object.assign({}, item)
      if (
        itemToSelect.id === id &&
        itemToSelect.type !== '10K' &&
        itemToSelect.type !== '10T'
      ) {
        itemToSelect.selected = isSelected
        selectedResult = true
      }
      return itemToSelect
    })
    return selectedResult
  }

  function DeselectAll() {
    state.items.forEach((item) => (item.selected = false))
  }

  function CreateItemToAdd(e) {
    let type = state.selectedItem
    // let gridPoint = CalcGridPointToSnap(e)
    if (
      state.selectedItem === '10PV' ||
      state.selectedItem === '10PL' ||
      state.selectedItem === '10PT' ||
      state.selectedItem === '10KO2' ||
      state.selectedItem === '10KO3'
    ) {
      if (state.canvas.cursor.side === 'left') type = state.selectedItem + 'B'
      else type = state.selectedItem + 'J'
    }

    return {
      pos: state.canvas.cursor,
      type: type,
    }
  }

  function AddTopsAfterShelfRemoval(removedShelf, affectedKonzols) {
    if (shelvesAll.includes(removedShelf.type)) {
      affectedKonzols.forEach((konzol) => {
        if (konzol.connections.length > 0) {
          let topsToInsert = WallManager.CreateTopToInsertWithoutCheck(state, {
            type: '10T',
            pos: konzol.pos,
          })
          topsToInsert = WallManager.AddSlotsDataToItems(state, topsToInsert)
          topsToInsert.forEach(AddItem)

          UpdateNewTopSlotsAfterRemovedShelf(removedShelf, topsToInsert)
        }
      })
    }
  }

  function UpdateSlotsAfterFabricRemoval(removedItem) {
    if (fabricsAll.includes(removedItem.type)) {
      ConnectionHelper.GetShelvesOnWallToUpdateSlotsAfterFabricDelete(
        state,
        removedItem
      ).forEach(UpdateItem)
    }
  }

  function CalcCursor(e) {
    var cursor = {
      x:
        e.evt.layerX / state.canvas.scale.x -
        state.canvas.x / state.canvas.scale.x,
      y:
        e.evt.layerY / state.canvas.scale.y -
        state.canvas.y / state.canvas.scale.y,
    }
    var cursorRelative = {
      x: e.evt.layerX,
      y: e.evt.layerY,
    }
    var cursorSnapped = GridHelper.GetSnappedCursor(state, cursor)
    var cursorSide = GridHelper.GetCursorSide(state, cursor, cursorSnapped)
    // if ongoing fabric && shift in start pos && cursor + shift match endpoint
    // then endpoint with shift to cursor

    return {
      ...cursorSnapped,
      side: cursorSide,
      relative: cursorRelative,
    }
  }

  function CalcCursorAfterZoom(newCanvas) {
    var cursor = {
      x:
        state.canvas.cursor.relative.x / newCanvas.scale.x -
        newCanvas.x / newCanvas.scale.x,
      y:
        state.canvas.cursor.relative.y / newCanvas.scale.y -
        newCanvas.y / newCanvas.scale.y,
    }
    var cursorSnapped = GridHelper.GetSnappedCursor(state, cursor)
    var cursorSide = GridHelper.GetCursorSide(state, cursor, cursorSnapped)

    return {
      ...cursorSnapped,
      side: cursorSide,
    }
  }

  function GetPickedItemData() {
    if (state.selectedItem === 'pointer' || state.selectedItem.includes('10V'))
      return {}
    else
      return state.itemsData.filter(function (wallItem) {
        if (
          state.selectedItem === '10PV' ||
          state.selectedItem === '10PL' ||
          state.selectedItem === '10PT' ||
          state.selectedItem === '10KO2' ||
          state.selectedItem === '10KO3'
        ) {
          if (state.canvas.cursor.side === 'left')
            return wallItem.type === state.selectedItem + 'B'
          else if (state.canvas.cursor.side === 'right')
            return wallItem.type === state.selectedItem + 'J'
        } else return wallItem.type === state.selectedItem
      })[0]
  }

  function GetFloatingWallItem() {
    var data = GetPickedItemData()

    let floatingWallItem = {
      x: state.canvas.cursor.x,
      y: state.canvas.cursor.y,
      side:
        typeof state.canvas.cursor.side === 'undefined'
          ? ''
          : state.canvas.cursor.side,
      id: -1,
      gridStepX: state.canvas.gridStep.x,
      gridStepY: state.canvas.gridStep.y,
    }
    if (state.canvas.view === '2D' && typeof data !== 'undefined')
      floatingWallItem = { ...floatingWallItem, ...data.view2D }
    else if (typeof data !== 'undefined')
      floatingWallItem = { ...floatingWallItem, ...data.view3D }

    // Check Availability
    if (typeof data !== 'undefined' && data.hasOwnProperty('type')) {
      let isAvailable = WallManager.IsPosAvailableMain(
        state,
        { x: floatingWallItem.x, y: floatingWallItem.y },
        data.type
      )
      floatingWallItem = { ...floatingWallItem, isAvailable: isAvailable }
    }

    return floatingWallItem
  }

  function Zoom(delta) {
    let newCanvas
    let scaleBy = 0.95


    let newScale =
      delta > 0
        ? state.canvas.scale.x * scaleBy
        : state.canvas.scale.y / scaleBy

    if (newScale > 0.36 && newScale < 0.668) {
      if (
        typeof state.canvas.cursor !== 'undefined' &&
        typeof state.canvas.cursor.relative !== 'undefined'
      ) {
        newCanvas = {
          scale: { x: newScale, y: newScale },
          x:
            -(
              state.canvas.cursor.x -
              state.canvas.cursor.relative.x / newScale
            ) * newScale,
          y:
            -(
              state.canvas.cursor.y -
              state.canvas.cursor.relative.y / newScale
            ) * newScale,
          zoom: Math.round(((newScale - 0.36) / 0.3) * 10) * 10,
        }
        let newCursor = CalcCursorAfterZoom(newCanvas)
        newCanvas = {
          ...newCanvas,
          cursor: { ...state.canvas.cursor, ...newCursor },
        }
      }
    }
    return newCanvas
  }

  function GetMessageForSelectedItem(type) {
    let selectedItemData = state.cart.items.filter(
      (item) => item.type === type
    )[0]
    let displayName = ''
    if (typeof selectedItemData !== 'undefined') {
      displayName = selectedItemData.displayName
    } else if (type === '10PT') {
      displayName = 'Etető'
    } else if (type === '10PV') {
      displayName = 'Virágtartó'
    } else if (type === '10VB') {
      displayName = 'Vászon sötét'
    } else if (type === '10VW') {
      displayName = 'Vászon világos'
    } else if (type === '10PL') {
      displayName = 'Polc Lépés'
    }
    return `Kiválasztottad a '${displayName}' elemet. Kattints a falon egy elérhető pontra, hogy hozzáadd az elemet.`
  }

  switch (action.type) {
    case 'SELECT_WALLITEM_TOOL':
      state.message = GetMessageForSelectedItem(action.payload)
      return {
        ...state,
        selectedItem: action.payload,
      }
    case 'UPDATE_CANVAS_VIEW':
      if (action.payload === '3D') {
        state.message =
          '3D nézetre váltottál. Ha vissza szeretnél menni 2D nézetre, kattints a 2D gombra a bal oldali menüben.'
      } else if (action.payload === '2D') {
        state.message =
          '2D nézetre váltottál. Ha vissza szeretnél menni 3D nézetre, kattints a 3D gombra a bal oldali menüben.'
      }
      return {
        ...state,
        canvas: {
          ...state.canvas,
          view: action.payload,
        },
      }
    case 'UPDATE_SCALE_BASED_ON_WALL_SIZE':
      let x = (state.windowSize.width - 250) / action.payload.width
      let y = (state.windowSize.height - 50) / action.payload.height
      let newScale = x < y ? x : y

      return {
        ...state,
        canvas: {
          ...state.canvas,
          scale: { x: newScale, y: newScale },
        },
      }
    case 'CANVAS_MOUSE_MOVED':
      let cursor = CalcCursor(action.payload)
      let floatingWallItem = GetFloatingWallItem()
      return {
        ...state,
        canvas: {
          ...state.canvas,
          cursor: cursor,
        },
        floatingWallItem: floatingWallItem,
      }
    case 'SET_CANVAS_BACKGROUND_COLOR':
      return {
        ...state,
        canvas: {
          ...state.canvas,
          backgroundColor: action.payload,
        },
      }
    case 'KEY_DOWN':
      let selectedItem = state.selectedItem
      switch (action.payload) {
        case '0':
          selectedItem = 'pointer'
          break
        case '1':
          state.message =
            'Kiválasztottad a vásznat, ha hozzáadnád a faladhoz, kattints a falon egy pontra, és megkezdheted a rajzolást.'
          selectedItem = '10VW'
          break
        case 'esc':
          state.message = ''
          selectedItem = '10VW'
          break
        case '2':
          state.message =
            'Kiválasztottad a polcot, ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = '10P40'
          break
        case '3':
          state.message =
            'Kiválasztottad a kaparó oszlopot, ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = '10KO2'
          break
        case '4':
          state.message =
            'Kiválasztottad a hidat, ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = '10H1K'
          break
        case '5':
          state.message =
            'Kiválasztottad az etetőt, ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = '10PT'
          break
        case '6':
          state.message =
            'Kiválasztottad a virágtartót, ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = '10PV'
          break
        case '7':
          state.message =
            'Kiválasztottad a szizál lépést, ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = '10KL'
          break
        case '8':
          state.message =
            'Kiválasztottad a párnát. A párna alá szükség van egy polcra is. Ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = '10PP40B'
          break
        case '9':
          state.message =
            'Kiválasztottad a cicát, ha hozzáadnád a faladhoz, kattints a falon egy elérhető pontra.'
          selectedItem = 'cat'
          break
        case 'S':
          state.slotsVisible = !state.slotsVisible
          break
        case 'C':
          state.clusterIdVisible = !state.clusterIdVisible
          break
        case 'I':
          state.idVisible = !state.idVisible
          break
        case 'O':
          state.orderVisible = !state.orderVisible
          break
        case 'G':
          state.gridVisible = !state.gridVisible
          break
        case 'del':
          DeleteSelectedItem()

          break
        case 'left':
          if (state.canvas.x / state.canvas.scale.x <= -1400) {
            state.message =
              'Balra toltad a fal nézetét. Bármikor visszaállíthatod az alapnézetet a bal alsó menüben az Alap Nézet gombbal.'
            state.canvas.x += 100 * state.canvas.scale.x
          }
          break
        case 'right':
          if (state.canvas.x / state.canvas.scale.x >= -3000) {
            state.message =
              'Jobbra toltad a fal nézetét. Bármikor visszaállíthatod az alapnézetet a bal alsó menüben az Alap Nézet gombbal.'
            state.canvas.x -= 100 * state.canvas.scale.x
          }
          break
        case 'up':
          if (state.canvas.y / state.canvas.scale.y <= -1600) {
            state.message =
              'Felfelé toltad a fal nézetét. Bármikor visszaállíthatod az alapnézetet a bal alsó menüben az Alap Nézet gombbal.'
            state.canvas.y += 100 * state.canvas.scale.y
          }
          break
        case 'down':
          if (state.canvas.y / state.canvas.scale.y >= -3000) {
            state.message =
              'Lefelé toltad a fal nézetét. Bármikor visszaállíthatod az alapnézetet a bal alsó menüben az Alap Nézet gombbal.'
            state.canvas.y -= 100 * state.canvas.scale.y
          }
          break
        default:
          break
      }
      return {
        ...state,
        selectedItem: selectedItem,
      }
    case 'CANVAS_DEFAULT_ZOOM': {
      let newScale = 0.4
      let newStage = {
        scaleX: newScale,
        scaleY: newScale,
        zoom: Math.round(((newScale - 0.36) / 0.3) * 10) * 10,
      }

      state.canvas.scale.x = newStage.scaleX
      state.canvas.scale.y = newStage.scaleY
      state.canvas.x = -1000
      state.canvas.y = -1000
      state.canvas.zoom = newStage.zoom

      state.message = 'Visszaállítottad az alapnézetet.'

      return {
        ...state,
      }
    }
    case 'CANVAS_ZOOM_OUT': {
      let newZoom = Zoom(1)
      if (typeof newZoom !== 'undefined')
        state.canvas = { ...canvas, ...newZoom }
      return {
        ...state,
      }
    }
    case 'CANVAS_ZOOM_IN': {
      let newZoom = Zoom(-1)
      if (typeof newZoom !== 'undefined')
        state.canvas = { ...canvas, ...newZoom }
      return {
        ...state,
      }
    }
    case 'MOUSE_WHEEL_CHANGED': {
      let newZoom = Zoom(action.payload.evt.deltaY)
      if (typeof newZoom !== 'undefined')
        state.canvas = { ...canvas, ...newZoom }
      return {
        ...state,
      }
    }
    case 'WALL_LOADER_SELECTED':
      FabricHelper.CleanupFabric(state)
      CartHelper.Cleanup(state)
      state.items = action.payload
      state.message = 'Sikeresen betöltöttél egy előre elkészített falat.'
      CartHelper.UpdateCart(state)
      UpdateWallSize(state, state.items)
      return {
        ...state,
      }

    case 'CANVAS_CLICKED': {

      const clickedId = action.payload.target.id()
      // Deselect everything
      DeselectAll()
      // Select item if clicked
      if (typeof clickedId === 'number' && clickedId !== -1) {
        FabricHelper.CleanupFabric(state)
        let selected = SetSelectedOnItem(clickedId, true)
        if (selected) state.message = 'Kiválasztottál egy elemet szerkesztésre.'
      }
      // Add new item
      else if (state.selectedItem !== 'select') {
        // If not fabric
        if (!state.selectedItem.startsWith('10V')) {
          FabricHelper.CleanupFabric(state)
          AddItemsMain(CreateItemToAdd(action.payload))
        }
        // If fabric
        else {
          DrawFabric()
        }
      }
      return { ...state }
    }
    case 'DELETE_WALLITEM': {
      DeleteItemMain(action.payload)
      return { ...state }
    }
    case 'UPDATE_WALLITEM': {
      UpdateItem(action.payload)
      return { ...state }
    }
    case 'SWITCH_COLOR_WALLITEM': {
      let switchedItem = WallManager.SwitchColorWallItem(
        state,
        GetSelectedItem()
      )
      UpdateItem(switchedItem)
      state.message = 'Sikeresen színt váltottál a kijelölt elemen.'
      return { ...state }
    }
    case 'SWITCH_SIDE_WALLITEM': {
      let switchedItem = WallManager.SwitchSideWallItem(
        state,
        GetSelectedItem()
      )
      UpdateItem(switchedItem)
      state.message = 'Sikeresen oldalt váltottál a kijelölt elemen.'
      return { ...state }
    }
    case 'SELECT_WALLITEM':
  
      state.items.map((item) => {
        var itemToSelect = Object.assign({}, item)
        if (itemToSelect.id === action.payload && itemToSelect.type !== '10K') {
          itemToSelect.selected = true
        }
        return itemToSelect
      })
      return { ...state }
    case 'DESELECT_WALLITEMS':
      DeselectAll()
      return { ...state }
    case 'CLEAR_WALLITEM_LIST':
      FabricHelper.CleanupFabric(state)
      CartHelper.Cleanup(state)
      state.message =
        'Sikeresen letöröltél minden elemet a falról. Válaszd ki a kezdő elemet, és kezdd meg a rajzolást.'
      return {
        ...state,
        items: [],
        shifts: [],
        wallSize: { x: 0, y: 0 },
      }
    case 'CART_DETAILED_VIEW_CLICKED':
      state.message =
        'Megnyitottad a részletes kosár nézetet. A kosár melletti Egyszerű Nézet gombra kattintva újra összecsukhatod.'
      state.cart.view = 'detailed'
      return { ...state }
    case 'CART_SIMPLE_VIEW_CLICKED':
      state.message =
        'Visszaállítottad az egyszerű kosár nézetet. A kosár melletti Részletes Nézet gombra kattintva újra kinyithatod a részleteket.'
      state.cart.view = 'simple'
      return { ...state }

    default:
      return state
  }
}
