import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { KEY_DOWN } from '../actions/WD/wallActions'
import '../WallDesigner.css'
import ItemMenu from '../components/WD/ItemMenu/ItemMenu'
import Message from '../components/WD/Message/Message'
import Canvas from '../components/WD/Canvas/Canvas'
import Sidebar from '../components/WD/Sidebar'

export default function WallDesignerScreen() {
  const dispatch = useDispatch()

  useEffect(() => {
    function handleKeyDown(e) {
      let key = ''
      switch (e.keyCode) {
        case 46:
          key = 'del'
          break
        case 27:
          key = 'esc'
          break
        case 37:
          key = 'left'
          break
        case 38:
          key = 'up'
          break
        case 39:
          key = 'right'
          break
        case 40:
          key = 'down'
          break
        default:
          key = String.fromCharCode(e.keyCode)
          break
      }
      dispatch(KEY_DOWN(key))
    }

    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      <div class='grid-container bg-secondary '>
        <Canvas />
        <Sidebar />
        <ItemMenu />
        {/* <Message /> */}
      </div>
    </>
  )
}
