import React, { useState } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveBillingAddress } from '../actions/cartActions'

const BillingScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { billingAddress } = cart
  const { shippingAddress } = cart

  const [name, setName] = useState(shippingAddress.name)
  const [address, setAddress] = useState(billingAddress.address)
  const [city, setCity] = useState(billingAddress.city)
  const [postalCode, setPostalCode] = useState(billingAddress.postalCode)
  const [country, setCountry] = useState(billingAddress.country)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [email, setEmail] = useState(shippingAddress.email)

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    shippingAddress.sameBillingAddress
      ? dispatch(saveBillingAddress({ ...shippingAddress }))
      : dispatch(
          saveBillingAddress({
            name,
            phone,
            email,
            address,
            city,
            postalCode,
            country,
          })
        )
    history.push('/payment')
  }

  return (
    <div className='page-container'>
      <Container className='header-margin py-3 col-md-10'>
        <FormContainer>
          <CheckoutSteps step1 step2 step3 />
          <h1>Számlázási adatok</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Név</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Gipsz Jakab'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='phone'>
              <Form.Label>Telefonszám</Form.Label>
              <Form.Control
                type='text'
                placeholder='+36301234567'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='email'>
              <Form.Label>Telefonszám</Form.Label>
              <Form.Control
                type='text'
                placeholder='pelda@gmail.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='address'>
              <Form.Label>Cím</Form.Label>
              <Form.Control
                type='text'
                placeholder='Utca, házszám, emelet, ajtó'
                value={
                  shippingAddress.sameBillingAddress
                    ? shippingAddress.address
                    : address
                }
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='city'>
              <Form.Label>Város</Form.Label>
              <Form.Control
                type='text'
                placeholder='Város'
                value={
                  shippingAddress.sameBillingAddress
                    ? shippingAddress.city
                    : city
                }
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='postalCode'>
              <Form.Label>Irányítószám</Form.Label>
              <Form.Control
                type='text'
                placeholder='Irányítószám'
                value={
                  shippingAddress.sameBillingAddress
                    ? shippingAddress.postalCode
                    : postalCode
                }
                onChange={(e) => setPostalCode(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='country'>
              <Form.Label>Ország</Form.Label>
              <Form.Control
                type='text'
                placeholder='Ország'
                value={
                  shippingAddress.sameBillingAddress
                    ? shippingAddress.country
                    : country
                }
                onChange={(e) => setCountry(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type='submit' variant='primary'>
              Tovább
            </Button>
          </Form>
        </FormContainer>
      </Container>
    </div>
  )
}

export default BillingScreen
