import React from 'react'
import { Layer, Circle, Line } from 'react-konva'
import { useSelector } from 'react-redux'
import '../../../WallDesigner.css'

function FabricDraw() {
  const wall = useSelector((state) => state.wall)
  function IsCursorOnEnpoint() {
    let result = false
    wall.fabric.endPoints.forEach((end) => {
      if (
        end.pos.x === wall.canvas.cursor.x &&
        end.pos.y === wall.canvas.cursor.y
      ) {
        result = true
      }
    })
    return result
  }
  function IsFabricAvailable(cursor) {
    let endPoint = wall.fabric.endPoints.filter((end) => {
      if (end.pos.x === cursor.x && end.pos.y === cursor.y) return true
    })[0]
    if (typeof endPoint !== 'undefined' && endPoint.isAvailable) return true
    return false
  }
  return (
    <>
      <Layer>
        {wall.fabric.ongoing &&
          wall.fabric.endPoints.map((end) => {
            return (
              <>
                <Circle
                  radius={20}
                  x={wall.fabric.startPoint.pos.x}
                  y={wall.fabric.startPoint.pos.y}
                  fill={
                    IsFabricAvailable(wall.canvas.cursor)
                      ? '#5BB069'
                      : '#F28482'
                  }
                />
                <Circle
                  radius={15}
                  x={wall.fabric.startPoint.pos.x}
                  y={wall.fabric.startPoint.pos.y}
                  fill='#dddddd'
                />
                <Circle
                  radius={20}
                  x={end.pos.x}
                  y={end.pos.y}
                  fill={end.isAvailable ? '#5BB069' : '#F28482'}
                />
                <Circle
                  radius={15}
                  x={end.pos.x}
                  y={end.pos.y}
                  fill='#dddddd'
                />
              </>
            )
          })}
      </Layer>
      <Layer>
        {wall.fabric.ongoing && IsCursorOnEnpoint() && (
          <Line
            strokeWidth={5}
            stroke={
              IsFabricAvailable(wall.canvas.cursor) ? '#5BB069' : '#F28482'
            }
            points={[
              wall.fabric.startPoint.pos.x,
              wall.fabric.startPoint.pos.y,
              wall.canvas.cursor.x,
              wall.canvas.cursor.y,
            ]}
          />
        )}
      </Layer>
    </>
  )
}

export default FabricDraw
