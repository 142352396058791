import { Route, useLocation, Switch } from 'react-router-dom'
import { useEffect } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import NewsBar from './components/NewsBar'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import PostScreen from './screens/PostScreen'
import BlogScreen from './screens/BlogScreen'
import CartScreen from './screens/CartScreen'
import InstallScreen from './screens/InstallScreen'
import AszfScreen from './screens/AszfScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import BillingScreen from './screens/BillingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import OffersScreen from './screens/OffersScreen'
import MaterialsScreen from './screens/MaterialsScreen'
import OrderListScreen from './screens/OrderListScreen'
import ContactScreen from './screens/ContactScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import WebshopScreen from './screens/WebshopScreen'
import WebshopPartScreen from './screens/WebshopPartScreen'
import WallDesignerScreen from './screens/WallDesignerScreen'
import GalleryScreen from './screens/GalleryScreen'
import PartnersScreen from './screens/PartnersScreen'
import DataProtectionScreen from './screens/DataProtectionScreen'
import CatDreamWallScreen from './screens/CatDreamWallScreen'
import NotFoundScreen from './screens/NotFoundScreen'
import NewsletterSignupPopup from './components/NewsletterSignupPopup '

function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {/* <NewsletterSignupPopup /> */}
      {location.pathname !== '/designer' && <Header />}
      <NewsBar />
      <main>
        <Switch>
          <Route path='/search/:keyword' component={WebshopScreen} />
          <Route path='/' component={HomeScreen} exact />
          <Route path='/login' component={LoginScreen} />
          <Route path='/dreamcatwall' component={CatDreamWallScreen} />
          <Route path='/shipping' component={ShippingScreen} />
          <Route path='/billing' component={BillingScreen} />
          <Route path='/payment' component={PaymentScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/install' component={InstallScreen} />
          <Route path='/partners' component={PartnersScreen} />
          <Route path='/offers' component={OffersScreen} />
          <Route path='/materials' component={MaterialsScreen} />
          <Route path='/order/:id' component={OrderScreen} />
          <Route path='/admin/orderlist' component={OrderListScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/webshop' component={WebshopScreen} />
          <Route path='/webshoppart' component={WebshopPartScreen} />
          <Route path='/gallery' component={GalleryScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/contact' component={ContactScreen} />
          <Route path='/aszf' component={AszfScreen} />
          <Route path='/dataprotection' component={DataProtectionScreen} />
          <Route path='/designer' component={WallDesignerScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/productlist' component={ProductListScreen} />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/post/:id' component={PostScreen} />
          <Route path='/blog' component={BlogScreen} />
          <Route component={NotFoundScreen} />
        </Switch>
      </main>
      {location.pathname !== '/designer' && <Footer />}
    </>
  )
}

export default App
