import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Container,
  Row,
  Col,
  Image,
  ListGroup,
  Button,
} from 'react-bootstrap'
import '../bootstrap.min_lux.css'
import ReactMarkdown from 'react-markdown';
import { Link } from "react-router-dom";
import { listPostDetails } from '../actions/postActions'
import { Helmet } from 'react-helmet-async'
import rehypeRaw from 'rehype-raw';

const PostScreen = ({ history, match }) => {

  const ImageRenderer = ({ src, alt, className }) => {
    return <img src={src} alt={alt} className={className} />;
  };

  const dispatch = useDispatch()

  const postDetails = useSelector((state) => state.postDetails)
  const { post } = postDetails
  useEffect(() => {
    dispatch(listPostDetails(match.params.id))
  }, [dispatch, match])

  const stripIndent = (str) => {
    try {
      return str.split("\n").map(l => l.trim()).join('\n')
    } catch {
      return str
    }
  }

  const postContent = stripIndent(post.description)

  const canonicalUrl = `/post/${post.id}`;

  return (
    <>
      <Helmet>
        <title>Cathletic Macskabútor - Tippek | Trükkök | Tanácsok</title>
        <meta name="description"
          content="Ismeretterjesztő blogunk, rengeteg izgalmas és hasznos információval. Menhelyek, macskamentés, állatorvos válaszol, környezetgazdagítás és még sokan mások." />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className='page-container'>
        <Container className='product-container header-margin py-3 mb-3 product-container'>
          <LinkContainer to='/blog'>
            <Button className='mb-3' variant='outline-primary' size='sm'>
              Vissza a cikkekhez
            </Button>
          </LinkContainer>
          <Row className=' mb-3'>
            <Col md={12}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h1 className=''>{post.name}</h1>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Image className='rounded' src={post.image} alt='Cathletic Macskafal' fluid />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  {/* <ReactMarkdown escapeHtml={false} >{postContent}</ReactMarkdown> */}

                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      img: (props) => <ImageRenderer {...props} className="blog-image" />,
                    }}
                  >
                    {postContent}
                  </ReactMarkdown>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>Ha szeretnéd lakásodat macskaparadicsommá változtatni, akkor nézz körül fali macskabútoraink, mászófalaink között. </p>
              <Link as={Link} to='/webshop'>
                <Button className='mb-3' variant='success' size=''>
                  Érdekelnek a cica mászófalak
                </Button>
              </Link>
              <p>Ha bármi kérdésed merülne fel, akkor keress minket bátran elérhetőségeinket, ahogy tudunk, válaszolunk.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default PostScreen
