import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import Rating from './Rating'

const Product = ({ product }) => {
  const history = useHistory()
  const addToCartHandler = () => {
    history.push(`/cart/${product.id}?qty=1`)
  }
  return (
    <Card className='my-3 p-3 rounded'>
      <Link to={`/product/${product.id}`}>
        <Card.Img
          src={product.images[0]}
          onMouseOver={(e) => (e.currentTarget.src = product.images[1])}
          onMouseOut={(e) => (e.currentTarget.src = product.images[0])}
          variant='top'
        />
      </Link>
      <Card.Body className='pt-3'>
        <Link to={`/product/${product.id}`}>
          <Card.Title as='h4'>{product.name}</Card.Title>
        </Link>
        <Card.Text as='div' className='pb-2'>
          <Rating
            value={product.reviews.reduce((acc, item) => item.rating + acc, 0) /
              product.reviews.length}
            text={` (${product.reviews.length})`}
          />
        </Card.Text>
        {/* {product.price > product.priceDisc ? (
          <Card.Text>
            Normál ár:{' '}<span className='strike-through'>
              {Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumFractionDigits: 0,
              }).format(product.price)}{' '}</span>
            <p className='big text-red  mb-0'>
              Most:{' '}
              {Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumFractionDigits: 0,
              }).format(product.priceDisc)}{' '}
            </p>
          </Card.Text>
        ) : (
          <Card.Text>
            <p className='big  mb-0'>
              {Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumFractionDigits: 0,
              }).format(product.priceDisc)}{' '}
            </p>
          </Card.Text>
        )} */}

      </Card.Body>
    </Card>
  )
}

export default Product
