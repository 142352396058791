import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'

const MaterialsScreen = ({ match, location }) => {
  return (
    <>
      <Helmet>
        <title>Cathletic Macskabútor Prémium anyagok és színes választék</title>
        <meta name="description"
          content="Prémium anyagainkról olvashatsz. Rengeteg szín közül választhatsz, szabd személyre álomfalad, hogy cicád is élvezze új játszóterér, és az új macskabútor otthonod tökéletes kiegészítője is legyen." />
        <link rel="canonical" href="/materials" />
      </Helmet>
      <div className='page-container'>
        <Container className=''>
          <Row className='align-items-center'>
            <Col className='py-0' md={6}>
              <h1 className='black-chango '>Anyagok bemutatása</h1>

              <h4>Borovi fenyő</h4>
              <p>
                A macskabútor kizárólag tömör fából, borovi fenyőből
                készül. Ez egy sokoldalúan használható, tartós faanyag, ezért
                kifejezetten népszerű a bútoriparban. Drágább ugyan, mint a
                préselt és fóliázott bútor alapanyagok, de cserébe sokkal
                masszívabb és időtállóbb eleme lesz otthonodnak. A természetes
                fenyő illatáról és látványáról nem is beszélve. Úgy gondoljuk,
                ez egy optimális anyagválasztás: szép felület, jó minőség,
                megfizethető áron.
              </p>
              <p>
                Polcaink, macska pihenők és a macskaágy felületkezelése egyesével, kézzel történik. A felületek
                gondos csiszolása, a polcok éleinek, sarkainak gömbölyítése
                után, utolsó lépésként a fa felületét egy speciális védőréteggel
                vonjuk be. Az általunk használt fakezelő anyag csakis
                természetes összetevőkből áll, nem tartalmaz illékony szerves
                anyagokat (VOC) és oldószereket, sem ólmot és kobaltot. Mivel
                így gyermekjátékok kezelésére is alkalmas, semmilyen egészségre
                káros hatása nincsen sem a macskára sem a gazdikra nézve. A
                természetes gyantaolaj beszívódik a fába, ezáltal rugalmas és
                ellenálló lesz a polcok felülete, a természetes
                gyantatartalomnak köszönhetően pedig vízlepergetővé is válik.
                Sem a szennyeződéseket, sem a nedvességet nem szívja magába, így
                a fa esetleges vetemedésétől sem kell tartani.
              </p>
            </Col>
            <Col md={6}>
              <Image
                variant='top'
                className='tool-image pb-5'
                src='images/materials/borovi.webp'
                alt='Borovi Fenyő Macskabútor'
              />
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center'>
            <Col md={6}>
              <Image
                variant='top'
                className='tool-image pb-5'
                src='images/materials/acel.webp'
                alt='Acél Macskabútor'
              />
            </Col>
            <Col className='py-4' md={6}>
              <h4>Rozsdamentes acél</h4>
              <p>
                A fali konzolok, amivel a polcok a falra rögzíthetők
                rozsdamentes acélból készülnek. Ez garantálja, hogy magasabb
                páratartalom vagy akár egy nedvesebb fal esetén sem jelenhet meg
                a fémen rozsda - még akkor sem, ha a falat később áthelyezed
                máshova és közben esetleg megkarcolod a fém felületét. Az
                átlagos minőségű horganyzott vagy festett acélokról ez kevésbé
                mondható el, mivel ott a fémek felülete képes csak a rozsdának
                ellenállni, de egy esetleges karc esetén ez a védelem már nem
                áll fenn. Nálunk ettől nem kell tartanod!
              </p>
              <p>
                Az etető és itató tálak rozsdamentes acélból készülnek, melynek
                köszönhetően rendkívül tartósak. Klasszikus formájuk tökéletesen
                passzol az etető és itató polcelemekbe. Ugyanakkor könnyen
                kivehetőek és elmoshatóak, mosogatógépben is tisztíthatóak.
                Űrtartalmuk: 370 ml.
              </p>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center'>
            <Col className='py-4' md={6}>
              <h4>Vászon</h4>
              <p>
                A vásznaink - macskaágy, macska pihenő és függőágy - mosógépben 30℃-on moshatóak. Mosás előtt a már
                felrögzített macskafalról is könnyedén leveheted az anyagot.
                Ehhez mindössze pár jól hozzáférhető csavart kell eltávolítanod
                a termékhez járó szerszámmal és már mehet is a mosógépbe. Számos
                konurrens cicabútornál gondot okoz a textilek tisztán tartása. A
                vászon rendkívül strapabíró, nem zsugorodik, így biztosan vissza
                tudod tenni az eredeti helyére mosást követően is. Az évek
                múlásával, ha úgy ítéled meg, hogy lecserélnéd bármelyik
                vásznadat, akkor jó hír, hogy ez külön is elérhető lesz
                webshopunkban.
              </p>
              <p>
                A varrás módja úgy lett kifejlesztve, hogy a vászon minden
                oldala először egy visszahajtással megerősítve kerül levarrásra
                - ezzel is védve az anyag széleit a macskák karmaitól. Majd az
                amúgy is vastag vászonanyag dupla rétegben kerül további
                varrásra, hogy igazán tartós legyen a termék.
              </p>
            </Col>
            <Col md={6}>
              <Image
                variant='top'
                className='tool-image pb-5'
                src='images/materials/vaszon.webp'
                alt='Textil Macskabútor'
              />
            </Col>
          </Row>{' '}
          <Row className='align-items-center justify-content-center'>
            <Col md={6}>
              <Image
                variant='top'
                className='tool-image pb-5'
                src='images/materials/szizal.webp'
                alt='Szizál Macskabútor'
              />
            </Col>
            <Col className='py-4' md={6}>
              <h4>Kaparók</h4>
              <p>
                A macska kaparófa és kaparólépés szintén csakis természetes
                anyagokból készül. A merevséget adó kartoncső
                újrahasznosított papírból készül. A kaparófa látványos részét az
                úgynevezett szizál kötél alkotja. Ez a kendernél szebb
                megjelenésű, világosabb színű, természetes anyag, mely
                kiemelkedően strapabíró tulajdonsággal rendelkezik. Mivel az
                átlagos bolti macskabútorok esetén sokszor problémát jelent,
                hogy a kötél az évek során előbb-utóbb elhasználódik és az elem
                nem pótolható, így nálunk kifejezetten fontos szempont volt,
                hogy a későbbiekben is bármikor cserélhető oszlopot kínáljunk.
              </p>
            </Col>
          </Row>{' '}
          <Row className='align-items-center justify-content-center'>
            <Col className='py-4' md={6}>
              <h4>Kaspó</h4>
              <p>
                ECO kaspóink különlegessége, hogy újrahasznosított műanyagból
                készülnek, 40% faanyag hozzáadásával. Az őrlemény szintén
                újrahasznosított, hiszen bútorok és más fatermékek gyártása
                során keletkezik. A kaspókat egy családi vállalkozás gyártja.
                Magas minőség és egyszerű , letisztult, strukturált dizájn
                jellemzi. Tökéletes lehet például cicafű tárolására. Nem csak a
                cicáknak okoz örömöt, hanem a cicafal látványát is feldobja.
                Átmérője: 14 cm.
              </p>
            </Col>
            <Col md={6}>
              <Image
                variant='top'
                className='tool-image pb-5'
                src='images/materials/kaspo.webp'
                alt='Kaspó Macskabútor'
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MaterialsScreen
