import { GridHelper } from './GridHelper'
import { WallManager } from './WallManager'

const whiteFabrics = [
  '10V80W_H',
  // '10V80W_VB',
  // '10V80W_VJ',
  '10V50W_DOWN',
  '10V50W_UP',
  '10V40W_VB',
  '10V40W_VJ',
  '10V40W_H',
]
const blackFabrics = [
  '10V80B_H',
  // '10V80B_VB',
  // '10V80B_VJ',
  '10V50B_DOWN',
  '10V50B_UP',
  '10V40B_VB',
  '10V40B_VJ',
  '10V40B_H',
]

export var FabricHelper = {
  AddStartPoint: function (state, pos) {
    state.fabric.startPoint = { pos: pos, isAvailable: true }
  },
  AddEndPoints: function (state, startPos) {
    this.GetSelectedColorFabricsData(state).forEach((fabric) => {
      let endPos = GridHelper.GetPointInDir(state, startPos, fabric.dimension)
      let isAvailable =
        WallManager.IsPosAvailableMain(state, startPos, fabric.type) &&
        WallManager.IsFreeSlotInPos(state, endPos)
      this.AddEndPoint(state, endPos, isAvailable, fabric.type)

      let oppositeDir = this.CalcOppositeDirection(state, fabric.dimension)
      let oppositeEndPos = GridHelper.GetPointInDir(
        state,
        startPos,
        oppositeDir
      )
      let isOppositeAvailable =
        WallManager.IsPosAvailableMain(state, oppositeEndPos, fabric.type) &&
        WallManager.IsFreeSlotInPos(state, oppositeEndPos)
      this.AddEndPoint(state, oppositeEndPos, isOppositeAvailable, fabric.type)
    })
  },
  AddEndPoint(state, pos, isAvailable, type) {
    if (this.IsEndAddedAlready(state, pos)) {
      let endPointAlready = this.GetEndPointInPos(state, pos)
      if (endPointAlready.isAvailable) return
      else if (isAvailable) {
        state.fabric.endPoints.map((endPoint) => {
          if (endPoint.pos.x === pos.x && endPoint.pos.y == pos.y) {
            endPoint.isAvailable = isAvailable
            endPoint.type = type
          }
        })
      }
    } else {
      state.fabric.endPoints.push({
        pos: pos,
        isAvailable: isAvailable,
        type: type,
      })
    }
  },
  IsEndAddedAlready(state, pos) {
    let addedEndPoint = state.fabric.endPoints.filter(
      (endPoint) => endPoint.pos.x === pos.x && endPoint.pos.y === pos.y
    )[0]
    if (typeof addedEndPoint !== 'undefined') {
      return true
    } else return false
  },
  GetEndPointInPos(state, pos) {
    return state.fabric.endPoints.filter(
      (endPoint) => endPoint.pos.x === pos.x && endPoint.pos.y === pos.y
    )[0]
  },
  GetSelectedColorFabricsData(state) {
    if (state.selectedItem === '10VW')
      return state.itemsData.filter((item) => whiteFabrics.includes(item.type))
    else if (state.selectedItem === '10VB')
      return state.itemsData.filter((item) => blackFabrics.includes(item.type))
    else return []
  },
  CalcOppositeDirection: function (state, direction) {
    return { x: direction.x * -1, y: direction.y * -1 }
  },
  CleanupFabric: function (state) {
    state.fabric.ongoing = false
    state.fabric.endPoints = []
    state.fabric.startPoint = {}
  },
  IsFabricStartAvailable: function (state, startPos) {
    let isFreeSlotInStartPos = WallManager.IsFreeSlotInPos(state, startPos)
    if (!isFreeSlotInStartPos) return false

    let availableEndpoints = state.fabric.endPoints.filter(
      (end) => end.isAvailable === true
    )
    if (availableEndpoints.length === 0) return false
    return true
  },
  IsEndAvailable: function (state) {
    let endPos = state.canvas.cursor

    let isFreeSlotInEndPos = WallManager.IsFreeSlotInPos(state, endPos)
    if (!isFreeSlotInEndPos) return false

    let endPoint = state.fabric.endPoints.filter((end) => {
      if (end.pos.x === endPos.x && end.pos.y === endPos.y) return true
    })[0]
    if (typeof endPoint !== 'undefined' && endPoint.isAvailable) return true
    return false
  },
  DetermineFabricToAdd: function (state) {
    let endPoint = this.GetEndPointInPos(state, state.canvas.cursor)

    // Opposite direction check not vertical
    let startPos =
      state.canvas.cursor.x > state.fabric.startPoint.pos.x
        ? state.fabric.startPoint.pos
        : state.canvas.cursor

    // Opposite direction check vertical
    if (
      endPoint.type.includes('_V') &&
      state.canvas.cursor.y > state.fabric.startPoint.pos.y
    ) {
      startPos = state.fabric.startPoint.pos
    }

    return {
      pos: startPos,
      type: endPoint.type,
    }
  },
}
