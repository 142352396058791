import React from 'react'
import { Stage, Layer, Rect } from 'react-konva'
import {
  useDispatch,
  useSelector,
  ReactReduxContext,
  Provider,
} from 'react-redux'
import '../../../WallDesigner.css'
import WallItem from './WallItem'
import {
  DESELECT_WALLITEMS,
  CANVAS_CLICKED,
  MOUSE_WHEEL_CHANGED,
} from '../../../actions/WD/wallActions'
import { CANVAS_MOUSE_MOVED } from '../../../actions/WD/wallActions'
import CanvasGrid from './CanvasGrid'
import HighlightPoint from './Cursor'
import FabricDraw from './FabricDraw'
import Message from '../Message/Message'
import Order from './Order'

function Canvas() {
  const dispatch = useDispatch()
  const wall = useSelector((state) => state.wall)

  function handleMouseMove(e) {
    dispatch(CANVAS_MOUSE_MOVED(e))
  }

  function handleMouseDown(e) {
    dispatch(DESELECT_WALLITEMS())
  }

  function handleStageClick(e) {
    dispatch(CANVAS_CLICKED(e))
  }

  function handleWheel(e) {
    dispatch(MOUSE_WHEEL_CHANGED(e))
  }

  let konzols = wall.items.filter(function (item) {
    return item.type === '10K'
  })
  let fabricsMinusVJ = wall.items.filter(
    (item) => item.type.startsWith('10V') && item.type !== '10V40W_VJ'
  )
  let fabricVJ = wall.items.filter((item) => item.type === '10V40W_VJ')
  let fabrics = wall.items.filter(function (item) {
    return item.type.startsWith('10V')
  })
  let tops = wall.items.filter(function (item) {
    return item.type !== '10K' && !item.type.startsWith('10V')
  })
  let rightPlant = wall.items.filter(function (item) {
    return item.type === '10PVJ'
  })

  return (
    <>
      <div className='canvas'>
        <ReactReduxContext.Consumer>
          {({ store }) => (
            <Stage
              x={wall.canvas.x}
              y={wall.canvas.y}
              scaleX={wall.canvas.scale.x}
              scaleY={wall.canvas.scale.y}
              width={window.innerWidth - 255}
              height={window.innerHeight - 10}
              style={{
                background: wall.canvas.backgroundColor,
                border: '1px solid grey',
              }}
              onClick={handleStageClick}
              onWheel={handleWheel}
              onContentMousemove={handleMouseMove}
              onMouseDown={handleMouseDown}
            >
              <Provider store={store}>
                <Layer>
                  <Rect x={0} y={0} width={400} height={348} fill='red' />
                  <Rect
                    x={wall.canvas.x}
                    y={wall.canvas.y}
                    width={400}
                    height={348}
                    fill='green'
                  />
                </Layer>
                {wall.gridVisible && <CanvasGrid />}
                <Layer>
                  {konzols.map((item) => {
                    return <WallItem {...item} />
                  })}
                </Layer>
                <Layer>
                  {fabricsMinusVJ.map((item) => {
                    return <WallItem {...item} />
                  })}
                </Layer>
                <Layer>
                  {tops.map((item) => {
                    return <WallItem {...item} />
                  })}
                </Layer>
                <Layer>
                  {rightPlant.map((item) => {
                    return <WallItem {...item} />
                  })}
                </Layer>
                <Layer>
                  {wall.items.map((item) => {
                    return <Order {...item} />
                  })}
                </Layer>
                <Layer>
                  {fabricVJ.map((item) => {
                    return <WallItem {...item} />
                  })}
                </Layer>
                <FabricDraw />
                <HighlightPoint />
              </Provider>
            </Stage>
          )}
        </ReactReduxContext.Consumer>
      </div>
      <Message />
    </>
  )
}

export default Canvas
