import React from 'react'
import { Layer, Line } from 'react-konva'
import { useSelector } from 'react-redux'
import '../../../WallDesigner.css'

const gridWidth = window.innerWidth * 30
const gridHeight = window.innerHeight * 30
const gridLinesX = []
const gridLinesY = []

function CanvasGrid() {
  const wall = useSelector((state) => state.wall)

  function initGrid() {
    for (let i = 0; i < gridHeight / wall.canvas.gridStep.x; i++) {
      gridLinesX.push(
        <Line
          strokeWidth={1}
          stroke={'#ccc'}
          points={[
            i * wall.canvas.gridStep.x,
            0,
            i * wall.canvas.gridStep.x,
            gridHeight,
          ]}
        />
      )

      gridLinesY.push(
        <Line
          strokeWidth={1}
          stroke={'#ccc'}
          points={[
            0,
            i * wall.canvas.gridStep.y,
            gridWidth,
            i * wall.canvas.gridStep.y,
          ]}
        />
      )
    }
  }
  initGrid()
  return (
    <Layer>
      {gridLinesX}
      {gridLinesY}
    </Layer>
  )
}

export default CanvasGrid
