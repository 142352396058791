import React from 'react'
import '../../../WallDesigner.css'
import { Image, Text } from 'react-konva'
import useImage from 'use-image'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_WALLITEM } from '../../../actions/WD/wallActions'
import { WallManager } from '../../../reducers/WD/WallManager/WallManager'
import Slots from './Slots'
import Id from './Id'
import ClusterId from './ClusterId'
import Order from './Order'

function WallItem(props) {
  const dispatch = useDispatch()
  const wall = useSelector((state) => state.wall)

  var selected = props.selected

  var data = wall.itemsData.filter(function (wallItem) {
    return wallItem.type === props.type
  })[0]

  let itemData = {
    ...props,
    x: props.pos.x,
    y: props.pos.y,
    gridStepX: wall.canvas.gridStep.x,
    gridStepY: wall.canvas.gridStep.y,
  }
  if (wall.canvas.view === '2D') {
    itemData.width = data.view2D.width
    itemData.height = data.view2D.height
    itemData.offsetX = data.view2D.offsetX
    itemData.offsetY = data.view2D.offsetY
    itemData.imageSrc = data.view2D.imageSrc
  } else {
    itemData.width = data.view3D.width
    itemData.height = data.view3D.height
    itemData.offsetX = data.view3D.offsetX
    itemData.offsetY = data.view3D.offsetY
    itemData.imageSrc = data.view3D.imageSrc
  }

  const [image] = useImage(itemData.imageSrc)

  return (
    <>
      <Image
        id={props.id}
        image={image}
        x={itemData.x + itemData.offsetX}
        y={itemData.y + itemData.offsetY}
        width={itemData.width}
        height={itemData.height}
        stroke={selected ? '#17b978' : ''}
        strokeWidth={selected ? 5 : 0}
        shadowColor='black'
        shadowBlur={10}
        shadowOffset={{ x: -10, y: 10 }}
        shadowOpacity={0.2}
        onMouseEnter={(e) => {
          if (itemData.type !== '10K' && itemData.type !== '10T') {
            const container = e.target.getStage().container()
            container.style.cursor = 'pointer'
          }
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'default'
        }}
        // draggable
        // onDragStart={(e) => {
        //   e.target.setAttrs({
        //     shadowColor: '#444',
        //     shadowBlur: 6,
        //     shadowOffset: {
        //       x: 3,
        //       y: 2,
        //     },
        //     scaleX: 1,
        //     scaleY: 1,
        //   })
        // }}
        // onDragEnd={(e) => {
        //   let newX =
        //     Math.round(e.target.x() / itemData.gridStepX) * itemData.gridStepX
        //   let newY =
        //     Math.round(e.target.y() / itemData.gridStepY) * itemData.gridStepY
        //   if (WallManager.IsPosAvailableMain(state, { x: newX, y: newY }, props.type)) {
        //     e.target.to({
        //       x: newX + itemData.offsetX,
        //       y: newY + itemData.offsetY,
        //       duration: 0.2,
        //       scaleX: 1,
        //       scaleY: 1,
        //       shadowColor: '#444',
        //       shadowBlur: 0,
        //       shadowOffsetX: 0,
        //       shadowOffsetY: 0,
        //     })

        //     dispatch(
        //       UPDATE_WALLITEM({
        //         id: props.id,
        //         pos: {
        //           x: newX,
        //           y: newY,
        //         },
        //         type: props.type,
        //         selected: props.selected,
        //         connections: props.connections,
        //       })
        //     )
        //   } else {
        //     e.target.to({
        //       x: itemData.x + itemData.offsetX,
        //       y: itemData.y + itemData.offsetY,
        //       duration: 0.2,
        //       scaleX: 1,
        //       scaleY: 1,
        //       shadowColor: '#444',
        //       shadowBlur: 0,
        //       shadowOffsetX: 0,
        //       shadowOffsetY: 0,
        //     })
        //   }
        // }}
      />
      {wall.slotsVisible && <Slots {...itemData} />}
      {wall.idVisible && <Id {...itemData} />}
      {wall.orderVisible && <Order {...itemData} />}
      {wall.clusterIdVisible && <ClusterId {...itemData} />}
    </>
  )
}

export default WallItem
