import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { React, useState } from 'react'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../Sidebar.css'
import { useDispatch, useSelector } from 'react-redux'
import { Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'

function Cart() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const dispatch = useDispatch()
  const wall = useSelector((state) => state.wall)
  return (
    <>
      {/* {wall.cart.view === 'detailed' && <CartDetailed />}{' '} */}
      <h5>
        <span class='tab-space'>
          <FontAwesomeIcon icon={faShoppingCart} />
        </span>
        Kosár
        <OverlayTrigger
          key='bottom'
          placement='bottom'
          overlay={<Tooltip>Részletes nézet</Tooltip>}
        >
          <Button
            size='sm'
            className='btn btn-rounded btn-dark ml-2 py-1 px-2'
            onClick={handleShow}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </OverlayTrigger>
      </h5>
      {/* <Button variant='primary' onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Részletes Kosár</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class='table cart-table table-striped'>
            <thead>
              <tr>
                <th scope='col'>Tétel</th>
                {/* <th scope='col'>Egységár</th> */}
                <th scope='col'>db</th>
                <th scope='col'>Teljes ár</th>
                <th scope='col'>Akciós ár</th>
              </tr>
            </thead>
            <tbody>
              {wall.cart.items
                .filter((item) => item.count > 0)
                .map((item) => (
                  <tr>
                    <td> {item.displayName}</td>
                    <td>{item.count}</td>
                    <td>
                      {Intl.NumberFormat('hu-HU', {
                        style: 'currency',
                        currency: 'HUF',
                        maximumFractionDigits: 0,
                      }).format(item.count * item.unitPrice)}
                    </td>
                    <td>
                      {Intl.NumberFormat('hu-HU', {
                        style: 'currency',
                        currency: 'HUF',
                        maximumFractionDigits: 0,
                      }).format(item.count * item.unitPrice * 0.8)}
                    </td>
                  </tr>
                ))}{' '}
            </tbody>
          </table>
          <table class='table cart-table table-striped'>
            <thead>
              <tr>
                <th colspan='4'>Összegzés</th>
              </tr>
            </thead>
            <tbody>
              <tr className='cart-price'>
                <td>Részösszeg</td>
                <td></td>
                <td></td>
                <td colspan='2'>
                  {Intl.NumberFormat('hu-HU', {
                    style: 'currency',
                    currency: 'HUF',
                    maximumFractionDigits: 0,
                  }).format(wall.cart.totalPrice)}
                </td>
              </tr>
              <tr className='cart-discount-amount'>
                <td>Kedvezmény</td>
                <td></td>
                <td></td>
                <td>
                  {' '}
                  {Intl.NumberFormat('hu-HU', {
                    style: 'currency',
                    currency: 'HUF',
                    maximumFractionDigits: 0,
                  }).format(wall.cart.totalPrice * 0.2)}
                </td>
              </tr>
              <tr className='cart-discount-price'>
                <td>Végösszeg</td>
                <td></td>
                <td></td>
                <td colspan='3'>
                  {' '}
                  {Intl.NumberFormat('hu-HU', {
                    style: 'currency',
                    currency: 'HUF',
                    maximumFractionDigits: 0,
                  }).format(wall.cart.discountPrice)}
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Bezárás
          </Button>
        </Modal.Footer>
      </Modal>
      <table className='table table-striped cart-table'>
        <thead>
          <tr>
            <th scope='col'>Tétel</th>
            <th scope='col'>db</th>
            <th scope='col'>Ár</th>
          </tr>
        </thead>
        <tbody>
          {wall.cart.groups
            .filter((group) => group.count > 0)
            .map((group) => (
              <tr>
                <td> {group.displayName}</td>
                <td>{group.count}</td>
                <td>
                  {' '}
                  {Intl.NumberFormat('hu-HU', {
                    style: 'currency',
                    currency: 'HUF',
                    maximumFractionDigits: 0,
                  }).format(group.totalPrice)}
                </td>
              </tr>
            ))}{' '}
          <tr className='cart-price'>
            <td>Részösszeg</td>
            <td></td>
            <td colspan='2'>
              {Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumFractionDigits: 0,
              }).format(wall.cart.totalPrice)}
            </td>
          </tr>
          <tr className='cart-discount-amount'>
            <td>Kedvezmény</td>
            <td></td>
            <td>
              {' '}
              {Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumFractionDigits: 0,
              }).format(wall.cart.totalPrice * 0.2)}
            </td>
          </tr>
          <tr className='cart-discount-price'>
            <td>Végösszeg</td>
            <td></td>
            <td colspan='2'>
              {' '}
              {Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumFractionDigits: 0,
              }).format(wall.cart.discountPrice)}
            </td>
          </tr>
        </tbody>
      </table>
      {/* <CartTotal /> */}
      {/* <CartMessage /> */}
    </>
  )
}

export default Cart
