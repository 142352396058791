const conflicts = require('./conflictsData.js').conflicts

export const items = [
  // 10K
  {
    type: '10K',
    id: '10K',
    dimension: { x: 0, y: 0 },
    conflicts: conflicts.filter((c) => c.type === '10K')[0].conflicts,
    view3D: {
      width: 35,
      height: 37,
      offsetX: -10,
      offsetY: 0,
      imageSrc: '../../images/WD/3D/10K.png',
    },
    view2D: {
      width: 52,
      height: 37,
      offsetX: -26,
      offsetY: 0,
      imageSrc: '../../images/WD/2D/10K.png',
    },
  },
  // 10T
  {
    type: '10T',
    id: '10T',
    dimension: { x: 0, y: 0 },
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 0, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10T')[0].conflicts,
    view3D: {
      width: 102,
      height: 74,
      offsetX: -76,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10T_3D.png',
    },
    view2D: {
      width: 52,
      height: 18,
      offsetX: -26,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10T.png',
    },
  },
  // 10KO2B
  {
    type: '10KO2B',
    id: '10KO2',
    level: 1,
    items: ['10KO2', '10POBA', '10POBF'],
    dimension: { x: 0, y: 2 },
    switch: { side: '10KO2J' },
    slots: [
      {
        direction: 'right',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 0, y: 2 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10KO2B')[0].conflicts,
    view3D: {
      width: 223,
      height: 761,
      offsetX: -192,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10KO2B_3D.png',
    },
    view2D: {
      width: 190,
      height: 720,
      offsetX: -164,
      offsetY: -20,
      imageSrc: '../../images/WD/2D/10KO2B.png',
    },
  },
  // 10KO2J
  {
    type: '10KO2J',
    id: '10KO2',
    level: 1,
    items: ['10KO2', '10POJA', '10POJF'],
    dimension: { x: 0, y: 2 },
    switch: { side: '10KO2B' },
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'left',
        dimension: { x: 0, y: 2 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10KO2J')[0].conflicts,
    view3D: {
      width: 218,
      height: 761,
      offsetX: -74,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10KO2J_3D.png',
    },
    view2D: {
      width: 190,
      height: 720,
      offsetX: -26,
      offsetY: -20,
      imageSrc: '../../images/WD/2D/10KO2B.png',
    },
  },
  // 10KO3B
  {
    type: '10KO3B',
    id: '10KO3',
    level: 1,
    items: ['10KO3', '10POBA', '10POBF'],
    dimension: { x: 0, y: 3 },
    switch: { side: '10KO3J' },
    slots: [
      {
        direction: 'right',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 0, y: 3 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10KO3B')[0].conflicts,
    view3D: {
      width: 218,
      height: 1109,
      offsetX: -192,
      offsetY: -18,
      imageSrc: '../../images/WD/3D/10KO2B.png',
    },
    view2D: {
      width: 190,
      height: 1068,
      offsetX: -164,
      offsetY: -20,
      imageSrc: '../../images/WD/2D/10KO2B.png',
    },
  },
  // 10KO3J
  {
    type: '10KO3J',
    id: '10KO3',
    level: 1,
    items: ['10KO3', '10POJA', '10POJF'],
    dimension: { x: 0, y: 3 },
    switch: { side: '10KO3B' },
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'left',
        dimension: { x: 0, y: 3 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10KO3J')[0].conflicts,
    view3D: {
      width: 218,
      height: 1109,
      offsetX: -54,
      offsetY: -18,
      imageSrc: '../../images/WD/3D/10KO2B.png',
    },
    view2D: {
      width: 190,
      height: 1068,
      offsetX: -26,
      offsetY: -20,
      imageSrc: '../../images/WD/2D/10KO2B.png',
    },
  },
  // 10P40
  {
    type: '10P40',
    id: '10P40',
    dimension: { x: 1, y: 0 },
    switch: {},
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 1, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10P40')[0].conflicts,
    view3D: {
      width: 502,
      height: 74,
      offsetX: -76,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10P40_3D.png',
    },
    view2D: {
      width: 452,
      height: 18,
      offsetX: -26,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10P40.png',
    },
  },
  {
    type: '10P40OB',
    id: '10P40O',
    dimension: { x: 1, y: 0 },
    switch: {},
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 1, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10P40OB')[0].conflicts,
    view3D: {
      width: 502,
      height: 74,
      offsetX: -76,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10P40OB_3D.png',
    },
    view2D: {
      width: 500,
      height: 632,
      offsetX: -74,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10P40OB.png',
    },
  },
  {
    type: '10P40OJ',
    id: '10P40O',
    dimension: { x: 1, y: 0 },
    switch: {},
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 1, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10P40OJ')[0].conflicts,
    view3D: {
      width: 502,
      height: 74,
      offsetX: -76,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10P40OJ_3D.png',
    },
    view2D: {
      width: 452,
      height: 18,
      offsetX: 26,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10P40OJ.png',
    },
  },
  {
    type: '10P80',
    id: '10P80',
    dimension: { x: 2, y: 0 },
    switch: {},
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 2, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10P80')[0].conflicts,
    view3D: {
      width: 902,
      height: 74,
      offsetX: -76,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10P80_3D.png',
    },
    view2D: {
      width: 852,
      height: 18,
      offsetX: -26,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10P40.png',
    },
  },
  {
    type: '10P80OB',
    id: '10P80O',
    dimension: { x: 2, y: 0 },
    switch: {},
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 2, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10P80OB')[0].conflicts,
    view3D: {
      width: 902,
      height: 74,
      offsetX: -76,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10P80OB_3D.png',
    },
    view2D: {
      width: 852,
      height: 18,
      offsetX: -26,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10P40.png',
    },
  },
  {
    type: '10P80OJ',
    id: '10P80O',
    dimension: { x: 2, y: 0 },
    switch: {},
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 2, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10P80OJ')[0].conflicts,
    view3D: {
      width: 902,
      height: 74,
      offsetX: -76,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10P80OJ_3D.png',
    },
    view2D: {
      width: 852,
      height: 18,
      offsetX: -26,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10P40.png',
    },
  },
  {
    type: '10PVB',
    id: '10PV',
    dimension: { x: 0, y: 0 },
    switch: { side: '10PVJ' },
    slots: [
      {
        direction: 'right',
        dimension: { x: 0, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10PVB')[0].conflicts,
    view3D: {
      width: 371,
      height: 351,
      offsetX: -283,
      offsetY: -241,
      imageSrc: '../../images/WD/3D/10PVB_3D.png',
    },
    view2D: {
      width: 190,
      height: 300,
      offsetX: -164,
      offsetY: -174,
      imageSrc: '../../images/WD/2D/10PVB.png',
    },
  },
  {
    type: '10PVJ',
    id: '10PV',
    dimension: { x: 0, y: 0 },
    switch: { side: '10PVB' },
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10PVJ')[0].conflicts,
    view3D: {
      width: 348,
      height: 339,
      offsetX: -128,
      offsetY: -230,
      imageSrc: '../../images/WD/3D/10PVJ_3D.png',
    },
    view2D: {
      width: 190,
      height: 300,
      offsetX: -26,
      offsetY: -174,
      imageSrc: '../../images/WD/2D/10PVJ.png',
    },
  },
  {
    type: '10PTB',
    id: '10PT',
    dimension: { x: 0, y: 0 },
    switch: { side: '10PTJ' },
    slots: [
      {
        direction: 'right',
        dimension: { x: 0, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10PTB')[0].conflicts,
    view3D: {
      width: 240,
      height: 97,
      offsetX: -214,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10PTB_3D.png',
    },
    view2D: {
      width: 240,
      height: 170,
      offsetX: -164,
      offsetY: -80,
      imageSrc: '../../images/WD/2D/10PTB.png',
    },
  },
  {
    type: '10PTJ',
    id: '10PT',
    dimension: { x: 0, y: 0 },
    switch: { side: '10PTB' },
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10PTJ')[0].conflicts,
    view3D: {
      width: 240,
      height: 97,
      offsetX: -74,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10PTJ_3D.png',
    },
    view2D: {
      width: 190,
      height: 170,
      offsetX: -26,
      offsetY: -80,
      imageSrc: '../../images/WD/2D/10PTJ.png',
    },
  },
  {
    type: '10PLJ',
    id: '10PL',
    dimension: { x: 0, y: 0 },
    switch: { side: '10PLB' },
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10PLJ')[0].conflicts,
    view3D: {
      width: 240,
      height: 74,
      offsetX: -74,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10PLJ_3D.png',
    },
    view2D: {
      width: 190,
      height: 18,
      offsetX: -26,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10PJ.png',
    },
  },
  {
    type: '10PLB',
    id: '10PL',
    dimension: { x: 0, y: 0 },
    switch: { side: '10PLJ' },
    slots: [
      {
        direction: 'right',
        dimension: { x: 0, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10PLB')[0].conflicts,
    view3D: {
      width: 240,
      height: 74,
      offsetX: -214,
      offsetY: -20,
      imageSrc: '../../images/WD/3D/10PLB_3D.png',
    },
    view2D: {
      width: 190,
      height: 18,
      offsetX: -164,
      offsetY: -18,
      imageSrc: '../../images/WD/2D/10PJ.png',
    },
  },
  {
    type: '10V40W_H',
    id: '10V40W',
    dimension: { x: 1, y: 0 },
    items: ['10V40W'],
    switch: { color: '10V40B' },
    conflicts: conflicts.filter((c) => c.type === '10V40W_H')[0].conflicts,
    view3D: {
      width: 394,
      height: 63,
      offsetX: -18,
      offsetY: -15,
      imageSrc: '../../images/WD/3D/10V40W_H_3D.png',
    },
    view2D: {
      width: 452,
      height: 6,
      offsetX: -26,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40W_H.svg',
    },
  },
  {
    type: '10V40W_VB',
    id: '10V40B',
    dimension: { x: 0, y: 1 },
    items: ['10V40W'],
    switch: { color: '10V40B_VB', side: '10V40W_VJ' },
    conflicts: conflicts.filter((c) => c.type === '10V40W_VB')[0].conflicts,
    view3D: {
      width: 50,
      height: 393,
      offsetX: -70,
      offsetY: -12,
      imageSrc: '../../images/WD/3D/10V40W_V_3D.png',
    },
    view2D: {
      width: 6,
      height: 348,
      offsetX: -29,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40W_V.svg',
    },
  },
  {
    type: '10V40W_VJ',
    id: '10V40W',
    dimension: { x: 0, y: 1 },
    items: ['10V40W'],
    switch: { color: '10V40B_VJ', side: '10V40W_VB' },
    conflicts: conflicts.filter((c) => c.type === '10V40W_VB')[0].conflicts,
    view3D: {
      width: 51,
      height: 400,
      offsetX: -20,
      offsetY: -12,
      imageSrc: '../../images/WD/3D/10V40W_V_3D.png',
    },
    view2D: {
      width: 6,
      height: 348,
      offsetX: 23,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40W_V.svg',
    },
  },
  {
    type: '10V50W_UP',
    id: '10V50W',
    dimension: { x: 1, y: -1 },
    items: ['10V50W'],
    switch: { color: '10V50B_UP' },
    conflicts: conflicts.filter((c) => c.type === '10V50W_UP')[0].conflicts,
    view3D: {
      width: 400,
      height: 388,
      offsetX: 0,
      offsetY: -370,
      imageSrc: '../../images/WD/3D/10V50W_UP_3D.png',
    },
    view2D: {
      width: 400,
      height: 348,
      offsetX: -26,
      offsetY: -338,
      imageSrc: '../../images/WD/2D/10V50W_UP.svg',
    },
  },
  {
    type: '10V50W_DOWN',
    id: '10V50W',
    dimension: { x: 1, y: 1 },
    items: ['10V50W'],
    switch: { color: '10V50B_DOWN' },
    conflicts: conflicts.filter((c) => c.type === '10V50W_DOWN')[0].conflicts,
    view3D: {
      width: 410,
      height: 396,
      offsetX: -30,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V50W_DOWN_3D.png',
    },
    view2D: {
      width: 400,
      height: 348,
      offsetX: -26,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V50W_DOWN_3D.svg',
    },
  },
  {
    type: '10V80W_H',
    id: '10V80W',
    dimension: { x: 2, y: 0 },
    items: ['10V80W'],
    switch: { color: '10V80B_H' },
    conflicts: conflicts.filter((c) => c.type === '10V80W_H')[0].conflicts,
    view3D: {
      width: 804,
      height: 68,
      offsetX: -24,
      offsetY: -17,
      imageSrc: '../../images/WD/3D/10V80W_H_3D.png',
    },
    view2D: {
      width: 852,
      height: 6,
      offsetX: -26,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40W_H.svg',
    },
  },
  {
    type: '10V80W_VB',
    id: '10V80W',
    dimension: { x: 0, y: 2 },
    items: ['10V80W'],
    switch: { color: '10V80B_VB', side: '10V80W_VJ' },
    conflicts: conflicts.filter((c) => c.type === '10V80W_VB')[0].conflicts,
    view3D: {
      width: 940,
      height: 35,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 6,
      height: 696,
      offsetX: -29,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40W_V.svg',
    },
  },
  {
    type: '10V80W_VJ',
    id: '10V80W',
    dimension: { x: 0, y: 2 },
    items: ['10V80W'],
    switch: { color: '10V80B_VJ', side: '10V80W_VB' },
    conflicts: conflicts.filter((c) => c.type === '10V80W_VJ')[0].conflicts,
    view3D: {
      width: 940,
      height: 35,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 6,
      height: 696,
      offsetX: 23,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40W_V.svg',
    },
  },
  {
    type: '10V40B_H',
    id: '10V40B',
    dimension: { x: 1, y: 0 },
    items: ['10V40B'],
    switch: { color: '10V40W_V' },
    conflicts: conflicts.filter((c) => c.type === '10V40B_H')[0].conflicts,
    view3D: {
      width: 385,
      height: 43,
      offsetX: -10,
      offsetY: -12,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 452,
      height: 6,
      offsetX: -26,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40B_H.svg',
    },
  },
  {
    type: '10V40B_VB',
    id: '10V40B',
    dimension: { x: 0, y: 1 },
    items: ['10V40B'],
    switch: { color: '10V40W_VB', side: '10V40B_VJ' },
    conflicts: conflicts.filter((c) => c.type === '10V40B_VB')[0].conflicts,
    view3D: {
      width: 385,
      height: 43,
      offsetX: -10,
      offsetY: -12,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 6,
      height: 348,
      offsetX: -29,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40B_V.svg',
    },
  },
  {
    type: '10V40B_VJ',
    id: '10V40B',
    dimension: { x: 0, y: 1 },
    items: ['10V40B'],
    switch: { color: '10V40W_VJ', side: '10V40B_VB' },
    conflicts: conflicts.filter((c) => c.type === '10V40B_VJ')[0].conflicts,
    view3D: {
      width: 385,
      height: 43,
      offsetX: -10,
      offsetY: -12,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 6,
      height: 348,
      offsetX: 23,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40B_V.svg',
    },
  },
  {
    type: '10V50B_UP',
    id: '10V50B',
    dimension: { x: 1, y: -1 },
    items: ['10V50B'],
    switch: { color: '10V50W_UP' },
    conflicts: conflicts.filter((c) => c.type === '10V50B_UP')[0].conflicts,
    view3D: {
      width: 540,
      height: 35,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 400,
      height: 348,
      offsetX: -26,
      offsetY: -338,
      imageSrc: '../../images/WD/2D/10V50B_UP.svg',
    },
  },
  {
    type: '10V50B_DOWN',
    id: '10V50B',
    dimension: { x: 1, y: 1 },
    items: ['10V50B'],
    switch: { color: '10V50W_DOWN' },
    conflicts: conflicts.filter((c) => c.type === '10V50B_DOWN')[0].conflicts,
    view3D: {
      width: 540,
      height: 35,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 400,
      height: 348,
      offsetX: -26,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V50B_DOWN.svg',
    },
  },
  {
    type: '10V80B_H',
    id: '10V80B',
    dimension: { x: 2, y: 0 },
    switch: { color: '10V80W_H' },
    items: ['10V80B'],
    conflicts: conflicts.filter((c) => c.type === '10V80B_H')[0].conflicts,
    view3D: {
      width: 940,
      height: 35,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 852,
      height: 6,
      offsetX: -26,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40B_H.svg',
    },
  },
  {
    type: '10V80B_VB',
    id: '10V80B',
    dimension: { x: 0, y: 2 },
    switch: { color: '10V80W_VB', side: '10V80B_VJ' },
    items: ['10V80B'],
    conflicts: conflicts.filter((c) => c.type === '10V80B_VB')[0].conflicts,
    view3D: {
      width: 940,
      height: 35,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 6,
      height: 696,
      offsetX: -29,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40B_V.svg',
    },
  },
  {
    type: '10V80B_VJ',
    id: '10V80B',
    dimension: { x: 0, y: 2 },
    switch: { color: '10V80W_VJ', side: '10V80B_VB' },
    items: ['10V80B'],
    conflicts: conflicts.filter((c) => c.type === '10V80B_VJ')[0].conflicts,
    view3D: {
      width: 940,
      height: 35,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10V40B.png',
    },
    view2D: {
      width: 6,
      height: 696,
      offsetX: 23,
      offsetY: -10,
      imageSrc: '../../images/WD/2D/10V40B_V.svg',
    },
  },
  {
    type: 'cat',
    conflicts: conflicts.filter((c) => c.type === 'cat')[0].conflicts,
    view3D: {
      width: 300,
      height: 300,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/cat.png',
    },
    view2D: {
      width: 200,
      height: 103,
      offsetX: 100,
      offsetY: -121,
      imageSrc: '../../images/WD/2D/cat.png',
    },
  },
  {
    type: '10KL',
    id: '10KL',
    dimension: { x: 0, y: 0 },
    conflicts: conflicts.filter((c) => c.type === '10KL')[0].conflicts,
    view3D: {
      width: 132,
      height: 134,
      offsetX: -90,
      offsetY: -40,
      imageSrc: '../../images/WD/3D/10KL_3D.png',
    },
    view2D: {
      width: 100,
      height: 100,
      offsetX: -50,
      offsetY: -50,
      imageSrc: '../../images/WD/2D/10KL.png',
    },
  },
  {
    type: '10PP40W',
    id: '10PP40W',
    dimension: { x: 1, y: 0 },
    switch: { color: '10PP40B' },
    conflicts: conflicts.filter((c) => c.type === '10PP40W')[0].conflicts,
    view3D: {
      width: 330,
      height: 310,
      offsetX: -110,
      offsetY: -233,
      imageSrc: '../../images/WD/3D/10PP40W.svg',
    },
    view2D: {
      width: 452,
      height: 35,
      offsetX: -26,
      offsetY: -53,
      imageSrc: '../../images/WD/2D/10PP40W.svg',
    },
  },
  {
    type: '10PP40B',
    id: '10PP40B',
    dimension: { x: 1, y: 0 },
    switch: { color: '10PP40W' },
    conflicts: conflicts.filter((c) => c.type === '10PP40B')[0].conflicts,
    view3D: {
      width: 330,
      height: 310,
      offsetX: -110,
      offsetY: -233,
      imageSrc: '../../images/WD/3D/10PP40B.svg',
    },
    view2D: {
      width: 452,
      height: 35,
      offsetX: -26,
      offsetY: -53,
      imageSrc: '../../images/WD/2D/10PP40B.svg',
    },
  },
  {
    type: '10H0K',
    id: '10H0K',
    dimension: { x: 2, y: 0 },
    conflicts: conflicts.filter((c) => c.type === '10H0K')[0].conflicts,
    view3D: {
      width: 852,
      height: 150,
      offsetX: -110,
      offsetY: -10,
      imageSrc: '../../images/WD/3D/10H0K.svg',
    },
    view2D: {
      width: 852,
      height: 150,
      offsetX: 26,
      offsetY: -70,
      imageSrc: '../../images/WD/2D/10H1K.svg',
    },
  },
  {
    type: '10H1K',
    id: '10H1K',
    dimension: { x: 2, y: 0 },
    slots: [
      {
        direction: 'left',
        dimension: { x: 0, y: 0 },
        free: true,
      },
      {
        direction: 'right',
        dimension: { x: 2, y: 0 },
        free: true,
      },
    ],
    conflicts: conflicts.filter((c) => c.type === '10H1K')[0].conflicts,
    view3D: {
      width: 902,
      height: 230,
      offsetX: -76,
      offsetY: -56,
      imageSrc: '../../images/WD/3D/10H1K_3D.png',
    },
    view2D: {
      width: 852,
      height: 150,
      offsetX: -26,
      offsetY: -70,
      imageSrc: '../../images/WD/2D/10H1K.svg',
    },
  },
]
